<template>
  <v-app>
    <SideMenu :alert="alert" :alert_type="alert_type" :message="message" submenuPage="Services" submenuGroup="Service Setup">
      <List>
        <template v-slot:action-button>
          <v-btn
            color="primary"
            class="mr-4"
            @click="ServiceCreateForm"
            v-if="staffPermission.createService"
          >
            + New Service
          </v-btn>
        </template>
        <template v-slot:tabs></template>
        <template v-slot:tab-items>
          <v-data-table
            :headers="headers"
            :items="services"      
            class="elevation-1"
            item-key="name"
          >
          <template v-slot:item="{ item }">
            <tr @click="ServiceUpdateForm(item.id)" style="cursor:pointer">
              <td><span class="dot" :style="{ backgroundColor: item.color }"></span> {{ item.name }}</td>
              <td>{{ item.service_type_name }}</td>
              <td>{{ item.price.toFixed(2) }}</td>
            </tr>
          </template>
          </v-data-table>
        </template>
      </List>
    </SideMenu>
  </v-app>
</template>

<style>
.dot {
  height: 10px;
  width: 10px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}
</style>

<script>
import SideMenu from '/src/components/SideMenu'
import List from '/src/components/List'
import gql from 'graphql-tag'
import * as queries from '@/graphql/gqlQueries.js';
  const GET_ALL_SERVICES_QUERY = gql`
      query Services {
        services {
          id
          name
          created_at
          color
          price
          service_type{
            name
          }
        }
      }
  `
  export default {
    name: 'Service',

    components:{
      SideMenu,
      List
    },

    data () {
      
      return {
        services: [],
        alert: this.$route.params.alert,
        alert_type: this.$route.params.alert_type,
        message: this.$route.params.message,

        headers:[
          {
            text: 'Name',
            value: 'name',
            class: 'white--text'
          },
          {
            text: 'Service Type',
            value: 'service_type_name',
            class: 'white--text'
          },
          {
            text: 'Price (RM)',
            value: 'price',
            class: 'white--text'
          }
        ],
        getAllPermissions: [],
        allPermissionsName: [],
        staffPermission: {},
      }
    },
    apollo: {
      services: {
        query: GET_ALL_SERVICES_QUERY,
        pollInterval: 300,
        async result() {
          const sortProperties = [
                    { property: 'id', direction: 'desc' },
                    { property: 'created_at', direction: 'desc' },
                ];

          // Use the multiPropertySort function to sort the commissionList
          this.services = await this.$generalFunctions.multiPropertySort(this.services, sortProperties);
          var service_list = []
          for(let i=0; i<this.services.length; i++){
            service_list.push(
              {
                id: this.services[i].id,
                name: this.services[i].name,
                service_type_name: this.services[i].service_type.name,
                color: this.services[i].color,
                price: this.services[i].price
              }
            )
          }
          this.services = service_list
        }
      },
    },
    methods: {
      ServiceUpdateForm(id) {
        this.$router.push({ path: '/service/'+id })
      },
      ServiceCreateForm() {
        this.$router.push({ path: '/create/service' })
      }
    },
    created(){
      this.$apollo.query({ query: queries.GET_ALL_PERMISSIONS_QUERY }).then(async result => {
        this.getAllPermissions = await result.data.getAllPermissions;
        this.$apollo.query(
          {
            query: queries.GET_STAFF_ROLES,
            variables: {
              staffId: this.$session.get("user_id") * 1
            }
          }
        ).then(async result => {
          this.getStaffRoles = await result.data.getStaffRoles;
          this.allPermissionsName = [];
          this.getStaffRoles.forEach(role => {
            role.role.RolePermission.forEach((item) => {
              const name = item.permission.name;
              this.allPermissionsName.push(name);
            });
          })
          this.$session.set('staffPermission', this.$generalFunctions.generatePermissionArray(this.getAllPermissions, this.allPermissionsName));
          this.staffPermission = await this.$generalFunctions.generatePermissionArray(this.getAllPermissions, this.allPermissionsName)
        })
      })
    }
  }
</script>
