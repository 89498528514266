<template>
  <v-app>
    <SideMenu submenuPage="Staff">
      <Form>
        <template v-slot:tabs>
          <v-tabs
            v-model="tab"
          >
            <v-tabs-slider></v-tabs-slider>
            <v-tab href="#details">Details</v-tab>
            <v-tab href="#outlet">Outlet</v-tab>
            <v-tab href="#service">Service</v-tab>
          </v-tabs>
        </template>
        <template v-slot:form>
          <v-tabs-items v-model="tab">

            <v-tab-item value="details">
              <v-form
                ref="form"
                lazy-validation
              >
                <v-text-field
                  v-model="staffById.fullname"
                  :counter="191"
                  :rules="nameRules.concat(characterRules)"
                  label="Full Name"
                  required
                ></v-text-field>

                <v-text-field
                  v-model="staffById.email"
                  :counter="191"
                  :rules="emailRules"
                  label="Email"
                ></v-text-field>

                <v-text-field
                  v-model="staffById.password"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showPassword ? 'text' : 'password'"
                  name="input-10-1"
                  hint="At least 8 characters"
                  counter
                  @click:append="showPassword = !showPassword"
                  :rules="passwordRules"
                  label="Password"
                ></v-text-field>

                <v-text-field
                  v-model="staffById.designation"
                  :counter="191"
                  :rules="characterRules"
                  label="Designation"
                ></v-text-field>

                <v-text-field
                  v-model="staffById.contact"
                  label="Phone Number"
                ></v-text-field>

                <v-select
                  v-model="staffById.status"
                  :items="status"
                  item-value="value"
                  item-text="label"
                  label="Status"
                ></v-select>
                
                <!-- <v-checkbox
                  v-model="staffById.admin"
                  label="Admin"
                ></v-checkbox> -->

                <!-- <v-checkbox
                  v-model="staffById.supervisor"
                  label="Supervisor"
                ></v-checkbox> -->

                <p class="p-0 m-0">Roles</p>
                <v-row>
                  <v-col cols="4" class="py-1" v-for="role of getRoles" :key="role.id">
                    <v-checkbox 
                      v-model="staffById.roles"
                      :label = "role.name"
                      :value = "role.id"
                      :hint = "role.description"
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-form>
            </v-tab-item>


            <v-tab-item value="outlet">
              <v-form
                ref="form2"
                lazy-validation
              >
                <v-checkbox
                  v-model="staffById.outlets"
                  v-for="outlet in outlets"
                  :key="outlet.id"
                  :label="outlet.name"
                  :value="outlet.id"
                ></v-checkbox>
              </v-form>
            </v-tab-item>

            
            <v-tab-item value="service">
              <v-form
                ref="form3"
                lazy-validation
              >
              <v-row class="align-items-baseline">
                <v-col class="py-0" cols="4">
                  General Commission: 
                </v-col>
                <v-col cols="2" class="py-0">
                  <v-select
                    v-model="generalCommission.type"
                    :items="[{name:'Fixed',label:'fixed'},{name:'Percentage',label:'%'}]"
                    label="Type"
                    item-text="label"
                    item-value="name"
                    hide-details
                    height="min-content"
                  ></v-select>
                </v-col>
                <v-col cols="3" class="py-0">
                  <v-text-field
                    v-model="generalCommission.amount"
                    label="Amount"
                    :rules="amountRules"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row v-for="service in services" class="align-items-baseline"
                    :key="service.id">
                <v-col cols="3" class="py-0">
                  <v-checkbox
                    v-model="staffById.services"
                    :label="service.name"
                    :value="service.id"
                  ></v-checkbox>
                </v-col>
                <v-col cols="3" class="py-0" v-if="checkServiceSelected(service.id)">
                  <v-checkbox
                    v-model="staffById.commissions"
                    :value="service.id"
                    label="Custom Commission"
                    @change="updateCustomCommission(service.id, $event)"
                  ></v-checkbox>
                </v-col>
                <v-col cols="2" class="py-0" v-if="checkCommissionSelected(service.id)">
                  <v-select
                    :items="[{name:'Fixed',label:'fixed'},{name:'Percentage',label:'%'}]"
                    label="Type"
                    item-text="label"
                    item-value="name"
                    value="Fixed"
                    hide-details
                    height="min-content"
                    @change="updateCustomCommissionType(service.id, $event)"
                  ></v-select>
                </v-col>
                <v-col cols="3" class="py-0" v-if="checkCommissionSelected(service.id)">
                  <v-text-field
                    label="Amount"
                    value="0"
                    @change="updateCustomCommissionAmount(service.id, $event)"
                    :rules="amountRules"
                  ></v-text-field>
                </v-col>
              </v-row>
              </v-form>
            </v-tab-item>

          </v-tabs-items>
        </template>



        <template v-slot:buttons>
          <v-btn
            class="mr-4"
            text
            @click="$router.push({ path: '/staffs' })"
          >
            Back to list
          </v-btn>
        
          <v-btn color="success" v-if="isNext" class="mr-4" @click="validateTab(tab)">
            Next
          </v-btn>
          <v-btn color="success" v-if="!isNext" class="mr-4" @click="validate">
            Create
          </v-btn>


        </template>
      </Form>
    </SideMenu>
  </v-app>
</template>

<script>
import SideMenu from '/src/components/SideMenu'
import Form from '/src/components/Form'
import gql from 'graphql-tag'
  const GET_ALL_OUTLETS_QUERY = gql`
      query Outlets {
        outlets {
          id
          name
          created_at
        }
      }
  `
  const GET_ALL_SERVICES_QUERY = gql`
      query Services {
        services {
          id
          name
          created_at
          color
          service_type{
            name
          }
        }
      }
  `
  const GET_ALL_ROLES_QUERY = gql`
        query getRoles {
            getRoles {
            id
            name
            description
          }
        }
    `
  export default {
    name: 'Staff',

    components:{
      SideMenu,
      Form
    },

    data () {
      
      return {
        staffById: {
          fullname: '',
          designation: '',
          contact: '',
          email: '',
          status: 'pending',
          password: '',
          outlets: [],
          services: [],
          commissions: [],
          roles:[]
        },
        showPassword: false,
        
        nameRules: [
          v => !!v || 'Name is required',
        ],
        amountRules: [
          v => !!v || 'Amount is required',
          v => /^\d+(\.\d+)?$/.test(v) || 'Amount must be a number'
        ],
        characterRules: [
          v => (v.length <= 191) || 'Field must be less than or equal to 191 characters',
        ],
        emailRules: [
          v => !!v || 'E-mail is required',
          v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
        ],
        passwordRules: [
          v => !!v || 'Password is required',
          v => (v.length >= 8) || 'Password need at least 8 characters',
        ],
        status: [
          {value:'pending', label: 'Pending'},
          {value:'active', label: 'Active'},
          {value:'suspended', label: 'Suspended'}
        ],
        outlets: [],
        services: [],
        tab: null,
        getRoles: [],
        customCommission: [],
        generalCommission:{
          type: 'Fixed',
          amount: 0
        },
        isNext: true,
      }
    },
    apollo: {
      outlets: {
        query: GET_ALL_OUTLETS_QUERY,
      },
      services: {
        query: GET_ALL_SERVICES_QUERY,
      },
      getRoles: {
            query: GET_ALL_ROLES_QUERY,
            pollInterval: 300,
            fetchPolicy: 'network-only',
        }
    },
    methods: {

      createStaff() {
      if (!this.staffById.fullname || !this.staffById.email || !this.staffById.password) {
        
        alert("Please fill in staff name, email, and password");
        return; 
      }
    },


      validateTab(tab) {
      switch (tab) {
        case "details":
          var validate = this.$refs.form.validate();
          if (!validate) {
            window.scrollTo(0, 0);
          } else {
            this.tab = "outlet";
          }
          break;
        case "outlet":
          this.tab = "service"; 
          this.isNext = false;

          break;
        default:
          this.isNext = false;
     
          break;
      }
    },

      validate () {
        
        const commissionList = [];

        this.staffById.services.forEach(serviceId => {
          const customCommission = this.customCommission.find(item => item.serviceId === serviceId);

          if (customCommission) {
            commissionList.push(customCommission);
          } else {
            const generalCommission = {
              serviceId,
              type: this.generalCommission.type,
              amount: this.generalCommission.amount
            };
            commissionList.push(generalCommission);
          }
        });
        console.log("commissionList",commissionList)
        var validate = this.$refs.form.validate()
        if(!validate){
          window.scrollTo(0,0);
          this.tab = "details";
              alert("Please ensure you have provided the following information:\n\n- Staff Name\n- Email\n- Password\n- Phone Number\n\nThese fields are required for creating a new staff member.");

        }
        var passwordHash = require('password-hash')
        if(validate){
          const CREATE_USER_QUERY = gql`
            mutation createStaff($email:String!, $password:String!, $fullname:String!, $designation: String, $contact:String, $status:String, $admin:Boolean, $supervisor:Boolean, $commissionType: CommissionType, $commissionAmount: Float) {
                createStaff(email:$email, password:$password, fullname:$fullname, designation: $designation, contact: $contact, status: $status, admin:$admin, supervisor:$supervisor,commissionType:$commissionType,commissionAmount:$commissionAmount)  {
                  id
                  fullname
                }
            }
          `
          this.$apollo.mutate(
            { 
              mutation: CREATE_USER_QUERY, 
              variables:{
                email: this.staffById.email,
                password: passwordHash.generate(this.staffById.password),
                fullname: this.staffById.fullname,
                designation: this.staffById.designation,
                contact: this.staffById.contact,
                status: this.staffById.status,
                admin: this.staffById.admin,
                supervisor: this.staffById.supervisor,
                commissionAmount: this.generalCommission.amount * 1,
                commissionType: this.generalCommission.type ? this.generalCommission.type.toLowerCase() : 'fixed',
              },
            }
          )
          .catch(error => {
            console.log(error)
          })
          .then(result => {
            if(result && result.data.createStaff != null){ 
              console.log("Create successfully")
              
              const CREATE_STAFF_OUTLET_QUERY = gql`
                mutation createStaffOutlet($staff_id:Int!, $outlet_id:Int!) {
                    createStaffOutlet(staff_id:$staff_id, outlet_id:$outlet_id)  {
                      staff_id
                    }
                }
              `
              const CREATE_STAFF_SERVICE_QUERY = gql`
                mutation createStaffService($staff_id:Int!, $service_id:Int!) {
                    createStaffService(staff_id:$staff_id, service_id:$service_id)  {
                      staff_id
                    }
                }
              `
              const CREATE_STAFF_ROLES_QUERY = gql`
                mutation createStaffRole($staffId:Int!, $roleId:Int!) {
                  createStaffRole(staffId:$staffId, roleId:$roleId)  {
                      roleId
                      staffId
                    }
                }
              `
              const CREATE_STAFF_COMMISSION = gql`
                mutation CreateStaffCommission($staff_id: Int!, $service_id: Int!, $commission: Float!, $type: CommissionType!) {
                  createStaffCommission(staff_id: $staff_id, service_id: $service_id, commission: $commission, type: $type) {
                    staff_id
                    service_id
                    commission
                    type
                  }
                }
              `;

              if (commissionList.length > 0) {
                for(let i = 0; i < commissionList.length; i++){
                  this.$apollo.mutate(
                    { 
                      mutation: CREATE_STAFF_COMMISSION, 
                      variables:{
                        staff_id: result.data.createStaff.id*1,
                        service_id: commissionList[i].serviceId*1 ,
                        commission: commissionList[i].amount * 1 ,
                        type: commissionList[i].type ? commissionList[i].type.toLowerCase() : 'fixed',
                      },
                    }
                  ).catch(error=>{
                    console.log(JSON.stringify(error, null, 2));
                  })
                } 
              }

              for(let i = 0; i < this.staffById.outlets.length; i++){
                this.$apollo.mutate(
                  { 
                    mutation: CREATE_STAFF_OUTLET_QUERY, 
                    variables:{
                      staff_id: result.data.createStaff.id*1,
                      outlet_id: this.staffById.outlets[i]*1
                    },
                  }
                )
              }
              for(let i = 0; i < this.staffById.services.length; i++){
                this.$apollo.mutate(
                  { 
                    mutation: CREATE_STAFF_SERVICE_QUERY, 
                    variables:{
                      staff_id: result.data.createStaff.id*1,
                      service_id: this.staffById.services[i]*1
                    },
                  }
                )
              }
              for(let i = 0; i < this.staffById.roles.length; i++){
                this.$apollo.mutate(
                  { 
                    mutation: CREATE_STAFF_ROLES_QUERY, 
                    variables:{
                      staffId: result.data.createStaff.id*1,
                      roleId: this.staffById.roles[i]*1
                    },
                  }
                )
              }

              const staffFullName = result.data.createStaff.fullname;
                this.$router.push({
                  name: 'Staff', 
                  query: {
                    alert: true,
                    alert_type: 'success',
                    message: `${staffFullName} has been created successfully.`
                  }
                });
              this.$router.push({name: 'Staff', params:{alert:true, alert_type:'success', message:result.data.createStaff.fullname+' created successfully'}})
              
            } else {
              this.tab = "details";
              alert("Please ensure you have provided the following information:\n\n- Staff Name\n- Email\n- Password\n- Phone Number\n\nThese fields are required for creating a new staff member.");

             // alertMessage: "Please ensure you have provided the following information:\n\n- Staff Name\n- Email\n- Password\n\nThese fields are required for creating a new staff member."
            }
          })
        }
      },
      updateCustomCommission(serviceId, isChecked) {
        if (isChecked.includes(serviceId)) {
          this.customCommission.push({
            serviceId,
            type: null,
            amount: 0
          });
        } else {
          const index = this.customCommission.findIndex(
            item => item.serviceId === serviceId
          );
          if (index !== -1) {
            this.customCommission.splice(index, 1);
          }
        }
      },
      updateCustomCommissionType(serviceId, value) {
        console.log(value)
        const customCommission = this.customCommission.find(
          item => item.serviceId === serviceId
        );
        if (customCommission) {
          customCommission.type = value;
        }
      },
      updateCustomCommissionAmount(serviceId, value) {
        const customCommission = this.customCommission.find(
          item => item.serviceId === serviceId
        );
        if (customCommission) {
          const parsedAmount = parseFloat(value);
          const isValidAmount = !isNaN(parsedAmount) && isFinite(parsedAmount);
          if(isValidAmount)customCommission.amount = parseFloat(value);
        }
      }
    },
    computed:{
      checkServiceSelected() {
        return function(serviceId) {
          return this.staffById.services.includes(serviceId);
        };
      },
      checkCommissionSelected() {
        return function(serviceId) {
          return this.staffById.commissions.includes(serviceId) && this.staffById.services.includes(serviceId);
        };
      }
    }
  }
</script>
