<template>
  <v-app>
    <SideMenu :alert="alert" :alert_type="alert_type" :message="message" submenuPage="Service Type" submenuGroup="Service Setup">
      <v-alert
        :type="alert_type || 'info'"
        dismissible
        v-model="alert"
      >{{ message }}</v-alert>
      <Form>
        <template v-slot:tabs>

        </template>
        <template v-slot:form>
          <v-form
            ref="form"
            lazy-validation
          >
            <v-text-field
              v-model="serviceTypeById.name"
              :counter="191"
              :rules="nameRules.concat(characterRules)"
              label="Name"
              required
            ></v-text-field>

            <v-text-field
              v-model="serviceTypeById.description"
              :counter="191"
              :rules="characterRules"
              label="Description"
            ></v-text-field>
          </v-form>
        </template>
        <template v-slot:buttons>
          <v-btn
            class="mr-4"
            text
            @click="$router.push({ path: '/type/services' })"
          >
            Back to list
          </v-btn>
          <v-dialog
              max-width="500"
              v-if="staffPermission.deleteServiceType"
          >
              <template v-slot:activator="{ on, attrs }">
              <v-btn
                  color="error"
                  v-bind="attrs"
                  v-on="on"
                  class="mr-4" 
              >Delete</v-btn>
              </template>
              <template v-slot:default="dialog">
              <v-card>
                  <v-card-title>
                      Are you sure to delete {{ serviceTypeById.name }}?
                  </v-card-title>
                  <v-card-actions class="justify-end">
                  <v-btn
                      text
                      @click="dialog.value = false"
                  >Close</v-btn>
                  <v-btn color="error" @click="deleteServiceType();dialog.value = false">
                      Delete
                  </v-btn>
                  </v-card-actions>
              </v-card>
              </template>
          </v-dialog>
          <v-btn
            color="success"
            class="mr-4"
            @click="validate"
            v-if="staffPermission.updateServiceType"
          >
            Update
          </v-btn>
        </template>
      </Form>
    </SideMenu>
  </v-app>
</template>

<script>
import SideMenu from '/src/components/SideMenu'
import Form from '/src/components/Form'
import gql from 'graphql-tag'
import * as queries from '@/graphql/gqlQueries.js';
  const GET_SERVICE_TYPE_QUERY = gql`
      query ServiceType($id: ID!) {
        serviceTypeById(id: $id) {
            name
            description
        }
      }
  `
  export default {
    name: 'Service',

    components:{
      SideMenu,
      Form
    },

    data () {
      
      return {
        serviceTypeById: {
          name: '',
          description: '',
          price: '',
          duration: '',
          color: '',
          price_type: 'single',
          service_type_id: '',
          padding_time: ''
        },
        
        nameRules: [
          v => !!v || 'Name is required',
        ],
        characterRules: [
          v => (v.length <= 191) || 'Field must be less than or equal to 191 characters',
        ],
        routeParam: this.$route.params.id,
        alert: this.$route.params.alert_type?true:false,
        alert_type: this.$route.params.alert_type,
        message: this.$route.params.message,
        getAllPermissions: [],
        allPermissionsName: [],
        staffPermission: {},
      }
    },
    apollo: {
      serviceTypeById: {
        query: GET_SERVICE_TYPE_QUERY,
        variables() {
          return{
            id: this.routeParam
          }
        }
      },
    },
    methods: {
      validate () {
        var validate = this.$refs.form.validate()
        if(!validate){
          window.scrollTo(0,0);
        }
        if(validate){
          const UPDATE_SERVICE_TYPE_QUERY = gql`
            mutation updateServiceType($id: ID!, $name: String!, $description:String) {
                updateServiceType(id:$id, name:$name, description:$description)  {
                  name
                }
            }
          `

          this.$apollo.mutate(
            { 
              mutation: UPDATE_SERVICE_TYPE_QUERY, 
              variables:{
                id: this.routeParam,
                name: this.serviceTypeById.name,
                description: this.serviceTypeById.description,
              },
            }
          ).then(result => {
            if(result.data.updateServiceType != null){ 
              console.log("Update successfully")
              this.alert = true
              this.alert_type='success'
              this.message = result.data.updateServiceType.name +' updated successfully'
              window.scrollTo(0,0)
            } else {
              console.log("Update failed")
              this.alert = true
              this.alert_type='error'
              this.message = 'Updated fail'
              window.scrollTo(0,0)
            }
          });
        }
      },
      deleteServiceType() {
        const DELETE_SERVICE_TYPE_QUERY = gql`
          mutation deleteServiceType($id:ID!) {
              deleteServiceType(id:$id)  {
                name
              }
          }
        `
        this.$apollo.mutate(
          { 
            mutation: DELETE_SERVICE_TYPE_QUERY, 
            variables:{
              id: this.routeParam,
            },
          }
        ).then(result => {
          if(result.data.deleteServiceType != null){ 
            console.log("Delete successfully")
            this.$router.push({name: 'Service Type', params:{alert:true, alert_type:'success', message:result.data.deleteServiceType.name+' deleted successfully'}})
          } else {
            console.log("Delete failed")
          }
        });
      }
    },
    created(){
      this.$apollo.query({ query: queries.GET_ALL_PERMISSIONS_QUERY }).then(async result => {
        this.getAllPermissions = await result.data.getAllPermissions;
        this.$apollo.query(
          {
            query: queries.GET_STAFF_ROLES,
            variables: {
              staffId: this.$session.get("user_id") * 1
            }
          }
        ).then(async result => {
          this.getStaffRoles = await result.data.getStaffRoles;
          this.allPermissionsName = [];
          this.getStaffRoles.forEach(role => {
            role.role.RolePermission.forEach((item) => {
              const name = item.permission.name;
              this.allPermissionsName.push(name);
            });
          })
          this.$session.set('staffPermission', this.$generalFunctions.generatePermissionArray(this.getAllPermissions, this.allPermissionsName));
          this.staffPermission = await this.$generalFunctions.generatePermissionArray(this.getAllPermissions, this.allPermissionsName)
        })
      })
    }
  }
</script>
