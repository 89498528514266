<template>
  <v-app>
    <SideMenu :alert="alert" :alert_type="alert_type" :message="message" submenuPage="Sessions" submenuGroup="Outlet Setup">
      <v-alert
        :type="alert_type || 'info'"
        dismissible
        v-model="alert"
      >{{message}}</v-alert>
      <Form>
        <template v-slot:tabs>
          <v-tabs
            v-model="tab"
          >
            <v-tabs-slider></v-tabs-slider>
            <v-tab href="#details">Details</v-tab>
            <v-tab href="#outlet">Outlet</v-tab>
          </v-tabs>
        </template>
        <template v-slot:form>
          <v-tabs-items v-model="tab">
            <v-tab-item value="details">
              <v-form
                ref="form"
                lazy-validation
              >
                <v-text-field
                  v-model="sessionsById.name"
                  :counter="191"
                  :rules="nameRules.concat(characterRules)"
                  label="Name"
                  required
                ></v-text-field>

                <v-select
                  v-model="type"
                  :items="types"
                  item-value="name"
                  item-text="name"
                  label="Type"
                  @change="changeType(type)"
                ></v-select>

                <v-select
                  v-if="type=='Day'"
                  v-model="sessionsById.day"
                  :items="dates"
                  item-value="id"
                  item-text="name"
                  label="Day"
                  required
                ></v-select>

                <div v-if="type=='Date'">
                  Date
                </div>
                <datepicker 
                  v-if="type=='Date'"
                  v-model="sessionsById.date" 
                  name="Date"
                  placeholder="Select Date"
                  input-class="datepicker"
                ></datepicker>

                <v-menu
                  v-if="!sessionsById.off_day"
                  ref="from_time_menu"
                  v-model="from_time_menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="sessionsById.from_time"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="sessionsById.from_time"
                      label="From Time"
                      prepend-icon="mdi-clock-time-four-outline"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-model="sessionsById.from_time"
                    :allowed-minutes="allowedStep"
                    class="mt-4"
                    format="24hr"
                    @click:minute="$refs.from_time_menu.save(sessionsById.from_time)"
                  ></v-time-picker>
                </v-menu>

                <v-menu
                  v-if="!sessionsById.off_day"
                  ref="to_time_menu"
                  v-model="to_time_menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="sessionsById.to_time"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="sessionsById.to_time"
                      label="To Time"
                      prepend-icon="mdi-clock-time-four-outline"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-model="sessionsById.to_time"
                    :allowed-minutes="allowedStep"
                    class="mt-4"
                    format="24hr"
                    @click:minute="$refs.to_time_menu.save(sessionsById.to_time)"
                  ></v-time-picker>
                </v-menu>
                <v-alert
                  v-if="!sessionsById.off_day && sessionsById.from_time >= sessionsById.to_time && sessionsById.from_time && sessionsById.to_time"
                  type="error"
                  class="mt-2"
                  dense
                >
                  From Time must be smaller than To Time
                </v-alert>

                <v-checkbox
                  v-model="sessionsById.off_day"
                  label="Off Day"
                ></v-checkbox>
              </v-form>
            </v-tab-item>
            <v-tab-item value="outlet">
              <v-form
                ref="form2"
                lazy-validation
              >
                <v-checkbox
                  v-model="session_outlets"
                  v-for="outlet in outlets"
                  :key="outlet.id"
                  :label="outlet.name"
                  :value="outlet.id"
                ></v-checkbox>
              </v-form>
            </v-tab-item>
          </v-tabs-items>
        </template>
        <template v-slot:buttons>
          <v-btn
            class="mr-4"
            text
            @click="$router.push({ path: '/sessions' })"
          >
            Back to list
          </v-btn>
          <v-dialog
              max-width="500"
              v-if="staffPermission.deleteOutletSession"
          >
              <template v-slot:activator="{ on, attrs }">
              <v-btn
                  color="error"
                  v-bind="attrs"
                  v-on="on"
                  class="mr-4" 
              >Delete</v-btn>
              </template>
              <template v-slot:default="dialog">
              <v-card>
                  <v-card-title>
                    Are you sure to delete {{ sessionsById.name }}?
                  </v-card-title>
                  <v-card-actions class="justify-end">
                  <v-btn
                      text
                      @click="dialog.value = false"
                  >Close</v-btn>
                  <v-btn color="error" @click="deleteSession();dialog.value = false">
                      Delete
                  </v-btn>
                  </v-card-actions>
              </v-card>
              </template>
          </v-dialog>
          <v-btn
            color="success"
            class="mr-4"
            @click="validate"
            v-if="staffPermission.updateOutletSession"
          >
            Update
          </v-btn>
        </template>
      </Form>
    </SideMenu>
  </v-app>
</template>

<script>
import SideMenu from '/src/components/SideMenu'
import Form from '/src/components/Form'
import gql from 'graphql-tag'
import * as queries from '@/graphql/gqlQueries.js';
import Datepicker from 'vuejs-datepicker';
  const GET_SESSION_QUERY = gql`
      query Session($id: ID!) {
        sessionsById(id: $id) {
            name
            day
            date
            from_time
            to_time
            off_day
            outlet_sessions{
              outlet_id
            }
        }
      }
  `
  const GET_ALL_OUTLETS_QUERY = gql`
      query Outlets {
        outlets {
          id
          name
          created_at
        }
      }
  `
  export default {
    name: 'Outlet',

    components:{
      SideMenu,
      Datepicker,
      Form
    },

    data () {
      
      return {
        sessionsById: {
          name: '',
          day:'',
          date: '',
          off_day: false,
          from_time: '',
          to_time: '',
          outlets:[]
        },
        errorMessage:"",
        nameRules: [
          v => !!v || 'Name is required',
        ],
        characterRules: [
          v => (v.length <= 191) || 'Field must be less than or equal to 191 characters',
        ],
        routeParam: this.$route.params.id,
        alert: this.$route.params.alert_type?true:false,
        alert_type: this.$route.params.alert_type,
        message: this.$route.params.message,
        serviceTypes: [],

        outlets: [],
        tab: null,

        dates: [{id:1,name:"Monday"},{id:2,name:"Tuesday"},{id:3,name:"Wednesday"},{id:4,name:"Thursday"},{id:5,name:"Friday"},{id:6,name:"Saturday"},{id:7,name:"Sunday"}],
        from_time_menu:false,
        to_time_menu:false,
        type:'Day',
        types:[{name:'Day'},{name:'Date'}],
        session_outlets:[],
        getAllPermissions: [],
        allPermissionsName: [],
        staffPermission: {},
      }
    },
    apollo: {
      sessionsById: {
        query: GET_SESSION_QUERY,
        variables() {
          return{
            id: this.routeParam
          }
        },
        pollInterval: 300,
        result() {
          var outlets = []
          for(let i=0; i<this.sessionsById.outlet_sessions.length; i++){
            outlets.push(this.sessionsById.outlet_sessions[i].outlet_id.toString())
          }
          this.session_outlets = outlets
          if(this.sessionsById.date){
            this.sessionsById.date = new Date(this.sessionsById.date*1)
            this.type = "Date"
          }
          if(this.sessionsById.from_time && !this.sessionsById.from_time.includes(':')){
            this.sessionsById.from_time = ("0" + (new Date(1620691200000+this.sessionsById.from_time*1).getUTCHours())).slice(-2) + ':' + ("0" + (new Date(1620691200000+this.sessionsById.from_time*1).getMinutes())).slice(-2)
            this.sessionsById.to_time = ("0" + (new Date(1620691200000+this.sessionsById.to_time*1).getUTCHours())).slice(-2) + ':' + ("0" + (new Date(1620691200000+this.sessionsById.to_time*1).getMinutes())).slice(-2)
          }
        }
      },
      outlets: {
        query: GET_ALL_OUTLETS_QUERY,
      },
    },
    methods: {
      validate () {
        var validate = this.$refs.form.validate()
        if(!validate){
          window.scrollTo(0,0);
        }
        if (!this.sessionsById.off_day && this.sessionsById.from_time >= this.sessionsById.to_time) {
          return
        }
        var overlappedbooking = [];
        var queryPromises = [];

        for (let i = 0; i < this.session_outlets.length; i++) {
          const outlet_id = this.session_outlets[i];

          const queryPromise = this.$apollo.query({
            query: queries.GET_VALIDATE_ROSTER_BOOKING,
            variables: {
              date: this.sessionsById.date != null && this.sessionsById.date > 0 ? new Date(this.sessionsById.date * 1).toISOString().slice(0, 10) : null,
              day: this.sessionsById.day * 1,
              from_time: this.sessionsById.from_time,
              to_time: this.sessionsById.to_time,
              break_from_time: "00:00",
              break_to_time: "00:00",
              outlet_id: outlet_id * 1,
              off_day: this.sessionsById.off_day
            },
          }).then(result => {
            if (!result.data.validateRosterBooking.validate) {
              overlappedbooking.push(result.data.validateRosterBooking.overlappingBookings);
            }
          });

          queryPromises.push(queryPromise);
        }

        Promise.all(queryPromises).then(() => {
          console.log("overlappedbooking", overlappedbooking);
          if(overlappedbooking.length > 0){
            this.alert = true
              this.alert_type='error'
              this.message = "Outlet(s) have overlapped booking."
              return window.scrollTo(0,0)
          }else{
            if(validate){
              const UPDATE_SESSIONS_QUERY = gql`
                mutation updateSessions($id:ID!, $name: String!, $day:Int, $date:String, $off_day:Boolean, $from_time:String, $to_time:String) {
                    updateSessions(id:$id, name:$name, day:$day, date:$date, off_day:$off_day, from_time:$from_time, to_time:$to_time)  {
                      id
                      name
                    }
                }
              `

              this.$apollo.mutate(
                { 
                  mutation: UPDATE_SESSIONS_QUERY, 
                  variables:{
                    id: this.routeParam,
                    name: this.sessionsById.name,
                    day: this.sessionsById.day*1,
                    date: this.sessionsById.date,
                    off_day: this.sessionsById.off_day,
                    from_time: this.sessionsById.from_time,
                    to_time: this.sessionsById.to_time
                  },
                }
              ).then(result => {
                if(result.data.updateSessions != null){ 
                  console.log("Update successfully")
                  const DELETE_OUTLET_SESSIONS_QUERY = gql`
                    mutation deleteOutletSessions($session_id:Int!) {
                        deleteOutletSessions(session_id:$session_id)  {
                          session_id
                        }
                    }
                  `
                  const CREATE_OUTLET_SESSIONS_QUERY = gql`
                    mutation createOutletSessions($session_id:Int!, $outlet_id:Int!) {
                        createOutletSessions(session_id:$session_id, outlet_id:$outlet_id)  {
                          session_id
                        }
                    }
                  `
                  this.$apollo.mutate(
                    { 
                      mutation: DELETE_OUTLET_SESSIONS_QUERY, 
                      variables:{
                        session_id: result.data.updateSessions.id*1
                      },
                    }
                  )
                  for(let i = 0; i < this.session_outlets.length; i++){
                    this.$apollo.mutate(
                      { 
                        mutation: CREATE_OUTLET_SESSIONS_QUERY, 
                        variables:{
                          session_id: result.data.updateSessions.id*1,
                          outlet_id: this.session_outlets[i]*1
                        },
                      }
                    )
                  }
                  this.alert = true
                  this.alert_type='success'
                  this.message = result.data.updateSessions.name+' updated successfully'
                  window.scrollTo(0,0)
                } else {
                  this.alert = true
                  this.alert_type='error'
                  this.message = 'Updated fail'
                  window.scrollTo(0,0)
                }
              });
            }
          }
        });
      },
      deleteSession() {
        if(this.sessionsById.outlet_sessions.length > 0){
          this.alert = true
          this.alert_type='error'
          this.message = "Session still have outlets."
          return window.scrollTo(0,0)
        }
        const DELETE_OUTLET_SESSIONS_QUERY = gql`
          mutation deleteOutletSessions($session_id:Int!) {
              deleteOutletSessions(session_id:$session_id)  {
                deleted_at
              }
          }
        `
        this.$apollo.mutate(
          { 
            mutation: DELETE_OUTLET_SESSIONS_QUERY, 
            variables:{
              session_id: this.routeParam*1
            },
          }
        )
        const DELETE_SESSIONS_QUERY = gql`
          mutation deleteSessions($id:ID!) {
              deleteSessions(id:$id)  {
                name
              }
          }
        `
        this.$apollo.mutate(
          { 
            mutation: DELETE_SESSIONS_QUERY, 
            variables:{
              id: this.routeParam,
            },
          }
        ).then(result => {
          if(result.data.deleteSessions != null){ 
            console.log("Delete successfully")
            this.$router.push({name: 'Sessions', params:{alert:true, alert_type:'success', message:'Session deleted successfully'}})
          } else {
            console.log("Delete failed")
          }
        });
      },
      allowedStep: m => m % 5 === 0,
      changeType(type){
        if(type == 'Day')
          this.sessionsById.date = null
        else
          this.sessionsById.day = null
      }
    },
    created(){
      this.$apollo.query({ query: queries.GET_ALL_PERMISSIONS_QUERY }).then(async result => {
        this.getAllPermissions = await result.data.getAllPermissions;
        this.$apollo.query(
          {
            query: queries.GET_STAFF_ROLES,
            variables: {
              staffId: this.$session.get("user_id") * 1
            }
          }
        ).then(async result => {
          this.getStaffRoles = await result.data.getStaffRoles;
          this.allPermissionsName = [];
          this.getStaffRoles.forEach(role => {
            role.role.RolePermission.forEach((item) => {
              const name = item.permission.name;
              this.allPermissionsName.push(name);
            });
          })
          this.$session.set('staffPermission', this.$generalFunctions.generatePermissionArray(this.getAllPermissions, this.allPermissionsName));
          this.staffPermission = await this.$generalFunctions.generatePermissionArray(this.getAllPermissions, this.allPermissionsName)
        })
      })
    }
  }
</script>
