import Vue from "vue";
import Router from "vue-router";
import Login from './views/Login';
import Dashboard from './views/Dashboard';
import Staffs from './views/Staff/List';
import StaffUpdateForm from './views/Staff/UpdateForm';
import StaffCreateForm from './views/Staff/CreateForm';
import StaffRosterUpdateForm from './views/Staff/StaffRoster/UpdateForm';
import StaffRosterCreateForm from './views/Staff/StaffRoster/CreateForm';

import Services from './views/Service/List';
import ServiceUpdateForm from './views/Service/UpdateForm';
import ServiceCreateForm from './views/Service/CreateForm';
import ServiceTypes from './views/Service/Type/List';
import ServiceTypeUpdateForm from './views/Service/Type/UpdateForm';
import ServiceTypeCreateForm from './views/Service/Type/CreateForm';

import Resources from './views/Resource/List';
import ResourceUpdateForm from './views/Resource/UpdateForm';
import ResourceCreateForm from './views/Resource/CreateForm';
import ResourceTypes from './views/Resource/Type/List';
import ResourceTypeUpdateForm from './views/Resource/Type/UpdateForm';
import ResourceTypeCreateForm from './views/Resource/Type/CreateForm';
import ResourceScheduleUpdateForm from './views/Resource/Schedule/UpdateForm';
import ResourceScheduleCreateForm from './views/Resource/Schedule/CreateForm';

import Outlets from './views/Outlet/List';
import OutletUpdateForm from './views/Outlet/UpdateForm';
import OutletCreateForm from './views/Outlet/CreateForm';
import Sessions from './views/Outlet/Sessions/List';
import SessionUpdateForm from './views/Outlet/Sessions/UpdateForm';
import SessionCreateForm from './views/Outlet/Sessions/CreateForm';

import Calendar from './views/Calendar/List';
import BookingDetails from './views/Booking/Details';
// import BookingCreateForm from './views/Booking/CreateForm';

import SettingsForm from './views/Settings/Form';

import Customer from './views/Customer/List';
// import Sales from './views/Sales/SaleList';
import NotFound from './views/NotFound.vue'
import Packages from './views/Packages/List'
import PackageUpdateForm from './views/Packages/UpdateForm';
import PackageCreateForm from './views/Packages/CreateForm';
import UserCreateForm from './views/Customer/CreateForm';
import UserUpdateForm from './views/Customer/UpdateForm';
import PurchasePackageForm from './views/Packages/PurchasePackage.vue'
import PendingPayment from './views/PendingTransaction/List'
import Roles from './views/Roles/RoleList.vue'
import createRole from './views/Roles/CreateRole.vue'
import updateRole from './views/Roles/UpdateRole.vue'
import commissionlisting from './views/Listing/CommissionListing.vue'
import commissionDetails from './views/Listing/CommissionDetails.vue'
import saleslisting from './views/Listing/SalesListing.vue'
import salesDetails from './views/Listing/SalesDetails.vue'

Vue.use(Router);

export default new Router({
    mode: 'history',
    base: __dirname,
    routes: [
        {
            path: "/",
            name: "Login",
            component: Login
        },
        {
            path: "/dashboard",
            name: "Dashboard",
            component: Dashboard
        },
        {
            path: "/staffs",
            name: "Staff",
            component: Staffs,
            props: true
        },
        {
            path: "/staff/:id",
            name: "Staff Update Form",
            component: StaffUpdateForm
        },
        {
            path: "/create/staff",
            name: "Staff Create Form",
            component: StaffCreateForm
        },
        {
            path: "/staff/roster/:id",
            name: "Staff Roster Update Form",
            component: StaffRosterUpdateForm
        },
        {
            path: "/create/roster/staff",
            name: "Staff Roster Create Form",
            component: StaffRosterCreateForm
        },
        {
            path: "/services",
            name: "Service",
            component: Services,
            props: true
        },
        {
            path: "/service/:id",
            name: "Service Update Form",
            component: ServiceUpdateForm
        },
        {
            path: "/create/service",
            name: "Service Create Form",
            component: ServiceCreateForm
        },
        {
            path: "/type/services",
            name: "Service Type",
            component: ServiceTypes,
            props: true
        },
        {
            path: "/type/service/:id",
            name: "Service Type Update Form",
            component: ServiceTypeUpdateForm
        },
        {
            path: "/create/type/service",
            name: "Service Type Create Form",
            component: ServiceTypeCreateForm
        },
        {
            path: "/resources",
            name: "Resource",
            component: Resources,
            props: true
        },
        {
            path: "/resource/:id",
            name: "Resource Update Form",
            component: ResourceUpdateForm
        },
        {
            path: "/create/resource",
            name: "Resource Create Form",
            component: ResourceCreateForm
        },
        {
            path: "/type/resources",
            name: "Resource Type",
            component: ResourceTypes,
            props: true
        },
        {
            path: "/type/resource/:id",
            name: "Resource Type Update Form",
            component: ResourceTypeUpdateForm
        },
        {
            path: "/create/type/resource",
            name: "Resource Type Create Form",
            component: ResourceTypeCreateForm
        },
        {
            path: "/resource/schedule/:id",
            name: "Resource Schedule Update Form",
            component: ResourceScheduleUpdateForm
        },
        {
            path: "/create/schedule/resource",
            name: "Resource Schedule Create Form",
            component: ResourceScheduleCreateForm
        },
        {
            path: "/outlets",
            name: "Outlet",
            component: Outlets,
            props: true
        },
        {
            path: "/outlet/:id",
            name: "Outlet Update Form",
            component: OutletUpdateForm
        },
        {
            path: "/create/outlet",
            name: "Outlet Create Form",
            component: OutletCreateForm
        },
        {
            path: "/sessions",
            name: "Sessions",
            component: Sessions,
            props: true
        },
        {
            path: "/session/:id",
            name: "Sessions Update Form",
            component: SessionUpdateForm
        },
        {
            path: "/create/session",
            name: "Session Create Form",
            component: SessionCreateForm
        },
        {
            path: "/calendar",
            name: "Calendar",
            component: Calendar,
            props: true
        },
        {
            path: "/booking/:id",
            name: "Booking Details",
            component: BookingDetails
        },
        // {
        //     path: "/create/booking",
        //     name: "Booking Form",
        //     component: BookingCreateForm
        // },
        {
            path: "/settings",
            name: "Settings Form",
            component: SettingsForm
        },
        {
            path: "/customers",
            name: "Customer",
            component: Customer
        },
        {
            path: "/packages",
            name: "Packages",
            component: Packages
        },
        {
            path: "/package/:id",
            name: "Package Update Form",
            component: PackageUpdateForm
        },
        {
            path: "/create/package",
            name: "Package Create Form",
            component: PackageCreateForm
        },
        {
            path: "/purchase/package",
            name: "Purchase Package Form",
            component: PurchasePackageForm
        },
        {
            path: "/create/user",
            name: "User Create Form",
            component: UserCreateForm
        },
        {
            path: "/user/:id",
            name: "User Update Form",
            component: UserUpdateForm
        },
        {
            path: "/pendingList",
            name: "Pending Payment",
            component: PendingPayment
        },
        {
            path: "/roles",
            name: "Roles",
            component: Roles
        },
        {
            path: "/create/role",
            name: "Create Role",
            component: createRole
        },
        {
            path: "/role/:id",
            name: "Role Update Form",
            component: updateRole
        },
        {
            path: "/listing/commission",
            name: "Commisson Listing",
            component: commissionlisting
        },
        {
            path: "/commission/:id",
            name: "Commisson Details",
            component: commissionDetails
        },
        {
            path: "/listing/sales",
            name: "Sales Listing",
            component: saleslisting
        },
        {
            path: "/sales/:id",
            name: `Sales Details`,
            component: salesDetails
        },
        {
            path: '*',
            name: 'Not Found',
            component: NotFound
        }
    ]
})