<template>
    <v-app>
        <SideMenu submenuPage="Roles">
            <v-alert
                :type="alert_type || 'info'"
                dismissible
                v-model="alert"
            >{{message}}</v-alert>
            <Form>
                <template v-slot:tabs>
                    <v-tabs v-model="tab">
                        <v-tabs-slider></v-tabs-slider>
                        <v-tab href="#details">Details</v-tab>
                    </v-tabs>
                </template>
                <template v-slot:form>
                    <v-tabs-items v-model="tab">
                        <v-tab-item value="details">
                            <v-form ref="form" lazy-validation>

                                <v-text-field v-model="roles.name" :counter="191" label="Name" required
                                    :rules="nameRules.concat(characterRules)"></v-text-field>

                                <p>Description</p>
                                <v-textarea rows="3" v-model="roles.description" :counter="191" outlined
                                    :rules="descRules"></v-textarea>

                                <p>Permissions</p>
                                <v-checkbox v-model="selectAll" label="Select All Permissions"
                                    @change="selectAllPermissions"></v-checkbox>
                                <v-card class="m-2" outlined v-for="(cat, index) of permissionsByCategory" :key="index">
                                    <v-list-group>
                                        <template v-slot:activator>
                                            <v-list-item-content>
                                                <v-list-item-title class="text-uppercase" style="color:black">{{
                                                    cat.category }} permissions</v-list-item-title>
                                            </v-list-item-content>
                                        </template>
                                        <div class="padding-30">
                                            <v-list-item>
                                                <v-container fluid>
                                                    <v-checkbox v-model="roles.permissions" v-for="p of cat.permissions"
                                                        :key="p.id" :label="p.description" :value="p.id"></v-checkbox>
                                                </v-container>
                                            </v-list-item>
                                        </div>
                                    </v-list-group>
                                </v-card>
                                <v-checkbox v-model="selectAll" label="Select All Permissions"
                                    @change="selectAllPermissions"></v-checkbox>
                            </v-form>
                        </v-tab-item>
                    </v-tabs-items>
                </template>
                <template v-slot:buttons>
                    <v-btn class="mr-4" text @click="$router.push({ path: '/roles' })">
                        Back
                    </v-btn>
                    <v-dialog
                        max-width="500"
                        v-if="staffPermission.deleteRole"
                    >
                        <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            color="error"
                            v-bind="attrs"
                            v-on="on"
                            class="mr-4" 
                        >Delete</v-btn>
                        </template>
                        <template v-slot:default="dialog">
                        <v-card>
                            <v-card-title>
                                Are you sure to delete {{ roles.name }}?
                            </v-card-title>
                            <v-card-actions class="justify-end">
                            <v-btn
                                text
                                @click="dialog.value = false"
                            >Close</v-btn>
                            <v-btn color="error" @click="deleteRole();dialog.value = false">
                                Delete
                            </v-btn>
                            </v-card-actions>
                        </v-card>
                        </template>
                    </v-dialog>
                    <v-btn color="success" class="mr-4" @click="validate" v-if="staffPermission.updateRole">
                        Update
                    </v-btn>
                </template>
            </Form>
        </SideMenu>
    </v-app>
</template>
<script>
import SideMenu from '/src/components/SideMenu'
import gql from 'graphql-tag'
import Form from '/src/components/Form'
import * as queries from '@/graphql/gqlQueries.js';
const GET_ALL_PERMISSIONS_QUERY = gql`
        query getAllPermissions {
            getAllPermissions {
            id
            name
            description
            category {
                name
            }
          }
        }
    `
const GET_ROLE_BY_ID_QUERY = gql`
  query GetRoleById($id: ID!) {
    getRoleById(id: $id) {
      id
      name
      description
      RolePermission {
        permissionId
        roleId
      }
    }
  }
`;
export default {
    name: "Role",

    components: {
        SideMenu,
        Form
    },

    data() {

        return {
            getAllPermissions: [],
            roles: {
                name: '',
                description: '',
                permissions: [],
            },
            permissionsByCategory: [],
            selectAll: false,
            tab: 'details',
            alert: false,
            alert_type: "",
            message: "",
            nameRules: [
                v => !!v || 'Name is required',
            ],
            characterRules: [
                v => (v.length <= 191) || 'Field must be less than or equal to 191 characters',
            ],
            descRules: [
                v => (v.length <= 191) || 'Field must be less than or equal to 500 characters',
            ],
            routeParam: this.$route.params.id,
            allPermissionsName: [],
            staffPermission: {},
        }
    },
    apollo: {
        getAllPermissions: {
            query: GET_ALL_PERMISSIONS_QUERY,
            pollInterval: 300,
            fetchPolicy: 'network-only',
            result() {
                this.getAllPermissions.forEach(permission => {
                    const category = permission.category.name;

                    // Find the index of the category in permissionsByCategory
                    const categoryIndex = this.permissionsByCategory.findIndex(item => item.category === category);

                    // If the category doesn't exist in permissionsByCategory, create an object for it
                    if (categoryIndex === -1) {
                        this.permissionsByCategory.push({
                            category: category,
                            permissions: [permission]
                        });
                    } else {
                        // Add the permission to the existing category object
                        this.permissionsByCategory[categoryIndex].permissions.push(permission);
                    }
                });
            }
        },
        getRoleById: {
            query: GET_ROLE_BY_ID_QUERY,
            pollInterval: 300,
            fetchPolicy: 'network-only',
            variables() {
                return {
                    id: this.routeParam
                }
            },
            result() {
                const role = { ...this.getRoleById }
                this.roles.name = role.name
                this.roles.description = role.description
                role.RolePermission.forEach(p => {
                    if (!this.roles.permissions.includes(p.permissionId)) this.roles.permissions.push(p.permissionId)
                })
                if (role.RolePermission.length === this.getAllPermissions.length) {
                    this.selectAll = true
                }
            }
        },
        getStaffRoles:{
            query: queries.GET_STAFF_ROLES,
            fetchPolicy: 'network-only',
            variables() {
            return{
                staffId: this.$session.get("user_id") * 1
            }
            },
            result() {
            this.allPermissionsName = []
            this.getStaffRoles.forEach( role =>{
                role.role.RolePermission.forEach((item) => {
                const name = item.permission.name;
                this.allPermissionsName.push(name);
                });
            })
            this.$session.set('staffPermission', this.$generalFunctions.generatePermissionArray(this.getAllPermissions,this.allPermissionsName));
            this.staffPermission = this.$session.get("staffPermission")
            this.$forceUpdate()
            }
        },
    },
    methods: {
        validate() {
            var validate = this.$refs.form.validate();
            if (!validate) {
                window.scrollTo(0, 0);
                return
            }
            if (validate) {
                const UPDATE_ROLE_MUTATION = gql`
            mutation updateRole($id: ID!, $name: String!, $description: String!) {
                updateRole(id:$id, name: $name, description: $description) {
                id
                name
                description
                }
            }
            `;
                this.$apollo.mutate({
                    mutation: UPDATE_ROLE_MUTATION,
                    variables: {
                        id: this.routeParam,
                        name: this.roles.name,
                        description: this.roles.description,
                    },
                }).catch(error => {
                    console.log(JSON.stringify(error, null, 2));
                }).then(async result => {
                    console.log(result)

                    const DELETE_ROLE_PERMISSION_MUTATION = gql`
                        mutation deleteRolePermission($roleId: Int!) {
                            deleteRolePermission(roleId: $roleId){
                                roleId
                            }
                        }
                    `;
                    this.$apollo.mutate({
                        mutation: DELETE_ROLE_PERMISSION_MUTATION,
                        variables: {
                            roleId: parseInt(this.routeParam),
                        },
                    }).catch(error => {
                        console.log(JSON.stringify(error, null, 2));
                    }).then(async () => {
                        const CREATE_ROLE_PERMISSION_MUTATION = gql`
                    mutation CreateRolePermission($permissionId: Int!, $roleId: Int!) {
                            createRolePermission(permissionId: $permissionId, roleId: $roleId) {
                            permissionId
                            roleId
                            }
                        }
                    `;
                        const mutationPromises = [];
                        this.roles.permissions.forEach(p => {
                            const mutationPromise = this.$apollo.mutate({
                                mutation: CREATE_ROLE_PERMISSION_MUTATION,
                                variables: {
                                    permissionId: parseInt(p),
                                    roleId: parseInt(this.routeParam),
                                },
                            }).catch(error => {
                                console.log(JSON.stringify(error, null, 2));
                            })
                            mutationPromises.push(mutationPromise);
                        })

                        try {
                            await Promise.all(mutationPromises);
                            this.alert = true
                            this.alert_type='success'
                            this.message = this.roles.name +' updated successfully'
                            window.scrollTo(0,0)
                        } catch (error) {
                            // Handle the error appropriately
                            console.log(error.message);
                            this.alert = true
                            this.alert_type='error'
                            this.message = 'Updated fail'
                            window.scrollTo(0,0)
                        }
                    })
                })
            }
        },
        selectAllPermissions() {
            const selectedPermissions = this.selectAll ? this.getAllPermissions.map(p => p.id) : []
            this.roles.permissions = selectedPermissions
        },
        deleteRole(){
            const DELETE_ROLE_MUTATION = gql`
            mutation deleteRole($id: ID!) {
                deleteRole(id:$id) {
                    id
                }
            }
            `;
            this.$apollo.mutate({
                    mutation: DELETE_ROLE_MUTATION,
                    variables: {
                        id : parseInt(this.routeParam), 
                    },
                }).catch(error => {
                    console.log(JSON.stringify(error, null, 2));
                }).then(result => {
                    if(result.data.deleteRole != null){ 
                        console.log("Delete successfully")
                        this.$router.push({name: 'Roles', params:{alert:true, alert_type:'success', message:this.roles.name+' deleted successfully'}})
                    } else {
                        console.log("Delete failed")
                    }
                })
        }
    },
    watch: {
        "roles.permissions"(value) {
            if (value.length == this.getAllPermissions.length) {
                this.selectAll = true
            } else {
                this.selectAll = false
            }
        }
    }
}
</script>