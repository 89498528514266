var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('SideMenu',{attrs:{"alert":_vm.alert,"alert_type":_vm.alert_type,"message":_vm.message,"submenuPage":"Commission Listing","submenuGroup":"Listing"}},[_c('List',{scopedSlots:_vm._u([{key:"action-button",fn:function(){return [_c('v-btn',{staticClass:"mr-4",attrs:{"color":"primary"},on:{"click":_vm.exportCSV}},[_vm._v(" Export (csv) ")])]},proxy:true},{key:"tabs",fn:function(){return [_c('v-row',[_c('v-col',{staticClass:"py-1",attrs:{"cols":"6"}},[_c('v-select',{staticClass:"ma-2",attrs:{"items":_vm.processed_outlets,"item-value":"id","item-text":"name","dense":"","outlined":"","hide-details":"","label":"Outlet"},model:{value:(_vm.outlet_id),callback:function ($$v) {_vm.outlet_id=$$v},expression:"outlet_id"}})],1),(_vm.staffPermission.viewAllOutletsListing || _vm.staffPermission.viewOutletsListing)?_c('v-col',{staticClass:"py-1",attrs:{"cols":"6"}},[_c('v-select',{staticClass:"ma-2",attrs:{"items":_vm.processed_staff,"item-value":"id","item-text":"fullname","dense":"","outlined":"","hide-details":"","label":"Staff"},model:{value:(_vm.staff_id),callback:function ($$v) {_vm.staff_id=$$v},expression:"staff_id"}})],1):_vm._e(),_c('v-col',{staticClass:"py-1",attrs:{"cols":"3"}},[_c('v-checkbox',{attrs:{"label":"Date Range","hide-details":""},model:{value:(_vm.date_range),callback:function ($$v) {_vm.date_range=$$v},expression:"date_range"}})],1),(_vm.date_range)?_c('v-col',{staticClass:"py-1",attrs:{"cols":"6"}},[_c('v-row',[_c('v-col',{staticClass:"py-1",attrs:{"cols":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"From","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}},'v-text-field',attrs,false),on))]}}],null,false,3034050708),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{on:{"input":function($event){_vm.menu2 = false}},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}})],1)],1),_c('v-col',{staticClass:"py-1",attrs:{"cols":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"To","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}},'v-text-field',attrs,false),on))]}}],null,false,2653320918),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.menu2 = false}},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}})],1)],1)],1)],1):_vm._e(),_c('v-col',{attrs:{"cols":"3"}},[_c('v-btn',{staticClass:"mr-4 w-100",attrs:{"color":"primary"},on:{"click":_vm.search}},[_vm._v(" search ")])],1)],1)]},proxy:true},{key:"tab-items",fn:function(){return [_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.commissionList,"item-key":"id"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.routeToDetails(item.id)}}},[_c('td',[_vm._v(_vm._s(item.outlet.name))]),_c('td',[_vm._v(_vm._s(item.staff.fullname))]),_c('td',[_vm._v(_vm._s(item.user.user_fullname)+" "),_c('br'),_vm._v(" "+_vm._s(item.user.user_contact))]),_c('td',[_vm._v(_vm._s(_vm._f("formatDate")(item.booked_date)))]),_c('td',[_vm._v(_vm._s(item.id))]),_c('td',[_vm._v(_vm._s(item.sale_id))]),_c('td',[_vm._v(_vm._s(item.amount.toFixed(2)))]),_c('td',[_vm._v(_vm._s(item.cAmount.toFixed(2)))])])]}}])})]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }