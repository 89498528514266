<template>
    <v-app>
      <SideMenu :alert="alert" :alert_type="alert_type" :message="message" submenuPage="Calender">
        <Form>
          <template v-slot:tabs>
            <v-tabs
              v-model="tab"
            >
              <v-tabs-slider></v-tabs-slider>
              <v-tab href="#details">Details</v-tab>
            </v-tabs>
          </template>
          <template v-slot:form>
            <v-tabs-items v-model="tab">
              <v-tab-item value="details">
                <v-form
                  ref="form"
                  lazy-validation
                >
                  <v-select
                    v-model="serviceById.service_type_id"
                    :items="serviceTypes"
                    item-value="id"
                    item-text="name"
                    label="Service Type"
                    :rules="[() => !!serviceById.service_type_id || 'Service type is required']"
                  ></v-select>
  
                  <v-text-field
                    v-model="serviceById.name"
                    :counter="191"
                    :rules="nameRules.concat(characterRules)"
                    label="Name"
                    required
                  ></v-text-field>
  
                  <v-text-field
                    v-model="serviceById.description"
                    :counter="191"
                    :rules="characterRules"
                    label="Description"
                  ></v-text-field>
  
                  <v-text-field
                    v-model="serviceById.duration"
                    label="Duration (minutes)"
                    type="number"
                    min="0"
                    step="1"
                    oninput="validity.valid||(value='');"
                    class="v-text-field-number"
                    :rules="durationRules"
                  ></v-text-field>
  
                </v-form>
              </v-tab-item>
            </v-tabs-items>
          </template>
          <template v-slot:buttons>
            <v-btn
              class="mr-4"
              text
              @click="$router.push({ path: '/services' })"
            >
              Back to list
            </v-btn>
            <v-btn
              color="error"
              class="mr-4"
              @click.stop="dialog = true"
            >
              Delete
            </v-btn>
            <v-btn
              color="success"
              class="mr-4"
              @click="validate"
            >
              Update
            </v-btn>
          </template>
        </Form>
      </SideMenu>
      <template>
        <v-row justify="center">
        <v-dialog
          v-model="dialog"
          max-width="290"
        >
          <v-card>
            <v-card-title class="text-h5">
              Delete Confirmation
            </v-card-title>
  
            <v-card-text>
              Are you sure to delete the service?
            </v-card-text>
  
            <v-card-actions>
              <v-spacer></v-spacer>
  
              <v-btn
                text
                @click="dialog = false"
              >
                No
              </v-btn>
  
              <v-btn
                text
                @click="deleteService"
              >
                Yes
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        </v-row>
      </template>
    </v-app>
  </template>
    
  <style>
  .dot {
    height: 10px;
    width: 10px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
  }
  </style>
    
  <script>
  import SideMenu from '/src/components/SideMenu'
  import Form from '/src/components/Form'
  import gql from 'graphql-tag'
  const GET_ALL_PACKAGES_QUERY = gql`
    query {
      getAllPackages {
        id
        package_name
        price
        status
        created_at
        deleted_at
      }
    }
  `
  export default {
    name: 'PurchasePackageForm',
  
    components: {
      SideMenu,
      Form
    },
  
    data() {
  
      return {
        alert: this.$route.params.alert,
        alert_type: this.$route.params.alert_type,
        message: this.$route.params.message,
  
        headers: [
          {
            text: 'Name',
            value: 'package_name',
            class: 'white--text',
            width: '40%'
          },
          {
            text: 'Price (RM)',
            value: 'price',
            class: 'white--text',
            width: '20%'
          },
          {
            text: 'Status',
            value: 'status',
            class: 'white--text',
            width: '10%'
          },
          {
            text: 'Created Date',
            value: 'created_at',
            class: 'white--text',
            width: '30%'
          }
        ],
        packages: []
      }
    },
    filters: {
      formatPrice(value) {
        return Number(value).toFixed(2);
      }
    },
    apollo: {
      getAllPackages: {
        query: GET_ALL_PACKAGES_QUERY,
        pollInterval: 300,
        result() {
          const options = {
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
            timeZone: "Asia/Singapore",
          }
          this.getAllPackages.forEach((pkg) => {
            const createdAt = new Date(pkg.created_at * 1);
            if (!isNaN(createdAt.getTime())) {
              pkg.created_at = new Intl.DateTimeFormat("en-US", options).format(createdAt);
            }
          })
  
          this.packages = this.getAllPackages.filter(p => !p.deleted_at);
        }
      }
    },
    methods: {
      packageUpdateForm(id) {
        this.$router.push({ path: '/package/' + id })
      },
      packageCreateForm() {
        this.$router.push({ path: '/create/package' })
      }
    }
  }
  </script>
    