<template>
   <v-app id="app">
      <v-main>
         <v-container fluid fill-height>
            <v-layout align-center justify-center>
               <v-flex xs12 sm8 md4>
                  <div align="center">
                     <v-img
                      style="margin-bottom:20px; border-radius:25px;"
                        height="200"
                        width="200"
                        :src=imageURL
                     ></v-img>
                  </div>
                  <v-card>
                     <v-toolbar elevation="0" dark color="info">
                        <v-toolbar-title>SIGN IN</v-toolbar-title>
                     </v-toolbar>
                     <v-form>
                        <v-card-text>
                           Login to access your account.
                           <v-text-field
                              name="login"
                              label="Email"
                              type="text"
                              v-model="email"
                              prepend-icon="mdi-email"
                              @keyup.enter="login"
                           ></v-text-field>
                           <v-text-field
                              id="password"
                              name="password"
                              label="Password"
                              type="password"
                              v-model="password"
                              prepend-icon="mdi-lock"
                              @keyup.enter="login"
                           ></v-text-field>
                        </v-card-text>
                        <v-card-actions>
                           <v-btn block elevation="0" color="info" class="login-button" @click="login">Sign In</v-btn>
                        </v-card-actions>
                     </v-form>
                  </v-card>
                  <DialogMessage></DialogMessage>
                  <v-snackbar
                     v-model="snackbar"
                  >
                     {{ snackbar_message }}

                     <template v-slot:action="{ attrs }">
                     <v-btn
                        color="white"
                        text
                        v-bind="attrs"
                        @click="snackbar = false"
                     >
                        Close
                     </v-btn>
                     </template>
                  </v-snackbar>
               </v-flex>
            </v-layout>
         </v-container>
      </v-main>
   </v-app>
</template>

<script>
import DialogMessage from '/src/components/DialogMessage'
import gql from 'graphql-tag'
import * as queries from '@/graphql/gqlQueries.js';

const GET_ALL_OUTLET_QUERY = gql`
      query Outlets($staff_id:Int, $service_id:Int) {
        outlets(staff_id:$staff_id, service_id:$service_id) {
          id
        }
      }
  `

export default {
   name: 'Login',
   props: {
      source: String,
   },
   components: {
      DialogMessage
   },
   data() {
      return {
         email: "",
         password: "",
         snackbar: false,
         snackbar_message: '',
         imageURL : null,
         getAllPermissions: [],
         allPermissionsName: [],
         staffPermission: {},
      }
   },
   apollo: {
      settings: {
        query: queries.GET_SETTINGS_QUERY,
        result (){
          document.title = this.websiteName = this.settings.name + " Login";
          if(this.settings.image_id != 0 && this.settings.image_id != null){
            const GET_IMAGE_QUERY = gql`
              query imageById($id: ID!) {
                  imageById(id:$id)  {
                    id
                    type
                    filename
                  }
              }
            `
            this.$apollo.query(
              { 
                query: GET_IMAGE_QUERY, 
                variables:{
                  id: this.settings.image_id * 1
                },
              }
            ).then(result => {
              if(result.data.imageById != null){ 
                this.imageURL = 'https://prod-app360.sfo3.digitaloceanspaces.com/' + result.data.imageById.filename;

              } else {
               this.imageURL = require('../../public/favicon.png');
                console.log("Image not found")
              }
            });
          }
        }
      },
      outlets: {
        query: GET_ALL_OUTLET_QUERY,
      },
   },
   beforeCreate: function () {
      if (this.$session.exists()) {
        this.$router.push('/calendar')
      }
    },
   methods: {
    // Log the user in
    login() {
      const GET_USER_QUERY = gql`
         query Staff($email:String!) {
            staff(email:$email)  {
               id
               email
               password
               admin
               supervisor
               fullname
               staff_outlet{
                  outlet_id
               }
            }
         }
      `
      if(this.email == "" && this.password == ""){
         this.snackbar_message = "An email and password must be present"
         this.snackbar = true
         return
      }
      this.$apollo.query(
         { 
            query: GET_USER_QUERY, 
            variables:{
               email:this.email
            },
         }
      ).then(result => {
         if(result.data.staff == null){
            this.snackbar_message = "The email or password is incorrect"
            this.snackbar = true
            return
         }
         var passwordHash = require('password-hash')
         if(!passwordHash.verify(this.password,result.data.staff.password)){
            this.snackbar_message = "The email or password is incorrect"
            this.snackbar = true
            return
         }
         //this.$emit("dialog",true)
         this.$session.start()
         this.$session.set('user_id', result.data.staff.id)
         this.$session.set('user_fullname', result.data.staff.fullname)
         this.$session.set('admin', result.data.staff.admin)
         this.$session.set('currentOutletInit',false)
         this.$session.set('supervisor', result.data.staff.supervisor)
         //this.$emit("authenticated", true)
         //this.$router.replace({ name: "secure" })
         var outlets = []
         if (this.$session.get("user_id") * 1 == 1 && this.outlets.length > 0) {
            this.outlets.forEach(o => {
               outlets.push(o.id.toString())
            }
            )
         } else if (result.data.staff.staff_outlet && result.data.staff.staff_outlet.length > 0) {
            for (let i = 0; i < result.data.staff.staff_outlet.length; i++) {
               outlets.push(result.data.staff.staff_outlet[i].outlet_id.toString())
            }
         }
         this.$session.set('outlets', outlets)
         this.$apollo.query({ query: queries.GET_ALL_PERMISSIONS_QUERY }).then(async result => {
            this.getAllPermissions = await result.data.getAllPermissions;
            this.$apollo.query(
               {
                  query: queries.GET_STAFF_ROLES,
                  variables: {
                  staffId: this.$session.get("user_id") * 1
                  }
               }
            ).then(async result => {
               this.getStaffRoles = await result.data.getStaffRoles;
               this.allPermissionsName = [];
               this.getStaffRoles.forEach(role => {
                  role.role.RolePermission.forEach((item) => {
                  const name = item.permission.name;
                  this.allPermissionsName.push(name);
                  });
               })
               this.$session.set('staffPermission', this.$generalFunctions.generatePermissionArray(this.getAllPermissions, this.allPermissionsName));
               this.$router.push({name: 'Calendar'})
            })
         })
      });
    }
  }
};
</script>

<style></style>
