<template>
  <v-app>
    <SideMenu :alert="alert" :alert_type="alert_type" :message="message" submenuPage="Resources" submenuGroup="Service Setup">
      <v-alert
        :type="alert_type || 'info'"
        dismissible
        v-model="alert"
      >{{message}}</v-alert>
      <Form>
        <template v-slot:tabs>

        </template>
        <template v-slot:form>
          <v-form
            ref="form"
            lazy-validation
          >
            <v-text-field
              v-model="resourceById.name"
              :counter="191"
              :rules="nameRules.concat(characterRules)"
              label="Name"
              required
            ></v-text-field>

            <v-text-field
              v-model="resourceById.description"
              :counter="191"
              :rules="characterRules"
              label="Description"
            ></v-text-field>

            <v-select
              v-model="resourceById.resource_type_id"
              :items="resourceTypes"
              item-value="id"
              item-text="name"
              label="Resource Type"
              :rules="[() => !!resourceById.resource_type_id || 'Resource type is required']"
            ></v-select>

            <v-select
              v-model="resourceById.outlet_id"
              :items="outlets"
              item-value="id"
              item-text="name"
              label="Outlet"
              :rules="[() => !!resourceById.outlet_id || 'Outlet is required']"
            ></v-select>
          </v-form>
        </template>
        <template v-slot:buttons>
          <v-btn
            class="mr-4"
            text
            @click="$router.push({ path: '/resources' })"
          >
            Back to list
          </v-btn>
          <v-dialog
              max-width="500"
              v-if="staffPermission.deleteResource"
          >
              <template v-slot:activator="{ on, attrs }">
              <v-btn
                  color="error"
                  v-bind="attrs"
                  v-on="on"
                  class="mr-4" 
              >Delete</v-btn>
              </template>
              <template v-slot:default="dialog">
              <v-card>
                  <v-card-title>
                    Are you sure to delete {{ resourceById.name }}?
                  </v-card-title>
                  <v-card-actions class="justify-end">
                  <v-btn
                      text
                      @click="dialog.value = false"
                  >Close</v-btn>
                  <v-btn color="error" @click="deleteResource();dialog.value = false">
                      Delete
                  </v-btn>
                  </v-card-actions>
              </v-card>
              </template>
          </v-dialog>
          <v-btn
            color="success"
            class="mr-4"
            @click="validate"
            v-if="staffPermission.updateResource"
          >
            Update
          </v-btn>
        </template>
      </Form>
    </SideMenu>
  </v-app>
</template>

<script>
import SideMenu from '/src/components/SideMenu'
import Form from '/src/components/Form'
import gql from 'graphql-tag'
import * as queries from '@/graphql/gqlQueries.js';
const GET_ALL_RESOURCE_TYPES_QUERY = gql`
      query ResourceTypes {
        resourceTypes {
          id
          name
        }
      }
  `
  const GET_RESOURCE_QUERY = gql`
      query Resource($id: ID!) {
        resourceById(id: $id) {
            name
            description
            resource_type_id
            outlet_id
        }
      }
  `
  const GET_ALL_OUTLET_QUERY = gql`
      query Outlets {
        outlets {
          id
          name
        }
      }
  `
  export default {
    name: 'Resource',

    components:{
      SideMenu,
      Form
    },

    data () {
      
      return {
        outlet_id:'',
        resourceById: {
          name: '',
          description: '',
          resource_type_id: '',
          outlet_id: ''
        },
        outlets:[],
        nameRules: [
          v => !!v || 'Name is required',
        ],
        characterRules: [
          v => (v.length <= 191) || 'Field must be less than or equal to 191 characters',
        ],
        routeParam: this.$route.params.id,
        alert: this.$route.params.alert_type?true:false,
        alert_type: this.$route.params.alert_type,
        message: this.$route.params.message,
        resourceTypes: [],
        getAllPermissions: [],
        allPermissionsName: [],
        staffPermission: {},
      }
    },
    apollo: {
      resourceById: {
        query: GET_RESOURCE_QUERY,
        variables() {
          return{
            id: this.routeParam
          }
        },
        result() {
          this.resourceById.resource_type_id = this.resourceById.resource_type_id.toString()
          this.resourceById.outlet_id = this.resourceById.outlet_id.toString()
        }
      },
      resourceTypes: {
        query: GET_ALL_RESOURCE_TYPES_QUERY,
      },
      outlets: {
        query: GET_ALL_OUTLET_QUERY
      },
    },
    methods: {
      validate () {
        var validate = this.$refs.form.validate()
        if(!validate){
          window.scrollTo(0,0);
        }
        if(validate){
          const UPDATE_RESOURCE_QUERY = gql`
            mutation updateResource($id: ID!, $name: String!, $description:String, $resource_type_id: Int!, $outlet_id: Int!) {
              updateResource(id:$id, name:$name, description:$description, resource_type_id: $resource_type_id, outlet_id:$outlet_id)  {
                name
              }
            }
          `

          this.$apollo.mutate(
            { 
              mutation: UPDATE_RESOURCE_QUERY, 
              variables:{
                id: this.routeParam,
                name: this.resourceById.name,
                description: this.resourceById.description,
                resource_type_id: this.resourceById.resource_type_id*1,
                outlet_id: this.resourceById.outlet_id*1
              },
            }
          ).then(result => {
            if(result.data.updateResource != null){ 
              console.log("Update successfully")
              this.alert = true
              this.alert_type='success'
              this.message = result.data.updateResource.name+' updated successfully'
              window.scrollTo(0,0)
            } else {
              console.log("Update failed")
              this.alert = true
              this.alert_type='error'
              this.message = 'Update failed'
              window.scrollTo(0,0)
            }
          });
        }
      },
      deleteResource() {
        const DELETE_RESOURCE_QUERY = gql`
          mutation deleteResource($id:ID!) {
              deleteResource(id:$id)  {
                name
              }
          }
        `
        this.$apollo.mutate(
          { 
            mutation: DELETE_RESOURCE_QUERY, 
            variables:{
              id: this.routeParam,
            },
          }
        ).then(result => {
          if(result.data.deleteResource != null){ 
            console.log("Delete successfully")
            this.$router.push({name: 'Resource', params:{alert:true, alert_type:'success', message:result.data.deleteResource.name+' deleted successfully'}})
          } else {
            console.log("Delete failed")
          }
        });
      }
    },
    created(){
      this.$apollo.query({ query: queries.GET_ALL_PERMISSIONS_QUERY }).then(async result => {
        this.getAllPermissions = await result.data.getAllPermissions;
        this.$apollo.query(
          {
            query: queries.GET_STAFF_ROLES,
            variables: {
              staffId: this.$session.get("user_id") * 1
            }
          }
        ).then(async result => {
          this.getStaffRoles = await result.data.getStaffRoles;
          this.allPermissionsName = [];
          this.getStaffRoles.forEach(role => {
            role.role.RolePermission.forEach((item) => {
              const name = item.permission.name;
              this.allPermissionsName.push(name);
            });
          })
          this.$session.set('staffPermission', this.$generalFunctions.generatePermissionArray(this.getAllPermissions, this.allPermissionsName));
          this.staffPermission = await this.$generalFunctions.generatePermissionArray(this.getAllPermissions, this.allPermissionsName)
        })
      })
    }
  }
</script>
