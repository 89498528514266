<template>
  <v-app>
    <SideMenu :alert="alert" :alert_type="alert_type" :message="message" submenuPage="Packages">
      <List>
        <template v-slot:action-button>
          <v-btn color="primary" class="mr-4" @click="packageCreateForm" v-if="staffPermission.createPackage">
            + New Package
          </v-btn>
        </template>
        <template v-slot:tabs></template>
        <template v-slot:tab-items>
          <v-data-table :headers="headers" :items="packages" class="elevation-1" item-key="id" :sort-by="['id']" :sort-desc="[true]">
            <template v-slot:item="{ item }">
              <tr @click="packageUpdateForm(item.id)" style="cursor:pointer">
                <td>{{ item.package_name }}</td>
                <td>{{ item.price | formatPrice }}</td>
                <td>{{ item.status }}</td>
                <td>{{ item.created_at }}</td>
              </tr>
            </template>
          </v-data-table>
        </template>
      </List>
    </SideMenu>
  </v-app>
</template>
  
<style>
.dot {
  height: 10px;
  width: 10px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}
</style>
  
<script>
import SideMenu from '/src/components/SideMenu'
import List from '/src/components/List'
import gql from 'graphql-tag'
import * as queries from '@/graphql/gqlQueries.js';
const GET_ALL_PACKAGES_QUERY = gql`
  query {
    getAllPackages {
      id
      package_name
      price
      status
      created_at
      deleted_at
    }
  }
`
export default {
  name: 'Packages',

  components: {
    SideMenu,
    List
  },

  data() {

    return {
      alert: this.$route.params.alert,
      alert_type: this.$route.params.alert_type,
      message: this.$route.params.message,

      headers: [
        {
          text: 'Name',
          value: 'package_name',
          class: 'white--text',
          width: '40%'
        },
        {
          text: 'Price (RM)',
          value: 'price',
          class: 'white--text',
          width: '20%'
        },
        {
          text: 'Status',
          value: 'status',
          class: 'white--text',
          width: '10%'
        },
        {
          text: 'Created Date',
          value: 'created_at',
          class: 'white--text',
          width: '30%'
        }
      ],
      packages: [],
      getAllPermissions: [],
      allPermissionsName: [],
      staffPermission: {},
    }
  },
  filters: {
    formatPrice(value) {
      return Number(value).toFixed(2);
    },
    formatDateTime(value) {
    const dateTime = new Date(value * 1);

    const day = dateTime.getDate().toString().padStart(2, '0');
    const month = dateTime.toLocaleString('en-US', { month: 'short' });
    const year = dateTime.getFullYear();
    const hours = dateTime.getHours().toString().padStart(2, '0');
    const minutes = dateTime.getMinutes().toString().padStart(2, '0');
    const period = dateTime.getHours() >= 12 ? 'PM' : 'AM';

    return `${day} ${month} ${year} ${hours}:${minutes} ${period}`;
  },
  },
  apollo: {
    getAllPackages: {
      query: GET_ALL_PACKAGES_QUERY,
      pollInterval: 300,
      async result() {
        const sortProperties = [
                    { property: 'id', direction: 'desc' },
                    { property: 'created_at', direction: 'desc' },
                ];

      // Use the multiPropertySort function to sort the commissionList
      this.getAllPackages = await this.$generalFunctions.multiPropertySort(this.getAllPackages, sortProperties);
      this.getAllPackages.forEach((pkg) => {
        const createdAt = new Date(pkg.created_at * 1);
        if (!isNaN(createdAt.getTime())) {
          pkg.created_at = this.$options.filters.formatDateTime(createdAt); // Use the formatDateTime filter here
        }
      });

      this.packages = this.getAllPackages.filter(p => !p.deleted_at);
    }
    },
  },
  methods: {
    packageUpdateForm(id) {
      this.$router.push({ path: '/package/' + id })
    },
    packageCreateForm() {
      this.$router.push({ path: '/create/package' })
    }
  },
  created(){
    this.$apollo.query({ query: queries.GET_ALL_PERMISSIONS_QUERY }).then(async result => {
      this.getAllPermissions = await result.data.getAllPermissions;
      this.$apollo.query(
        {
          query: queries.GET_STAFF_ROLES,
          variables: {
            staffId: this.$session.get("user_id") * 1
          }
        }
      ).then(async result => {
        this.getStaffRoles = await result.data.getStaffRoles;
        this.allPermissionsName = [];
        this.getStaffRoles.forEach(role => {
          role.role.RolePermission.forEach((item) => {
            const name = item.permission.name;
            this.allPermissionsName.push(name);
          });
        })
        this.$session.set('staffPermission', this.$generalFunctions.generatePermissionArray(this.getAllPermissions, this.allPermissionsName));
        this.staffPermission = await this.$generalFunctions.generatePermissionArray(this.getAllPermissions, this.allPermissionsName)
      })
    })
  }
}
</script>
  