<template>
  <v-app>
    <SideMenu :alert="alert" :alert_type="alert_type" :message="message" submenuPage="Resource Type" submenuGroup="Service Setup">
      <v-alert
        :type="alert_type || 'info'"
        dismissible
        v-model="alert"
      >{{message}}</v-alert>
      <Form>
        <template v-slot:tabs>
          <v-tabs
            v-model="tab"
          >
            <v-tabs-slider></v-tabs-slider>
            <v-tab href="#details">Details</v-tab>
            <v-tab href="#price">Price</v-tab>
          </v-tabs>
        </template>
        <template v-slot:form>
          <v-tabs-items v-model="tab">
            <v-tab-item value="details">
              <v-form
                ref="form"
                lazy-validation
              >
                <v-text-field
                  v-model="resourceTypeById.name"
                  :counter="191"
                  :rules="nameRules.concat(characterRules)"
                  label="Name"
                  required
                ></v-text-field>

                <v-text-field
                  v-model="resourceTypeById.description"
                  :counter="191"
                  :rules="characterRules"
                  label="Description"
                ></v-text-field>

                <v-text-field
                  v-model="resourceTypeById.min_pax"
                  label="Min Pax"
                  type="number"
                  class="v-text-field-number"
                  min="0"
                  step="1"
                  oninput="validity.valid||(value='');"
                  :rules="[() => !!resourceTypeById.min_pax || 'Min Pax is required']"
                ></v-text-field>

                <v-text-field
                  v-model="resourceTypeById.max_pax"
                  label="Max Pax"
                  type="number"
                  class="v-text-field-number"
                  min="0"
                  step="1"
                  oninput="validity.valid||(value='');"
                ></v-text-field>

                <v-text-field
                  v-model="resourceTypeById.duration"
                  label="Duration (minutes)"
                  type="number"
                  class="v-text-field-number"
                  min="0"
                  step="1"
                  oninput="validity.valid||(value='');"
                  :rules="[() => !!resourceTypeById.duration || 'Duration is required']"
                ></v-text-field>

                <!-- <v-text-field
                  v-model="resourceTypeById.padding_time"
                  label="Padding Time (minutes)"
                  type="number"
                  class="v-text-field-number"
                  min="0"
                  step="1"
                  oninput="validity.valid||(value='');"
                ></v-text-field> -->

                <v-color-picker
                  dot-size="25"
                  hide-mode-switch
                  mode="hexa"
                  swatches-max-height="200"
                  label="Color"
                  v-model="resourceTypeById.color"
                ></v-color-picker>
              </v-form>
            </v-tab-item>
            <v-tab-item value="price">
              <v-form
                ref="form1"
                lazy-validation
              >
                <v-text-field
                  v-model="resourceTypeById.price"
                  label="Default Price"
                  type="number"
                  class="v-text-field-number"
                  step="0.01"
                  min="0"
                  oninput="validity.valid||(value='');"
                ></v-text-field>
                <div>
                  Custom Daily Price
                </div>
                <table class="roster">
                  <tr>
                    <td>
                      Day
                    </td>
                    <td 
                      v-for="date in dates"
                      :key="date"
                    >
                      {{date}}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Price
                    </td>
                    <td
                      v-for="(price, index) in prices"
                      :key="index"
                    >
                      <v-text-field
                        v-model="price.amount"
                        label="Price"
                        type="number"
                        step="0.01"
                        min="0"
                      ></v-text-field>
                    </td>
                  </tr>
                </table>
                <br/>
                <v-btn
                  color="primary"
                  class="mr-4"
                  @click="addCustomPrice"
                >
                  + New Custom Price
                </v-btn>
                <br/>
                <table>
                  <tr
                    v-for="(custom_price, index) in custom_prices"
                    :key="index"
                  >
                    <td>
                      <v-menu
                        v-model="custom_price.datemenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="custom_price.date"
                            prepend-icon="mdi-calendar"
                            placeholder="Date"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="custom_price.date"
                          @input="custom_price.datemenu = false"
                        ></v-date-picker>
                      </v-menu>
                    </td>
                    <td>
                      <v-text-field
                        v-model="custom_price.amount"
                        label="Price"
                        type="number"
                        step="0.01"
                        min="0"
                      ></v-text-field>
                    </td>
                    <td>
                      <v-btn
                        color="error"
                        @click="removeCustomPrice(index)"
                      >
                        <v-icon dark left>mdi-minus-circle</v-icon>
                        Remove
                      </v-btn>
                    </td>
                  </tr>
                </table>
              </v-form>
            </v-tab-item>
          </v-tabs-items>
        </template>
        <template v-slot:buttons>
          <v-btn
            class="mr-4"
            text
            @click="$router.push({ path: '/type/resources' })"
          >
            Back to list
          </v-btn>
          <v-dialog
              max-width="500"
              v-if="staffPermission.deleteResourceType"
          >
              <template v-slot:activator="{ on, attrs }">
              <v-btn
                  color="error"
                  v-bind="attrs"
                  v-on="on"
                  class="mr-4" 
              >Delete</v-btn>
              </template>
              <template v-slot:default="dialog">
              <v-card>
                  <v-card-title>
                    Are you sure to delete {{ resourceTypeById.name }}?
                  </v-card-title>
                  <v-card-actions class="justify-end">
                  <v-btn
                      text
                      @click="dialog.value = false"
                  >Close</v-btn>
                  <v-btn color="error" @click="deleteResourceType();dialog.value = false">
                      Delete
                  </v-btn>
                  </v-card-actions>
              </v-card>
              </template>
          </v-dialog>
          <v-btn
            color="success"
            class="mr-4"
            @click="validate"
            v-if="staffPermission.updateResourceType"
          >
            Update
          </v-btn>
        </template>
      </Form>
    </SideMenu>
  </v-app>
</template>

<script>
import SideMenu from '/src/components/SideMenu'
import Form from '/src/components/Form'
import gql from 'graphql-tag'
import * as queries from '@/graphql/gqlQueries.js';
  const GET_RESOURCE_TYPE_QUERY = gql`
      query ResourceType($id: ID!) {
        resourceTypeById(id: $id) {
            name
            description
            min_pax
            max_pax
            duration
            color
            padding_time
            price_type
            price
            custom_price{
              day
              date
              amount
              description
            }
        }
      }
  `
  export default {
    name: 'Resource',

    components:{
      SideMenu,
      Form
    },

    data () {
      
      return {
        resourceTypeById: {
          name: '',
          description: '',
          min_pax: '',
          max_pax: '',
          duration: '',
          color: '',
          padding_time: '',
          price_type: 'single',
          price: 0.00
        },
        
        nameRules: [
          v => !!v || 'Name is required',
        ],
        characterRules: [
          v => (v.length <= 191) || 'Field must be less than or equal to 191 characters',
        ],
        routeParam: this.$route.params.id,
        alert: this.$route.params.alert_type?true:false,
        alert_type: this.$route.params.alert_type,
        message: this.$route.params.message,

        tab: null,

        dates: {1:"Monday",2:"Tuesday",3:"Wednesday",4:"Thursday",5:"Friday",6:"Saturday",7:"Sunday"},
        prices: [{amount:null},{amount:null},{amount:null},{amount:null},{amount:null},{amount:null},{amount:null}],
        custom_prices: [],
        getAllPermissions: [],
        allPermissionsName: [],
        staffPermission: {},
      }
    },
    apollo: {
      resourceTypeById: {
        query: GET_RESOURCE_TYPE_QUERY,
        variables() {
          return{
            id: this.routeParam
          }
        },
        pollInterval: 300,
        result() {
          for(let x=0; x<this.prices.length; x++){
            for(let i=0; i<this.resourceTypeById.custom_price.length; i++){
              if(x+1 == this.resourceTypeById.custom_price[i].day){
                this.prices[x].amount = this.resourceTypeById.custom_price[i].amount
                break
              }
            }
          }
          this.custom_prices = []
          for(let i=0; i<this.resourceTypeById.custom_price.length; i++){
            if(this.resourceTypeById.custom_price[i].date != null){
              this.custom_prices.push({datemenu:false, date:new Date(this.resourceTypeById.custom_price[i].date*1).toISOString().slice(0, 10), amount:this.resourceTypeById.custom_price[i].amount})
            }
          }
        }
      },
    },
    methods: {
      validate () {
        var validate = this.$refs.form.validate()
        if(!validate){
          window.scrollTo(0,0);
        }
        if(validate){
          const UPDATE_RESOURCE_TYPE_QUERY = gql`
            mutation updateResourceType($id: ID!, $name: String!, $description:String, $price_type:String!, $price:Float, $min_pax:Int!, $max_pax:Int, $duration:Int!, $color:String, $padding_time: Int) {
                updateResourceType(id:$id, name:$name, description:$description, price_type:$price_type, price:$price, min_pax:$min_pax, max_pax:$max_pax, duration:$duration, color:$color, padding_time:$padding_time)  {
                  id
                  name
                }
            }
          `

          this.$apollo.mutate(
            { 
              mutation: UPDATE_RESOURCE_TYPE_QUERY, 
              variables:{
                id: this.routeParam,
                name: this.resourceTypeById.name,
                description: this.resourceTypeById.description,
                min_pax: this.resourceTypeById.min_pax*1,
                max_pax: this.resourceTypeById.max_pax*1,
                duration: this.resourceTypeById.duration*1,
                color: this.resourceTypeById.color,
                padding_time: this.resourceTypeById.padding_time*1,
                price: this.resourceTypeById.price*1,
                price_type: 'single',
              },
            }
          ).then(result => {
            if(result.data.updateResourceType != null){ 
              var resource_type_id = result.data.updateResourceType.id*1
              console.log("Update successfully")
              const DELETE_CUSTOM_PRICE_QUERY = gql`
                mutation deleteResourceTypeCustomPrice($resource_type_id:Int) {
                    deleteResourceTypeCustomPrice(resource_type_id:$resource_type_id)  {
                      resource_type_id
                    }
                }
              `
              const CREATE_CUSTOM_PRICE_QUERY = gql`
                mutation createCustomPrice($resource_type_id:Int, $service_id:Int, $day:Int, $date:String, $amount:Float, $description:String){
                  createCustomPrice(resource_type_id:$resource_type_id, service_id:$service_id, day:$day, date:$date, amount:$amount, description:$description){
                    id
                  }
                }
              `
              this.$apollo.mutate(
                { 
                  mutation: DELETE_CUSTOM_PRICE_QUERY, 
                  variables:{
                    resource_type_id: result.data.updateResourceType.id*1
                  },
                }
              )
              .then(()=>{
                for(let i = 0; i < this.prices.length; i++){
                  if(this.prices[i].amount != null){
                    this.$apollo.mutate(
                      { 
                        mutation: CREATE_CUSTOM_PRICE_QUERY, 
                        variables:{
                          resource_type_id: resource_type_id,
                          day: i+1,
                          amount: this.prices[i].amount*1
                        },
                      }
                    )
                  }
                }
                for(let i = 0; i < this.custom_prices.length; i++){
                  if(this.custom_prices[i].amount != null && this.custom_prices[i].date != null){
                    this.$apollo.mutate(
                      { 
                        mutation: CREATE_CUSTOM_PRICE_QUERY, 
                        variables:{
                          resource_type_id: resource_type_id,
                          date: this.custom_prices[i].date,
                          amount: this.custom_prices[i].amount*1
                        },
                      }
                    )
                  }
                }
              })
              this.alert = true
              this.alert_type='success'
              this.message = result.data.updateResourceType.name+' updated successfully'
              window.scrollTo(0,0)
            } else {
              this.alert = true
              this.alert_type='error'
              this.message = 'Updated fail'
              window.scrollTo(0,0)
            }
          });
        }
      },
      deleteResourceType() {
        const DELETE_RESOURCE_TYPE_QUERY = gql`
          mutation deleteResourceType($id:ID!) {
              deleteResourceType(id:$id)  {
                name
              }
          }
        `
        this.$apollo.mutate(
          { 
            mutation: DELETE_RESOURCE_TYPE_QUERY, 
            variables:{
              id: this.routeParam,
            },
          }
        ).then(result => {
          if(result.data.deleteResourceType != null){ 
            console.log("Delete successfully")
            this.$router.push({name: 'Resource Type', params:{alert:true, alert_type:'success', message:result.data.deleteResourceType.name+' deleted successfully'}})
          } else {
            console.log("Delete failed")
          }
        });
      },
      addCustomPrice() {
        this.custom_prices.push({datemenu:false, date:null, amount:null})
      },
      removeCustomPrice(index) {
        this.custom_prices.splice(index,1)
      }
    },
    created(){
      this.$apollo.query({ query: queries.GET_ALL_PERMISSIONS_QUERY }).then(async result => {
        this.getAllPermissions = await result.data.getAllPermissions;
        this.$apollo.query(
          {
            query: queries.GET_STAFF_ROLES,
            variables: {
              staffId: this.$session.get("user_id") * 1
            }
          }
        ).then(async result => {
          this.getStaffRoles = await result.data.getStaffRoles;
          this.allPermissionsName = [];
          this.getStaffRoles.forEach(role => {
            role.role.RolePermission.forEach((item) => {
              const name = item.permission.name;
              this.allPermissionsName.push(name);
            });
          })
          this.$session.set('staffPermission', this.$generalFunctions.generatePermissionArray(this.getAllPermissions, this.allPermissionsName));
          this.staffPermission = await this.$generalFunctions.generatePermissionArray(this.getAllPermissions, this.allPermissionsName)
        })
      })
    }
  }
</script>
