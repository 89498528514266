<template>
  <v-app>
    <SideMenu :alert="alert" :alert_type="alert_type" :message="message" submenuPage="Services" submenuGroup="Service Setup">
      <v-alert
        :type="alert_type || 'info'"
        dismissible
        v-model="alert"
    >{{message}}</v-alert>
      <Form>
        <template v-slot:tabs>
          <v-tabs
            v-model="tab"
          >
            <v-tabs-slider></v-tabs-slider>
            <v-tab href="#details">Details</v-tab>
            <v-tab href="#price">Price</v-tab>
            <v-tab href="#outlet">Outlet</v-tab>
            <v-tab href="#staff">Staff</v-tab>
          </v-tabs>
        </template>
        <template v-slot:form>
          <v-tabs-items v-model="tab">
            <v-tab-item value="details">
              <v-form
                ref="form"
                lazy-validation
              >
                <v-select
                  v-model="serviceById.service_type_id"
                  :items="serviceTypes"
                  item-value="id"
                  item-text="name"
                  label="Service Type"
                  :rules="[() => !!serviceById.service_type_id || 'Service type is required']"
                ></v-select>

                <v-text-field
                  v-model="serviceById.name"
                  :counter="191"
                  :rules="nameRules.concat(characterRules)"
                  label="Name"
                  required
                ></v-text-field>

                <v-text-field
                  v-model="serviceById.description"
                  :counter="191"
                  :rules="characterRules"
                  label="Description"
                ></v-text-field>

                <v-text-field
                  v-model="serviceById.duration"
                  label="Duration (minutes)"
                  type="number"
                  min="0"
                  step="1"
                  oninput="validity.valid||(value='');"
                  class="v-text-field-number"
                  :rules="durationRules"
                ></v-text-field>

                <!-- <v-text-field
                  v-model="serviceById.padding_time"
                  label="Padding Time (minutes)"
                  type="number"
                  class="v-text-field-number"
                  min="0"
                  step="1"
                  oninput="validity.valid||(value='');"
                ></v-text-field> -->

                <div>Image Upload</div>
                <div id="app">
                  <vue-dropzone
                    ref="dropzone"
                    id="drop1"
                    v-model="files"
                    :options="dropOptions"
                    :destroyDropzone="false"
                    @vdropzone-complete="afterComplete"
                    @vdropzone-success="uploadSuccess"
                    @vdropzone-removed-file='removeThisFile'
                  ></vue-dropzone>
                  <input id="image_id" type="hidden" v-model="serviceById.image_id"/>
                </div>
                <br/>

                <v-color-picker
                  dot-size="25"
                  hide-mode-switch
                  mode="hexa"
                  swatches-max-height="200"
                  label="Color"
                  v-model="serviceById.color"
                ></v-color-picker>
              </v-form>
            </v-tab-item>
            <v-tab-item value="price">
              <v-form
                ref="form1"
                lazy-validation
              >
                <v-text-field
                  v-model="serviceById.price"
                  label="Default Price"
                  type="number"
                  class="v-text-field-number"
                  step="0.01"
                  min="0"
                ></v-text-field>
                <div>
                  Custom Daily Price
                </div>
                <table class="roster">
                  <tr>
                    <td>
                      Day
                    </td>
                    <td 
                      v-for="date in dates"
                      :key="date"
                    >
                      {{date}}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Price
                    </td>
                    <td
                      v-for="(price, index) in prices"
                      :key="index"
                    >
                      <v-text-field
                        v-model="price.amount"
                        label="Price"
                        type="number"
                        step="0.01"
                        min="0"
                      ></v-text-field>
                    </td>
                  </tr>
                </table>
                <br/>
                <v-btn
                  color="primary"
                  class="mr-4"
                  @click="addCustomPrice"
                >
                  + New Custom Price
                </v-btn>
                <br/>
                <table>
                  <tr
                    v-for="(custom_price, index) in custom_prices"
                    :key="index"
                  >
                    <td>
                      <v-menu
                        v-model="custom_price.datemenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="custom_price.date"
                            prepend-icon="mdi-calendar"
                            placeholder="Date"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="custom_price.date"
                          @input="custom_price.datemenu = false"
                        ></v-date-picker>
                      </v-menu>
                    </td>
                    <td>
                      <v-text-field
                        v-model="custom_price.amount"
                        label="Price"
                        type="number"
                        step="0.01"
                        min="0"
                      ></v-text-field>
                    </td>
                    <td>
                      <v-btn
                        color="error"
                        @click="removeCustomPrice(index)"
                      >
                        remove
                      </v-btn>
                    </td>
                  </tr>
                </table>
              </v-form>
            </v-tab-item>
            <v-tab-item value="outlet">
              <v-form
                ref="form2"
                lazy-validation
              >
                <v-checkbox
                  v-model="service_outlet"
                  v-for="outlet in outlets"
                  :key="outlet.id"
                  :label="outlet.name"
                  :value="outlet.id"
                ></v-checkbox>
              </v-form>
            </v-tab-item>
            <v-tab-item value="staff">
              <v-form
                ref="form3"
                lazy-validation
              >
                <v-checkbox
                  v-model="staff_service"
                  v-for="staff in staffs"
                  :key="staff.id"
                  :label="staff.fullname"
                  :value="staff.id"
                ></v-checkbox>
              </v-form>
            </v-tab-item>
          </v-tabs-items>
        </template>
        <template v-slot:buttons>
          <v-btn
            class="mr-4"
            text
            @click="$router.push({ path: '/services' })"
          >
            Back to list
          </v-btn>
          <v-btn
            color="error"
            class="mr-4"
            @click.stop="dialog = true"
            v-if="staffPermission.deleteService"
          >
            Delete
          </v-btn>
          <v-btn
            color="success"
            class="mr-4"
            @click="validate"
            v-if="staffPermission.updateService"
          >
            Update
          </v-btn>
        </template>
      </Form>
    </SideMenu>
    <template>
      <v-row justify="center">
      <v-dialog
        v-model="dialog"
        max-width="290"
      >
        <v-card>
          <v-card-title class="text-h5">
            Delete Confirmation
          </v-card-title>

          <v-card-text>
            Are you sure to delete the service?
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              text
              @click="dialog = false"
            >
              No
            </v-btn>

            <v-btn
              text
              @click="deleteService"
            >
              Yes
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      </v-row>
    </template>
  </v-app>
</template>

<script>
import SideMenu from '/src/components/SideMenu'
import Form from '/src/components/Form'
import gql from 'graphql-tag'
import * as queries from '@/graphql/gqlQueries.js';
import vueDropzone from "vue2-dropzone"
  const GET_ALL_SERVICE_TYPES_QUERY = gql`
      query ServiceTypes {
        serviceTypes {
          id
          name
        }
      }
  `
  const GET_SERVICE_QUERY = gql`
      query Service($id: ID!) {
        serviceById(id: $id) {
            name
            description
            price
            duration
            color
            price_type
            service_type_id
            padding_time
            image_id
            service_outlet{
              outlet_id
            }
            staff_service{
              staff_id
            }
            custom_price{
              day
              date
              amount
              description
            }
        }
      }
  `
  const GET_ALL_OUTLETS_QUERY = gql`
      query Outlets {
        outlets {
          id
          name
          created_at
        }
      }
  `
  const GET_ALL_STAFFS_QUERY = gql`
      query Staffs {
        staffs {
          id
          fullname
        }
      }
  `
  export default {
    name: 'Service',

    components:{
      SideMenu,
      vueDropzone,
      Form
    },
    data () {
      
      return {
        serviceById: {
          name: '',
          description: '',
          price: '',
          duration: '',
          color: '',
          price_type: 'single',
          service_type_id: '',
          padding_time: ''
        },
        
        nameRules: [
          v => !!v || 'Name is required',
        ],
        characterRules: [
          v => (v.length <= 191) || 'Field must be less than or equal to 191 characters',
        ],
        durationRules: [
          v => v != 0 || 'Duration has to be at least 1 minute',
        ],
        dropOptions: {
          url: "https://httpbin.org/post",//dummy url, since 'url' is a required field but we are not using it
          maxFilesize: 2, // MB
          maxFiles: 1,
          // chunking: true,
          // chunkSize: 500, // Bytes
          thumbnailWidth: 150, // px
          thumbnailHeight: 150,
          addRemoveLinks: true,
        },

        routeParam: this.$route.params.id,
        alert: this.$route.params.alert_type?true:false,
        alert_type: this.$route.params.alert_type,
        message: this.$route.params.message,
        serviceTypes: [],

        service_outlet:[],
        outlets: [],
        staff_service:[],
        services: [],
        tab: null,

        dates: {1:"Monday",2:"Tuesday",3:"Wednesday",4:"Thursday",5:"Friday",6:"Saturday",7:"Sunday"},
        prices: [{amount:null},{amount:null},{amount:null},{amount:null},{amount:null},{amount:null},{amount:null}],
        custom_prices: [],

        files:[],

        dialog:false,
        getAllPermissions: [],
        allPermissionsName: [],
        staffPermission: {},
      }
    },
    apollo: {
      serviceById: {
        query: GET_SERVICE_QUERY,
        variables() {
          return{
            id: this.routeParam
          }
        },
        pollInterval: 300,
        result() {
          this.serviceById.service_type_id = this.serviceById.service_type_id.toString()
          var outlets = []
          var staffs = []
          for(let i=0; i<this.serviceById.service_outlet.length; i++){
            outlets.push(this.serviceById.service_outlet[i].outlet_id.toString())
          }
          for(let i=0; i<this.serviceById.staff_service.length; i++){
            staffs.push(this.serviceById.staff_service[i].staff_id.toString())
          }
          this.service_outlet = outlets
          this.staff_service = staffs

          for(let x=0; x<this.prices.length; x++){
            for(let i=0; i<this.serviceById.custom_price.length; i++){
              if(x+1 == this.serviceById.custom_price[i].day){
                this.prices[x].amount = this.serviceById.custom_price[i].amount
                break
              }
              else{
                this.prices[x].amount = this.serviceById.price
              }
            }
          }
          this.custom_prices = []
          for(let i=0; i<this.serviceById.custom_price.length; i++){
            if(this.serviceById.custom_price[i].date != null){
              this.custom_prices.push({datemenu:false, date:new Date(this.serviceById.custom_price[i].date*1).toISOString().slice(0, 10), amount:this.serviceById.custom_price[i].amount})
            }
          }

          if(this.serviceById.image_id != 0 && this.serviceById.image_id != null){
            const GET_IMAGE_QUERY = gql`
              query imageById($id: ID!) {
                  imageById(id:$id)  {
                    id
                    type
                    filename
                  }
              }
            `
            this.$apollo.query(
              { 
                query: GET_IMAGE_QUERY, 
                variables:{
                  id: this.serviceById.image_id
                },
              }
            ).then(result => {
              if(result.data.imageById != null && this.$refs.dropzone){
                var file = { name: result.data.imageById.filename, size:123 }
                this.files.push(file)
                var url = "https://prod-app360.sfo3.digitaloceanspaces.com/"+result.data.imageById.filename
                if(this.files.length == 1){
                  this.$refs.dropzone.manuallyAddFile(file, url)
                }
              } else {
                console.log("Image not found")
              }
            });
          }
        }
      },
      serviceTypes: {
        query: GET_ALL_SERVICE_TYPES_QUERY,
      },
      outlets: {
        query: GET_ALL_OUTLETS_QUERY,
      },
      staffs: {
        query: GET_ALL_STAFFS_QUERY,
        result() {
          for(let i=0; i<this.staffs.length; i++){
            if(this.staffs[i].id == 1){
              this.staffs.splice(i, 1)
              break
            }
          }
        }
      },
    },
    methods: {
      validate () {
        var validate = this.$refs.form.validate()
        if(!validate){
          window.scrollTo(0,0);
        }
        if(validate){
          const UPDATE_SERVICE_QUERY = gql`
            mutation updateService($id: ID!, $name: String!, $description:String, $price:Float, $duration: Int, $color:String, $price_type: String!, $service_type_id: Int!, $image_id: Int! $padding_time: Int) {
                updateService(id:$id, name:$name, description:$description, price:$price, duration: $duration, color:$color, price_type: $price_type, service_type_id: $service_type_id, image_id: $image_id padding_time: $padding_time)  {
                  id
                  name
                }
            }
          `

          this.$apollo.mutate(
            { 
              mutation: UPDATE_SERVICE_QUERY, 
              variables:{
                id: this.routeParam,
                name: this.serviceById.name,
                description: this.serviceById.description,
                price: this.serviceById.price*1,
                price_type: 'single',
                duration: this.serviceById.duration*1,
                color: this.serviceById.color,
                service_type_id: this.serviceById.service_type_id*1,
                image_id: this.serviceById.image_id*1,
                padding_time: this.serviceById.padding_time*1,
              },
            }
          ).then(result => {
            if(result.data.updateService != null){ 
              var service_id = result.data.updateService.id*1
              console.log("Update successfully")
              const DELETE_SERVICE_OUTLET_QUERY = gql`
                mutation deleteServiceOutlet($service_id:Int!) {
                    deleteServiceOutlet(service_id:$service_id)  {
                      service_id
                    }
                }
              `
              const CREATE_SERVICE_OUTLET_QUERY = gql`
                mutation createServiceOutlet($service_id:Int!, $outlet_id:Int!) {
                    createServiceOutlet(service_id:$service_id, outlet_id:$outlet_id)  {
                      service_id
                    }
                }
              `
              const DELETE_STAFF_SERVICE_QUERY = gql`
                mutation deleteStaffService($service_id:Int!) {
                    deleteStaffService(service_id:$service_id)  {
                      service_id
                    }
                }
              `
              const CREATE_STAFF_SERVICE_QUERY = gql`
                mutation createStaffService($staff_id:Int!, $service_id:Int!) {
                    createStaffService(staff_id:$staff_id, service_id:$service_id)  {
                      service_id
                    }
                }
              `
              const DELETE_CUSTOM_PRICE_QUERY = gql`
                mutation deleteServiceCustomPrice($service_id:Int) {
                    deleteServiceCustomPrice(service_id:$service_id)  {
                      service_id
                    }
                }
              `
              const CREATE_CUSTOM_PRICE_QUERY = gql`
                mutation createCustomPrice($resource_type_id:Int, $service_id:Int, $day:Int, $date:String, $amount:Float, $description:String){
                  createCustomPrice(resource_type_id:$resource_type_id, service_id:$service_id, day:$day, date:$date, amount:$amount, description:$description){
                    id
                  }
                }
              `
              this.$apollo.mutate(
                { 
                  mutation: DELETE_CUSTOM_PRICE_QUERY, 
                  variables:{
                    service_id: result.data.updateService.id*1
                  },
                }
              )
              .then(()=>{
                for(let i = 0; i < this.prices.length; i++){
                  if(this.prices[i].amount != null){
                    this.$apollo.mutate(
                      { 
                        mutation: CREATE_CUSTOM_PRICE_QUERY, 
                        variables:{
                          service_id: service_id,
                          day: i+1,
                          amount: this.prices[i].amount*1
                        },
                      }
                    )
                  }
                }
                for(let i = 0; i < this.custom_prices.length; i++){
                  if(this.custom_prices[i].amount != null && this.custom_prices[i].date != null){
                    this.$apollo.mutate(
                      { 
                        mutation: CREATE_CUSTOM_PRICE_QUERY, 
                        variables:{
                          service_id: service_id,
                          date: this.custom_prices[i].date,
                          amount: this.custom_prices[i].amount*1
                        },
                      }
                    )
                  }
                }
              })
              
              this.$apollo.mutate(
                { 
                  mutation: DELETE_SERVICE_OUTLET_QUERY, 
                  variables:{
                    service_id: result.data.updateService.id*1
                  },
                }
              )
              .then(()=>{
                for(let i = 0; i < this.service_outlet.length; i++){
                  this.$apollo.mutate(
                    { 
                      mutation: CREATE_SERVICE_OUTLET_QUERY, 
                      variables:{
                        service_id: service_id,
                        outlet_id: this.service_outlet[i]*1
                      },
                    }
                  )
                }
              })
              
              this.$apollo.mutate(
                { 
                  mutation: DELETE_STAFF_SERVICE_QUERY, 
                  variables:{
                    service_id: result.data.updateService.id*1
                  },
                }
              )
              .then(()=>{
                for(let i = 0; i < this.staff_service.length; i++){
                  this.$apollo.mutate(
                    { 
                      mutation: CREATE_STAFF_SERVICE_QUERY, 
                      variables:{
                        staff_id: this.staff_service[i]*1,
                        service_id: service_id
                      },
                    }
                  )
                }
              })
              this.alert = true
              this.alert_type='success'
              this.message = result.data.updateService.name +' updated successfully'
              window.scrollTo(0,0)
            } else {
              console.log("Update failed")
              this.alert = true
              this.alert_type='error'
              this.message = 'Updated fail'
              window.scrollTo(0,0)
            }
          });
        }
      },
      maxFileExceeded(file) {
        this.removeAllFiles();
        this.addFile(file);
      },
      afterComplete(file) {
        if(!file.manuallyAdded){
          var req = process.env.VUE_APP_SERVER_DOMAIN + 'image/upload?filename=' + file.name
          fetch(req, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              'dataURL': file.dataURL
            })
          })
          .then(res => {
            if(!res.ok){
              console.log('error')
            }
            else{
              res.json().then(data => {
                this.files.push({'filename':data.filename})
                const CREATE_IMAGE_QUERY = gql`
                  mutation createImage($type: String!, $filename: String!) {
                      createImage(type: $type, filename: $filename)  {
                        id
                        filename
                      }
                  }
                `
                this.$apollo.mutate(
                  { 
                    mutation: CREATE_IMAGE_QUERY, 
                    variables:{
                      type: "service",
                      filename: process.env.VUE_APP_DO_SPACES_FOLDER+'/'+file.name,
                    },
                  }
                ).then(result => {
                  if(result.data.createImage != null){ 
                    this.serviceById.image_id = result.data.createImage.id
                    console.log("Create successfully")
                  } else {
                    console.log("Create failed")
                  }
                });
              })
            }
          })
          .catch((err) => {
            console.log(err)
          })
        }
      },
      uploadSuccess(file, response) {
        console.log('File Successfully Uploaded with file name: ' + response.file + file)
      },
      removeThisFile(){
        this.serviceById.image_id = 0
      },
      deleteService() {
        this.dialog = false
        const DELETE_SERVICE_QUERY = gql`
          mutation deleteService($id:ID!) {
              deleteService(id:$id)  {
                name
              }
          }
        `
        this.$apollo.mutate(
          { 
            mutation: DELETE_SERVICE_QUERY, 
            variables:{
              id: this.routeParam,
            },
          }
        ).then(result => {
          if(result.data.deleteService != null){ 
            console.log("Delete successfully")
            this.$router.push({name: 'Service', params:{alert:true, alert_type:'success', message:result.data.deleteService.name+' deleted successfully'}})
          } else {
            console.log("Delete failed")
          }
        });
      },
      addCustomPrice() {
        this.custom_prices.push({datemenu:false, date:null, amount:null})
      },
      removeCustomPrice(index) {
        console.log(index)
        this.custom_prices.splice(index,1)
      }
    },
    created(){
      this.$apollo.query({ query: queries.GET_ALL_PERMISSIONS_QUERY }).then(async result => {
        this.getAllPermissions = await result.data.getAllPermissions;
        this.$apollo.query(
          {
            query: queries.GET_STAFF_ROLES,
            variables: {
              staffId: this.$session.get("user_id") * 1
            }
          }
        ).then(async result => {
          this.getStaffRoles = await result.data.getStaffRoles;
          this.allPermissionsName = [];
          this.getStaffRoles.forEach(role => {
            role.role.RolePermission.forEach((item) => {
              const name = item.permission.name;
              this.allPermissionsName.push(name);
            });
          })
          this.$session.set('staffPermission', this.$generalFunctions.generatePermissionArray(this.getAllPermissions, this.allPermissionsName));
          this.staffPermission = await this.$generalFunctions.generatePermissionArray(this.getAllPermissions, this.allPermissionsName)
        })
      })
    }
  }
</script>
