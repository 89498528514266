<template>
  <v-card
    height="100%"
    width="100%"
    class="mx-auto"
  >
  <v-app-bar
      transparent
      elevation="0"
      color="white"
    >
    <v-app-bar-nav-icon @click.stop="navBarCollapse"></v-app-bar-nav-icon>

    <v-toolbar-title>{{this.$route.name}}</v-toolbar-title>

    <v-spacer></v-spacer>

    <v-menu
      bottom
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          text
        >
          <v-icon>mdi-account</v-icon>
          <v-icon>mdi-chevron-down</v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item
          v-for="(profile_menu, index) in profile_menus"
          :key="index"
        >
          <v-icon>{{ profile_menu.icon }}</v-icon>
          <router-link :to="profile_menu.path" style="text-decoration: none; color: inherit;">
            <v-list-item-title>{{ profile_menu.title }}</v-list-item-title>
          </router-link>
        </v-list-item>
        <v-list-item>
          <v-icon>mdi-exit-to-app</v-icon>
          <v-list-item @click="logout" style="text-decoration: none; color: inherit;">
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
    <v-navigation-drawer 
      v-model="drawer"
      :mini-variant.sync="mini"
      app
      fixed
      permanent
      dark
      color="#002242"
      >
      <v-list-item class="px-2 padding-top-15">
        <v-list-item-avatar>
          <v-img :src="image"></v-img>
        </v-list-item-avatar>
        <v-list-item-title class="title">
          {{ staffById.fullname }}
        </v-list-item-title>
      </v-list-item>
      <v-divider style="border-color:grey; opacity:1;"></v-divider>

      <v-list
        dense
        nav
      >
        <v-list-item
          v-for="(submenu,submenu_index) in filtredsubmenus"
          :key="submenu_index"
          :href="submenu.route"
          :class="submenu.title == submenuPage ? 'submenu-active' : ''"
          :target="submenu.target"
        >
          <v-list-item-icon v-if="!submenu.submenu">
            <v-icon>{{ submenu.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item>
            <div v-if="!submenu.submenu">
              <v-list-item-title>{{ submenu.title }}</v-list-item-title>
            </div>
            <v-list-group
              v-else
              style="margin-left:-15px;"
              color="white"
              :value="submenu.title == submenuGroup ? true : false"
            >
              <template v-slot:activator>
                <v-list-item-icon>
                  <v-icon>{{ submenu.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title style="margin-left:5px; width:98px">{{ submenu.title }}</v-list-item-title>
              </template>

              <v-list-item
                  v-for="(sub, sub_index) in submenu.submenu"
                  :href="sub.route"
                  :key="sub_index"
                  :target="submenu.target"
                  style="padding-left:20px;"
                  :class="sub.title == submenuPage ? 'submenu-active' : ''"
              >
                <v-list-item-icon>
                  <v-icon>{{ sub.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title> {{ sub.title }}</v-list-item-title>
              </v-list-item>
            </v-list-group>
          </v-list-item>
        </v-list-item>
      </v-list>
      <template v-slot:append v-if="!this.mini">
        <div class="text-center">
          <v-chip
          class="ma-2"
          color="primary"
        >
          {{ version }}
        </v-chip>
        </div>
      </template>
    </v-navigation-drawer>
    <v-main class="pa-10">
      <Alert :alert_type="type" v-if="alert_condition">{{ alert_message }}</Alert>
      <slot></slot>
    </v-main>
  </v-card>
</template>

<script>
import Alert from '/src/components/Alert'
import gql from 'graphql-tag'
import * as queries from '@/graphql/gqlQueries.js';
import VueCookies from 'vue-cookies'
  const GET_SETTINGS_QUERY = gql`
      query settings {
        settings {
          slot_duration,
          booking_url,
          image_id
        }
      }
  `
  const GET_USER_QUERY = gql`
      query Staff($id: ID!) {
        staffById(id: $id) {
            fullname
            status
            admin
            staff_outlet{
              outlet_id
            }
        }
      }
  `
  const GET_ALL_OUTLET_QUERY = gql`
      query Outlets($staff_id:Int, $service_id:Int) {
        outlets(staff_id:$staff_id, service_id:$service_id) {
          id
          name
          staff_outlet{
            outlet_id
          }
          service_outlet{
            outlet_id
          }
          image_id
        }
      }
  `
  var staff_id = null

  export default {
    name: 'SideMenu',
    components:{
      Alert
    },
    beforeCreate: function () {
      if (!this.$session.exists()) {
        this.$router.push('/')
      }
      staff_id = this.$session.get('user_id')
    },
    props:['alert','alert_type','message','submenuPage','submenuGroup'],
    data () {
      var submenus = [ // admin submenu
        { title: 'Dashboard', icon: 'mdi-view-dashboard', permission:'viewDashboard'},
        { title: 'Calendar', icon: 'mdi-calendar', route: '/calendar', permission:'viewBooking'},
        { title: 'Staff', icon: 'mdi-account-group', route: '/staffs', permission:'viewStaff'},
        { title: 'Roles', icon: 'mdi-account-key', route: '/roles', permission:'viewRole' },
        { title: 'Outlet Setup', icon: 'mdi-home-city', permission:'viewOutlet', submenu:[
            {title: 'Outlet', route: '/outlets', icon: 'mdi-office-building-marker', permission:'viewOutlet'},
            {title: 'Sessions', route: '/sessions', icon: 'mdi-clock-time-five-outline', permission:'viewOutletSession'}
          ]
        },
        { title: 'Service Setup', icon: 'mdi-briefcase', permission:'viewService', submenu:[
            {title: 'Service Type', route: '/type/services', icon: 'mdi-briefcase-search', permission:'viewServiceType'},
            {title: 'Services', route: '/services', icon: 'mdi-briefcase-edit', permission:'viewService'},
            {title: 'Resource Type', route: '/type/resources', icon: 'mdi-folder-home-outline', permission:'viewResourceType'},
            {title: 'Resources', route: '/resources', icon: 'mdi-home-edit', permission:'viewResource'}
          ]
        },
        { title: 'Packages', icon: 'mdi-package', route: '/packages', permission:'viewPackage'},
        { title: 'Customer', icon: 'mdi-account-box-multiple', route: '/customers', permission:'viewCustomer'},
        { title: 'Listing', icon: 'mdi-file-chart', permission:'viewListing',submenu:[
            {title: 'Commission Listing', route: '/listing/commission', icon: 'mdi-hand-coin-outline', permission:['viewAllOutletsListing','viewOutletsListing','viewOwnListing']},
            {title: 'Sales Listing', icon: 'mdi-currency-usd', route: '/listing/sales', permission: ['viewAllOutletsSalesListing','viewOutletsSalesListing']},
          ] 
        },
        { title: 'Online Booking', icon: 'mdi-cloud', route: '', target: '_blank'},
        { title: 'Settings', icon: 'mdi-cog', route: '/settings'},
      ]
      return {
        drawer: true,
        submenus: submenus,
        profile_menus:[
          { title: 'Edit Profile', icon: 'mdi-account-edit', path: '/' },
        ],
        width: window.outerWidth,
        closeCount: 0,
        mini: VueCookies.get('mini') === 'true' ?? false ,
        route_name: this.$route.name,
        type: this.alert_type,
        alert_condition: this.alert,
        alert_message: this.message,
        settings:[],
        booking_url: '',
        staff_outlet:[],
        staffById: {
          fullname: '',
          status: 'pending',
          admin: 0,
          outlets: [],
        },
        outlets: [],
        getAllPermissions: [],
        allPermissionsName: [],
        staffPermission: {},
        filtredsubmenus: [],
        image: null,
        version: "v1.0.4",
      }
    },
    created() {
      window.addEventListener("resize", this.onResize);
      if (this.width < 1025 && !this.closeCount) {
        this.mini = true
        this.closeCount++
      }
      this.$apollo.query({ query: GET_ALL_OUTLET_QUERY}).then(async result=>{
          this.outlets = await result.data.outlets
      })
      // Fetch getAllPermissions query
      this.$apollo.query({ query: queries.GET_ALL_PERMISSIONS_QUERY }).then(async result => {
        // Process result and set data as needed
        // For example, you can set this.getAllPermissions or perform other operations
        this.getAllPermissions = await result.data.getAllPermissions;
        // Fetch getStaffRoles query
        this.$apollo.query(
          {
            query: queries.GET_STAFF_ROLES,
            variables: {
              staffId: this.$session.get("user_id") * 1
            }
          }
        ).then(async result => {
          // Process result and set data as needed
          // For example, you can set this.getStaffRoles or perform other operations
          this.getStaffRoles = await result.data.getStaffRoles;
          // Now that you have both data, you can proceed with the rest of your logic
          this.allPermissionsName = [];
          this.getStaffRoles.forEach(role => {
            role.role.RolePermission.forEach((item) => {
              const name = item.permission.name;
              this.allPermissionsName.push(name);
            });
          })
          this.$session.set('staffPermission', this.$generalFunctions.generatePermissionArray(this.getAllPermissions, this.allPermissionsName));
          this.staffPermission = await this.$generalFunctions.generatePermissionArray(this.getAllPermissions, this.allPermissionsName)
          this.filtredsubmenus = this.submenus.filter(submenu => {
            if (submenu.submenu) {
              submenu.submenu = submenu.submenu.filter(sub => {
                const permissions = Array.isArray(sub.permission) ? sub.permission : [sub.permission];
                const hasPermission = permissions.some(permission => this.staffPermission[permission]);
                return hasPermission;
              });
            }
            if (submenu.permission) {
              const permissions = Array.isArray(submenu.permission) ? submenu.permission : [submenu.permission];
              const hasPermission = permissions.some(permission => this.staffPermission[permission]);
              return hasPermission;
            } else {
              return true
            }
          });
          this.$forceUpdate()
        });
      });
    },
    apollo:{
      settings: {
        query: GET_SETTINGS_QUERY,
        result(){
          for(let i=0; i<this.submenus.length; i++){
            if(this.submenus[i].title == 'Online Booking'){
              let bookingUrl = this.settings.booking_url;
              // Check if the URL starts with "https://" or "http://"
              if (!bookingUrl.startsWith('https://') && !bookingUrl.startsWith('http://')) {
                bookingUrl = 'https://' + bookingUrl;
              }
              this.submenus[i].route = bookingUrl
              break
            }
          }
          if(this.settings.image_id != 0 && this.settings.image_id != null){
            const GET_IMAGE_QUERY = gql`
              query imageById($id: ID!) {
                  imageById(id:$id)  {
                    id
                    type
                    filename
                  }
              }
            `
            this.$apollo.query(
              { 
                query: GET_IMAGE_QUERY, 
                variables:{
                  id: this.settings.image_id * 1
                },
              }
            ).then(result => {
              if(result.data.imageById != null){ 
                this.image = "https://prod-app360.sfo3.digitaloceanspaces.com/"+result.data.imageById.filename
              } else {
                console.log("Image not found")
              }
            });
          }
        }
      },
      outlets: {
        query: GET_ALL_OUTLET_QUERY,
        pollInterval: 300,
      },
      staffById: {
        query: GET_USER_QUERY,
        variables() {
          return{
            id: staff_id
          }
        },
        result() {
          var outlets = []
          for(let i=0; i<this.staffById.staff_outlet.length; i++){
            outlets.push(this.staffById.staff_outlet[i].outlet_id.toString())
          }
          if(this.$session.get("user_id") * 1 == 1 && this.outlets.length > 0){
            outlets = []
            this.outlets.forEach(o =>{
              outlets.push(o.id.toString())
            }
            )
          }
          this.staff_outlet = outlets
          this.$session.set('outlets', this.staff_outlet)
        }
      },
    },
    methods: {
      logout: function () {
        this.$session.destroy()
        this.$router.push('/')
      },
      navBarCollapse: function() {
        VueCookies.set('mini', !this.mini)
        this.mini = !this.mini
      },
      onResize() {
        var prev =  this.width * 1
        this.width = window.outerWidth;
        if(this.width != prev && this.width < 1025) {
          this.mini = true
        }
      },
    },
    destroyed() {
      window.removeEventListener("resize", this.onResize);
    },
  }
</script>

<style>
.v-navigation-drawer__content::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.v-navigation-drawer__content {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
</style>