<template>
    <v-app>
        <SideMenu :alert="alert" :alert_type="alert_type" :message="message" submenuPage="Sales Listing"
            submenuGroup="Listing">
            <List>
                <template v-slot:action-button>
                    <v-btn color="primary" class="mr-4" @click="exportCSV">
                        Export (csv)
                    </v-btn>
                </template>
                <template v-slot:tabs>
                    <v-row class="align-items-center">
                        <v-col class="py-1" cols="3">
                            <v-text-field v-model="sales_id" outlined dense hide-details label="Sale Id"></v-text-field>
                        </v-col>
                        <v-col class="py-1" cols="3">
                            <v-select v-model="sales_type" :items="['all','package', 'booking']" dense
                                outlined hide-details label="Sales Type" class="ma-2"></v-select>
                        </v-col>
                        <v-col class="py-1" cols="6">
                            <v-select v-model="outlet_id" :items="processed_outlets" item-value="id" item-text="name" dense
                                outlined hide-details label="Outlet" class="ma-2"></v-select>
                        </v-col>
                        <v-col class="py-1" cols="3">
                            <v-checkbox v-model="date_range" label="Date Range" class="m-0" hide-details></v-checkbox>
                        </v-col>
                        <v-col class="py-1" cols="6" v-if="date_range">
                            <v-row>
                                <v-col class="py-1" cols="6">
                                    <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40"
                                        transition="scale-transition" offset-y min-width="auto">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field v-model="startDate" label="From" prepend-icon="mdi-calendar"
                                                readonly v-bind="attrs" v-on="on"></v-text-field>
                                        </template>
                                        <v-date-picker v-model="startDate" @input="menu2 = false"></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col class="py-1" cols="6">
                                    <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40"
                                        transition="scale-transition" offset-y min-width="auto">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field v-model="endDate" label="To" prepend-icon="mdi-calendar" readonly
                                                v-bind="attrs" v-on="on"></v-text-field>
                                        </template>
                                        <v-date-picker v-model="endDate" @input="menu2 = false"></v-date-picker>
                                    </v-menu>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="3">
                            <v-btn color="primary" class="mr-4 w-100" @click="search">
                                search
                            </v-btn>
                        </v-col>
                    </v-row>

                </template>
                <template v-slot:tab-items>
                    <v-data-table :headers="headers" :items="salesList" class="elevation-1" item-key="id" multi-sort :sort-by="['id','created_at']" :sort-desc="[true,true]">
                        <template v-slot:item="{ item }">
                            <tr style="cursor:pointer">
                                <td @click="routeToDetails(item.id)">{{ item.id }}</td>
                                <td @click="routeToDetails(item.id)">{{ item.sales_type }}</td>
                                <td @click="routeToDetails(item.id)">{{  item.amount.toFixed(2)}}</td>
                                <td @click="routeToDetails(item.id)">{{ item.created_at | formatDate }}</td>
                                <td @click="routeToCustomer(item.user_id)">{{ item.User.user_fullname }}</td>
                                <td @click="routeToOutlet(item.Outlet.id)">{{ item.Outlet.name }}</td>
                                <td @click="routeToStaff(item.staff.id)"><span v-if="item.online_booking">Online</span><span v-else>{{ item.staff.fullname}}</span></td>
                                <td><span v-for="(p,i) of item.package_details" @click="routeToPackage(p.id)" :key="'package-'+i">
                                    {{ p.name }}<span v-if="i !== item.package_details.length - 1">,</span>
                                </span></td>
                                <td><span v-for="(b,i) of item.booking_id"  @click="routeToBooking(b)" :key="'booking-'+i">
                                    {{ b }}<span v-if="i !== item.booking_id.length - 1">,</span>
                                </span></td>
                                <td>{{ item.invoice_no }}</td>
                            </tr>
                        </template>
                    </v-data-table>
                </template>
            </List>
        </SideMenu>
    </v-app>
</template>
  
<script>
import SideMenu from '/src/components/SideMenu'
import List from '/src/components/List'
//   import gql from 'graphql-tag'
import * as queries from '@/graphql/gqlQueries.js';
export default {
    name: 'Listing',

    components: {
        SideMenu,
        List,
    },

    data() {
        return {
            alert: this.$route.params.alert,
            alert_type: this.$route.params.alert_type,
            message: this.$route.params.message,

            headers: [
                {
                    text: 'ID',
                    value: 'id',
                    class: 'white--text',
                },
                {
                    text: 'Sales Type',
                    value: 'sales_type',
                    class: 'white--text',
                },
                {
                    text: 'Amount (RM)',
                    value: 'amount',
                    class: 'white--text',
                },
                {
                    text: "Created At",
                    value: 'created_at',
                    class: 'white--text',
                },
                {
                    text: 'Customer (Name, Contact)',
                    value: 'user.user_fullname',
                    class: 'white--text',
                },
                {
                    text: 'Outlet',
                    value: 'Outlet.name',
                    class: 'white--text',
                },
                {
                    text: 'staff',
                    value: 'staff.name',
                    class: 'white--text',
                },
                {
                    text: 'Package(s)',
                    value: 'package_id',
                    class: 'white--text',
                },
                {
                    text: 'Booking(s)',
                    value: 'booking_id',
                    class: 'white--text',
                },
                {
                    text: 'Invoice no',
                    value: 'invoice_no',
                    class: 'white--text',
                },
            ],

            getAllPermissions: [],
            allPermissionsName: [],
            staffPermission: {},
            getCommissionList: [],
            getAllSales: [],
            commissionList: [],
            salesList: [],
            outlet_id: '',
            outlet_ids: [],
            processed_outlets: [],
            endDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            startDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            menu: false,
            menu2: false,
            date_range: false,
            sales_id: "",
            sales_type:"all",
        }
    },
    apollo: {   
        getAllPackages:{
            query: queries.GET_ALL_PACKAGES_QUERY,
        },
        getAllSales: {
            query: queries.GET_ALL_SALES,
            variables() {
                return{
                    outlet_id: !this.staffPermission.viewAllOutletsSalesListing ? this.$session.get('outlets')[0] * 1 : null
                }
            },
            result() {
                const list = [...this.getAllSales]
                this.salesList = list

            }
        },

        outlets: {
            query: queries.GET_ALL_OUTLET_QUERY,
            fetchPolicy: 'network-only',
            result() {
                this.outlet_ids = this.$session.get('outlets') ?? []
                if (!this.staffPermission.viewAllOutletsSalesListing) {
                    for (let i = 0; i < this.outlets.length; i++) {
                        for (let x = 0; x < this.outlet_ids.length; x++) {
                            if (this.outlet_ids[x] == this.outlets[i].id.toString()) {
                                this.processed_outlets.push(this.outlets[i])
                                break
                            }
                        }
                    }
                }
                else {
                    this.processed_outlets = this.outlets
                }
                if (this.staffPermission.viewAllOutletsSalesListing && this.processed_outlets[0].name != 'All outlets') {
                    this.processed_outlets.unshift({ id: '', name: 'All outlets' })
                } else {
                    this.outlet_id = this.processed_outlets[0]
                }
            }
        },
    },
    methods: {
        routeToDetails(value){
            this.$router.push({ path: '/sales/' + value })
        },
        routeToOutlet(value){
            if(!this.staffPermission.viewOutlet) return
            this.$router.push({ path: '/outlet/' + value})
        },
        routeToCustomer(value){
            if(!this.staffPermission.viewCustomer) return
            this.$router.push({ path: '/user/' + value})
        },
        routeToPackage(value){
            if(!this.staffPermission.viewPackage) return
            this.$router.push({ path: '/package/' + value})
        },
        routeToStaff(value){
            if(!this.staffPermission.viewStaff) return
            this.$router.push({ path: '/staff/' + value})
        },
        routeToBooking(value){
            if(!this.staffPermission.viewBooking) return
            this.$router.push({ path: '/booking/' + value})
        },
        search(){
            console.log(this.outlet_id)
            this.$apollo.query(
                {
                    query: queries.GET_ALL_SALES,
                    variables: {
                        id: this.sales_id? this.sales_id * 1 : null,
                        outlet_id: this.outlet_id ? this.outlet_id * 1 : null,
                        sales_type: this.sales_type === "all"? null : this.sales_type,
                        startDate: this.date_range ? this.startDate : null,
                        endDate: this.date_range ? this.endDate : null,
                    },
                }
            ).then(result => {
                var list = [...result.data.getAllSales]
                this.salesList = list
                this.updateSalesList()
                console.log(this.salesList)
            }).catch(error=>
            {
                console.log(JSON.stringify(error, null, 2));
            })
        },
        exportCSV() {
            var report = [];
            var totalSalesAmount = 0; // Variable to store the subtotal
            var totalSales = this.salesList.length
            for (const c of this.salesList) {
                const newItem = {
                    ID: c.id,
                    outlet: c.Outlet.name,
                    staff: c.staff.fullname,
                    customer: c.User.user_fullname,
                    customer_contact: c.User.user_contact,
                    booking:  c.booking_id? c.booking_id.join(" ") : null,
                    package: c.package_list?c.package_list.join(" "): null,
                    created_at: new Date(c.created_at * 1).toLocaleDateString(),
                    amount: c.amount * 1,
                    invoice_no: c.invoice_no,
                };
                console.log(newItem)
                report.push(newItem);

                totalSalesAmount += c.amount * 1; // Add the amount to the subtotal
            }

            const keys = Object.keys(report[0]); // Get the keys from the first item in the report

            const headers = keys.join(",") + "\n";
            const rows = report
                .map(item => Object.values(item).join(","))
                .join("\n");

            const csvContent = headers + rows + "\n";
            const subtotalRow = `Total Sales:,,${totalSales}`;
            const subtotalAmountRow = `Total Sales Amount:,,${totalSalesAmount.toFixed(2)}`; // Add a subtotal row
            const contentWithSubtotalAndTotalCommission = csvContent + subtotalRow + "\n" + subtotalAmountRow;

            const blob = new Blob([contentWithSubtotalAndTotalCommission], { type: "text/csv;charset=utf-8;" });
            const link = document.createElement("a");
            const url = URL.createObjectURL(blob);

            link.setAttribute("href", url);
            link.setAttribute("download", "sales-list.csv");
            link.style.visibility = "hidden";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },
        updateSalesList() {
            if (this.getAllPackages && this.getAllPackages.length > 0 && this.salesList && this.salesList.length > 0) {
                this.salesList.forEach((s) => {
                    if (s.package_id) {
                        s.package_details = [];
                        s.package_list = []
                        s.package_id.forEach((pId) => {
                            const foundPackage = this.getAllPackages.find((p) => p.id == pId);
                            if (foundPackage) {
                                s.package_details.push({
                                    id: foundPackage.id,
                                    name: foundPackage.package_name,
                                });
                                s.package_list.push(foundPackage.package_name)
                            }
                        });
                    }
                });
            }
        }

    },
    filters: {formatDate(value) {
    const date = new Date(value * 1);

    const year = date.getFullYear();
    const month = date.toLocaleString("en-US", { month: "short" });
    const day = date.getDate().toString().padStart(2, '0');

    return `${day} ${month} ${year}`;
  },

  formatTime(value) {
    const time = new Date(value * 1);

    const hours = time.getHours().toString().padStart(2, '0');
    const minutes = time.getMinutes().toString().padStart(2, '0');
    const seconds = time.getSeconds().toString().padStart(2, '0');

    return `${hours}:${minutes}:${seconds}`;
  },
        formatPrice(value) {
            return Number(value).toFixed(2);
        }
    },
    watch: {
        value() {
            this.formattedValue = this.formatDate(this.value)
        },
        getAllPackages : {
            handler() {
                this.updateSalesList();
            },
            deep: true,
        },
        getAllSales: {
            handler() {
                this.updateSalesList();
            },
            deep: true,
        },
    },
    created(){
    this.$apollo.query({ query: queries.GET_ALL_PERMISSIONS_QUERY }).then(async result => {
      this.getAllPermissions = await result.data.getAllPermissions;
      this.$apollo.query(
        {
          query: queries.GET_STAFF_ROLES,
          variables: {
            staffId: this.$session.get("user_id") * 1
          }
        }
      ).then(async result => {
        this.getStaffRoles = await result.data.getStaffRoles;
        this.allPermissionsName = [];
        this.getStaffRoles.forEach(role => {
          role.role.RolePermission.forEach((item) => {
            const name = item.permission.name;
            this.allPermissionsName.push(name);
          });
        })
        this.$session.set('staffPermission', this.$generalFunctions.generatePermissionArray(this.getAllPermissions, this.allPermissionsName));
        this.staffPermission = await this.$generalFunctions.generatePermissionArray(this.getAllPermissions, this.allPermissionsName)
      })
    })
  }
}
</script>
  