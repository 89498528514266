<template>
  <v-app>
    <SideMenu submenuPage="Resources" submenuGroup="Service Setup">
      <Form>
        <template v-slot:tabs></template>
        <template v-slot:form>
          <v-form
            ref="form"
            lazy-validation
          >
            <v-text-field
              v-model="resourceById.name"
              :counter="191"
              :rules="nameRules.concat(characterRules)"
              label="Name"
              required
            ></v-text-field>

            <v-text-field
              v-model="resourceById.description"
              :counter="191"
              :rules="characterRules"
              label="Description"
            ></v-text-field>

            <v-select
              v-model="resourceById.resource_type_id"
              :items="resourceTypes"
              item-value="id"
              item-text="name"
              label="Resource Type"
              :rules="[() => !!resourceById.resource_type_id || 'Resource type is required']"
            ></v-select>

            <v-select
              v-model="resourceById.outlet_id"
              :items="outlets"
              item-value="id"
              item-text="name"
              label="Outlet"
              :rules="[() => !!resourceById.outlet_id || 'Outlet is required']"
            ></v-select>
          </v-form>
        </template>
        <template v-slot:buttons>
          <v-btn
            class="mr-4"
            text
            @click="$router.push({ path: '/resources' })"
          >
            Back to list
          </v-btn>
          <v-btn
            color="success"
            class="mr-4"
            @click="validate"
          >
            Create
          </v-btn>
        </template>
      </Form>
    </SideMenu>
  </v-app>
</template>

<script>
import SideMenu from '/src/components/SideMenu'
import Form from '/src/components/Form'
import gql from 'graphql-tag'
  const GET_ALL_RESOURCE_TYPES_QUERY = gql`
      query ResourceTypes {
        resourceTypes {
          id
          name
        }
      }
  `
  const GET_ALL_OUTLET_QUERY = gql`
      query Outlets {
        outlets {
          id
          name
        }
      }
  `
  export default {
    name: 'Resource',

    components:{
      SideMenu,
      Form
    },

    data () {
      
      return {
        resourceById: {
          name: '',
          description: '',
          resource_type_id: '',
          outlet_id: ''
        },
        outlets:[],
        showPassword: false,
        
        nameRules: [
          v => !!v || 'Name is required',
        ],
        characterRules: [
          v => (v.length <= 191) || 'Field must be less than or equal to 191 characters',
        ],
        resourceTypes: []
      }
    },
    apollo: {
      resourceTypes: {
        query: GET_ALL_RESOURCE_TYPES_QUERY,
        result(){
          if(this.resourceTypes){
            this.resourceById.resource_type_id = this.resourceTypes[0].id
          }
        }
      },
      outlets: {
        query: GET_ALL_OUTLET_QUERY
      },
    },
    methods: {
      validate () {
        var validate = this.$refs.form.validate()
        if(!validate){
          window.scrollTo(0,0);
        }
        if(validate){
          const CREATE_RESOURCE_QUERY = gql`
            mutation createResource($name: String!, $description:String, $resource_type_id: Int!, $outlet_id: Int!) {
              createResource(name:$name, description:$description, resource_type_id: $resource_type_id, outlet_id:$outlet_id)  {
                name
              }
            }
          `
          this.$apollo.mutate(
            { 
              mutation: CREATE_RESOURCE_QUERY, 
              variables:{
                name: this.resourceById.name,
                description: this.resourceById.description,
                resource_type_id: this.resourceById.resource_type_id*1,
                outlet_id: this.resourceById.outlet_id*1
              },
            }
          )
          .catch(error => {
            console.log(error)
          })
          .then(result => {
            if(result && result.data.createResource != null){ 
              console.log("Create successfully")
              this.$router.push({name: 'Resource', params:{alert:true, alert_type:'success', message:result.data.createResource.name+' created successfully'}})
            } else {
              console.log("Create failed")
            }
          });
        }
      },
    }
  }
</script>
