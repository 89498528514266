<template>
  <v-app>
    <SideMenu :alert="alert" :alert_type="alert_type" :message="message" submenuPage="Outlet" submenuGroup="Outlet Setup">
      <List>
        <template v-slot:action-button>
          <v-btn
            color="primary"
            class="mr-4"
            @click="OutletCreateForm"
            v-if="staffPermission.createOutlet"
          >
            + New Outlet
          </v-btn>
        </template>
        <template v-slot:tab-items>
          <v-data-table
            :headers="headers"
            :items="outlets"
            class="elevation-1"
            item-key="name"
          >
          <template v-slot:item="{ item }">
            <tr
              @click="OutletUpdateForm(item.id)"
              style="cursor:pointer"
            >
              <td>{{ item.name }}</td>
              <td>{{ item.city }}</td>
              <td>{{ item.state }}</td>
            </tr>
          </template>
          </v-data-table>
        </template>
      </List>
    </SideMenu>
  </v-app>
</template>

<script>
import SideMenu from '/src/components/SideMenu'
import List from '/src/components/List'
import gql from 'graphql-tag'
import * as queries from '@/graphql/gqlQueries.js';
  const GET_ALL_OUTLETS_QUERY = gql`
      query Outlets {
        outlets {
          id
          name
          city
          state
          created_at
        }
      }
  `
  export default {
    name: 'Outlet',

    components:{
      SideMenu,
      List
    },

    data () {
      
      return {
        outlets: [],
        alert: this.$route.params.alert,
        alert_type: this.$route.params.alert_type,
        message: this.$route.params.message,

        headers:[
          {
            text: 'Name',
            value: 'name',
            class: 'white--text'
          },
          {
            text: 'City',
            value: 'city',
            class: 'white--text'
          },
          {
            text: 'State',
            value: 'state',
            class: 'white--text'
          }
        ],
        getAllPermissions: [],
        allPermissionsName: [],
        staffPermission: {},
      }
    },
    apollo: {
      outlets: {
        query: GET_ALL_OUTLETS_QUERY,
        pollInterval: 300,
        async result(){
          const sortProperties = [
                    { property: 'id', direction: 'desc' },
                    { property: 'created_at', direction: 'desc' },
                ];

        // Use the multiPropertySort function to sort the commissionList
        this.outlets = await this.$generalFunctions.multiPropertySort(this.outlets, sortProperties);
        }
      },
    },
    methods: {
      OutletUpdateForm(id) {
        this.$router.push({ path: '/outlet/'+id })
      },
      OutletCreateForm() {
        this.$router.push({ path: '/create/outlet' })
      }
    },
    created(){
    this.$apollo.query({ query: queries.GET_ALL_PERMISSIONS_QUERY }).then(async result => {
      this.getAllPermissions = await result.data.getAllPermissions;
      this.$apollo.query(
        {
          query: queries.GET_STAFF_ROLES,
          variables: {
            staffId: this.$session.get("user_id") * 1
          }
        }
      ).then(async result => {
        this.getStaffRoles = await result.data.getStaffRoles;
        this.allPermissionsName = [];
        this.getStaffRoles.forEach(role => {
          role.role.RolePermission.forEach((item) => {
            const name = item.permission.name;
            this.allPermissionsName.push(name);
          });
        })
        this.$session.set('staffPermission', this.$generalFunctions.generatePermissionArray(this.getAllPermissions, this.allPermissionsName));
        this.staffPermission = await this.$generalFunctions.generatePermissionArray(this.getAllPermissions, this.allPermissionsName)
      })
    })
  }
  }
</script>
