<template>
  <v-app>
    <SideMenu>
      <Alert :alert_type="alert_type" v-if="alert_condition">{{
        alert_message
      }}</Alert>
      <Form>
        <template v-slot:tabs>
          <v-tabs v-model="tab">
            <v-tabs-slider></v-tabs-slider>
            <v-tab href="#details">Details</v-tab>
          </v-tabs>
        </template>
        <template v-slot:form>
          <v-tabs-items v-model="tab">
            <v-tab-item value="details">
              <v-form ref="form" lazy-validation>
                <v-text-field
                  v-model="user.name"
                  :counter="191"
                  :rules="nameRules.concat(characterRules)"
                  label="Name"
                  required
                ></v-text-field>

                <v-text-field
                  v-model="user.contact"
                  :counter="counterLength"
                  :rules="contactRules.concat(numericRule)"
                  label="Phone number"
                ></v-text-field>

                <v-text-field
                  v-model="user.password"
                  :append-icon="passwordVisible ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="passwordVisible ? 'text' : 'password'"
                  label="Password"
                  @click:append="passwordVisible = !passwordVisible"
                  :rules="passwordRules"
                ></v-text-field>

                <v-text-field
                  v-model="user.confirmPassword"
                  :append-icon="
                    confirmPasswordVisible ? 'mdi-eye' : 'mdi-eye-off'
                  "
                  :type="confirmPasswordVisible ? 'text' : 'password'"
                  label="Confirm Password"
                  @click:append="
                    confirmPasswordVisible = !confirmPasswordVisible
                  "
                  :rules="confirmPasswordRules"
                ></v-text-field>
              </v-form>
            </v-tab-item>
          </v-tabs-items>
        </template>
        <template v-slot:buttons>
          <v-btn
            class="mr-4"
            text
            @click="$router.push({ path: '/customers' })"
          >
            Back to list
          </v-btn>
          <v-btn color="success" class="mr-4" @click="validate"> Create </v-btn>
        </template>
      </Form>
    </SideMenu>
  </v-app>
</template>

<script>
import Alert from "/src/components/Alert";
import SideMenu from "/src/components/SideMenu";
import Form from "/src/components/Form";
import gql from "graphql-tag";

export default {
  name: "User",

  components: {
    SideMenu,
    Form,
    Alert,
  },

  data() {
    return {
      user: {
        name: "",
        contact: "",
        password: "",
        confirmPassword: "",
      },
      counterLength: 12,
      passwordVisible: false,
      confirmPasswordVisible: false,

      nameRules: [(v) => !!v || "Name is required"],
      contactRules: [(v) => !!v || "Phone number is required"],
      numericRule: [
        (v) => /^\d+$/.test(v) || "Field must contain only numbers",
      ],
      characterRules: [
        (v) =>
          v.length <= 191 ||
          "Field must be less than or equal to 191 characters",
      ],
      passwordRules: [
        (v) => v.length >= 2 || "Password must be at least 2 characters",
      ],

      confirmPasswordRules: [
        (v) => v === this.user.password || "Passwords do not match",
      ],
      tab: null,
      alert_type: "",
      alert_message: "",
      alert_condition: false,
    };
  },
  watch: {
    "user.contact"(value) {
      this.updateCounter(value);
    },
  },
  methods: {
    updateCounter(value) {
      const number = value.trim();
      if (number.startsWith("60")) {
        this.counterLength = 12;
      } else if (number.startsWith("0")) {
        this.counterLength = 11;
      } else if (number.startsWith("1")) {
        this.counterLength = 10;
      } else {
        this.counterLength = 12;
      }
    },
    parsePhoneNumber(phoneNumber) {
      const countryCode = "60";
      if (
        !phoneNumber ||
        !countryCode ||
        phoneNumber.length < 1 ||
        countryCode.length < 2
      )
        return phoneNumber;

      const normalizedNumber = phoneNumber.replace(/\D/g, ""); // Remove non-digit characters

      if (normalizedNumber.startsWith(countryCode)) {
        return normalizedNumber; // Number is already in the desired format
      } else if (normalizedNumber.startsWith("0")) {
        return countryCode + normalizedNumber.slice(1); // Prepend country code, excluding the leading '0'
      }

      return countryCode + normalizedNumber; // Prepend country code to the number
    },
    async validate() {
      var validate = this.$refs.form.validate();
      if (!validate) {
        window.scrollTo(0, 0);
      }
      this.user.contact = this.parsePhoneNumber(this.user.contact);

      var passwordHash = require("password-hash");
      if (validate) {
        const CREATE_USER_QUERY = gql`
          mutation createUser(
            $user_id_external: String
            $user_fullname: String
            $user_contact: String!
            $password: String
          ) {
            createUser(
              user_id_external: $user_id_external
              user_fullname: $user_fullname
              user_contact: $user_contact
              password: $password
            ) {
              user_id_external
              user_fullname
              user_contact
              password
            }
          }
        `;

        try {
          const result = await this.$apollo.mutate({
            mutation: CREATE_USER_QUERY,
            variables: {
              user_id_external: null,
              user_fullname: this.user.name,
              user_contact: this.user.contact,
              password: passwordHash.generate(this.user.password),
            },
          });
          console.log(result.data.createUser);
          // handle success
          this.$router.push({
            name: "Customer",
            params: {
              alert: true,
              alert_type: "success",
              message: "User created successfully",
            },
          });
        } catch (error) {
          // console.error(error);
          // handle error
          if (
            error.message ==
            "GraphQL error: User with this contact already exists"
          ) {
            this.alert_type = "error";
            this.alert_message = "User with this contact already exists.";
            this.alert_condition = true;
          }
        }
      }
    },
  },
};
</script>
