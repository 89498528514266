<template>
  <v-app>
    <SideMenu submenuPage="Packages">
      <Alert :alert_type="alert_type" v-if="alert_condition">{{
        alert_message
      }}</Alert>
      <Form>
        <template v-slot:tabs>
          <v-tabs v-model="tab">
            <v-tabs-slider></v-tabs-slider>
            <v-tab href="#details">Details</v-tab>
            <v-tab href="#services">Services</v-tab>
            <v-tab href="#resources">Resources</v-tab>
            <v-tab href="#outlet">Outlet</v-tab>
          </v-tabs>
        </template>

        <template v-slot:form>
          <div class="selector_container" v-if="tab === 'services'">
            <multiselect v-model="value" :options="serviceOptions" track-by="dname" label="dname" :show-labels="false"
              placeholder="Select service">
              <template slot="option" slot-scope="props">
                <div >
                  <span v-if="props.option.serviceId" class="ml-3">{{ props.option.dname }}</span>
                  <span v-if="!props.option.serviceId" class="font-weight-bold">{{ props.option.dname }}</span>
                </div>
              </template>
            </multiselect>
            <v-btn color="primary" class="mr-4" @click="addService">
              + add
            </v-btn>
          </div>

          <div class="selector_container" v-if="tab === 'resources'">
            <multiselect v-model="value2" :options="resOptions" track-by="dname" label="dname" :show-labels="false"
              placeholder="Select resource">
              <template slot="option" slot-scope="props">
                <div>
                  <span v-if="props.option.resourceId" class="ml-3">{{ props.option.dname }}</span>
                  <span v-if="!props.option.resourceId" class="font-weight-bold">{{ props.option.dname }}</span>
                </div>
              </template>
            </multiselect>
            <v-btn color="primary" class="mr-4" @click="addRes">
              + add
            </v-btn>
          </div>

          <v-tabs-items v-model="tab">
            <v-tab-item value="details">
              <v-form ref="form" lazy-validation>
                <p class="m-0">Package Name</p>
                <v-text-field v-model="pkg.name" :counter="191" :rules="nameRules.concat(characterRules)"
                  required></v-text-field>

                <p class="m-0">Price</p>
                <v-text-field v-model="pkg.price" type="number" class="v-text-field-number" step="0.01" min="0"
                  oninput="validity.valid||(value='');"></v-text-field>

                <p>Status</p>
                <v-select v-model="pkg.status" :items="pkgStatus" outlined class="w-50"></v-select>

                <p class="m-0">Expiry Days<small> (0 represents as permanent)</small></p>
                <v-text-field v-model="pkg.duration_days" type="number" class="v-text-field-number" step="1" min="0" suffix="days"></v-text-field>

                <p>Description</p>
                <v-textarea v-model="pkg.description" :counter="191" :rules="descRules" outlined></v-textarea>
              </v-form>
              <template>
                <div>
                  <div class="label">Created At: {{ packageById.created_at }}</div>
                  <div class="label">Updated At: {{ packageById.updated_at }}</div>
                  <div class="label" v-if="packageById.deleted_at">Deleted At: {{ packageById.deleted_at }}
                  </div>
                </div>
              </template>
            </v-tab-item>

            <v-tab-item value="services">
              <v-data-table :headers="headers" :items="servicesInPackages" class="elevation-1" item-key="id" :sort-by="['id']" :sort-desc="[true]">
                <template v-slot:item="{ item }">
                  <tr style="cursor:pointer">
                    <td>{{ item.typeName }}</td>
                    <td>{{ item.serviceName }}</td>
                    <td class="amount_input"> <input type="number" v-model="item.amount"> </td>
                    <td class="text-center"><v-icon class="deleteBtnIcon"
                        @click="removeItem(item)">mdi-close-circle</v-icon></td>
                  </tr>
                </template>
              </v-data-table>
            </v-tab-item>

            <v-tab-item value="resources">
              <v-data-table :headers="resHeaders" :items="resourceInPackages" class="elevation-1" item-key="id" :sort-by="['id']" :sort-desc="[true]">
                <template v-slot:item="{ item }">
                  <tr style="cursor:pointer">
                    <td>{{ item.resTypeName }}</td>
                    <td>{{ item.resName }}</td>
                    <td class="amount_input"> <input type="number" v-model="item.amount"> </td>
                    <td class="text-center"><v-icon class="deleteBtnIcon"
                        @click="removeResItem(item)">mdi-close-circle</v-icon></td>
                  </tr>
                </template>
              </v-data-table>
            </v-tab-item>
            <v-tab-item value="outlet">
              <v-form ref="form2" lazy-validation>
                <v-container fluid class="pt-0 pb-0">
                  <v-checkbox v-model="selectAllOutlets" :label="'All Outlets'" @change="selectOutlets"></v-checkbox>
                  <v-checkbox v-model="selectedOutlets" v-for="outlet in outlets" :key="outlet.id" :label="outlet.name"
                    :value="outlet.id" @change="detectAllOrNot"></v-checkbox>
                </v-container>
              </v-form>
            </v-tab-item>
          </v-tabs-items>
        </template>
        <template v-slot:buttons>
          <v-btn class="mr-4" text @click="$router.push({ path: '/packages' })">
            Back to list
          </v-btn>
          <v-dialog
              max-width="500"
              v-if="tab === 'details' && !packageById.deleted_at && staffPermission.deletePackage"
          >
              <template v-slot:activator="{ on, attrs }">
              <v-btn
                  color="error"
                  v-bind="attrs"
                  v-on="on"
                  class="mr-4" 
              >Delete</v-btn>
              </template>
              <template v-slot:default="dialog">
              <v-card>
                  <v-card-title>
                    Are you sure to delete {{ pkg.name }}?
                  </v-card-title>
                  <v-card-actions class="justify-end">
                  <v-btn
                      text
                      @click="dialog.value = false"
                  >Close</v-btn>
                  <v-btn color="error" @click="deletePackage();dialog.value = false">
                      Delete
                  </v-btn>
                  </v-card-actions>
              </v-card>
              </template>
          </v-dialog>
          <v-btn color="success" class="mr-4" @click="validate" v-if="staffPermission.updatePackage">
            Update
          </v-btn>
        </template>
      </Form>
    </SideMenu>
  </v-app>
</template>
  
<script>
import SideMenu from '/src/components/SideMenu'
import Form from '/src/components/Form'
import Alert from "/src/components/Alert";
import * as queries from '@/graphql/gqlQueries.js';
import Multiselect from 'vue-multiselect'
import gql from 'graphql-tag'
const GET_ALL_SERVICE_TYPES_QUERY = gql`
        query ServiceTypes {
          serviceTypes {
            id
            name
          }
        }
    `
const GET_ALL_SERVICE_QUERY = gql`
      query Services {
        services {
          id
          name
          service_type_id
        }
      }
  `
const GET_ALL_OUTLETS_QUERY = gql`
        query Outlets {
          outlets {
            id
            name
            created_at
          }
        }
    `
const GET_ALL_STAFFS_QUERY = gql`
        query Staffs {
          staffs {
            id
            fullname
          }
        }
    `
const GET_ALL_RESOURCESTYPES_QUERY = gql`
      query ResourceTypes {
        resourceTypes {
          id
          name
        }
      }
  `
const GET_ALL_RESOURCES_QUERY = gql`
      query Resources {
        resources {
          id
          name
          resource_type_id
      }
    }
  `
const GET_PACKAGE = gql`
  query GetPackage($id: ID!) {
    packageById(id: $id) {
      id
      package_name
      price
      status
      description
      created_at
      updated_at
      deleted_at
      duration_days
      Package_Details {
        id
        service_type_id
        service_id
        resource_type_id
        resource_id
      }
      Package_Outlet {
        id
        outlet_id
      }
    }
  }
`;
export default {
  name: 'Packages',

  components: {
    SideMenu,
    Multiselect,
    Form,
    Alert
  },
  data() {

    return {
      nameRules: [
        v => !!v || 'Name is required',
      ],
      characterRules: [
        v => (v.length <= 191) || 'Field must be less than or equal to 191 characters',
      ],
      descRules: [
        v => (v.length <= 191) || 'Field must be less than or equal to 500 characters',
      ],
      durationRules: [
        v => v != 0 || 'Duration has to be at least 1 minute',
      ],
      serviceTypes: [],
      services: [],
      resourceTypes: [],
      resources: [],
      outlets: [],
      staffs: [],
      serviceOptions: [],
      resOptions: [],
      selectedOutlets: [],
      selectAllOutlets: false,
      tab: null,
      pkgStatus: ["draft", "published"],
      pkg: {
        name: "",
        price: "",
        description: "",
        status: "draft",
        duration_days: 0
      },
      value:
      {
        dname: "Select service",
        typeId: "",
        typeName: "",
        serviceId: "",
        serviceName: "",
        id: "",
        name: "",
        service_type_id: "",
      },
      value2: {
        dname: "Select resource",
        typeId: "",
        typeName: "",
        resourceId: "",
        resourceName: "",
        id: "",
        name: "",
        resource_type_id: "",
      },
      servicesInPackages: [],
      headers: [
        {
          text: 'Service Type',
          value: 'typeName',
          class: 'white--text', width: '35%'
        },
        {
          text: 'Service Name',
          value: 'serviceName',
          class: 'white--text', width: '35%'
        },
        {
          text: 'Amount',
          value: 'amount',
          class: 'white--text', width: '15%'
        },
        {
          text: 'Remove',
          value: 'remove',
          class: 'white--text', width: '15%'
        }
      ],
      resourceInPackages: [],
      resHeaders: [
        {
          text: 'Resource Type',
          value: 'resTypeName',
          class: 'white--text', width: '35%'
        },
        {
          text: 'Resource Name',
          value: 'resName',
          class: 'white--text', width: '35%'
        },
        {
          text: 'Amount',
          value: 'amount',
          class: 'white--text', width: '15%'
        },
        {
          text: 'Remove',
          value: 'remove',
          class: 'white--text', width: '15%'
        }
      ],
      packageList: [],
      serviceList: [],
      resList: [],
      alert_type: "",
      alert_message: "",
      alert_condition: false,
      packageById: [],
      getRes: false,
      getAllPermissions: [],
      allPermissionsName: [],
      staffPermission: {},
    }
  },
  computed: {
   
  },


  apollo: {
    serviceTypes: {
      query: GET_ALL_SERVICE_TYPES_QUERY,
      result() {
        this.changeServices()
      }
    },
    services: {
      query: GET_ALL_SERVICE_QUERY,
      result() {
        this.changeServices()
      }
    },
    resourceTypes: {
      query: GET_ALL_RESOURCESTYPES_QUERY,
      result() {
        this.changeRes()
      }
    },
    resources: {
      query: GET_ALL_RESOURCES_QUERY,
      result() {
        this.changeRes()
      }
    },
    outlets: {
      query: GET_ALL_OUTLETS_QUERY,
    },
    staffs: {
      query: GET_ALL_STAFFS_QUERY,
    },
    packageById: {
      query: GET_PACKAGE,
      pollInterval: 300,
      variables() {
        return {
          id: this.$route.params.id,
        }
      },
      result() {
        if (this.packageById) {
          console.log("123",this.packageById)
          const options = {
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
            timeZone: "Asia/Singapore",
          }
          const updatedAt = new Date(this.packageById.updated_at * 1);
          if (!isNaN(updatedAt.getTime())) {
            this.packageById.updated_at = new Intl.DateTimeFormat("en-US", options).format(updatedAt);
          }
          if (this.packageById.deleted_at) {
            const deletedAt = new Date(this.packageById.deleted_at * 1);
            if (!isNaN(deletedAt.getTime())) {
              this.packageById.deleted_at = new Intl.DateTimeFormat("en-US", options).format(deletedAt);
            }
          }

          const createdAt = new Date(this.packageById.created_at * 1);
          if (!isNaN(createdAt.getTime())) {
            this.packageById.created_at = new Intl.DateTimeFormat("en-US", options).format(createdAt);
          }

          this.pkg.name = this.packageById.package_name
          this.pkg.price = this.packageById.price
          this.pkg.duration_days = this.packageById.duration_days * 1 || 0
          if (this.packageById.description) this.pkg.description = this.packageById.description
          this.pkg.status = this.packageById.status
          if (this.packageById.Package_Details.length > 0 && !this.getRes) {
            const mergedPackageDetails = this.mergePackageDetails(this.packageById.Package_Details);
            mergedPackageDetails.forEach(e => {
              if (e.resource_type_id) {
                let res = {}
                res.resTypeId = e.resource_type_id;
                res.resTypeName = e.resource_type_id?this.resourceTypes.filter(r => r.id == e.resource_type_id)[0].name:"";
                res.resId = e.resource_id || ""
                res.resName = e.resource_id ? this.resources.filter(r => r.id == e.resource_id)[0].name : ""
                res.dname = e.resource_id ? `${res.resName} (${res.resTypeName})` : res.resTypeName;
                res.amount = e.amount
                this.resourceInPackages.push(res)
              } else {
                console.log("e",e)
                if (this.services.length == 0) return
                let service = {}
                service.typeId = e.service_type_id;
                service.typeName = e.service_type_id ?this.serviceTypes.filter(r => r.id == e.service_type_id)[0].name:"";
                service.serviceId = e.service_id || ""
                service.serviceName = e.service_id ? this.services.filter(r => r.id == e.service_id)[0].name : ""
                service.dname = e.service_id ? `${service.serviceName} (${service.typeName})` : service.typeName;
                service.amount = e.amount
                this.servicesInPackages.push(service)
              }
            })
            this.getRes =  true
          }
          if (this.packageById.Package_Outlet.length > 0) {
            this.selectedOutlets = []
            this.packageById.Package_Outlet.forEach(o => {
              const outlet = o.outlet_id.toString()
              this.selectedOutlets.push(outlet)
            })
            this.detectAllOrNot()
          }
        }

      }
    },
  },
  methods: {

    capitalizeFirstWord(item) {
    return item.charAt(0).toUpperCase() + item.slice(1);
  },


    validate() {
      if (this.pkg.status) {
      this.pkg.status = this.capitalizeFirstWord(this.pkg.status);
    }

      this.packageList = []
      this.serviceList = []
      this.resList = []
      for (const item of this.servicesInPackages) {
        for (let i = 0; i < parseInt(item.amount); i++) {
          const newItem = {
            service_type_id: item?.typeId ? item.typeId : null,
            service_id: item?.serviceId ? item.serviceId : null,
            resource_type_id: item?.resTypeId ? item?.resTypeId : null,
            resource_id: item?.resId ? item?.resId : null,
          }
          this.serviceList.push(newItem)
        }
      }
      for (const item of this.resourceInPackages) {
        for (let i = 0; i < parseInt(item.amount); i++) {
          const newItem = {
            service_type_id: item?.typeId ? item.typeId : null,
            service_id: item?.serviceId ? item.serviceId : null,
            resource_type_id: item?.resTypeId ? item?.resTypeId : null,
            resource_id: item?.resId ? item?.resId : null,
          }
          this.resList.push(newItem)
        }
      }
      this.packageList = [...this.serviceList, ...this.resList]

      var validate = this.$refs.form.validate()
      if (!validate) {
        return window.scrollTo(0, 0);
      }
      else if (this.packageList.length == 0) {
        window.scrollTo(0, 0);
        this.alert_type = "error";
        this.alert_message = "At least pick one from service and resource";
        this.alert_condition = true;
        setTimeout(() => {
          this.alert_condition = false;
        }, 5000);
      }
      else if (this.selectedOutlets.length == 0) {
        window.scrollTo(0, 0);
        this.tab = "outlet"
        return
      }
      if (validate && this.packageList.length > 0 && this.selectedOutlets.length > 0) {
        const UPDATE_PACKAGE = gql`
          mutation UpdatePackage($id: ID!, $name: String, $description: String, $price: Float, $status: packageStatus, $duration_days: Int) {
            updatePackage(id: $id, name: $name, description: $description, price: $price, status: $status, duration_days: $duration_days) {
              id
            }
          }
        `;

        this.$apollo.mutate(
          {
            mutation: UPDATE_PACKAGE,
            variables: {
              id: this.$route.params.id,
              name: this.pkg.name,
              description: this.pkg.description,
              price: this.pkg.price * 1,
              status: this.pkg.status.toLowerCase(),
              duration_days: this.pkg.duration_days * 1
            },
          }
        ).then(() => {
          this.servicesInPackages = []
          this.resourceInPackages = []
          const DELETE_PACKAGE_DETAILS_QUERY = gql`
            mutation deletePackageDetailsByPackageId($packageId: Int!) {
              deletePackageDetailsByPackageId(packageId: $packageId) {
                success
              }
            }
          `;

          const DELETE_PACKAGE_OUTLETS_QUERY = gql`
            mutation deletePackageOutletsByPackageId($packageId: Int!) {
              deletePackageOutletsByPackageId(packageId: $packageId) {
                success
              }
            }
          `;

          const CREATE_PACKAGE_DETAILS = gql`
            mutation CreatePackageDetails($packageId: Int!, $serviceTypeId: Int, $serviceId: Int, $resourceTypeId: Int, $resourceId: Int) {
              createPackageDetails(packageId: $packageId, serviceTypeId: $serviceTypeId, serviceId: $serviceId, resourceTypeId: $resourceTypeId, resourceId: $resourceId) {
                id
                package_id
              }
            }
          `;

          const CREATE_PACKAGE_OUTLET = gql`
            mutation CreatePackageOutlet($packageId: Int!, $outletId: Int!) {
              createPackageOutlet(packageId: $packageId, outletId: $outletId) {
                id
                package_id
                outlet_id
              }
            }
          `;

          this.$apollo.mutate({
            mutation: DELETE_PACKAGE_DETAILS_QUERY,
            variables: {
              packageId: this.$route.params.id * 1,
            },
          }).then(() => {
            for (let i = 0; i < this.packageList.length; i++) {
              const item = this.packageList[i];

              this.$apollo.mutate({
                mutation: CREATE_PACKAGE_DETAILS,
                variables: {
                  packageId: parseInt(this.$route.params.id * 1),
                  serviceTypeId: parseInt(item.service_type_id),
                  serviceId: parseInt(item.service_id),
                  resourceTypeId: parseInt(item.resource_type_id),
                  resourceId: parseInt(item.resource_id),
                },
              });
            }
          }).catch((error) => {
            console.log(error)
          });

          this.$apollo.mutate({
            mutation: DELETE_PACKAGE_OUTLETS_QUERY,
            variables: {
              packageId: this.$route.params.id * 1,
            },
          }).then(() => {
            for (let i = 0; i < this.selectedOutlets.length; i++) {
              const outletId = parseInt(this.selectedOutlets[i]);

              this.$apollo.mutate({
                mutation: CREATE_PACKAGE_OUTLET,
                variables: {
                  packageId: parseInt(this.$route.params.id * 1),
                  outletId: parseInt(outletId),
                },
              }).then(()=>{
                this.alert_type = "success";
                this.alert_message = "update successfully";
                this.alert_condition = true;
                window.scrollTo(0,0)
              })

              this.getRes = false
            }

          }).catch((error) => {
            console.log(error)
          });

        })
      }
    },
    addService() {
      if (this.value?.id) {
        const selectedService = { ...this.value }
        selectedService.amount = 1

        // Check if the selected service already exists in the array
        const existingService = selectedService.serviceId ? this.servicesInPackages.find(service => service.serviceId == selectedService.serviceId) : this.servicesInPackages.find(service => service.typeId == selectedService.typeId && !service.serviceId)
        if (existingService) {
          // Item already exists, you can handle this case as per your requirement
        } else {
          // Item doesn't exist, add it to the array
          this.servicesInPackages.push(selectedService)
        }

        this.value = null;
      }
    },
    removeItem(item) {
      // Implement the logic to remove the item from the servicesInPackages array
      const index = this.servicesInPackages.indexOf(item);
      if (index > -1) {
        this.servicesInPackages.splice(index, 1);
      }
    },
    changeServices() {
      const options = [];
      const types = [...this.serviceTypes];
      const serviceList = [...this.services];
      const addedServiceIds = new Set();

      types.forEach(serviceType => {
        serviceType.typeId = serviceType.id;
        serviceType.typeName = serviceType.name;
        serviceType.dname = serviceType.name;
        serviceType.serviceId = ""
        serviceType.serviceName = ""
        options.push(serviceType);

        serviceList.forEach(service => {
          if (
            parseInt(service.service_type_id) === parseInt(serviceType.id) &&
            !addedServiceIds.has(service.id) // check service id in Set
          ) {
            service.typeId = serviceType.id;
            service.typeName = serviceType.name;
            service.serviceId = service.id
            service.serviceName = service.name
            service.dname = `${service.name} (${serviceType.name})`;
            options.push(service);
            addedServiceIds.add(service.id); // add service id into Set
          }
        });
      });
      this.serviceOptions = options
    },
    addRes() {
      if (this.value2?.id) {
        const selectedRes = { ...this.value2 }
        selectedRes.amount = 1

        // Check if the selected service already exists in the array
        const existingRes = selectedRes.resId ? this.resourceInPackages.find(res => res.resId == selectedRes.resId) : this.resourceInPackages.find(res => res.resTypeId == selectedRes.resTypeId && !res.resId)
        if (existingRes) {
          // Item already exists, you can handle this case as per your requirement
        } else {
          // Item doesn't exist, add it to the array
          this.resourceInPackages.push(selectedRes)
        }

        this.value2 = null;
      }
    },
    changeRes() {
      const options = [];
      const types = [...this.resourceTypes];
      const resList = [...this.resources];
      const addedResIds = new Set();

      types.forEach(resType => {
        resType.resTypeId = resType.id;
        resType.resTypeName = resType.name;
        resType.dname = resType.name;
        resType.resId = ""
        resType.resName = ""
        options.push(resType);

        resList.forEach(res => {
          if (
            parseInt(res.resource_type_id) === parseInt(resType.id) &&
            !addedResIds.has(res.id) // check service id in Set
          ) {
            res.resTypeId = resType.id;
            res.resTypeName = resType.name;
            res.resId = res.id
            res.resName = res.name
            res.dname = `${res.name} (${resType.name})`;
            options.push(res);
            addedResIds.add(res.id); // add service id into Set
          }
        });
      });
      this.resOptions = options
    },
    removeResItem(item) {
      // Implement the logic to remove the item from the servicesInPackages array
      const index = this.resourceInPackages.indexOf(item);
      if (index > -1) {
        this.resourceInPackages.splice(index, 1);
      }
    },
    selectOutlets() {
      if (this.selectAllOutlets) {
        this.selectedOutlets = this.outlets.map(o => o.id);
      } else {
        this.selectedOutlets = [];
      }
    },
    detectAllOrNot() {
      if (this.selectedOutlets.length === this.outlets.length) {
        this.selectAllOutlets = true
      } else {
        this.selectAllOutlets = false
      }
    },
    mergePackageDetails(packageDetails) {
      const mergedDetails = {};

      // 遍历 Package_Details 数据
      packageDetails.forEach((detail) => {
        const key = `${detail.service_type_id}-${detail.service_id}-${detail.resource_type_id}-${detail.resource_id}`;
        if (!mergedDetails[key]) {
          // 如果 key 不存在，创建新的 mergedDetails 条目
          mergedDetails[key] = {
            ...detail,
            amount: 1,
          };
        } else {
          // 如果 key 已存在，增加 amount 值
          mergedDetails[key].amount += 1;
        }
      });

      // 将合并后的结果转换为数组形式
      const mergedArray = Object.values(mergedDetails);

      return mergedArray;
    },
    deletePackage() {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation DeletePackage($id: ID!) {
                deletePackage(id: $id) {
                id
                }
            }
            `,
          variables: {
            id: parseInt(this.$route.params.id * 1)
          }
        })
        .then(() => {
          this.$router.push({
              name: "Packages",
              params: {
              alert: true,
              alert_type: "success",
              message: this.pkg.name+"deleted successfully",
              },
          });
        })
        .catch(error => {
          console.error('Failed to delete user:', error);
          this.alert_type = "error";
          this.alert_message = "Delete Failed";
          this.alert_condition = true;
          setTimeout(() => {
            this.alert_condition = false;
          }, 3000);
        });
    }
  },
  created(){
    this.$apollo.query({ query: queries.GET_ALL_PERMISSIONS_QUERY }).then(async result => {
      this.getAllPermissions = await result.data.getAllPermissions;
      this.$apollo.query(
        {
          query: queries.GET_STAFF_ROLES,
          variables: {
            staffId: this.$session.get("user_id") * 1
          }
        }
      ).then(async result => {
        this.getStaffRoles = await result.data.getStaffRoles;
        this.allPermissionsName = [];
        this.getStaffRoles.forEach(role => {
          role.role.RolePermission.forEach((item) => {
            const name = item.permission.name;
            this.allPermissionsName.push(name);
          });
        })
        this.$session.set('staffPermission', this.$generalFunctions.generatePermissionArray(this.getAllPermissions, this.allPermissionsName));
        this.staffPermission = await this.$generalFunctions.generatePermissionArray(this.getAllPermissions, this.allPermissionsName)
      })
    })
  }
}
</script>
<style>
/* Capitalize the first letter of the selected item */
.v-select .v-select__selection {
  text-transform: capitalize;
}
.v-menu__content .v-list-item__title::first-letter {
  text-transform: capitalize;
}
</style>

  