<template>
  <div>
    <v-alert
    :type="type"
    dismissible
    >
      <slot></slot>
    </v-alert>
  </div>
</template>

<script>
  export default {
    props:['alert_type'],
    data () {
      return {
        type: this.alert_type,
      }
    },
  }
</script>