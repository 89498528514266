var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('SideMenu',{attrs:{"submenuPage":"Staff"}},[_c('Form',{scopedSlots:_vm._u([{key:"tabs",fn:function(){return undefined},proxy:true},{key:"form",fn:function(){return [_c('v-form',{ref:"form",attrs:{"lazy-validation":""}},[_c('v-text-field',{attrs:{"label":"Staff","value":_vm.staffById.fullname,"disabled":""}}),(_vm.$route.query.date)?_c('v-text-field',{attrs:{"label":"Date","value":_vm.$route.query.date,"disabled":""}}):_c('v-text-field',{attrs:{"label":"Day","value":_vm.dates[_vm.$route.query.day*1],"disabled":""}}),_c('v-select',{attrs:{"items":_vm.outlets,"item-value":"id","item-text":"display_name","label":"Outlet","rules":[function () { return !!_vm.staffrosterById.outlet_id || 'Outlet is required'; }]},model:{value:(_vm.staffrosterById.outlet_id),callback:function ($$v) {_vm.$set(_vm.staffrosterById, "outlet_id", $$v)},expression:"staffrosterById.outlet_id"}}),(!_vm.staffrosterById.off_day)?_c('div',[_c('v-menu',{ref:"from_time_menu",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.staffrosterById.from_time,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.staffrosterById, "from_time", $event)},"update:return-value":function($event){return _vm.$set(_vm.staffrosterById, "from_time", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"From Time","prepend-icon":"mdi-clock-time-four-outline","readonly":"","rules":[
                    function () { return _vm.changeAsDate(_vm.staffrosterById.from_time) >= _vm.outlet_fromTime || 'From Time cannot be earlier than Outlet Session Start Time'; },
                    function () { return _vm.changeAsDate(_vm.staffrosterById.from_time) <= _vm.outlet_toTime || 'From Time cannot be later than Outlet Session Start Time'; },
                    function () { return _vm.changeAsDate(_vm.staffrosterById.to_time) >= _vm.changeAsDate(_vm.staffrosterById.from_time) || 'From Time has to earlier than To Time'; }]},model:{value:(_vm.staffrosterById.from_time),callback:function ($$v) {_vm.$set(_vm.staffrosterById, "from_time", $$v)},expression:"staffrosterById.from_time"}},'v-text-field',attrs,false),on))]}}],null,false,1863569623),model:{value:(_vm.from_time_menu),callback:function ($$v) {_vm.from_time_menu=$$v},expression:"from_time_menu"}},[_c('v-time-picker',{staticClass:"mt-4",attrs:{"allowed-minutes":_vm.allowedStep,"format":"24hr","rules":[
                    function () { return _vm.changeAsDate(_vm.staffrosterById.from_time) >= _vm.outlet_fromTime || 'From Time cannot be earlier than Outlet Session Start Time'; },
                    function () { return _vm.changeAsDate(_vm.staffrosterById.from_time) <= _vm.outlet_toTime || 'From Time cannot be later than Outlet Session Start Time'; },
                    function () { return _vm.changeAsDate(_vm.staffrosterById.to_time) >= _vm.changeAsDate(_vm.staffrosterById.from_time) || 'From Time has to earlier than To Time'; }]},on:{"click:minute":function($event){return _vm.$refs.from_time_menu.save(_vm.staffrosterById.from_time)}},model:{value:(_vm.staffrosterById.from_time),callback:function ($$v) {_vm.$set(_vm.staffrosterById, "from_time", $$v)},expression:"staffrosterById.from_time"}})],1),_c('v-menu',{ref:"to_time_menu",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.staffrosterById.to_time,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.staffrosterById, "to_time", $event)},"update:return-value":function($event){return _vm.$set(_vm.staffrosterById, "to_time", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"To Time","prepend-icon":"mdi-clock-time-four-outline","readonly":"","rules":[
                    function () { return _vm.changeAsDate(_vm.staffrosterById.to_time) >= _vm.outlet_fromTime || 'To Time cannot be earlier than Outlet Session Start Time'; },
                    function () { return _vm.changeAsDate(_vm.staffrosterById.to_time) <= _vm.outlet_toTime || 'To Time cannot be later than Outlet Session Start Time'; },
                    function () { return _vm.changeAsDate(_vm.staffrosterById.to_time) >= _vm.changeAsDate(_vm.staffrosterById.from_time) || 'From Time has to earlier than To Time'; }]},model:{value:(_vm.staffrosterById.to_time),callback:function ($$v) {_vm.$set(_vm.staffrosterById, "to_time", $$v)},expression:"staffrosterById.to_time"}},'v-text-field',attrs,false),on))]}}],null,false,2956110807),model:{value:(_vm.to_time_menu),callback:function ($$v) {_vm.to_time_menu=$$v},expression:"to_time_menu"}},[_c('v-time-picker',{staticClass:"mt-4",attrs:{"allowed-minutes":_vm.allowedStep,"format":"24hr"},on:{"click:minute":function($event){_vm.$refs.to_time_menu.save(_vm.staffrosterById.to_time);_vm.$refs.form.validate();}},model:{value:(_vm.staffrosterById.to_time),callback:function ($$v) {_vm.$set(_vm.staffrosterById, "to_time", $$v)},expression:"staffrosterById.to_time"}})],1),_c('v-menu',{ref:"break_from_time_menu",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.staffrosterById.break_from_time,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.staffrosterById, "break_from_time", $event)},"update:return-value":function($event){return _vm.$set(_vm.staffrosterById, "break_from_time", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Break From Time","prepend-icon":"mdi-clock-time-four-outline","readonly":"","rules":[function () { return _vm.staffrosterById.break_from_time <= _vm.staffrosterById.break_to_time || 'From Time has to earlier than To Time'; }]},model:{value:(_vm.staffrosterById.break_from_time),callback:function ($$v) {_vm.$set(_vm.staffrosterById, "break_from_time", $$v)},expression:"staffrosterById.break_from_time"}},'v-text-field',attrs,false),on))]}}],null,false,680484837),model:{value:(_vm.break_from_time_menu),callback:function ($$v) {_vm.break_from_time_menu=$$v},expression:"break_from_time_menu"}},[_c('v-time-picker',{staticClass:"mt-4",attrs:{"allowed-minutes":_vm.allowedStep,"format":"24hr"},on:{"click:minute":function($event){return _vm.$refs.break_from_time_menu.save(_vm.staffrosterById.break_from_time)}},model:{value:(_vm.staffrosterById.break_from_time),callback:function ($$v) {_vm.$set(_vm.staffrosterById, "break_from_time", $$v)},expression:"staffrosterById.break_from_time"}})],1),_c('v-menu',{ref:"break_to_time_menu",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.staffrosterById.break_to_time,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.staffrosterById, "break_to_time", $event)},"update:return-value":function($event){return _vm.$set(_vm.staffrosterById, "break_to_time", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Break To Time","prepend-icon":"mdi-clock-time-four-outline","readonly":""},model:{value:(_vm.staffrosterById.break_to_time),callback:function ($$v) {_vm.$set(_vm.staffrosterById, "break_to_time", $$v)},expression:"staffrosterById.break_to_time"}},'v-text-field',attrs,false),on))]}}],null,false,2931895613),model:{value:(_vm.break_to_time_menu),callback:function ($$v) {_vm.break_to_time_menu=$$v},expression:"break_to_time_menu"}},[_c('v-time-picker',{staticClass:"mt-4",attrs:{"allowed-minutes":_vm.allowedStep,"format":"24hr"},on:{"click:minute":function($event){return _vm.$refs.break_to_time_menu.save(_vm.staffrosterById.break_to_time)}},model:{value:(_vm.staffrosterById.break_to_time),callback:function ($$v) {_vm.$set(_vm.staffrosterById, "break_to_time", $$v)},expression:"staffrosterById.break_to_time"}})],1)],1):_vm._e(),_c('v-checkbox',{attrs:{"label":"Off Day"},model:{value:(_vm.staffrosterById.off_day),callback:function ($$v) {_vm.$set(_vm.staffrosterById, "off_day", $$v)},expression:"staffrosterById.off_day"}})],1)]},proxy:true},{key:"buttons",fn:function(){return [_c('v-btn',{staticClass:"mr-4",attrs:{"text":""},on:{"click":function($event){return _vm.$router.push({ path: '/staffs', query:{'tab':'roster'} })}}},[_vm._v(" Back to list ")]),_c('v-btn',{staticClass:"mr-4",attrs:{"color":"success"},on:{"click":_vm.validate}},[_vm._v(" Create ")])]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }