<template>
  <v-app>
    <div class="text-right list-view-button">
        <slot name="action-button"></slot>
    </div>
    <v-card class="tab">
        <v-card-title>
            <slot name="tabs"></slot>
        </v-card-title>
        <div class="margin-20">
            <slot name="tab-items"></slot>
        </div>
    </v-card>
  </v-app>
</template>

<script>
  export default {
    components:{
      
    },
  }
</script>