const dotenv = require('dotenv');
dotenv.config();
import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';

import ApolloClient from "apollo-client";
import { HttpLink } from "apollo-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";
import GeneralFunctionsPlugin from './generalFunctions.js';

import VueSession from 'vue-session';
import VueApollo from "vue-apollo";

import * as VueGoogleMaps from 'vue2-google-maps'

import axios from 'axios';

import router from "./router";
import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.css";
import "@/assets/css/default.css";

Vue.config.productionTip = false

const link = new HttpLink({
  uri: process.env.VUE_APP_SERVER_URL,
  headers:{
    authorization: process.env.VUE_APP_TOKEN,
    id: window.location.host
    // id: "service.app360.my",
  }
});
const client = new ApolloClient({
  link: link,
  cache: new InMemoryCache({
    addTypename: true
  })
});
import Print from 'vue-print-nb'
// Global instruction 
Vue.use(Print);
Vue.use(VueSession, {"persist":true});
Vue.use(VueApollo);
Vue.use(GeneralFunctionsPlugin);
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyCI1tcmvw0hiVk9bUPOHNjiU9aZq4A4lEE',
    libraries: 'places'
  },
})
Vue.prototype.$http = axios;

const apolloProvider = new VueApollo({
  defaultClient: client,
})

new Vue({
  vuetify,
  apolloProvider,
  router,
  render: h => h(App)
}).$mount('#app')

import 'vue2-dropzone/dist/vue2Dropzone.min.css'