<template>
  <v-app>
    <v-card class="tab">
    <v-card-title>
        <slot name="tabs"></slot>
    </v-card-title>
        <div class="padding-30">
            <slot name="form"></slot>
        </div>
    </v-card>
    <br/>
    <div class="text-right">
        <slot name="buttons"></slot>
    </div>
  </v-app>
</template>

<script>

  export default {
    components:{
      
    },
  }
</script>