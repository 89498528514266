<template>
  <v-app>
    <SideMenu :alert="alert" :alert_type="alert_type" :message="message" submenuPage="Staff">
      <List>
        <template v-slot:action-button>
          <v-btn
            color="primary"
            class="mr-4"
            @click="StaffCreateForm"
            v-if="staffPermission.createStaff"
          >
          + New Staff
        </v-btn>
        </template>
        <template v-slot:tabs>
          <v-tabs
            v-model="tab"
          >
            <v-tabs-slider></v-tabs-slider>
            <v-tab href="#staffs">Staffs</v-tab>
            <v-tab href="#roster">Roster</v-tab>
          </v-tabs>
        </template>
        <template v-slot:tab-items>
          <v-tabs-items v-model="tab">
            <v-tab-item value="staffs">
              <v-data-table
                :headers="headers"
                :items="staffs"
                class="elevation-1"
                item-key="name"
              >
              <template v-slot:item="{ item }">
                <tr
                  @click="StaffUpdateForm(item.id)"
                  style="cursor:pointer"
                >
                  <td>{{ item.fullname }}</td>
                  <td>{{ item.designation }}</td>
                  <td><span v-if="item.serviceNames">{{  item.serviceNames.join(', ') }}</span></td>
                  <td>{{ item.outlets }}</td>
                  <td>{{ item.status[0].toUpperCase() + item.status.slice(1) }}</td>
                </tr>
              </template>
              </v-data-table>
            </v-tab-item>
            <v-tab-item value="roster" class="margin-20">
              <v-select
              v-model="outlet_id"
                :items="outlets"
                item-value="id"
                item-text="name"
                dense
                outlined
                hide-details
                label="Outlet"
                class="ma-2"
                @change="GetRoster()"
              ></v-select>
              <v-row no-gutters>
                <v-col cols="1">
                  <v-btn
                    icon
                    class="ma-2"
                    @click="AddDaysToDate(-7)"
                  >
                    <v-icon>mdi-chevron-left</v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="4">
                  <v-menu
                    v-model="datemenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="formattedDate"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="date"
                      @input="datemenu = false"
                      @change="AddDaysToDate(0)"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="1">
                  <v-btn
                    icon
                    class="ma-2"
                    @click="AddDaysToDate(7)"
                  >
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="4"></v-col>
                <v-col cols="2">
                  <div class="text-right">
                    <v-btn-toggle v-model="type" class="ma-1" color="blue" mandatory>
                      <v-btn value="day" @change="GetWeekDays(), GetRoster()">
                        Day
                      </v-btn>
                      <v-btn value="date" @change="GetWeekDates(), GetRoster()">
                        Date
                      </v-btn>
                    </v-btn-toggle>
                  </div>
                </v-col>
              </v-row>
              <table class="roster">
                <thead v-if="type=='date'">
                  <td>Staffs</td>
                  <td 
                    v-for="(date, index) in dates" 
                    :key="index"
                  >
                    {{date}} ({{day_strings[index]}})
                  </td>
                </thead>
                <thead v-if="type=='day'">
                  <td>Staffs</td>
                  <td 
                    v-for="(date, index) in dates" 
                    :key="index"
                  >
                    {{date}}
                  </td>
                </thead>
                <tr
                  v-for="staff in staff_rosters"
                  :key="staff.id"
                >
                  <td>
                    {{staff.fullname}}
                  </td>
                  <td
                    v-for="roster in staff.roster"
                    :key="roster.id"
                    @click="StaffRosterForm(roster.id, staff.id, roster.day, dates[roster.day], roster.date)"
                  >
                    <div v-if="roster.id">
                      <span v-if="roster.off_day">
                        <b v-if="roster.date">*</b>
                        Off Day
                      </span>
                      <span v-else>
                        <b v-if="roster.date">*</b>
                        {{roster.from_time}} ~ {{roster.to_time}}
                      </span>
                    </div>
                    <div v-else>
                      +
                    </div>
                  </td>
                </tr>
              </table>
            </v-tab-item>
          </v-tabs-items>
        </template>
      </List>
    </SideMenu>
  </v-app>
</template>

<style>
.roster{
  width:100%;
  border: 1px solid black;
  border-collapse: collapse;
}
.roster td{
  padding:10px;
  border: 1px solid black;
  border-collapse: collapse;
  text-align:center
}
</style>

<script>
import SideMenu from '/src/components/SideMenu'
import List from '/src/components/List'
import gql from 'graphql-tag'
import * as queries from '@/graphql/gqlQueries.js';
  const GET_ALL_USERS_QUERY = gql`
      query staffs($outlet_id:Int) {
        staffs(outlet_id:$outlet_id) {
          id
          fullname
          created_at
          status
          designation
          staff_outlet{
            outlet_id
          }
          staff_roster{
            id
            day
            date
            from_time
            to_time
            off_day
          }
          staff_service{
            staff_id
            service_id
          }
        }
      }
  `
  const GET_ALL_USERS_ROSTER_QUERY = gql`
      query staffroster($outlet_id:Int, $date:String) {
        staffroster(outlet_id:$outlet_id, date:$date) {
          id
          day
          date
          from_time
          to_time
          staff_id
          off_day
        }
      }
  `
  const GET_ALL_OUTLET_QUERY = gql`
      query Outlets {
        outlets {
          id
          name
        }
      }
  `
  const GET_ALL_SERVICE_QUERY = gql`
      query Services {
        services {
          id
          name
        }
      }
  `
  export default {
    name: 'Staff',

    components:{
      SideMenu,
      List
    },

    data () {
      return {
        staffs: [],
        staffroster:[],
        staff_rosters: [],
        alert: this.$route.params.alert,
        alert_type: this.$route.params.alert_type,
        message: this.$route.params.message,
        tab:this.$route.query.tab,
        outlet_id:'',
        outlets: [],
        dates: {1:"Monday",2:"Tuesday",3:"Wednesday",4:"Thursday",5:"Friday",6:"Saturday",7:"Sunday"},
        day_strings: {1:"Monday",2:"Tuesday",3:"Wednesday",4:"Thursday",5:"Friday",6:"Saturday",7:"Sunday"},
        date: new Date().toISOString().slice(0, 10),
        formattedDate: this.formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)),
        datemenu:false,

        type: 'day',

        headers:[
          {
            text: 'Name',
            value: 'fullname',
            class: 'white--text'
          },
          {
            text: 'Designation',
            value: 'designation',
            class: 'white--text'
          },
             {text: 'Service',
            value: 'servise',
            class: 'white--text'
          },
          {
            text: 'Outlets',
            value: 'outlets',
            class: 'white--text'
          },
          {
            text: 'Status',
            value: 'status',
            class: 'white--text'
          }
        ],
        getAllPermissions: [],
        allPermissionsName: [],
        staffPermission: {},
      }
    },
    apollo: {
      outlets: {
        query: GET_ALL_OUTLET_QUERY,
        result() {
          if(this.outlets[0].name != 'All outlets'){
            this.outlets.unshift({id:'',name:'All outlets'})
          }
        }
      },
      services: {
        query: GET_ALL_SERVICE_QUERY
      },
      staffs: {
        query: GET_ALL_USERS_QUERY,
        variables (){
          return {
            outlet_id: this.outlet_id*1
          }
        },
        pollInterval: 300,
        async result() {
          const sortProperties = [
                    { property: 'id', direction: 'desc' },
                    { property: 'created_at', direction: 'desc' },
                ];

      // Use the multiPropertySort function to sort the commissionList
      this.staffs = await this.$generalFunctions.multiPropertySort(this.staffs, sortProperties);

          this.staffs.forEach(staffItem => {
            staffItem.serviceNames = [];
          staffItem.staff_service.forEach(staffServiceItem => {
            this.services.forEach(serviceItem => {   
              if (staffServiceItem.service_id== serviceItem.id) {
              staffItem.serviceNames.push(serviceItem.name);
            }
            });
          });
        });


          if(!this.outlets)this.$apollo.queries.outlets.refresh()
          for(let i=0; i<this.staffs.length; i++){
            this.staffs[i].outlets = []
            for(let x=0; x<this.staffs[i].staff_outlet.length; x++){
              for(let y=0; y<this.outlets.length; y++){
                if(this.outlets[y].id == this.staffs[i].staff_outlet[x].outlet_id){
                  this.staffs[i].outlets.push(this.outlets[y].name)
                }
              }
            }
            this.staffs[i].outlets = this.staffs[i].outlets.join(', ')
            if(this.staffs[i].id == 1){
              this.staffs.splice(i, 1)
              i -= 1
              continue
            }
          }
          this.GetRoster()
        }
      },
      staffroster: {
        query: GET_ALL_USERS_ROSTER_QUERY,
        variables (){
          return {
            outlet_id: this.outlet_id*1,
            date: this.date
          }
        },
        pollInterval: 300,
        result() {
          if(this.type=='date'){
            this.GetRoster()
          }
        }
      },
    },
    watch: {
      date () {
        this.formattedDate = this.formatDate(this.date)
      },
    },
    computed: {
      computedDateFormatted () {
        return this.formatDate(this.date)
      },
    },
    methods: {
      formatDate (date) {
        if (!date) return null
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
          "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
        ];
        const days_string = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"]
        var dateObject = new Date(date)
        return `${dateObject.getDate()} ${monthNames[dateObject.getMonth()]} ${dateObject.getFullYear()} (${days_string[dateObject.getDay()]})`
      },
      StaffUpdateForm(id) {
        this.$router.push({ path: '/staff/'+id })
      },
      StaffCreateForm() {
        this.$router.push({ path: '/create/staff' })
      },
      StaffRosterForm(roster_id, staff_id, day, date, roster_date) {
        if(roster_id){
          if(this.type == 'date' && !roster_date){
            if(!this.staffPermission.createStaff) return
            this.$router.push({ path: '/create/roster/staff', query:{'staff_id':staff_id, 'date':date, 'roster_id':roster_id} })
          }
          else{
            if(!this.staffPermission.updateStaff) return
            this.$router.push({ path: '/staff/roster/'+roster_id })
          }
        }
        else{
          var days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
          if(!days.includes(date)){
            this.$router.push({ path: '/create/roster/staff', query:{'staff_id':staff_id, 'date':date} })
          }
          else{
            this.$router.push({ path: '/create/roster/staff', query:{'staff_id':staff_id, 'day':day, 'date':date} })
          }
          
        }
      },
      GetRoster(){
        this.staff_rosters = []

        for(let i=0; i<this.staffs.length; i++){
          var roster = []
          for(var date_index in this.dates){
            var time = {day:date_index}
            for(let y=0; y<this.staffs[i].staff_roster.length; y++){
              if(date_index == this.staffs[i].staff_roster[y].day){
                time = {
                  day:date_index,
                  id: this.staffs[i].staff_roster[y].id,
                  from_time:("0" + (new Date(1620691200000+this.staffs[i].staff_roster[y].from_time*1).getUTCHours())).slice(-2) + ':' + ("0" + (new Date(1620691200000+this.staffs[i].staff_roster[y].from_time*1).getMinutes())).slice(-2), 
                  to_time:("0" + (new Date(1620691200000+this.staffs[i].staff_roster[y].to_time*1).getUTCHours())).slice(-2) + ':' + ("0" + (new Date(1620691200000+this.staffs[i].staff_roster[y].to_time*1).getMinutes())).slice(-2),
                  off_day: this.staffs[i].staff_roster[y].off_day
                }
                break
              }
            }
            roster.push(time)
          }
          this.staffs[i].roster = roster
          if(this.staffs[i].staff_outlet.length>0){
            this.staff_rosters.push(this.staffs[i])
          }
        }

        for(let i=0; i<this.staff_rosters.length; i++){
          for(date_index in this.dates){
            time = {day:date_index}
            for(let y=0; y<this.staffroster.length; y++){
              if(this.dates[date_index] == new Date(this.staffroster[y].date*1).toISOString().slice(0, 10) && this.staff_rosters[i].id == this.staffroster[y].staff_id){
                time = {
                  date:true,
                  day:date_index,
                  id: this.staffroster[y].id,
                  off_day: this.staffroster[y].off_day,
                  from_time:("0" + (new Date(1620691200000+this.staffroster[y].from_time*1).getUTCHours())).slice(-2) + ':' + ("0" + (new Date(1620691200000+this.staffroster[y].from_time*1).getMinutes())).slice(-2), 
                  to_time:("0" + (new Date(1620691200000+this.staffroster[y].to_time*1).getUTCHours())).slice(-2) + ':' + ("0" + (new Date(1620691200000+this.staffroster[y].to_time*1).getMinutes())).slice(-2),
                }
                this.staff_rosters[i].roster[date_index-1] = time
              }
            }
          }
        }
      },
      GetWeekDates(){
        let current_date = new Date(this.date)
        
        let week = {}
        for (let i = 1; i <= 7; i++) {
          let first = current_date.getDate() - current_date.getDay() + i 
          if(current_date.getDay() == 0){
            first = current_date.getDate() - 7 + i 
          }
          let day = new Date(current_date.setDate(first)).toISOString().slice(0, 10)
          week[i] = day
        }
        this.dates = week
        
        for (let i = 1; i <= 7; i++) {
          let first = current_date.getDate() - current_date.getDay() + i 
          if(current_date.getDay() == 0){
            first = current_date.getDate() - 7 + i 
          }
          let day = new Date(current_date.setDate(first)).toISOString().slice(0, 10)
          week[i] = day
        }
      },
      GetWeekDays(){
        this.dates = {1:"Monday",2:"Tuesday",3:"Wednesday",4:"Thursday",5:"Friday",6:"Saturday",7:"Sunday"}
      },
      AddDaysToDate(day){
        this.type = 'date'
        this.date = new Date(new Date(this.date).setDate(new Date(this.date).getDate() + day)).toISOString().slice(0, 10)
        this.GetWeekDates()
      }
    },
    created(){
      this.$apollo.query({ query: queries.GET_ALL_PERMISSIONS_QUERY }).then(async result => {
        this.getAllPermissions = await result.data.getAllPermissions;
        this.$apollo.query(
          {
            query: queries.GET_STAFF_ROLES,
            variables: {
              staffId: this.$session.get("user_id") * 1
            }
          }
        ).then(async result => {
          this.getStaffRoles = await result.data.getStaffRoles;
          this.allPermissionsName = [];
          this.getStaffRoles.forEach(role => {
            role.role.RolePermission.forEach((item) => {
              const name = item.permission.name;
              this.allPermissionsName.push(name);
            });
          })
          this.$session.set('staffPermission', this.$generalFunctions.generatePermissionArray(this.getAllPermissions, this.allPermissionsName));
          this.staffPermission = await this.$generalFunctions.generatePermissionArray(this.getAllPermissions, this.allPermissionsName)
        })
      })
    }
  }
</script>
