<template>
  <v-app>
    <SideMenu submenuPage="Outlet" submenuGroup="Outlet Setup">
      <Form>
        <template v-slot:tabs>
          <v-tabs
            v-model="tab"
          >
            <v-tabs-slider></v-tabs-slider>
            <v-tab href="#details">Details</v-tab>
            <v-tab href="#sessions">Sessions</v-tab>
          </v-tabs>
        </template>
        <template v-slot:form>
          <v-tabs-items v-model="tab">
            <v-tab-item value="details">
              <v-form
                ref="form"
                lazy-validation
              >
                <v-text-field
                  v-model="outletById.name"
                  :counter="191"
                  :rules="nameRules.concat(characterRules)"
                  label="Name"
                  required
                ></v-text-field>

                <v-container class="padding-0" style="margin:0px">
                  <v-row no-gutters>
                    <v-col>
                      <v-text-field
                        v-model="outletById.address1"
                        label="Address line 1"
                      ></v-text-field>

                      <v-text-field
                        v-model="outletById.address2"
                        label="Address line 2"
                      ></v-text-field>

                      <v-text-field
                        v-model="outletById.address3"
                        label="Address line 3"
                      ></v-text-field>

                      <v-text-field
                        v-model="outletById.city"
                        label="City"
                      ></v-text-field>

                      <!-- <v-text-field
                        v-model="outletById.state"
                        label="State"
                      ></v-text-field> -->

                      <v-text-field
                        v-model="outletById.postcode"
                        label="Postcode"
                      ></v-text-field>
<!-- 
                      <v-text-field
                        v-model="outletById.country"
                        label="Country"
                      ></v-text-field> -->

                      <p class="mb-1">Country</p>
                      <multiselect class="mb-3" :allowEmpty="false" v-model="selectedCountry" :options="countriesList" placeholder="Select one country" label="name" track-by="name" @input="checkState()">
                      </multiselect>

                      <p class="mb-1">State</p>
                      <multiselect class="mb-3" :allowEmpty="false" v-model="selectedState" :options="statesList" placeholder="Select one state" label="name" track-by="name">
                      </multiselect>
                    </v-col>

                    <v-col>
                      <v-container>
                        <v-row no-gutters>
                          <v-col>
                            <GmapMap
                              :center="position"
                              :zoom="15"
                              map-type-id="roadmap"
                              class="google-map"
                              @click="relocate"
                            >
                              <GmapMarker
                                :position="position"
                              />
                            </GmapMap>
                          </v-col>
                        </v-row>
                      
                        <v-row>
                          <v-col>
                            <v-text-field
                              v-model="outletById.longitude"
                              label="Longitude"
                              class="md-layout-item"
                            ></v-text-field>
                          </v-col>
                          <v-col>
                            <v-text-field
                              v-model="outletById.latitude"
                              label="Latitude"
                              class="md-layout-item"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row no-gutters>
                          <v-col>
                            <v-btn
                              color="info"
                              class="mr-4"
                              @click="centerFromCoordinates"
                            >
                              Center from coordinates
                            </v-btn>
                            <v-btn
                              color="info"
                              class="mr-4"
                              @click='setFromAddress'
                            >
                              Set from address
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-col>
                  </v-row>
                </v-container>

                <v-text-field
                  v-model="outletById.contact"
                  label="Contact"
                ></v-text-field>

                <v-text-field
                  v-model="outletById.email"
                  :counter="191"
                  :rules="emailRules"
                  label="Email"
                ></v-text-field>

                <v-text-field
                  v-model="outletById.fax"
                  label="Fax"
                ></v-text-field>

                <v-checkbox
                  v-model="outletById.online_booking"
                  label="Online booking"
                ></v-checkbox>

                <div>Image Upload</div>
                <div id="app">
                  <vue-dropzone
                    ref="dropzone"
                    id="drop1"
                    :options="dropOptions"
                    :destroyDropzone="false"
                    @vdropzone-complete="afterComplete"
                    @vdropzone-success="uploadSuccess"
                    @vdropzone-removed-file='removeThisFile'
                  ></vue-dropzone>
                  <input id="image_id" type="hidden" v-model="outletById.image_id"/>
                </div>
                <br/>
              </v-form>
            </v-tab-item>
            <v-tab-item value="sessions">
              <v-form
                ref="form2"
                lazy-validation
              >
                <v-checkbox
                  v-model="outletById.sessions"
                  v-for="session in sessions"
                  :key="session.id"
                  :label="session.name"
                  :value="session.id"
                ></v-checkbox>
              </v-form>
            </v-tab-item>
          </v-tabs-items>
        </template>
        <template v-slot:buttons>
          <v-btn
            class="mr-4"
            text
            @click="$router.push({ path: '/outlets' })"
          >
            Back to list
          </v-btn>
          <v-btn
            color="success"
            class="mr-4"
            @click="validate"
          >
            Create
          </v-btn>
        </template>
      </Form>
    </SideMenu>
  </v-app>
</template>
<script>
import SideMenu from '/src/components/SideMenu'
import Form from '/src/components/Form'
import gql from 'graphql-tag'
import Multiselect from 'vue-multiselect'
import vueDropzone from "vue2-dropzone"
import * as queries from '@/graphql/gqlQueries.js';
  const GET_ALL_SESSIONS_QUERY = gql`
    query Sessions {
      sessions {
        id
        name
      }
    }
  `
  export default {
    name: 'Outlet',

    components:{
      SideMenu,
      vueDropzone,
      Form,
      Multiselect
    },

    data () {
      
      return {
        countriesList:[
          {
            name: "Malaysia",
            iso: "MY"
          },
          {
            name: "Singapore",
            iso: "SG"
          }
        ],
        selectedCountry:{
          name: "Malaysia",
          iso: "MY"
        },
        selectedState:{},
        statesList:[],
        outletById: {
          name: '',
          image_id: '',
          address1: '',
          address2: '',
          address3: '',
          city: '',
          state: '',
          postcode: '',
          country: '',
          longitude: '',
          latitude: '',
          contact: '',
          email: '',
          fax: '',
          type: '',
          online_booking: true,
          sessions:[]
        },
        showPassword: false,
        
        nameRules: [
          v => !!v || 'Name is required',
        ],
        characterRules: [
          v => (v.length <= 191) || 'Field must be less than or equal to 191 characters',
        ],
        emailRules: [
          v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
        ],
        dropOptions: {
          url: "https://httpbin.org/post",//dummy url, since 'url' is a required field but we are not using it
          maxFilesize: 2, // MB
          maxFiles: 1,
          thumbnailWidth: 150, // px
          thumbnailHeight: 150,
          addRemoveLinks: true
        },
        tab:'',
        sessions:[],

        position:{lat:3.1569486,lng:101.712303}
      }
    },
    apollo: {
      sessions: {
        query: GET_ALL_SESSIONS_QUERY,
      }
    },
    methods: {
      async checkState(){
        try {
          const result = await this.$apollo.query({
            query: queries.GET_COUNTRY_STATES_BY_ISO,
            variables:{
              iso : this.selectedCountry.iso
            }
          })
          this.statesList = result.data.countryStatesByIso
          this.selectedState = this.statesList[0]
        } catch (error) {
          console.log(error)
          console.log(JSON.stringify(error, null, 2));
        }
      },
      validate () {
        var validate = this.$refs.form.validate()
        if(!validate){
          window.scrollTo(0,0);
        }
        if(validate){
          this.outletById.country = this.selectedCountry.name
          this.outletById.state = this.selectedState.name
          if(this.outletById.state.includes("No State"))this.outletById.state = null
          const CREATE_OUTLET_QUERY = gql`
            mutation createOutlet($name: String!, $image_id: Int, $address1: String, $address2: String, $address3: String, $city: String, $state: String, $postcode: String, $country: String, $longitude: Float, $latitude: Float, $contact: String, $email: String, $fax: String, $type: String, $online_booking: Boolean) {
                createOutlet(name: $name, image_id: $image_id, address1: $address1, address2: $address2, address3: $address3, city: $city, state: $state, postcode: $postcode, country: $country, longitude: $longitude, latitude: $latitude, contact: $contact, email: $email, fax: $fax, type: $type, online_booking: $online_booking)  {
                  id
                  name
                }
            }
          `
          this.$apollo.mutate(
            { 
              mutation: CREATE_OUTLET_QUERY, 
              variables:{
                name: this.outletById.name,
                image_id: this.outletById.image_id*1,
                address1: this.outletById.address1,
                address2: this.outletById.address2,
                address3: this.outletById.address3,
                city: this.outletById.city,
                state: this.outletById.state,
                postcode: this.outletById.postcode,
                country: this.outletById.country,
                longitude: this.outletById.longitude*1,
                latitude: this.outletById.latitude*1,
                contact: this.outletById.contact,
                email: this.outletById.email,
                fax: this.outletById.fax,
                type: this.outletById.type,
                online_booking: this.outletById.online_booking
              },
            }
          )
          .catch(error => {
            console.log(error)
          })
          .then(result => {
            if(result && result.data.createOutlet != null){ 
              console.log("Create successfully")
              const CREATE_OUTLET_SESSIONS_QUERY = gql`
                mutation createOutletSessions($session_id:Int!, $outlet_id:Int!) {
                    createOutletSessions(session_id:$session_id, outlet_id:$outlet_id)  {
                      outlet_id
                    }
                }
              `
              for(let i = 0; i < this.outletById.sessions.length; i++){
                this.$apollo.mutate(
                  { 
                    mutation: CREATE_OUTLET_SESSIONS_QUERY, 
                    variables:{
                      session_id: this.outletById.sessions[i]*1,
                      outlet_id: result.data.createOutlet.id*1
                    },
                  }
                )
              }
              this.$router.push({name: 'Outlet', params:{alert:true, alert_type:'success', message:result.data.createOutlet.name+' created successfully'}})
            } else {
              console.log("Create failed")
            }
          });
        }
      },
      afterComplete(file) {
        if(!file.manuallyAdded){
          var req = process.env.VUE_APP_SERVER_DOMAIN + 'image/upload?filename=' + file.name
          fetch(req, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              'dataURL': file.dataURL
            })
          })
          .then(res => {
            if(!res.ok){
              console.log('error')
            }
            else{
              res.json().then(() => {
                const CREATE_IMAGE_QUERY = gql`
                  mutation createImage($type: String!, $filename: String!) {
                      createImage(type: $type, filename: $filename)  {
                        id
                        filename
                      }
                  }
                `
                this.$apollo.mutate(
                  { 
                    mutation: CREATE_IMAGE_QUERY, 
                    variables:{
                      type: "outlet",
                      filename: process.env.VUE_APP_DO_SPACES_FOLDER+'/'+file.name,
                    },
                  }
                ).then(result => {
                  if(result.data.createImage != null){ 
                    this.outletById.image_id = result.data.createImage.id
                    console.log("Create successfully")
                  } else {
                    console.log("Create failed")
                  }
                });
              })
            }
          })
          .catch((err) => {
            console.log(err)
          })
        }
      },
      uploadSuccess(file, response) {
        console.log('File Successfully Uploaded with file name: ' + response.file + file)
        this.fileName = response.file
      },
      removeThisFile(){
        this.outletById.image_id = 0
      },
      centerFromCoordinates(){
        const marker = {
          lat: this.outletById.latitude*1,
          lng: this.outletById.longitude*1
        }
        this.position = marker
      },
      relocate(value){
        this.outletById.latitude = value.latLng.lat()
        this.outletById.longitude = value.latLng.lng()
        const marker = {
          lat: this.outletById.latitude*1,
          lng: this.outletById.longitude*1
        }
        this.position = marker
      },
      async setFromAddress() {
        this.currentPlace = this.outletById.address1
        if(this.outletById.address2){
          this.currentPlace += ','+this.outletById.address2
        }
        if(this.outletById.address3){
          this.currentPlace += ','+this.outletById.address3
        }
        if(this.outletById.postcode){
          this.currentPlace += ','+this.outletById.postcode
        }
        if(this.outletById.city){
          this.currentPlace += ','+this.outletById.city
        }
        if(this.outletById.state){
          this.currentPlace += ','+this.outletById.state
        }
        if(this.outletById.country){
          this.currentPlace += ','+this.outletById.country
        }
        if (this.currentPlace) {
          try {
            const response = await this.$http.get(
              'https://maps.googleapis.com/maps/api/geocode/json?address='+this.currentPlace+'&key=AIzaSyCI1tcmvw0hiVk9bUPOHNjiU9aZq4A4lEE'
            );
            this.position = response.data.results[0].geometry.location
            this.outletById.latitude = this.position.lat
            this.outletById.longitude = this.position.lng
          } catch (error) {
            console.log(error);
          }
        }
      }
    },
    async created(){
      try {
          const result = await this.$apollo.query({
            query: queries.GET_COUNTRY_STATES_BY_ISO,
            variables:{
              iso : "MY"
            }
          })
          this.statesList = await result.data.countryStatesByIso
          this.selectedState = this.statesList[0]
        } catch (error) {
          console.log(error)
          console.log(JSON.stringify(error, null, 2));
        }
    }
  }
</script>