<template>
    <v-app>
        <SideMenu>
            <template>
                <v-alert v-if="alert_condition" :type="alert_type">
                    {{ alert_message }}
                </v-alert>
            </template>
            <Form>
                <template v-slot:tabs>
                    <v-tabs v-model="tab">
                        <v-tabs-slider></v-tabs-slider>
                        <v-tab href="#details">Details</v-tab>
                        <v-tab href="#transaction">Transaction</v-tab>
                        <v-tab href="#bookings">Bookings</v-tab>
                        <v-tab href="#packages">Packages</v-tab>
                    </v-tabs>
                </template>
                <template v-slot:form>
                    <v-tabs-items v-model="tab">
                        <v-tab-item value="details">
                            <v-form ref="form" lazy-validation>
                                <v-text-field v-model="user.name" :counter="191" :rules="nameRules.concat(characterRules)"
                                    label="Name" required></v-text-field>

                                <v-text-field v-model="user.contact" :counter="counterLength"
                                    :rules="contactRules.concat(numericRule)" label="Phone number"></v-text-field>

                                <v-text-field v-model="user.user_id_external" :counter="30"
                                    label="User External Id"></v-text-field>

                                <v-text-field v-model="user.password"
                                    :append-icon="passwordVisible ? 'mdi-eye' : 'mdi-eye-off'"
                                    :type="passwordVisible ? 'text' : 'password'" label="Password"
                                    @click:append="passwordVisible = !passwordVisible"></v-text-field>

                                <v-text-field v-model="user.confirmPassword"
                                    :append-icon="confirmPasswordVisible ? 'mdi-eye' : 'mdi-eye-off'"
                                    :type="confirmPasswordVisible ? 'text' : 'password'" label="Confirm Password"
                                    @click:append="confirmPasswordVisible = !confirmPasswordVisible"
                                    :rules="confirmPasswordRules"></v-text-field>
                            </v-form>
                            <template>
                                <div>
                                    <div class="label">Created At: {{ userById.created_at }}</div>
                                    <div class="label">Updated At: {{ userById.updated_at }}</div>
                                    <div class="label" v-if="userById.deleted_at">Deleted At: {{ userById.deleted_at }}
                                    </div>
                                </div>
                            </template>
                        </v-tab-item>
                        <v-tab-item value="transaction">
                            <List>
                                <template v-slot:tab-items>
                                    <v-data-table :headers="transactionHeaders" :items="userById.Sales"
                                        class="elevation-1" item-key="name">
                                        <template v-slot:item="{ item }">
                                            <tr style="cursor:pointer">
                                                <td>{{ item.sales_type }}</td>
                                                <td>{{ item.amount.toFixed(2) }}</td>
                                                <td>{{ item.online_booking }}</td>
                                                <td>{{ item.staff_id ? item.staff_name : "" }}</td>
                                                <td>{{ item.status }}</td>
                                               <td>{{ item.created_at }}</td>   <!--//Transaction table -->
                                            </tr>
                                        </template>
                                    </v-data-table>
                                </template>
                            </List>
                        </v-tab-item>
                        <v-tab-item value="bookings">
                            <List>
                                <template v-slot:tab-items>
                                    <v-data-table :headers="bookingHeaders" :items="userById.bookings" class="elevation-1"
                                        item-key="name">
                                        <template v-slot:item="{ item }">
                                            <tr @click="routeToBookingDetails(item.id)" style="cursor:pointer">
                                                <td>{{ item.outlet_id }}</td>
                                                <td>{{ item.resource_type_name }}</td>
                                                <td>{{ item.service_name }}</td>
                                                <td>{{ item.package_transaction_id }}</td>
                                                <td>{{ item.amount.toFixed(2) }}</td>
                                                <td>{{ item.booked_date_time }}</td>
                                                <td>{{ item.duration }}</td>
                                                <td>{{ item.created_at }}</td>
                                            </tr>
                                        </template>
                                    </v-data-table>
                                </template>
                            </List>
                        </v-tab-item>
                        <v-tab-item value="packages">
                            <List>
                                <template v-slot:tab-items>
                                    <v-data-table :headers="packageHeaders" :items="userById.Package_Transaction"
                                        class="elevation-1" item-key="name">
                                        <template v-slot:item="{ item }">
                                            <tr style="cursor:pointer">
                                                <td>{{ item.Package.package_name }}</td>
                                                <td>{{ item.service_Type ? item.service_Type.name: "" }}</td>
                                                <td>{{ item.service? item.service.name : "" }}</td>
                                                <td>{{ item.resource_Type? item.resource_Type.name :"" }}</td>
                                                <td>{{ item.resource? item.resource.name : "" }}</td>
                                                <td>{{ item.used_at }}</td>
                                                <td>{{ item.created_at }}</td>
                                            </tr>
                                        </template>
                                    </v-data-table>
                                </template>
                            </List>
                        </v-tab-item>
                    </v-tabs-items>
                </template>
                <template v-slot:buttons>
                    <v-btn class="mr-4" text @click="$router.push({ path: '/customers' })">
                        Back to list
                    </v-btn>
                    <v-dialog
                        max-width="500"
                        v-if="tab === 'details' && !userById.deleted_at && staffPermission.deleteCustomer"
                    >
                        <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            color="error"
                            v-bind="attrs"
                            v-on="on"
                            class="mr-4" 
                        >Delete</v-btn>
                        </template>
                        <template v-slot:default="dialog">
                        <v-card>
                            <v-card-title>
                             Are you sure to delete {{ user.name }}?
                            </v-card-title>
                            <v-card-actions class="justify-end">
                            <v-btn
                                text
                                @click="dialog.value = false"
                            >Close</v-btn>
                            <v-btn color="error" @click="deleteUser();dialog.value = false">
                                Delete
                            </v-btn>
                            </v-card-actions>
                        </v-card>
                        </template>
                    </v-dialog>
                    <v-btn color="success" class="mr-4" @click="validate" v-if="tab === 'details' && staffPermission.updateCustomer">
                        Update {{ user.name }}
                    </v-btn>
                </template>
            </Form>
        </SideMenu>
    </v-app>
</template>

<script>
import SideMenu from "/src/components/SideMenu";
import Form from "/src/components/Form";
import List from '/src/components/List'
import gql from "graphql-tag";
import * as queries from '@/graphql/gqlQueries.js';
const GET_USER_QUERY = gql`
  query User($id: ID!) {
    userById(id: $id) {
      id
      user_id_external
      user_fullname
      user_contact
      password
      created_at
      updated_at
      deleted_at
      bookings{
        id
        outlet_id
        resource_type_id
        resource_type_name
        service_id
        service_name
        booked_date
        from_time
        duration
        amount
        package_transaction_id
        created_at
      }
      Package_Transaction{
        Package{
            package_name
        }
        used_at
        created_at
        resource_Type{
            id
            name
        }
        service_Type{
            id
            name
        }
        resource{
            id
            name
        }
        service{
            id
            name
        }
      }
      Sales{
        id
        sales_type
        status
        package_id
        booking_id
        amount
        user_id
        outlet_id
        staff_id
        online_booking
        created_at
        updated_at
        deleted_at
        voided_at
      }
    }
  }
`
const GET_ALL_STAFF_QUERY = gql`
      query staffs {
        staffs {
          id
          fullname
        }
      }
  `

export default {
    name: "User",

    components: {
        SideMenu,
        Form,
        List
    },

    data() {
        return {
            user: {
                name: "",
                contact: "",
                confirmPassword: "",
                password: "",
                user_id_external: "",
            },

            userById: {
                deleted_at: ""
            },

            counterLength: 12,
            passwordVisible: false,
            confirmPasswordVisible: false,

            nameRules: [(v) => !!v || "Name is required"],
            contactRules: [(v) => !!v || "Phone number is required"],
            numericRule: [
                (v) => /^\d+$/.test(v) || "Field must contain only numbers",
            ],
            characterRules: [
                (v) =>
                    v.length <= 191 ||
                    "Field must be less than or equal to 191 characters",
            ],

            confirmPasswordRules: [
                v => v === this.user.password || 'Passwords do not match',
            ],
            tab: null,
            routeParam: this.$route.params.id,
            alert_type: "",
            alert_message: "",
            alert_condition: false,
            bookingHeaders: [
                {
                    text: 'Outlet',
                    value: 'outlet_id',
                    class: 'white--text'
                },
                {
                    text: 'Resource Type',
                    value: 'resource_type_name',
                    class: 'white--text'
                },
                {
                    text: 'Service',
                    value: 'service_name',
                    class: 'white--text'
                },
                {
                    text: 'Package',
                    value: 'package_transaction_id',
                    class: 'white--text'
                },
                {
                    text: 'Amount',
                    value: 'amount',
                    class: 'white--text'
                },
                {
                    text: 'Booked Date',
                    value: 'booked_date',
                    class: 'white--text'
                },
                {
                    text: 'Duration (minute)',
                    value: 'duration',
                    class: 'white--text'
                },
                {
                    text: 'Created Date',
                    value: 'created_at',
                    class: 'white--text'
                },
            ],
            packageHeaders: [
                {
                    text: 'Package Name',
                    value: 'Package.package_name',
                    class: 'white--text'
                },
                {
                    text: 'Service Type',
                    value: 'service_Type.name',
                    class: 'white--text'
                },
                {
                    text: 'Service',
                    value: 'service.name',
                    class: 'white--text'
                },
                {
                    text: 'Resource Type',
                    value: 'resource_Type.name',
                    class: 'white--text'
                },
                {
                    text: 'Resource',
                    value: 'resource.name',
                    class: 'white--text'
                },
                {
                    text: 'Used At',
                    value: 'used_at',
                    class: 'white--text'
                },
                {
                    text: 'Created At',
                    value: 'created_at',
                    class: 'white--text'
                },
            ],
            transactionHeaders: [
                {
                    text: 'Type',
                    value: 'sales_type',
                    class: 'white--text'
                },
                {
                    text: 'Amount',
                    value: 'amount',
                    class: 'white--text'
                },
                {
                    text: 'Online Booking',
                    value: 'resource.name',
                    class: 'white--text'
                },
                {
                    text: 'Staff',
                    value: 'staff_id',
                    class: 'white--text'
                },
                {
                    text: 'Status',
                    value: 'status',
                    class: 'white--text'
                },
                {
                    text: 'Created At',
                    value: 'created_at',
                    class: 'white--text'
                },
            ],
            getAllPermissions: [],
            allPermissionsName: [],
            staffPermission: {},
        };
    },
    computed: {
        type() {
            // Compute the alert type based on the value of alertType
            switch (this.alert_type) {
                case 'success':
                    return 'success';
                case 'error':
                    return 'error';
                case 'warning':
                    return 'warning';
                default:
                    return 'info';
            }
        }
    },
    watch: {
        'user.contact'(value) {
            this.updateCounter(value);
        },
    },
    apollo: {
        staffs: {
            query: GET_ALL_STAFF_QUERY,
        },
        userById: {
            query: GET_USER_QUERY,
            fetchPolicy: 'network-only',
            variables() {
                return {
                    id: this.routeParam,
                };
            },
            result() {
                this.user.name = this.userById.user_fullname
                this.user.contact = this.userById.user_contact
                this.user.user_id_external = this.userById.user_id_external
                console.log("123userById", this.userById)
                // const options = {
                //     year: "numeric",
                //     month: "long",
                //     day: "numeric",
                //     hour: "numeric",
                //     minute: "numeric",
                //     hour12: true,
                //     timeZone: "Asia/Singapore",
                // }
                const updatedAt = new Date(this.userById.updated_at * 1);
                if (!isNaN(updatedAt.getTime())) {
                    this.userById.updated_at = this.$options.filters.formatDateTime(updatedAt);
                }
                if (this.userById.deleted_at) {
                    const deletedAt = new Date(this.userById.deleted_at * 1);
                    if (!isNaN(deletedAt.getTime())) {
                        this.userById.deleted_at = this.$options.filters.formatDateTime(deletedAt);
                    }
                }

                const createdAt = new Date(this.userById.created_at * 1);
                if (!isNaN(createdAt.getTime())) {
                    this.userById.created_at = this.$options.filters.formatDateTime(createdAt );
                }

                if (this.userById.bookings.length > 0) {
                    this.userById.bookings.forEach(booking => {
                        const bookedDate = new Date(booking.booked_date * 1);
                        const bookedCreatedAt = new Date(booking.created_at * 1)
                        // const optionMYD = {
                        //     year: "numeric",
                        //     month: "long",
                        //     day: "numeric",
                        //     timeZone: "Asia/Singapore",
                        // }
                        if (!isNaN(bookedDate.getTime())) {
                            booking.booked_date = this.$options.filters.formatDate(bookedDate);
                        }
                        if (!isNaN(bookedCreatedAt.getTime())) {
                            booking.created_at = this.$options.filters.formatDateTime(bookedCreatedAt);
                        }
                        if (booking.from_time && !booking.from_time.includes(':')) {
                            booking.from_time = ("0" + (new Date(1620691200000 + booking.from_time * 1).getUTCHours())).slice(-2) + ':' + ("0" + (new Date(1620691200000 + booking.from_time * 1).getMinutes())).slice(-2)
                        }
                        booking.booked_date_time = booking.booked_date + " at " + booking.from_time
                    });
                }

                if (this.userById.Package_Transaction.length > 0) {
                    this.userById.Package_Transaction.forEach(p => {
                        const packageUsedDate = new Date(p.used_at * 1);
                        const packageCreatedAt = new Date(p.created_at * 1)
                        console.log("123",p.used_at)
                        if (!isNaN(packageUsedDate.getTime())) {
                            if(p.used_at)p.used_at = this.$options.filters.formatDateTime(packageUsedDate);
                        }
                        if (!isNaN(packageCreatedAt.getTime())) {
                            p.created_at = this.$options.filters.formatDate(packageCreatedAt);
                        }
                    });
                }

                if (this.userById.Sales.length > 0) {
                    this.userById.Sales.forEach(s => {
                        const salesCreatedAt = new Date(s.created_at * 1)
                        if (!isNaN(salesCreatedAt.getTime())) {
                            s.created_at = this.$options.filters.formatDateTime(salesCreatedAt);
                            console.log("selcecreae", s.created_at)
                        }
                        if(s.staff_id){
                            s.staff_name = this.staffs.find(staff => staff.id == s.staff_id).fullname
                        }
                    });
                }

            }
        },
    },
    methods: {
        updateCounter(value) {
            const number = value.trim();
            if (number.startsWith('60')) {
                this.counterLength = 12;
            } else if (number.startsWith('0')) {
                this.counterLength = 11;
            } else if (number.startsWith('1')) {
                this.counterLength = 10;
            } else {
                this.counterLength = 12;
            }
        },
        parsePhoneNumber(phoneNumber) {
            const countryCode = "60";
            if (!phoneNumber || !countryCode || phoneNumber.length < 1 || countryCode.length < 2) return phoneNumber;

            const normalizedNumber = phoneNumber.replace(/\D/g, ''); // Remove non-digit characters

            if (normalizedNumber.startsWith(countryCode)) {
                return normalizedNumber; // Number is already in the desired format
            } else if (normalizedNumber.startsWith('0')) {
                return countryCode + normalizedNumber.slice(1); // Prepend country code, excluding the leading '0'
            }

            return countryCode + normalizedNumber; // Prepend country code to the number
        },
        resetAlert() {
            // Reset the alert properties
            this.alert_type = null;
            this.alert_message = null;
            this.alert_condition = false;
        },
        async validate() {
            var validate = this.$refs.form.validate();
            if (!validate) {
                window.scrollTo(0, 0);
            }
            this.user.contact = this.parsePhoneNumber(this.user.contact)

            // var passwordHash = require('password-hash')
            if (validate) {
                const UPDATE_USER_MUTATION = gql`
                mutation updateUser($id: ID!, $user_contact: String, $user_fullname: String, $user_id_external: String) {
                    updateUserById(id: $id, user_contact: $user_contact, user_fullname: $user_fullname, user_id_external: $user_id_external) {
                    id
                    user_contact
                    user_fullname
                    user_id_external
                    updated_at
                    }
                }
                `

                this.$apollo
                    .mutate({
                        mutation: UPDATE_USER_MUTATION,
                        variables: {
                            id: this.routeParam, // Replace with the user ID you want to update
                            user_contact: this.user.contact, // Provide the updated values for the fields you want to modify
                            user_fullname: this.user.name,
                            user_id_external: this.user.user_id_external
                        }
                    })
                    .then(response => {
                        const updatedUser = response.data.updateUserById;
                        console.log(updatedUser);
                        this.alert_type = "success";
                        this.alert_message = "Update Profile successfully";
                        this.alert_condition = true;
                        setTimeout(() => {
                            this.alert_condition = false;
                        }, 3000);
                        if (!this.user.password && !this.user.confirmPassword) return
                        const UPDATE_USER_PASSWORD_MUTATION = gql`
                        mutation UpdateUserPassword($id: ID!, $newPassword: String!) {
                            updateUserPassword(id: $id, newPassword: $newPassword) {
                            id
                            user_id_external
                            user_fullname
                            user_contact
                            password
                            created_at
                            updated_at
                            deleted_at
                            }
                        }
                        `

                        var passwordHash = require('password-hash')
                        this.$apollo.mutate({
                            mutation: UPDATE_USER_PASSWORD_MUTATION,
                            variables: {
                                id: updatedUser.id, // Replace with the actual user ID
                                newPassword: passwordHash.generate(this.user.password), // Replace with the new password
                            },
                        })
                            .then(result => {
                                const updatedUser = result.data.updateUserPassword;
                                console.log('Updated User:', updatedUser);
                                this.user.password = ''
                                this.user.confirmPassword = ''
                                this.alert_type = "success";
                                this.alert_message = "Update Password successfully";
                                this.alert_condition = true;
                                setTimeout(() => {
                                    this.alert_condition = false;
                                }, 3000);
                                // Handle the updated user as needed
                            })
                            .catch(error => {
                                this.alert_type = "error";
                                this.alert_message = error.message;
                                this.alert_condition = true;
                                setTimeout(() => {
                                    this.alert_condition = false;
                                }, 3000);
                            });

                    })
                    .catch(error => {
                        if (error.message === "GraphQL error: User with this contact already exists") {
                            this.user.contact = this.userById.user_contact;
                            this.alert_type = "error";
                            this.alert_message = "User with this contact already exists.";
                            this.alert_condition = true;
                            setTimeout(() => {
                                this.alert_condition = false;
                            }, 3000);
                        } else {
                            console.error(error);
                            // Handle other errors here
                        }
                    });

            }

        },
        routeToBookingDetails(id) {
            this.$router.push({ path: '/booking/' + id })
        },

        deleteUser() {
            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation DeleteUser($id: ID!) {
                            deleteUser(id: $id) {
                            id
                            user_fullname
                            }
                        }
                        `,
                    variables: {
                        id: this.routeParam
                    }
                })
                .then(response => {
                    const deletedUser = response.data.deleteUser;
                    console.log(`Deleted user with ID ${deletedUser.id}`);
                    this.$router.push({
                        name: "Customer",
                        params: {
                        alert: true,
                        alert_type: "success",
                        message: "User deleted successfully",
                        },
                    });
                })
                .catch(error => {
                    console.error('Failed to delete user:', error);
                });
        }


    },

    filters: {
    formatDateTime(value) {
        const dateTime = new Date(value * 1);

        const year = dateTime.getFullYear();
        const month = dateTime.toLocaleString('en-US', { month: 'short' });
        const day = dateTime.getDate().toString().padStart(2, '0');

        let hours = dateTime.getHours();
        const minutes = dateTime.getMinutes().toString().padStart(2, '0');
        const period = hours >= 12 ? 'PM' : 'AM';

        // Convert hours to 12-hour format
        if (hours > 12) {
        hours -= 12;
        } else if (hours === 0) {
        hours = 12;
        }

        return `${day} ${month} ${year} ${hours}:${minutes}${period}`;
    },

    formatDate(value) {
        const dateTime = new Date(value * 1);

        const year = dateTime.getFullYear();
        const month = dateTime.toLocaleString('en-US', { month: 'short' });
        const day = dateTime.getDate().toString().padStart(2, '0');
        return `${day} ${month} ${year}`;
    },
  },
  created(){
    this.$apollo.query({ query: queries.GET_ALL_PERMISSIONS_QUERY }).then(async result => {
      this.getAllPermissions = await result.data.getAllPermissions;
      this.$apollo.query(
        {
          query: queries.GET_STAFF_ROLES,
          variables: {
            staffId: this.$session.get("user_id") * 1
          }
        }
      ).then(async result => {
        this.getStaffRoles = await result.data.getStaffRoles;
        this.allPermissionsName = [];
        this.getStaffRoles.forEach(role => {
          role.role.RolePermission.forEach((item) => {
            const name = item.permission.name;
            this.allPermissionsName.push(name);
          });
        })
        this.$session.set('staffPermission', this.$generalFunctions.generatePermissionArray(this.getAllPermissions, this.allPermissionsName));
        this.staffPermission = await this.$generalFunctions.generatePermissionArray(this.getAllPermissions, this.allPermissionsName)
      })
    })
  }
};
</script>
