<template>
  <v-app>
    <SideMenu :alert="alert" :alert_type="alert_type" :message="message" submenuPage="Calendar">
      <List>
        <template v-slot:action-button>
          <v-btn
            color="primary"
            class="mr-4 mt-1"
            href="/pendingList"
            v-if="staffPermission.viewPendingOrder"
          >
          <v-icon left>
            mdi-receipt-text-clock
          </v-icon>
            Pending Orders
            <span class="pending_circle" v-if="getPendingSales.length > 0">{{ getPendingSales.length | filterLength }}</span>
          </v-btn>
          <v-btn
            color="primary"
            class="mr-4 mt-1"
            @click = "openCheckNumberDialog('package',false)"
            v-if="staffPermission.createPurchase"
          >
          <v-icon left>
            mdi-package
          </v-icon>
            Purchase Package
          </v-btn>
          <v-btn
            color="primary"
            class="mr-4 mt-1"
            @click = "openMultiBookingDialog"
            v-if="staffPermission.createBooking"
          >
            + New Booking
            <span class="pending_circle" v-if="selectedBookingList.length > 0">{{ selectedBookingList.length | filterLength }}</span>
          </v-btn>
        </template>
        <template v-slot:tabs>
          <v-row no-gutters>
            <v-col cols="12" md="6">
              <v-select
                v-model="outlet_id"
                :items="processed_outlets"
                item-value="id"
                item-text="name"
                dense
                outlined
                hide-details
                label="Outlet"
                class="ma-2"
                @input="getEvents"
              ></v-select>
            </v-col>
            <v-col cols="12" md="6">
              <v-select
                v-model="staff_id"
                :items="processed_staffs"
                item-value="id"
                item-text="fullname"
                dense
                outlined
                hide-details
                label="Staff"
                class="ma-2"
                @input="getEvents"
              ></v-select>
            </v-col>
            <v-col cols="12" md="6" class="d-flex align-items-center">
              <v-btn
                icon
                class="ma-2"
                @click="$refs.calendar.prev(), getEvents()"
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-menu
                v-model="datemenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="formattedValue"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    class="pt-0"
                    hide-details
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="value"
                  @input="datemenu = false"
                ></v-date-picker>
              </v-menu>
              <v-btn
                icon
                class="ma-2"
                @click="$refs.calendar.next()"
              >
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="12" md="6" >
              <div class="text-right">
                <v-btn-toggle v-model="type" class="ma-1" color="blue" @change="changeCalenderType">
                  <v-btn :value="types[2]">
                    Daily
                  </v-btn>
                  <v-btn :value="types[1]">
                    Weekly
                  </v-btn>
                  <v-btn :value="types[0]">
                    Monthly
                  </v-btn>
                </v-btn-toggle>
              </div>
            </v-col>
          </v-row>
        </template>
        <template v-slot:tab-items>
          <v-sheet height="600">
            <v-calendar
              ref="calendar"
              v-model="value"
              :type="type"
              :events="events"
              :event-overlap-mode="mode"
              :event-overlap-threshold="30"
              :categories="categories"
              :key="forceRender"
              @change="getEvents"
              @click:event="BookingDetails"
              @click:more="viewDay"
              @click:date="viewDay"
              @click:day="BookingCreateForm"
              @mouseenter:event="showEvent"
              category-show-all
              category-hide-dynamic
              :interval-minutes= settings.slot_duration
              :first-time = from_time
              :interval-count = intervalCount
              color='rgb(7, 91, 170, 0.2)'
              :short-intervals="false"
              @click:time-category="clickDay"
              @mousedown:event="startDrag"
              @contextmenu:event="checkdetails"
              @mouseup:event="endDrag"
              @mousedown:time="startTime"
              @mousedown:time-category="startTime"
              @mousemove:time="mouseMove"
              @mousemove:time-category="mouseMove"
              @mouseleave.native="cancelDrag"
            >
            <template v-slot:day-label="{ date, day }">
              <div v-if="isOffDay(date)"
              :style="{ color: '#c8b9b9',width:'100%',height:'100%'}"
              >{{ day }}</div>
            </template>
            <template v-slot:interval="{date, time}">
              <div 
              :style="{ backgroundColor: isOffDay(date)?'#c8b9b9':'transparent',width:'100%',height:'100%'}"
              @click="createBookingInWeek(date, time)"
              >
              </div>
            </template>
            <template v-slot:event="{event,eventParsed,timeSummary,formatTime,eventSummary}">
              <v-tooltip bottom v-if="type!='month'">
                <template v-slot:activator="{ on, attrs }" class="h-100 w-100">
                  <div
                    class="h-100 w-100"
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ event.name }} <br>
                    {{timeSummary()}}
                </div>
                </template>
                <span v-if="!event.temporary">right click to view details.<br>drag to reschedule time.</span>
                <span v-else>click modify booking</span>
              </v-tooltip>
              <div v-if="type=='month'" v-html="eventSummary()"> </div>
            </template>
          </v-calendar>
            <v-menu
              v-model="selectedOpen"
              open-on-hover
              :close-on-content-click="false"
              :activator="selectedElement"
              offset-x
            >
              <v-card
                color="grey lighten-4"
                min-width="350px"
                flat
              >
                <v-toolbar
                  :color="selectedEvent.color"
                  dark
                  @click="BookingDetailsById(selectedEvent.id)"
                  class="clickable"
                >
                  <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
                  <v-spacer></v-spacer>
                </v-toolbar>
                <v-card-text v-if="selectedEvent.start" @click="BookingDetailsById(selectedEvent.id)" class="clickable">
                  From: <span v-html="selectedEvent.start.toLocaleString()"></span><br/>
                  To: <span v-html="selectedEvent.end.toLocaleString()"></span>
                </v-card-text>
                <v-card-actions>
                  <v-btn
                    text
                    color="secondary"
                    @click="selectedOpen = false"
                  >
                    OK
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
          </v-sheet>
        </template>
      </List>
    </SideMenu>
    <template>
      <v-row justify="center">
      <v-dialog
        v-model="dialog"
        max-width="290"
        @click:outside="cancelReschedule"
      >
        <v-card>
          <v-card-title class="text-h5">
            Rearrange/<br/>
            Reschedule
          </v-card-title>

          <v-card-text>
            Are you sure to rearrange/reschedule the booking?
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              text
              @click="cancelReschedule"
            >
              No
            </v-btn>

            <v-btn
              text
              @click="reschedule(event)"
            >
              Yes
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      </v-row>
    </template>
    <template>
      <v-row justify="center">
      <v-dialog
        v-model="bookDialog"
        max-width="450"
        @click:outside="cancelBooking"
      >
        <v-card>
          <v-card-title class="text-h5">
            <span v-if="!bookingById.bookEvent">Book an appointment</span>
            <span v-else>Edit appointment</span>
          </v-card-title>

          <v-card-text>
            <v-form
              ref="form"
              lazy-validation
            >
              <v-select
                v-model="bookingById.outlet_id"
                :items="booking_outlets"
                item-value="id"
                item-text="name"
                label="Outlet"
                :rules="outletRules"
                @input="setOutletName"
                required
                no-data-text="No outlet available"
              ></v-select>

              <v-select
                v-model="bookingById.staff_id"
                :items="booking_staffs"
                item-value="id"
                item-text="fullname"
                label="Staff"
                :rules="staffRules"
                @input="setStaffName"
                @change="processedTimeSlot"
                return-object
                required
                no-data-text="No staff available"
              >
                <template slot="item" slot-scope="data">
                  {{ data.item.fullname }}
                </template>
              </v-select>

              <v-select
                v-model="bookingById.service_id"
                :items="booking_services"
                item-value="id"
                item-text="name"
                label="Service"
                :rules="serviceRules"
                @input="setServiceName"
                return-object
                required
                no-data-text="No service available"
              >
                <template slot="item" slot-scope="data">
                  {{ data.item.name }} ({{ data.item.duration }} minutes)
                </template>
              </v-select>

              <datepicker 
                v-model="bookingById.booked_date" 
                :highlighted="highlightedDates"
                name="Date"
                placeholder="Select Date"
                input-class="datepicker"
                disabled
              ></datepicker>

              <v-text-field
                v-if="(type == 'week' || type == 'category') && !bookingById.bookEvent"
                v-model="bookingById.from_time"
                item-value="time"
                item-text="time"
                label="Time"
                :error-messages="time_available?'':'No Time Slots Available'"
                return-object
                disabled
              ></v-text-field>
              <v-select
                v-if="type == 'month' || ( bookingById.bookEvent && bookingById.bookEvent.temporary)"
                v-model="bookingById.from_time"
                :items="timeslots"
                item-value="time"
                item-text="time"
                label="Time"
                :rules="timeRules"
                no-data-text="No Time Slots Available"
                return-object
                required
              ></v-select>

              <v-text-field
                v-model="bookingById.duration"
                label="Duration (minutes)"
                type="number"
                class="v-text-field-number"
                min="0"
                step="1"
                oninput="validity.valid||(value='');"
                :rules="durationRules"
              ></v-text-field>
            </v-form>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              text
              @click="cancelBooking"
            >
              Cancel
            </v-btn>

            <v-btn
              v-if="!bookingById.bookEvent"
              text
              @click="booking(false)"
            >
              Add
            </v-btn>
            <v-btn
              v-if="!bookingById.bookEvent"
              text
              @click="booking(true)"
            >
              Checkout
            </v-btn>
            <v-btn
              v-if="bookingById.bookEvent && bookingById.bookEvent.temporary"
              color="error"
              @click="removeTemp"
            >
              Remove
            </v-btn>
            <v-btn
              v-if="bookingById.bookEvent && bookingById.bookEvent.temporary"
              color="primary"
              @click="changeTemp"
            >
              Change
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      </v-row>
    </template>
  <template>
      <v-row justify="center">
        <v-dialog
          v-model="packageBillDialog"
          max-width="1000"
          @click:outside="cancelPackageBill"
        >
        <v-card>
          <v-card-title class="p-0 pr-1">
            <template>
              <v-spacer></v-spacer>
              <v-btn icon @click="cancelPackageBill">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
          </v-card-title>
          <v-card-text class="package_bill">
            <div class="column_one">
              <div style="max-height:25%">
                <v-row>
                  <v-col cols="6" no-gutters>
                    <div class="d-flex align-items-center mt-0">
                      <h4 class="m-0">Customer Details</h4>
                      <v-btn icon color="blue" class="ml-2" @click="openCheckNumberDialog('package',true)" >
                        <v-icon>mdi-account-convert</v-icon>
                      </v-btn>
                    </div>
                    
                    <p v-if="user.user_fullname" class="m-0">Name: {{ user.user_fullname }}</p>
                    <p v-if="user.user_contact" class="m-0">Phone Number: {{ parsePhoneNumber(this.user.user_contact) }}</p>
                    <p v-if="user.package_transaction.length > 0" class="m-0">
                      Package's Service Count: {{ user.package_transaction_length }}
                    </p>
                    <v-btn v-if="user.package_transaction.length > 0" color="primary" x-small @click="openMemberPkgDialog">Package List</v-btn>
                    <p v-if="!user.user_id" class="h-75 d-flex justify-content-center align-items-center">No Customer Details</p>
                    <br v-if="user.user_id">
                  </v-col>
                  <v-col cols="6" no-gutters>
                    <div class="d-flex align-items-center mt-0">
                      <h4 class="m-0">Staff Details</h4>
                    </div>
                    
                    <v-row class="d-flex justify-content-between align-items-center mt-0">
                      <v-col
                        cols="4" class="p-0"
                      >
                      <v-subheader>Name:</v-subheader>
                      </v-col>
                      <v-col
                        cols="7" class="text-right py-0"
                      >
                      {{ this.$session.get("user_fullname") }}
                      </v-col>
                    </v-row>
                    <v-row class="d-flex justify-content-between">
                      <v-col
                        cols="3" class="p-0"
                      >
                      <v-subheader>Outlet:</v-subheader>
                      
                      </v-col>
                      <v-col
                        cols="7" class="text-right py-0"
                      >
                      <v-select
                        v-model="currentOutlet"
                        :items="staff_outlet"
                        item-text="name"
                        label="Outlet"
                        @input="updateCurrentOutlet"
                        solo
                      ></v-select>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </div>
              <div style="max-height:70%" class="mt-2">
                <h4>Select Package</h4>
                <div class="selector_container">
                  <multiselect v-model="selectedPkg" :options="allPackages" track-by="package_name" label="package_name" :show-labels="false"
                    placeholder="Select Package">
                    <template slot="option" slot-scope="props">
                    <div>
                      <span>{{ props.option.package_name }}( RM{{ props.option.price }} )</span>
                    </div>
                  </template>
                  </multiselect>
                  <v-btn color="primary" class="mr-4" @click="addPackage">
                    + Add
                  </v-btn>
                </div>
                <template>
                  <div class="scrollY" style="height:50vh">
                    <v-card v-for="(pkg,index) in selectedPkgList" :key="index " class="pkg-card">
                      <v-card-title @click="toggleDetails(index)">
                        <span style="width: 90%;">{{ pkg.package_name }}</span>
                        <v-btn icon @click="remove(pkg)">
                          <v-icon color="error">mdi-delete</v-icon>
                        </v-btn>
                        <div style="margin: 0;width: 90%;">
                          <small>RM {{ pkg.price | formatPrice }}</small>
                        </div>
                        <v-btn icon>
                          <v-icon>{{ isExpanded(index) ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                        </v-btn>
                      </v-card-title>
                      <v-expand-transition>
                        <v-card-text v-if="isExpanded(index)">
                          <v-radio-group v-model="pkg.expiryType" row class="mt-0">
                            <v-radio label="Days" value="days"></v-radio>
                            <v-radio label="Date" value="date"></v-radio>
                          </v-radio-group>
                          <v-text-field
                            v-model="pkg.duration_days" v-if="pkg.expiryType == 'days'" label="Expiry Days (0 represents as permanent)"
                            type="number" step="1" min="0" class="w-50" suffix="days" @change="calcDate(pkg)"
                          ></v-text-field>
                          <datepicker v-if="pkg.expiryType == 'date'" v-model="pkg.duration_date" name="Date" placeholder="Select Date"
                          :highlighted="highlightedDates" @input="calcDays(pkg)" input-class="datepicker" class="date-picker-above"></datepicker>
                          <p >description: {{ pkg.description || "-" }}</p>
                          <div >
                            <p v-for="(details, index) in pkg.filteredDetails" :key="index">
                            {{ details.display_name }} x {{ details.amount }}
                            </p>
                          </div>
                        </v-card-text>
                      </v-expand-transition>
                    </v-card>
                  </div>
                </template>
              </div>
              
            </div>
            <div class="column_two">
              <div class="paymentDetails">
                <h4>Payment Details</h4>
                <v-row class="d-flex justify-content-between mt-0">
                  <v-col
                    cols="5" class="p-0"
                  >
                  <v-subheader>Subtotal</v-subheader>
                  </v-col>
                  <v-col
                    cols="7" class="text-right py-0"
                  >
                  <v-text-field
                    v-model = "pkgPrice.subtotal"
                    solo
                    disabled
                    reverse
                    suffix="RM"
                    type="number"
                    step="0.01"
                    min="0"
                    class="disabled-field"
                  ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="d-flex justify-content-between mt-0" v-for="t of pkgPrice.taxList" :key="t.id">
                  <v-col
                    cols="5" class="p-0"
                  >
                  <v-subheader>{{ t.name }}({{ t.percentage }}%)</v-subheader>
                  </v-col>
                  <v-col
                    cols="7" class="text-right py-0"
                  >
                  <v-text-field
                    solo
                    disabled
                    reverse
                    :value="t.amount"
                    suffix="RM"
                    type="number"
                    step="0.01"
                    min="0"
                    class="disabled-field"
                  ></v-text-field>
                  </v-col>
                </v-row>
                <v-row v-if="pkgPrice.discount" class="d-flex justify-content-between mt-0" >
                  <v-col
                    cols="5" class="p-0"
                  >
                  <v-subheader>Discount</v-subheader>
                  </v-col>
                  <v-col
                    cols="7" class="text-right py-0"
                  >
                  <v-text-field
                    v-model = "pkgPrice.discount"
                    solo
                    disabled
                    reverse
                    suffix="-RM"
                    type="number"
                    step="0.01"
                    min="0"
                    class="disabled-field"
                  ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="d-flex justify-content-between mt-0">
                  <v-col
                    cols="5" class="p-0"
                  >
                  <v-subheader><h2 class="m-0">Total</h2></v-subheader>
                  </v-col>
                  <v-col
                    cols="7" class="text-right py-0"
                  >
                  <v-text-field
                    v-model = "pkgPrice.total"
                    solo
                    reverse
                    :error-messages="pkgPrice.alertMessage"
                    @blur="handleBlur"
                    suffix="RM"
                    type="number"
                    step="0.01"
                    min="0"
                  ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="d-flex justify-content-between mt-0">
                  <v-col
                    cols="6" class="p-0"
                  >
                  <v-subheader class="pr-0 py-0">Payment Method</v-subheader>
                  </v-col>
                  <v-col
                    cols="6" class="text-right pr-0"
                  >
                  <v-container
                    class="p-0"
                    fluid
                  >
                  <v-switch
                    v-model="splitPayment"
                    @click="resetMethodPayment"
                    label="Split Payment"
                    hide-details
                    class="small-switch mt-0 pt-0"
                  ></v-switch>
                  </v-container>
                  </v-col>
                  <v-col v-if="splitPayment" class="py-0">
                      <v-row v-for="m of paymentMethodsList" :key="m.id">
                        <v-col cols="6" class="p-1">
                          <v-subheader> {{ m.name }} </v-subheader></v-col>
                        <v-col cols="6" class="p-1">
                          <v-text-field
                            v-model = "m.amount"
                            solo
                            reverse
                            @blur="calcRemainBalance"
                            suffix="RM"
                            type="number"
                            step="0.01"
                            min="0"
                            hide-details
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" v-if="m.name.toLowerCase() == 'cash'">
                          <v-row no-gutters>
                            <v-col v-for="amount of [5, 10, 50, 100]" :key="amount" class="text-center" >
                              <v-btn color="neutralgray" @click='addCashAmount(amount)' small > RM {{ amount }}</v-btn>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="p-1">
                          <v-subheader v-if="remainBalance > 0"> Balance: RM{{ remainBalance | formatPrice }} </v-subheader>
                          <v-subheader v-if="remainBalance < 0" style="color: red;"> Balance: RM{{ remainBalance | formatPrice }} </v-subheader>
                        </v-col>
                      </v-row>
                  </v-col>
                  <v-col v-if="!splitPayment" class="py-0">
                    <v-card class="m-0 p-0">
                      <v-container
                        fluid
                        class="my-0"
                      >
                        <v-radio-group v-model="selectedPaymentMethod" class="mt-0 pt-0" hide-details>
                          <v-radio
                            v-for="n in paymentMethodsList"
                            :key="n.id"
                            :label="n.name"
                            :value="n"
                          ></v-radio>
                        </v-radio-group>
                      </v-container>
                    </v-card>
                  </v-col>
                </v-row>
              </div>
              <div>
                <v-btn color="green" @click="payPkg" :disabled="!user.user_id || !selectedPkgList || selectedPkgList.length == 0 || ( remainBalance * 1 != 0 && splitPayment )  " class="p-2 w-100" >
                      PAY
                </v-btn>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
  </template>
  <template>
      <v-row justify="center">
        <v-dialog
          v-model="bookingBillDialog"
          max-width="1000"
          @click:outside="cancelbookingBill"
        >
        <v-card class="minh-80v">
          <v-card-title class="p-0 pr-1">
            <template>
              <v-spacer></v-spacer>
              <v-btn icon @click="cancelbookingBill">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
          </v-card-title>
          <v-card-text class="package_bill">
            <div class="column_one">
              <div>
                <div style="max-height:25%">
                  <v-row no-gutters>
                    <v-col cols="6" no-gutters>
                      <div class="d-flex align-items-center mt-0">
                        <h4 class="m-0">Customer Details</h4>
                        <v-btn icon color="blue" class="ml-2" @click="openCheckNumberDialog('booking', true)" >
                          <v-icon>mdi-account-convert</v-icon>
                        </v-btn>
                      </div>
                    
                      <p v-if="user.user_fullname" class="m-0">Name: {{ user.user_fullname }}</p>
                      <p v-if="user.user_contact" class="m-0">Phone Number: {{ parsePhoneNumber(this.user.user_contact) }}</p>
                      <p v-if="user.package_transaction.length > 0" class="m-0">
                        Package's Service Count: {{ user.package_transaction_length }}
                      </p>
                      <v-btn v-if="user.package_transaction.length > 0" color="primary" x-small @click="openMemberPkgDialog">Package List</v-btn>
                      <p v-if="!user.user_id" class="h-75 d-flex justify-content-center align-items-center">No Customer Details</p>
                      <br v-if="user.user_id">
                    </v-col>
                    <v-col cols="6">
                      <div class="d-flex align-items-center mt-0">
                        <h4 class="m-0">Staff Details</h4>
                      </div>
                    
                      <v-row class="d-flex justify-content-between align-items-center mt-0">
                        <v-col
                          cols="4" class="p-0"
                        >
                        <v-subheader>Name:</v-subheader>
                        </v-col>
                        <v-col
                          cols="7" class="text-right py-0"
                        >
                        {{ this.$session.get("user_fullname") }}
                        </v-col>
                      </v-row>
                      <v-row class="d-flex justify-content-between">
                        <v-col
                          cols="3" class="p-0"
                        >
                        <v-subheader>Outlet:</v-subheader>
                      
                        </v-col>
                        <v-col
                          cols="7" class="text-right py-0"
                        >
                        <v-select
                          v-model="currentOutlet"
                          :items="staff_outlet"
                          item-text="name"
                          label="Outlet"
                          @input="updateCurrentOutlet"
                          solo
                        ></v-select>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </div>
                <div style="max-height:70%">
                  <div class="d-flex justify-content-between align-items-center mt-0">
                    <h4 class="m-0">Book appointment</h4>
                    <div>
                      <v-btn color="primary" class="mr-4" @click="openBookDialog">
                        + Add
                      </v-btn>
                      <v-btn color="red" class="mr-4" @click="clearAllSelectedBooking">
                        Clear
                      </v-btn>
                    </div>
                  </div>
                  <template>
                      <div  class="scrollY pt-2" style="height:50vh">
                        <template>
                          <div>
                            <v-card v-for="(item, index) in selectedBookingList" :key="index" outlined>
                              <v-card-title>
                                <span style="width: 90%;">{{ item.service_name || item.resource_type_id.display_name }}</span>
                                <v-btn icon @click="removeBooking(item)">
                                  <v-icon color="error">mdi-delete</v-icon>
                                </v-btn>
                                <p style="margin: 0;width: 90%;"><small>RM {{ item.price | formatPrice }}</small></p>
                                <v-btn icon @click="toggleBookingDetails(index)">
                                  <v-icon>{{ isExpanded2(index) ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                                </v-btn>
                                <v-btn v-if="item.appliedPackage == null" :disabled="!user.package_transaction || user.package_transaction.length == 0" @click="usePackageDiscount(item)">apply package</v-btn>
                                <v-btn v-if="item.appliedPackage" @click="cancelPackageDiscount(item)">remove package</v-btn>
                                <span v-if="item.appliedPackage" class="small-text-booking">{{ item.appliedPackage.Package.package_name }}</span>
                                <span v-if="!item.suitablePackage" class="small-text-booking"> no suitable package</span>
                              </v-card-title>
                              <v-expand-transition>
                              <v-card-text  v-if="isExpanded2(index)">
                                  <p class="m-0" v-if="item.staff_name">Staff ID: {{ item.staff_name }}</p>
                                  <p class="m-0">Outlet ID: {{ item.outlet_name }}</p>
                                  <p class="m-0">Booked Date: {{ item.booked_date | formatDate }}</p>
                                  <p class="m-0">From Time: {{ item.from_time }}</p>
                                  <p class="m-0">Duration: {{ item.duration }} minutes</p>
                                  <v-row class="d-flex align-items-center mt-0" no-gutters v-if="item.service_id">
                                    <v-col
                                      cols="3" class="p-0"
                                    >
                                      <p class="m-0 p-0">Commission(RM): </p>
                                    </v-col>
                                    <v-col
                                      cols="3" class="text-right py-0"
                                    >
                                      <input type="number" style="border: 1px black solid;" v-model="item.commissionAmount" prefix="RM" />
                                    </v-col>
                                    <v-col
                                      cols="3" class="text-right py-0"
                                    >
                                      <v-btn color="primary" text @click="resetCommission(item, item.oriCommissionAmount)">reset</v-btn>
                                    </v-col>
                                  </v-row>
                                </v-card-text>
                              </v-expand-transition>
                            </v-card>
                          </div>
                        </template>
                      </div>
                  </template>
                </div>
              </div>
            </div>
            <div class="column_two">
              <div class="paymentDetails">
                <h4>Payment Details</h4>
                <v-row class="d-flex justify-content-between mt-0">
                  <v-col
                    cols="5" class="p-0"
                  >
                  <v-subheader>Subtotal</v-subheader>
                  </v-col>
                  <v-col
                    cols="7" class="text-right py-0"
                  >
                  <v-text-field
                    v-model = "bookingPrice.subtotal"
                    solo
                    disabled
                    reverse
                    suffix="RM"
                    type="number"
                    step="0.01"
                    min="0"
                    class="disabled-field"
                  ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="d-flex justify-content-between mt-0" v-for="t of bookingPrice.taxList" :key="t.id">
                  <v-col
                    cols="5" class="p-0"
                  >
                  <v-subheader>{{ t.name }}({{ t.percentage }}%)</v-subheader>
                  </v-col>
                  <v-col
                    cols="7" class="text-right py-0"
                  >
                  <v-text-field
                    solo
                    disabled
                    reverse
                    :value="t.amount"
                    suffix="RM"
                    type="number"
                    step="0.01"
                    min="0"
                    class="disabled-field"
                  ></v-text-field>
                  </v-col>
                </v-row>
                <v-row v-if="bookingPrice.discount" class="d-flex justify-content-between mt-0" >
                  <v-col
                    cols="5" class="p-0"
                  >
                  <v-subheader>Discount</v-subheader>
                  </v-col>
                  <v-col
                    cols="7" class="text-right py-0"
                  >
                  <v-text-field
                    v-model = "bookingPrice.discount"
                    solo
                    disabled
                    reverse
                    suffix="-RM"
                    type="number"
                    step="0.01"
                    min="0"
                    class="disabled-field"
                  ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="d-flex justify-content-between mt-0">
                  <v-col
                    cols="5" class="p-0"
                  >
                  <v-subheader><h2 class="m-0">Total</h2></v-subheader>
                  </v-col>
                  <v-col
                    cols="7" class="text-right py-0"
                  >
                  <v-text-field
                    v-model = "bookingPrice.total"
                    solo
                    reverse
                    @blur="handleBlur"
                    :error-messages="bookingPrice.alertMessage"
                    suffix="RM"
                    type="number"
                    step="0.01"
                    min="0"
                  ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="d-flex justify-content-between mt-0">
                  <v-col
                    cols="6" class="p-0"
                  >
                  <v-subheader class="pr-0 py-0">Payment Method</v-subheader>
                  </v-col>
                  <v-col
                    cols="6" class="text-right pr-0"
                  >
                  <v-container
                    class="p-0"
                    fluid
                  >
                  <v-switch
                    v-model="splitPayment"
                    @click="resetMethodPayment"
                    label="Split Payment"
                    hide-details
                    class="small-switch mt-0 pt-0"
                  ></v-switch>
                  </v-container>
                  </v-col>
                  <v-col v-if="splitPayment" class="py-0">
                      <v-row v-for="m of paymentMethodsList" :key="m.id">
                        <v-col cols="6" class="p-1">
                          <v-subheader> {{ m.name }} </v-subheader></v-col>
                        <v-col cols="6" class="p-1">
                          <v-text-field
                            v-model = "m.amount"
                            solo
                            reverse
                            @blur="calcRemainBalance"
                            suffix="RM"
                            type="number"
                            step="0.01"
                            min="0"
                            hide-details
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" v-if="m.name.toLowerCase() == 'cash'">
                          <v-row no-gutters>
                            <v-col v-for="amount of [5, 10, 50, 100]" :key="amount" class="text-center" >
                              <v-btn color="neutralgray" @click='addCashAmount(amount)' small > RM {{ amount }}</v-btn>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="p-1">
                          <v-subheader v-if="remainBalance * 1 > 0"> Balance: RM{{ remainBalance | formatPrice }} </v-subheader>
                          <v-subheader v-if="remainBalance * 1 < 0" style="color: red;"> Balance: RM{{ remainBalance | formatPrice }} </v-subheader></v-col>
                      </v-row>
                  </v-col>
                  <v-col v-if="!splitPayment" class="py-0">
                    <v-card class="m-0 p-0">
                      <v-container
                        fluid
                        class="my-0"
                      >
                        <v-radio-group v-model="selectedPaymentMethod" class="mt-0 pt-0" hide-details>
                          <v-radio
                            v-for="n in paymentMethodsList"
                            :key="n.id"
                            :label="n.name"
                            :value="n"
                          ></v-radio>
                        </v-radio-group>
                      </v-container>
                    </v-card>
                  </v-col>
                </v-row>
              </div>
              <div>
                <v-row >
                  <v-col cols="5">
                    <v-btn color="yellow" @click="payLater" :disabled="!this.user.user_id || this.selectedBookingList.length == 0" class="p-2 w-100">
                      PAY LATER
                    </v-btn>
                  </v-col>
                  <v-col cols="7">
                    <v-btn color="green" @click="payBooking" :disabled="!this.user.user_id || this.selectedBookingList.length == 0" class="p-2 w-100" >
                      PAY
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
  </template>
  <template>
      <v-row justify="center">
      <v-dialog
        v-model="bookInBillDialog"
        max-width="500"
        @click:outside="cancelBookingInBillPop"
      >
        <v-card>
          <v-card-title class="text-h5 d-flex justify-content-between">
            <span>Book an appointment</span>
            <div class="text-right">
              <v-btn-toggle v-model="inBilltype" class="ma-1" color="blue">
                <v-btn value="service" @click="dataReset()">
                  Service
                </v-btn>
                <v-btn value="resource" @click="dataReset()">
                  Resource
                </v-btn>
              </v-btn-toggle>
            </div>
          </v-card-title>

          <v-card-text class="scrollY">
            <v-form
              ref="form2"
              lazy-validation
            >
              <v-select
                v-model="bookingInBill.outlet_id"
                :items="booking_outlets"
                item-value="id"
                item-text="name"
                label="Outlet"
                @input="processedDate();"
                @change="setOutletName"
                :rules="outletRules"
                required
                no-data-text="No outlet available"
              ></v-select>

              <v-select
                v-if="inBilltype == 'service'"
                v-model="bookingInBill.staff_id"
                :items="booking_staffs"
                item-value="id"
                item-text="fullname"
                label="Staff"
                :rules="staffRules"
                @input="processedDate();"
                @change="setStaffNameInBill"
                return-object
                required
                no-data-text="No staff available"
              >
                <template slot="item" slot-scope="data">
                  {{ data.item.fullname }}
                </template>
              </v-select>

              <v-select
                v-if="inBilltype == 'service'"
                v-model="bookingInBill.service_id"
                :items="booking_services"
                item-value="id"
                item-text="name"
                label="Service"
                :rules="serviceRules"
                @input="setServiceNameInBill"
                return-object
                required
                :disabled="disableService"
                no-data-text="No service available"
              >
                <template slot="item" slot-scope="data">
                  {{ data.item.name }} ({{ data.item.duration }} minutes)
                </template>
              </v-select>

              <v-select v-if="inBilltype == 'resource'" v-model="bookingInBill.resource_type_id" :items="processed_resource_types"
              item-value="resource.id" item-text="display_name" label="Resources" @input="setResourceTypeName" :rules="resourceRules"
              return-object required></v-select>

              <datepicker 
                v-model="bookingInBill.booked_date" 
                name="Date"
                placeholder="Select Date"
                :highlighted="highlightedDates"
                :disabled-dates="disabledDates"
                @input="processedTimeSlot()"
                input-class="datepicker"
              ></datepicker>

              <v-select
                v-model="bookingInBill.from_time"
                :items="timeslots"
                item-value="time"
                item-text="time"
                label="Time"
                :rules="timeRules"
                return-object
                required
              ></v-select>

              <v-text-field
                v-model="bookingInBill.duration"
                label="Duration (minutes)"
                type="number"
                class="v-text-field-number"
                min="0"
                step="1"
                oninput="validity.valid||(value='');"
                :rules="durationRules"
              ></v-text-field>
            </v-form>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              text
              @click="cancelBookingInBillPop"
            >
              No
            </v-btn>

            <v-btn
              text
              @click="addBookingInBillPop"
            >
              Add
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      </v-row>
    </template>
    <template>
      <v-row justify="center">
      <v-dialog
        ref="memberPkgDialogRef"
        v-model="memberPkgDialog"
        max-width="500"
        @click:outside="closeMemberPkgDialog"
      >
        <v-card>
          <v-card-title class="text-h5">
            Member Packages
          </v-card-title>

          <v-card-text>
            <div class="d-flex justify-content-between">
              <p>Service Count: {{ this.user.package_transaction_length }}</p>
              <p>Applying Service Count: {{ this.user.package_transaction_length - this.user.package_transaction.length  }}</p>
            </div>
          <v-card v-for="(pkg, index) in filteredPackages" :key="index" class="mb-2">
            <v-list-group>
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title style="color:black"> {{ pkg.packageName }}</v-list-item-title>
                  <v-list-item-subtitle>Expiry Date: {{ pkg.expiration_date || "Permenant" }}</v-list-item-subtitle>
                </v-list-item-content>
              </template>
              <template>
                <div class="d-flex flex-wrap">
                  <v-card
                  max-width="226"
                  outlined
                  v-for="(value, key) in pkg.statistics" :key="key" >
                  <v-list-item two-line @click="openWithPackage(pkg.items,key)">
                    <v-list-item-content>
                      <v-list-item-title class="mb-1">
                        {{ key }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        count: {{ value }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
                </div>
              </template>
            </v-list-group>
          </v-card>
          </v-card-text>
        </v-card>
      </v-dialog>
      </v-row>
    </template>
    <template>
      <v-row justify="center">
      <v-dialog
        v-model="selectPkgVoucherDialog"
        max-width="400"
        @click:outside="remainVoucher"
      >
        <v-card>
          <v-card-title class="text-h5">
            Select Package's Service
          </v-card-title>

          <v-card-text>
            <template>
              <v-card v-for="(pkg, index) in groupedSuitablePackages" :key="index" class="mb-1">
                <v-list-group>
                  <template v-slot:activator>
                    <div class="w-100">
                      <v-list-item-title style="color:black"> {{ pkg.packageName }}</v-list-item-title>
                      <v-list-item-subtitle>Expiry Date: {{ pkg.packageExpiry || "Permenant" }}</v-list-item-subtitle>
                    </div>
                  </template>
                  <template>
                    <div v-for="(p, index) of pkg.packagelist" :key="index" class="px-3" style="color: black">
                      <label class='w-100'>
                        <input type="radio" v-model="selectedPackage" :value="p" class="mx-2"/>
                        <span v-if="p.service">{{p.service_Type.name}}({{p.service.name}})</span>
                        <span v-if="p.service_Type && !p.service">{{p.service_Type.name}}</span>
                        <span v-if="p.resource">{{p.resource_Type.name}}({{p.resource.name}})</span>
                        <span v-if="p.resource_Type && !p.resource">{{p.resource_Type.name}}</span>
                      </label>
                    </div>
                  </template>
                </v-list-group>
              </v-card>
            </template>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              text
              @click="remainVoucher"
            >
              Cancel
            </v-btn>

            <v-btn
              text
              @click="useVoucher(selectedPackage)"
            >
              Apply
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      </v-row>
    </template>
    <template>
      <v-row justify="center">
        <v-dialog
          v-model="checkNumberDialog"
          max-width="450"
          @click:outside="cancelPackage"
        >
        <v-card>
          <v-card-title>
            {{ this.purchaseType == 'package'? 'Purchase Package' : 'Book an appointment' }}
          </v-card-title>
          <v-card-text>
            <v-text-field
              v-model="user_contact"
              :counter="counterLength"
              :rules="numericRule"
              label="Customer Phone Number"
            >
            </v-text-field>
            <p v-if="invalidPhone" style="color: red;">Invalid Phone Number</p>
            <div class="text-right">
              <v-btn
                color="primary"
                class="mr-4"
                @click="cancelPackage"
                v-if="!showNameAndPkg"
              >
              Cancel
              </v-btn>
              <v-btn
                color="primary"
                class="mr-4"
                @click="checkUserPhoneNumberExists"
                v-if="!showNameAndPkg"
              >
              Next
              </v-btn>
            </div>
            
            <div v-if="showNameAndPkg">
              <v-text-field v-model="user.user_fullname" :counter="191"  :disabled="!newUser"
              label="Name (optional)" required>
              </v-text-field>

              <v-text-field
                  v-model="user.password"
                  :append-icon="passwordVisible ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="passwordVisible ? 'text' : 'password'"
                  label="Password"
                  @click:append="passwordVisible = !passwordVisible"
                  :rules="passwordRules"
                  v-if="newUser"
                ></v-text-field>
              <small v-if="newUser" style="color: red;">Default password will be phone number last 6 digits</small>
              <div class="text-right">
                <v-btn
                  color="primary"
                  class="mr-4"
                  @click="cancelPackage"
                >
                Cancel
                </v-btn>
                <v-btn
                  color="primary"
                  class="mr-4"
                  @click="openPackagePayment"
                >
                Next
                </v-btn>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
  </template>
  <template>
      <v-row justify="center">
        <v-dialog
          v-model="pdfDialog"
          max-width="450"
          persistent
        >
        <v-card>
          <v-card-text class="d-flex flex-column justify-content-center align-items-center">
              <v-icon v-if="pdfUploaded" color="green" style="font-size: 100px;">mdi-check-circle</v-icon>
              <v-icon v-if="!pdfUploaded" color="error" style="font-size: 100px;">mdi-close-circle</v-icon>
              <h3>{{pdfUploaded?"Purchase Successfully!":"PDF Build Failed!"}}</h3>

              <div class="text-right">
                <v-btn
                  color="primary"
                  class="mr-4"
                  @click="openReceipt"
                >
                Print Receipt
                </v-btn>
                <v-btn
                  color="primary"
                  class="mr-4"
                  @click="downloadPdf"
                  :disabled = "!pdfUploaded"
                >
                Download PDF
                </v-btn>
                <v-btn
                  color="primary"
                  class="mr-4"
                  @click="closePDFDialog"
                >
                OK
                </v-btn>
              </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
  </template>
  <template>
    <v-row justify="center">
        <v-dialog
          v-model="alertDialog"
          max-width="600"
          @click:outside="alertDialog = false"
        >
          <v-card>
            <v-card-title class="text-h6" v-html="alertDialog_text"></v-card-title>
            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              @click="alertDialog = false"
            >
              Ok
            </v-btn>
          </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
  </template>
  <template>
    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </template>
  <template>
    <v-row justify="center">
        <v-dialog
          v-model="receiptDialog"
          max-width="450"
          @click:outside="closeReceiptDialog"
        >
        <v-card>
          <v-card-text class="d-flex flex-column justify-content-center align-items-center pt-3">
            <div class="text-right w-100"><v-icon color="grey" @click="openPrintSetting">mdi-cog</v-icon></div>
            <div class="print-receipt-container">
              <div id="printMe" :style="{'width': receiptWidth+'px'}" class="d-flex flex-column mx-2">
                <div class="d-flex flex-column align-items-center w-100 header">
                    <div class="w-25">
                        <img class="w-100" :src="imageURL">
                    </div>
                    <p class="m-0"><b>{{ data.outlet.name }}</b></p>
                    <p class="m-0" v-if="data.outlet.address1">{{ data.outlet.address1 }}</p>
                    <p class="m-0" v-if="data.outlet.address2">{{ data.outlet.address2 }}</p>
                    <p class="m-0" v-if="data.outlet.address3">{{ data.outlet.address3 }}</p>
                    <p class="m-0" v-if="data.outlet.passcode && data.outlet.city  ">{{ data.outlet.passcode }} {{ data.outlet.city }}</p>
                    <p class="m-0" v-if="data.outlet.state">{{ data.outlet.state }}</p>
                    <p class="m-0" v-if="data.outlet.contact">Tel: {{ data.outlet.contact }}</p>
                    <p class="m-0" v-if="data.outlet.email">Email: {{ data.outlet.contact }}</p>
                    <br>
                    <p class="m-0 mb-1" v-if="data.isPrint"><b>COPY / REPRINT</b></p>
                    <p class="m-0 mb-1" v-if="data.invoice"><b>Invoice#{{ data.invoice }}</b></p>
                </div>
                <div class="content">
                    <p class="m-0">Date: {{ data.date }}</p>
                    <p class="m-0">Staff: {{ data.staff.name }}</p>
                    <p class="m-0 mb-2">Customer: {{ data.customer.name }}({{ data.customer.contact }})</p>
                    <p class="dashed-line m-0"></p>
                    <table class="pos-table" v-if="data.type == 'package'" >
                        <thead>
                        <tr>
                            <th class="w-50">Item</th>
                            <th class="w-25">QTY</th>
                            <th class="w-25">Price(RM)</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(item, index) in data.items" :key="index" class="td-btm-spacing">
                            <td>{{ index + 1 }}. {{ item.package_name }}</td>
                            <td>{{ item.quantity || 1 }}</td>
                            <td>{{ item.price | formatPrice }}</td>
                        </tr>
                        </tbody>
                    </table>
                    <table class="pos-table" v-if="data.type == 'booking'" >
                        <thead>
                        <tr>
                            <th class="w-50">Item</th>
                            <th class="w-25">Price(RM)</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(item, index) in data.items" :key="index" class="td-btm-spacing">
                            <td>
                              <span>{{ index + 1 }}. </span>
                              <span v-if="item.service_name">{{ item.service_name }}</span>
                              <span v-if="item.resource_type_name">{{ item.resource_type_name }}</span>
                              <span v-if="item.staffname">({{ item.staffname }})</span>
                              <br>{{item.booked_date | formatDate}} {{item.from_time}} ({{item.duration}} mins)
                            </td>
                            <td>
                              <span v-if="item.appliedPackage">0.00</span>
                              <span v-else>{{ item.price | formatPrice }}</span>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <p class="dashed-line m-0"></p>
                <div class="d-flex align-items-end flex-column">
                  <p class="m-0  price">Subtotal: RM{{ data.subtotal | formatPrice }}</p>
                  <p class="m-0 price" v-for="(t,i) of data.taxList" :key="i"><small>{{ t.name }}({{ t.percentage }}%): RM{{ t.amount | formatPrice }}</small></p>
                  <p class="m-0 price" v-if="data.tax">Tax: RM{{ data.tax | formatPrice }}</p>
                  <p class="m-0 price">Discount: RM{{ data.discount | formatPrice }}</p>
                  <h4 class="m-0">Total: RM{{ data.total | formatPrice }}</h4>
                </div>
              </div>
            </div>
            <div v-if="printSetting" class="w-75">
              <p class="m-0">Receipt Size Adjust</p>
              <v-slider
                v-model="receiptWidth"
                class="align-center"
                :max="400"
                :min="100"
                hide-details
              >
                <template v-slot:append>
                  <v-text-field
                    v-model="receiptWidth"
                    suffix="px"
                    class="mt-0 pt-0"
                    hide-details
                    single-line
                    type="number"
                    style="width: 60px"
                    min="100"
                    max="400"
                    @change="adjustPrintWidth"
                  ></v-text-field>
                </template>
              </v-slider>
              <v-btn @click="savePrintSetting" color="primary"
                  class="w-100 mt-1">Save Settings</v-btn>
              <div class="d-flex justify-content-between">
                <v-btn @click="resetPrintSetting(true)" color="primary"
                  class="w-75 mt-1 mr-1">Reset Changes</v-btn>
                  <v-btn @click="resetPrintSetting(false)" color="error" class="w-25 mt-1 ">
                    <v-icon >mdi-close</v-icon>
                  </v-btn>
              </div>
                  
              <hr class="mb-0">
            </div>
            <div class="mt-5 w-100 text-center" v-if="!printSetting">
              <v-btn v-print="printObj" color="primary"
                  class="w-75 mb-2">Print</v-btn>
              <v-btn
                  color="error"
                  class="w-75"
                  @click="closeReceiptDialog"
                >
                Close
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
  </template>
  </v-app>
</template>

<script>
import SideMenu from '/src/components/SideMenu'
import List from '/src/components/List'
import Datepicker from 'vuejs-datepicker';
import Multiselect from 'vue-multiselect'
import gql from 'graphql-tag'
import axios from 'axios';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import * as queries from '@/graphql/gqlQueries.js';
import print from 'vue-print-nb'
  const GET_ALL_BOOKING_QUERY = gql`
      query Booking($staff_id: Int, $outlet_id: Int, $booked_date: String, $service_id: Int, $resource_type_id:Int, $status:BookingStatus) {
        bookings(staff_id: $staff_id, outlet_id: $outlet_id, booked_date: $booked_date, service_id: $service_id, resource_type_id: $resource_type_id, status: $status) {
          id
          service_name
          resource_type_name
          outlet_id
          booked_date
          from_time
          duration
          booking_from
          service{
            color
          }
          staff{
            fullname
            id
          }
          resource_type{
            name
            color
          }
        }
      }
  `
  const GET_ALL_OUTLET_QUERY = gql`
      query Outlets($staff_id:Int, $service_id:Int) {
        outlets(staff_id:$staff_id, service_id:$service_id) {
          id
          name
          staff_outlet{
            outlet_id
          }
          service_outlet{
            outlet_id
          }
          image_id
        }
      }
  `
  const GET_ALL_STAFF_QUERY = gql`
      query staffs($outlet_id:Int, $date:String, $time:String, $service_id:Int) {
        staffs(outlet_id:$outlet_id, date:$date, time:$time, service_id:$service_id) {
          id
          fullname
          staff_outlet{
            outlet_id
          }
          staff_service{
            service_id
          }
          staff_roster{
            outlet_id
            date
            day
            from_time
            to_time
            break_from_time
            break_to_time
            off_day
          }
        }
      }
  `
  const GET_ALL_SERVICE_QUERY = gql`
      query Services($outlet_id:Int) {
        services(outlet_id:$outlet_id) {
          id
          name
          price
          duration
          service_outlet{
            service_id
          }
          staff_service{
            staff_id
            service_id
          }
          image_id
          service_type_id
          color
        }
      }
  `
  const GET_CUSTOM_PRICE_QUERY = gql`
    query customprice($service_id:Int, $resource_type_id:Int, $date:String){
      customprice(service_id:$service_id, resource_type_id:$resource_type_id, date:$date){
        date
        day
        service_id
        resource_type_id
        amount
      }
    }
  `
  const GET_SETTINGS_QUERY = gql`
      query settings {
        settings {
          slot_duration
          image_id
          name
        }
      }
  `
  const GET_PENDING_SALES_QUERY = gql`
  query GetPendingSales {
    getPendingSales {
      id
    }
  }
`;
  const GET_STAFF_QUERY = gql`
      query Staff($id: ID!) {
        staffById(id: $id) {
            fullname
            designation
            contact
            email
            created_at
            status
            admin
            supervisor
            staff_outlet{
              outlet_id
              outlet{
                id
                name
              }
            }
        }
      }
  `
  const GET_ALL_PACKAGES_QUERY = gql`
  query getAllPackages { 
    getAllPackages {
      id
      package_name
      description
      price
      status
      duration_days
      Package_Details{
        id
        service_type_id
        service_id
        resource_type_id
        resource_id
      }
      deleted_at
    }
  }
`
const GET_ALL_SERVICE_TYPES_QUERY = gql`
        query ServiceTypes {
          serviceTypes {
            id
            name
          }
        }
    `
    const GET_ALL_RESOURCESTYPES_QUERY = gql`
      query ResourceTypes {
        resourceTypes {
          id
          name
          duration
          price
        }
      }
  `
  const GET_ALL_RESOURCES_QUERY = gql`
    query Resources($date:String, $time:String, $outlet_id:Int){
      resources(date:$date, time:$time, outlet_id:$outlet_id) {
        id
        name
        resource_type_id
        resource_schedule{
          date
          day
          from_time
          to_time
          break_from_time
          break_to_time
          off_day
        }
        outlet_id
      }
    }
  `
  const GET_PAYMENT_METHODS = gql`
  query {
    paymentMethods {
      id
      name
      isActive
    }
  }
`
  export default {
    name: 'Calendar',
    directives: {
        print
    },
    components:{
      SideMenu,
      Datepicker,
      Multiselect,
      List
    },

    data () {

      return {
        receiptWidth: 250,
        printSetting: false,
        data: {
                isPrint: false,
                id:null,
                date: "",
                invoice:"Invoice#Book000022",
                outlet: {
                    name: "妖精の尻尾",
                    address1: "9-4, Jalan Kenari 19a",
                    address2: "",
                    address3: "",
                    passcode: "47170",
                    city: "Puchong",
                    state: "Selangor",
                    contact: "019-9136916",
                    email: ""
                },
                staff:{
                    name:"choo"
                },
                customer:{
                    name:"choo",
                    contact: "199136916",
                },
                items: [
                    { name: 'Item 1', quantity: 5, price: "10.00" },
                    { name: 'Item 2', quantity: 2, price: "15.00" },
                    { name: 'Item 3', quantity: 3, price: "8.00" },
                ],
                subtotal:"33.00",
                total:"33.00",
                tax:"0.00",
                discount:"0.00",
            },
        printLoading: true,
        printObj: {
            id: "printMe",
            preview: false,
            previewTitle: 'Print Receipt Preview', // The title of the preview window.
            popTitle: 'Receipt',
            extraCss: "https://cdn.bootcdn.net/ajax/libs/animate.css/4.1.1/animate.compat.css, https://cdn.bootcdn.net/ajax/libs/hover.css/2.3.1/css/hover-min.css",
            extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>',
            // previewBeforeOpenCallback(vue) {
            //     console.log('正在加载预览窗口', vue)
            // },
            // previewOpenCallback(vue) {
            //     console.log('已经加载完预览窗口', vue)
            // },
            // beforeOpenCallback(vue) {
            //     vue.printLoading = true
            //     console.log('打开之前')
            // },
            // openCallback: (vue) => {
            //   console.log('执行了打印',vue) 
            // },a
            closeCallback: (vue) => {
              console.log('关闭了打印工具', vue);
              this.updateReceipt(); // Use an arrow function to maintain the context
            }
        },
        receiptDialog: false,
        isOnEvent: false,
        isMousePressed: false,
        alertDialog: false,
        alertDialog_text: "",
        package_from_user:{
            "id": "",
            "serviceType": null,
            "service": null,
            "resource": null,
            "resourceType": null,
            "key": null
        },
        disableService: false,
        overlay: false,
        staff_outlet: [],
        selectedPkg: null,
        selectedPkgList:[],
        numericRule: [
        (v) =>  v === null || v === '' || /^\d+$/.test(v) || "Field must contain only numbers",
        ],
        counterLength: 12,
        user_contact: "",
        user:{
          user_id: "",
          user_fullname: "",
          password:"",
          package_transaction: [],
        },
        passwordRules: [
        (v) => v === "" || v.length >= 2 || "Password must be at least 2 characters",
        ],
        passwordVisible: false,
        showNameAndPkg: false,
        newUser:false,
        resources: [],
        alert: this.$route.params.alert,
        alert_type: this.$route.params.alert_type,
        message: this.$route.params.message,
        bookings: [],

        type: 'month',
        types: ['month', 'week', 'category'],
        outlet_id: '',
        outlets: [],
        mode: 'stack',
        modes: ['stack', 'column'],
        value: new Date().toISOString().substring(0, 10),
        formattedValue: this.formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)),
        events: [],
        staff_id: '',
        staffs: [],
        categories: [],
        datemenu: false,
        forceRender: 1,

        selectedEvent: {},
        selectedElement: null,
        selectedOpen: false,
        outlet_ids: this.$session.get('outlets') ?? [],
        admin: this.$session.get('admin') ?? 0,
        processed_outlets:[],
        booking_outlets:[],
        booking_staffs:[],
        processed_staffs:[],
        booking_services:[],
        event: null,

        dialog:false,
        bookDialog:false,
        pdfDialog: false,
        pdfUploaded: false,
        pdfSalesIid: null,
        bookEvent: null,

        bookingById: {
          staff_id: '',
          outlet_id: '',
          resource_type_id: '',
          resource_type_name: '',
          service_id: '',
          service_name: '',
          booked_date: '',
          from_time: '',
          duration: '',
          number_of_people: '',
          status: '',
          suitablePackage: true,
        },
        bookingInBill: {
          staff_id: '',
          outlet_id: '',
          resource_type_id: '',
          resource_type_name: '',
          service_id: '',
          service_name: '',
          booked_date: '',
          from_time: '',
          duration: '',
          number_of_people: '',
          status: '',
          price:"",
          suitablePackage: true,
        },
        inBilltype: "service",
        staff_name: '',
        service_color: null,
        price: 0.00,
        outletRules: [
          v => !!v || 'Outlet is required',
        ],
        staffRules: [
          v => !!v || 'Staff is required',
        ],
        serviceRules: [
          v => !!v || 'Service is required',
        ],
        durationRules: [
          v => v != 0 || 'Duration has to be at least 1 minute',
        ],
        timeRules: [
          v => !!v || 'Time is required',
        ],
        timeslots:[],
        from_time:"09:00",
        to_time:"22:00",
        time_available:true,
        checkNumberDialog: false,
        packageBillDialog: false,
        allPackages: [],
        resourceTypes: [],
        serviceTypes: [],
        expandedList: [],
        expandedBookingList: [],
        processed_resource_types: [],
        pkgPrice:{
          subtotal: "0.00",
          total: "0.00",
          discount: 0.00,
          alertMessage: "",
          totalTax:"",
          taxList:[],
          subtotalTax:"",
        },
        bookingPrice:{
          subtotal: "0.00",
          total:"0.00",
          discount: 0.00,
          alertMessage: "",
          totalTax:"",
          taxList:[],
          subtotalTax:"",
        },
        currentOutlet:{},
        invalidPhone: false,
        invoice_no: null,
        purchaseType: null,
        bookingBillDialog: false,
        bookInBillDialog: false,
        selectedBookingList: [],
        resourceRules: [
        v => !!v || 'Resource is required',
      ],
      memberPkgDialog: false,
      filteredPackages: [],
      suitablePackageList: [],
      selectPkgVoucherDialog: false,
      selectedPackage: {},
      selectedBooking: {},
      getPendingSales: [],
      pkgDialog: false,
      groupedSuitablePackages: [],
      paymentMethodsList: [],
      selectedPaymentMethod: {},
      splitPayment: false,
      remainBalance: 0,
      getAllPermissions: [],
      allPermissionsName: [],
      staffPermission: this.$session.get('staffPermission') || {},
      getAllTax: [],
      imageURL: null,
      websiteName: null,
      settings:{
        slot_duration : 30
      },
      disabledDates: {
        to: "",
        days: [],
        dates: []
      },
      highlightedDates: {
        dates: [new Date()], // Highlight today's date
      },
      }
    },
    apollo: {
      settings: {
        query: GET_SETTINGS_QUERY,
        result (){
          this.timeSlot()
          document.title = this.websiteName = this.settings.name;
          if(this.settings.image_id != 0 && this.settings.image_id != null){
            const GET_IMAGE_QUERY = gql`
              query imageById($id: ID!) {
                  imageById(id:$id)  {
                    id
                    type
                    filename
                  }
              }
            `
            this.$apollo.query(
              { 
                query: GET_IMAGE_QUERY, 
                variables:{
                  id: this.settings.image_id * 1
                },
              }
            ).then(result => {
              if(result.data.imageById != null){ 
                this.imageURL = window.location.hostname === 'localhost'
  ? 'https://placekitten.com/200/200'
  : 'https://prod-app360.sfo3.digitaloceanspaces.com/' + result.data.imageById.filename;

              } else {
                console.log("Image not found")
              }
            });
          }
        }
      },
      getAllTax: {
        query: queries.GET_ALL_TAX,
        pollInterval: 300,
        fetchPolicy: "network-only"
      },
      paymentMethods: {
        query: GET_PAYMENT_METHODS,
        result(){
          const result = [...this.paymentMethods]; // Create a copy of paymentMethods array
          const filteredMethods = result.filter(m => m.isActive);
          const updatedMethodsList = filteredMethods.map(m => ({ ...m, amount: 0 }));

          this.paymentMethodsList = updatedMethodsList;
          if (updatedMethodsList.length > 0) {
            this.selectedPaymentMethod = updatedMethodsList[0];
          }
        }
      },
      bookings: {
        query: GET_ALL_BOOKING_QUERY,
        variables() {
          return{
            staff_id: this.staff_id*1,
            outlet_id: this.outlet_id*1,
            status: 'booked'
          }
        },
        pollInterval: 300,
        result() {
          this.getEvents()
        }
      },
      outlets: {
        query: GET_ALL_OUTLET_QUERY,
        variables (){
          return {
            staff_id: this.bookingById.staff_id ?this.bookingById.staff_id.id*1:this.bookingInBill.staff_id?this.bookingInBill.staff_id.id * 1:null,
            service_id: this.bookingById.service_id && this.bookingById.service_id.id?this.bookingById.service_id.id*1:this.bookingInBill.service_id && this.bookingInBill.service_id.id?this.bookingInBill.service_id.id * 1:null
          }
        },
        result() {
          this.outlet_ids = this.$session.get('outlets') ?? []
          if(!this.staffPermission.createBookingAllOutlets){
            for(let i=0; i<this.outlets.length; i++){
              for(let x=0; x<this.outlet_ids.length; x++){
                if(this.outlet_ids[x] == this.outlets[i].id.toString()){
                  this.processed_outlets.push(this.outlets[i])
                  break
                }
              }
            }
          }
          else{
            this.processed_outlets = [...this.outlets]
          }
          if(this.processed_outlets.length == 0 || this.processed_outlets[0].name != 'All outlets'){
            if(this.staffPermission.createBookingAllOutlets){
              this.processed_outlets.unshift({id:'',name:'All outlets'})
            }else{
              console.log("not all outlets",this.staffPermission)
            }
          }
          if(this.processed_outlets && this.processed_outlets.length> 0)this.outlet_id = this.processed_outlets[0].id
          this.booking_outlets = []
          for(let i=0; i<this.outlets.length; i++){
            if(this.outlets[i].staff_outlet.length > 0 && this.outlets[i].service_outlet.length > 0){
              this.booking_outlets.push(this.outlets[i])
            }
          }
        }
      },
      outletSessions: {
        query: queries.GET_OUTLET_SESSION_QUERY,
        variables (){
          var selectedDate = null
          if(this.bookingById.booked_date){
            selectedDate = new Date(this.bookingById.booked_date).toISOString().substring(0, 10)
          }else if(this.bookingInBill.booked_date){
            selectedDate = new Date(this.bookingInBill.booked_date).toISOString().substring(0, 10)
          }
          return {
            outlet_id: this.bookingById.outlet_id?this.bookingById.outlet_id*1:this.bookingInBill.outlet_id?this.bookingInBill.outlet_id * 1:this.outlet_id?this.outlet_id*1:null,
            date: selectedDate,
          }
        },
        result (){
          var session_valid = false
          if(this.outletSessions.length === 0){
            this.time_available = false
          }else{
            for(let i=0; i<this.outletSessions.length; i++){
              if (this.outletSessions[i].off_day) {
                this.from_time = "00:00"
                this.to_time = "0:00"
                this.time_available = false
                this.bookingById.from_time = ""
                this.bookingInBill.from_time = ""
                break
              }
              if(this.outletSessions[i].outlet_sessions.length > 0){
                this.from_time = ("0" + (new Date(1620691200000+this.outletSessions[i].from_time*1).getUTCHours())).slice(-2) + ':' + ("0" + (new Date(1620691200000+this.outletSessions[i].from_time*1).getMinutes())).slice(-2)
                this.to_time = ("0" + (new Date(1620691200000+this.outletSessions[i].to_time*1).getUTCHours())).slice(-2) + ':' + ("0" + (new Date(1620691200000+this.outletSessions[i].to_time*1).getMinutes())).slice(-2)
                session_valid = true
                this.time_available = true
                break
              }
              if(!session_valid){
                this.time_available = false
                this.bookingById.from_time = ""
                this.bookingInBill.from_time = ""
              }
            }
          }
          
          if(!this.outlet_id && !this.bookingById.outlet_id && !this.bookingInBill.outlet_id){
            this.disabledDates= {
                to: "",
                days: [],
                dates: []
              }
          }else{
            this.processedDate()
          }
          this.processedTimeSlot()
        }
      },
      staffById: {
        query: GET_STAFF_QUERY,
        variables() {
          return{
            id: this.$session.get("user_id") * 1
          }
        },
        result() {
          var filteredoutlets = []
          for(let i=0; i<this.staffById.staff_outlet.length; i++){
            let outlet = {}
            outlet.id = this.staffById.staff_outlet[i].outlet_id.toString()
            outlet.name = this.staffById.staff_outlet[i].outlet.name
            filteredoutlets.push(outlet)
          }

          if(this.$session.get("user_id") * 1 == 1 && this.outlets.length > 0){
            filteredoutlets = []
            this.outlets.forEach(o =>{
              let outlet = {
                id: o.id,
                name: o.name
              }
              filteredoutlets.push(outlet)
            }
            )
          }
          this.staff_outlet = filteredoutlets
          if(!this.$session.get("currentOutletInit"))this.$session.set('currentOutlet', this.staff_outlet[0].name)
          this.$session.set('currentOutletInit',true)
        }
      },
      staffs: {
        query: GET_ALL_STAFF_QUERY,
        fetchPolicy: "network-only",
        variables() {
          var selectedDate = null
          if(this.type == "category" && this.value){
            selectedDate = new Date(this.value).toISOString().substring(0, 10)
          }
          if(this.bookingById.booked_date){
            selectedDate = new Date(this.bookingById.booked_date).toISOString().substring(0, 10)
          }
          if(this.bookingInBill.booked_date){
            selectedDate = new Date(this.bookingInBill.booked_date).toISOString().substring(0, 10)
          }
          var outlet_id = this.bookingById.outlet_id 
          ? this.bookingById.outlet_id * 1 
          : this.bookingInBill.outlet_id 
          ? this.bookingInBill.outlet_id * 1 
          : this.outlet_id * 1
          var service_id = this.bookingById.service_id && this.bookingById.service_id.id ? this.bookingById.service_id.id * 1 : this.bookingInBill.service_id && this.bookingInBill.service_id.id ? this.bookingInBill.service_id.id * 1 : null
          return{
            outlet_id: outlet_id,
            service_id: service_id,
            date: selectedDate,
            time: this.bookingById.from_time?this.bookingById.from_time:this.bookingInBill.from_time?this.bookingInBill.from_time:null
          }
        },
        pollInterval: 300,
        async result() {
          this.processed_staffs = []
          this.booking_staffs = []
          this.staffs = await this.staffs.filter(staff => staff.staff_outlet.length > 0);
          if(this.bookingById.service_id) {
            this.staffs = await this.staffs.filter(staff => staff.staff_service.length > 0);
            if(this.bookingById.staff_id && this.bookingById.staff_id.id){
              var haveStaffForService = await this.staffs.find(staff => staff.id*1 == this.bookingById.staff_id.id*1)
              if (!haveStaffForService || haveStaffForService.length == 0 ){
                this.bookingById.staff_id = ''
              }
            }
          }
          if(this.bookingById.booked_date) {
            this.staffs = await this.staffs.filter(staff => staff.staff_roster.length > 0);
            if(this.bookingById.staff_id && this.bookingById.staff_id.id){
              var haveStaffForDate = await this.staffs.find(staff => staff.id == this.bookingById.staff_id.id)
              if (!haveStaffForDate || haveStaffForDate.length == 0 ){
                this.bookingById.staff_id = ''
              }
            }
          }
          if(this.bookingInBill.service_id) {
            this.staffs = await this.staffs.filter(staff => staff.staff_service.length > 0);
            if(this.bookingInBill.staff_id && this.bookingInBill.staff_id.id){
              var haveStaffForService1 = await this.staffs.find(staff => staff.id == this.bookingInBill.staff_id.id)
              if (!haveStaffForService1 || haveStaffForService1.length == 0 ){
                this.bookingInBill.staff_id = ''
              }
            }
          }
          if(this.bookingInBill.booked_date) {
            this.staffs = await this.staffs.filter(staff => staff.staff_roster.length > 0);
            if(this.bookingInBill.staff_id && this.bookingInBill.staff_id.id){
              var haveStaffForDate1 = await this.staffs.find(staff => staff.id == this.bookingInBill.staff_id.id)
              if (!haveStaffForDate1 || haveStaffForDate1.length == 0 ){
                this.bookingInBill.staff_id = ''
              }
            }
          }
          if(this.value && this.type == "category" && !this.bookingById.booked_date && !this.bookingInBill.booked_date) {
            this.staffs = await this.staffs.filter(staff => staff.staff_roster.length > 0);
          }
          for(let i=0; i<this.staffs.length; i++){
            if(this.staffs[i].id == 1){
              this.staffs.splice(i, 1)
              break
            }
          }
          this.processed_staffs = await [...this.staffs]
          this.booking_staffs = await [...this.staffs]
          if(!this.staffPermission.viewStaff){
            var ownStaff = await this.staffs.filter(s => s.id*1 === this.$session.get("user_id")*1)
            this.processed_staffs = [...ownStaff]
            this.booking_staffs = [...ownStaff]
          }
          if(this.processed_staffs && this.processed_staffs.length > 0 && this.processed_staffs[0].fullname != 'All staffs' && this.staffPermission.viewStaff){
            await this.processed_staffs.unshift({id:'',fullname:'All staffs'})
          }
          if(!this.staffPermission.viewStaff){
            this.staff_id = await this.processed_staffs[0].id
          }
          if(this.booking_staffs.length  == 0 && this.type == "category"){
            this.alertDialog = true
            this.alertDialog_text = "There are no available staff members on " + this.bookingById.booked_date + "."
            this.cancelBooking()
            this.clearDate()
          }
          this.getEvents()
        }
      },
      services: {
        query: GET_ALL_SERVICE_QUERY,
        variables (){
          return {
            outlet_id: this.bookingById.outlet_id? this.bookingById.outlet_id * 1 : this.bookingInBill.outlet_id? this.bookingInBill.outlet_id * 1:this.outlet_id*1,
          }
        },
        result(){
          this.booking_services = []
          for(let i=0; i<this.services.length; i++){
            if(this.services[i].service_outlet.length > 0){
              this.booking_services.push(this.services[i])
            }
          }
          if(this.bookingById.staff_id?.id || this.bookingInBill.staff_id){
            const staffId = this.bookingById.staff_id?.id ?? this.bookingInBill.staff_id
            this.booking_services = this.booking_services.filter((service) => {
                return service.staff_service.some((staffService) => staffService.staff_id == staffId);
            });
          }
          if(this.package_from_user.serviceType && this.package_from_user.serviceType.id && !this.package_from_user.service){
            this.booking_services = this.booking_services.filter(s => s.service_type_id == this.package_from_user.serviceType.id)
          }
        }
      },
      getPendingSales: {
        query: GET_PENDING_SALES_QUERY,
        pollInterval: 3000,
      },
      serviceTypes: {
        query: GET_ALL_SERVICE_TYPES_QUERY,
      },
      resourceTypes: {
        query: GET_ALL_RESOURCESTYPES_QUERY,
      },
      resources: {
        variables() {
          const variables = {};

          if (this.bookingById.outlet_id || this.bookingInBill.outlet_id ) {
            variables.outlet_id = this.bookingById.outlet_id * 1 || this.bookingInBill.outlet_id * 1;
          }

          if (this.bookingById.booked_date || this.bookingInBill.booked_date) {
            variables.date = new Date(this.bookingById.booked_date || this.bookingInBill.booked_date).toISOString().substring(0, 10);
          }

          if (this.bookingById.from_time || this.bookingInBill.from_time) {
            variables.time = this.bookingById.from_time || this.bookingInBill.from_time;
          }
          return variables;
        },
        query: GET_ALL_RESOURCES_QUERY,
        result() {
          this.processedResourceTypes()
        }
      },
      getAllPackages: {
        query: GET_ALL_PACKAGES_QUERY,
        result(){
          this.allPackages = this.getAllPackages.filter(p => p.status.toLowerCase() == "published" && p.Package_Details.length > 0 && !p.deleted_at )
          this.allPackages.forEach(async p => {
            // Create a new array to store the filtered details
            const filteredDetails = [];

            // Create a map to store the counts of each combination
            const countsMap = new Map();

            // Iterate through the Package_Details array
            p.Package_Details.forEach(detail => {
              const { service_type_id, service_id, resource_type_id, resource_id } = detail;

              // Generate a unique key for each combination
              const key = `${service_type_id}_${service_id}_${resource_type_id}_${resource_id}`;

              // Increase the count for the current combination
              const count = countsMap.get(key) || 0;
              countsMap.set(key, count + 1);
            });

            // Iterate through the countsMap and add the filtered details to the array
            countsMap.forEach((count, key) => {
              const [service_type_id, service_id, resource_type_id, resource_id] = key.split('_');
              filteredDetails.push({
                service_type_id: Number(service_type_id),
                service_id: Number(service_id),
                resource_type_id: Number(resource_type_id),
                resource_id: Number(resource_id),
                amount: count
              });
            });

            // Assign the filteredDetails array to the packageObj
            p.filteredDetails = filteredDetails;
            const resources = await this.$apollo.query({ query: GET_ALL_RESOURCES_QUERY }); 
            const services = await this.$apollo.query({ query: GET_ALL_SERVICE_QUERY }); 
            p.filteredDetails.forEach(d => {
              if (d.service_type_id) {
                let service_type = this.serviceTypes.find(st => st.id == d.service_type_id);
                let service_name = services.data.services.find(s => s.id == d.service_id);
                if (service_type && service_name) {
                  d.display_name = service_type.name + "(" + service_name.name + ")";
                } else if (service_type) {
                  d.display_name = service_type.name;
                }
              } else if (d.resource_id) {
                let res_type = this.resourceTypes.find(rt => rt.id == d.resource_type_id);
                let res = resources.data.resources.find(r => r.id == d.resource_id);

                if (res_type && res) {
                  d.display_name = res_type.name + "(" + res.name + ")";
                } else if (res_type) {
                  d.display_name = res_type.name;
                }
              } else if (d.resource_type_id) {
                let res_type = this.resourceTypes.find(rt => rt.id == d.resource_type_id);

                if (res_type) {
                  d.display_name = res_type.name;
                }
              }
            })
          });
        }
      },
      customprice: {
        variables (){
          var service_id = null
          var resource_type_id = null
          if(this.bookingById.service_id){
            service_id = this.bookingById.service_id.id
          }
          if (this.bookingById.resource_type_id) {
            resource_type_id = this.bookingById.resource_type_id.id
          }
          if(this.bookingInBill.service_id){
            service_id = this.bookingInBill.service_id.id
          }
          if (this.bookingInBill.resource_type_id) {
            resource_type_id = this.bookingInBill.resource_type_id.id
          }
          return {
            resource_type_id: resource_type_id*1,
            service_id: service_id*1,
            date: this.bookingById.booked_date ? new Date(this.bookingById.booked_date).toISOString().substring(0, 10) : new Date().toISOString().substring(0, 10)
          }
        },
        query: GET_CUSTOM_PRICE_QUERY,
      },
    },
    watch: {
      value () {
        this.formattedValue = this.formatDate(this.value)
      },
      "user_contact"(value) {
        this.updateCounter(value);
      },
      selectedPkgList(value){
        this.calcPkgTotal(value)
      },
      selectedBookingList(value){
        this.calcBookingTotal(value)
      },
    },
    methods: {
      updateCounter(value) {
        const number = value.trim();
        if (number.startsWith("60")) {
          this.counterLength = 12;
        } else if (number.startsWith("0")) {
          this.counterLength = 11;
        } else if (number.startsWith("1")) {
          this.counterLength = 10;
        } else {
          this.counterLength = 12;
        }
      },
      parsePhoneNumber(phoneNumber) {
        const countryCode = "60";
        if (
          !phoneNumber ||
          !countryCode ||
          phoneNumber.length < 1 ||
          countryCode.length < 2
        )
          return phoneNumber;

        const normalizedNumber = phoneNumber.replace(/\D/g, ""); // Remove non-digit characters

        if (normalizedNumber.startsWith(countryCode)) {
          return normalizedNumber; // Number is already in the desired format
        } else if (normalizedNumber.startsWith("0")) {
          return countryCode + normalizedNumber.slice(1); // Prepend country code, excluding the leading '0'
        }

        return countryCode + normalizedNumber; // Prepend country code to the number
      },
      formatDate (date) {
        if (!date) return null
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
          "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
        ];
        const days_string = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"]
        var dateObject = new Date(date)
        return `${dateObject.getDate()} ${monthNames[dateObject.getMonth()]} ${dateObject.getFullYear()} (${days_string[dateObject.getDay()]})`
      },
      viewDay ({ date }) {
        this.value = date
        this.type = 'category'
        this.bookingById.booked_date = date
      },
      BookingDetails(object) {
        if(this.type != 'week' && this.type != 'category' && this.staffPermission.viewBooking && object.event.id){
          this.$router.push({ path: '/booking/' + object.event.id })
        }else if(object.event.temporary){
          var booking_details = this.selectedBookingList.find(booking => booking.bookEvent.temporary_id === object.event.temporary_id)
          var bookdetails = {...booking_details}
          this.bookingById = bookdetails
          this.bookingById.staff_id = {
            id: booking_details.staff_id
          }
          this.bookDialog = true
        }
      },
      BookingDetailsById(id, access=false) {
        if(((this.type != 'week' && this.type != 'category') || access) && this.staffPermission.viewBooking && id){
          this.$router.push({ path: '/booking/' + id })
        }
      },
      BookingCreateFormWithParams({date, hour, minute, category}) {
        if(this.type != 'week' && this.type != 'category'){
          if(hour <10){
            hour = '0'+hour
          }
          if(minute < 30){
            minute = '00'
          }
          else{
            minute = '30'
          }
          var time = hour+':'+minute
          this.$router.push({ path: '/create/booking', query:{bookeddate:date, time:time, category:category} })
        }
      },
      BookingCreateForm({date}) {
        if(this.isOffDay(date)) return
        if(this.type != 'week' && this.type != 'category'){
            if(!this.staffPermission.createBooking) return
            this.createEvent = {
              name: `Appointment (Draft)`,
              color: this.colors,
              start: date,
              end:date,
              timed: true,
              temporary: true,
              temporary_id: this.selectedBookingList.length + 1,
            }
            this.bookingById.outlet_id = this.outlet_id?this.outlet_id:this.$session.get("currentOutlet")?this.staff_outlet.filter(o => o.name == this.$session.get("currentOutlet"))[0].id:this.staff_outlet[0].id
            this.bookingById.staff_id = this.staff_id ? this.staff_id : null

            this.bookingById.booked_date = date
            this.bookDialog = true

            //this.$router.push({ path: '/create/booking', query:{bookeddate:date} })
        }
      },

      getEvents () {
        const categories = []
        if(this.staff_id == ''){
          for(let i=0; i < this.processed_staffs.length; i++) {
            if(this.processed_staffs[i].fullname != 'All staffs' && this.processed_staffs[i].staff_outlet.length > 0){
              categories.push(this.processed_staffs[i].fullname)
            }
          }
        }
        else{
          for(let i=0; i < this.processed_staffs.length; i++) {
            if(this.staff_id == this.processed_staffs[i].id && this.processed_staffs[i].staff_outlet.length > 0){
              categories.push(this.processed_staffs[i].fullname)
              break
            }
          }
        }
        this.categories = categories

        let temporaryEvents = []
        if(this.events && this.events.length > 0) {
          temporaryEvents = this.events.filter(e => e?.temporary)
        }
        const events = []
        for (let i = 0; i < this.bookings.length; i++) {
          var first = new Date(this.bookings[i].booked_date*1)
          var first_timestamp = new Date(1620691200000+this.bookings[i].from_time*1)
          var year = first.getFullYear()
          var month = first.getMonth()+1
          var date = first.getDate()
          var first_hour = first_timestamp.getHours()
          var first_minute = first_timestamp.getMinutes()
          first = new Date(year+'-'+month+'-'+date+' '+first_hour+':'+first_minute)
          first.setHours(first.getUTCHours())
          var second_timestamp = new Date(1620691200000+this.bookings[i].from_time*1 + this.bookings[i].duration*60*1000)
          var second1 = new Date(this.bookings[i].booked_date*1 + this.bookings[i].duration*60*1000)
          var year2 = second1.getFullYear()
          var month2 = second1.getMonth()+1
          var date2 = second1.getDate()
          var second_hour = second_timestamp.getHours()
          var second_minute = second_timestamp.getMinutes()
          var second = new Date(year2+'-'+month2+'-'+date2+' '+second_hour+':'+second_minute)
          second.setHours(second.getUTCHours())

          var name = '';
          var color = '';
          var category = '';
          if(this.bookings[i].service){
            if(this.bookings[i].booking_from == 'onlinebooking'){
              name = '[ONLINE] ' + this.bookings[i].service_name + ' (' + this.bookings[i].staff.fullname + ')'
              color = this.bookings[i].service.color
            }
            else{
              name = this.bookings[i].service_name + ' (' + this.bookings[i].staff?.fullname + ')'
              color = this.bookings[i].service.color
            }
          }
          else{
            name = this.bookings[i].resource_type_name
            color = this.bookings[i].resource_type.color
          }
          if(this.bookings[i].staff){
            category = this.bookings[i].staff.fullname
          }
          else if (this.bookings[i].resource_type){
            category = this.bookings[i].resource_type.name
          }
          if(this.outlet_ids.includes(this.bookings[i].outlet_id.toString())){
            events.push({
              name: name,
              start: first,
              end: second,
              color: color,
              timed: true,
              id: this.bookings[i].id,
              staff_id: this.bookings[i].staff.id,
              category: category,
              date: new Date(this.bookings[i].booked_date*1),
              time: this.bookings[i].from_time,
              outletId: this.bookings[i].outlet_id,
            })
          }
        }
        this.events = events
        if(temporaryEvents.length > 0 ){
          temporaryEvents.forEach(e => {
            this.events.push(e)
          })
        }
        this.$forceUpdate();
      },
      showEvent ({ nativeEvent, event }) {
        if(this.type != 'week' && this.type != 'category' && this.staffPermission.viewBooking ){
          const open = () => {
            this.selectedEvent = event
            this.selectedElement = nativeEvent.target
            requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true))
          }

          if (this.selectedOpen) {
            this.selectedOpen = false
            requestAnimationFrame(() => requestAnimationFrame(() => open()))
          } else {
            open()
          }

          nativeEvent.stopPropagation()
        }
      },

      startDrag ({ event, timed }) {
        if(!this.staffPermission.createBooking) return
        if (event && timed && !event.temporary) {
          this.dragEvent = event
          this.isOnEvent = true
          this.dragTime = null
          this.extendOriginal = null
          this.defaultStart = event.start
          this.defaultEnd = event.end
          this.defaultCategory = event.category
        }
      },
      startTime (tms) {
        if(!this.staffPermission.createBooking || this.isOffDay(tms.date)) return
        const mouse = this.toTime(tms)

        if (this.dragEvent && this.dragTime === null) {
          const start = this.dragEvent.start

          this.dragTime = mouse - start
        }         
        // else if(this.type == 'category'){
        //   console.log("event",tms)
        //   this.createStart = this.roundTime(mouse)
        //   this.createEvent = {
        //     name: `Appointment (Draft)`,
        //     color: this.colors,
        //     start: this.createStart,
        //     end: this.createStart,
        //     timed: true,
        //   }
          // this.events.push(this.createEvent)
        // }
      },
      mouseMove (tms) {
        if(!this.staffPermission.createBooking) return
        const mouse = this.toTime(tms)
        if(this.type == 'week' || this.type == 'category' && this.isMousePressed){
          if (this.dragEvent && this.dragTime !== null && (tms.category || this.type == 'week')) {
            const start = this.dragEvent.start
            const end = this.dragEvent.end
            const duration = end - start
            const newStartTime = mouse - this.dragTime
            const newStart = this.roundTime(newStartTime)
            const newEnd = newStart + duration

            this.dragEvent.start = new Date(newStart)
            this.dragEvent.end = new Date(newEnd)
            this.dragEvent.date = tms.date
            if(this.type == 'category'){
              const newCategory = tms.category.categoryName
              this.dragEvent.category = newCategory
            }
          } else if (this.createEvent && this.createStart !== null && (tms.category || this.type == 'week')) {
            const mouseRounded = this.roundTime(mouse, false)
            const min = Math.min(mouseRounded, this.createStart)
            const max = Math.max(mouseRounded, this.createStart)

            this.createEvent.start = new Date(min)
            this.createEvent.end = new Date(max)
            this.createEvent.date = tms.date
            if(this.type == 'category'){
              this.createEvent.category = tms.category.categoryName
            }
          }
        }
      },
      async endDrag({event}){
        if(!this.staffPermission.createBooking) return
        if(this.type == 'week' || this.type == 'category'){
          this.event = event
          var editEvent = {
            staffId:event.staff_id * 1,
            outletId: event.outletId *1,
            date: event.date,
            start: event.start,
            end: event.end,
          }
          if(this.dragEvent && (this.dragEvent.start != this.defaultStart || this.dragEvent.category != this.defaultCategory)){
            const isStaffAvailability = await this.isStaffAvailability(editEvent)
            if(this.type == "week" && this.dragEvent.outletId && !this.outlet_id){
              this.outlet_id = await this.dragEvent.outletId.toString()
              this.overlay = true
              await this.getEvents();
              if (this.isOffDay(event.date)) {
                this.overlay = false
                return this.cancelReschedule();
              }
              if( !isStaffAvailability ) {
                this.overlay = false
                this.alertDialog = true
                this.alertDialog_text = "Unfortunately, staff is not available / rest during the requested time.<br>Please consider selecting a different time or date for your rearranged appointment."
                return this.cancelReschedule();
              }
              this.dialog = true;
              this.overlay = false
            }else{
              if(this.isOffDay(event.date)){
                this.overlay = false
                return this.cancelReschedule()
              }
              if( !isStaffAvailability ) {
                this.overlay = false
                this.alertDialog = true
                this.alertDialog_text = "Unfortunately, staff is not available / rest during the requested time.<br>Please consider selecting a different time or date for your rearranged appointment."
                return this.cancelReschedule();
              }
              this.dialog = true
            }
          }
          else{
            this.dragTime = null
            this.dragEvent = null
            this.createStart = null
            this.extendOriginal = null
          }
        }
      },
      reschedule (event) {
        this.dialog = false
        if(this.type == 'week' || this.type == 'category'){
          const UPDATE_BOOKING_QUERY = gql`
            mutation updateBooking($id: ID!, $staff_id: Int, $booked_date:String, $from_time:String){
              updateBooking(id:$id, staff_id:$staff_id, booked_date:$booked_date, from_time:$from_time){
                booking_no
                booked_date
                duration
                service_name
                resource_type_name
              }
            }
          `
          var staff_id = null
          for(let i=0; i<this.staffs.length; i++){
            if(this.staffs[i].fullname == event.category){
              staff_id = this.staffs[i].id
              break
            }
          }
          this.$apollo.mutate(
            { 
              mutation: UPDATE_BOOKING_QUERY, 
              variables:{
                id: event.id*1,
                booked_date: event.date, 
                staff_id: staff_id*1,
                from_time:new Date(event.start).getHours()+':'+new Date(event.start).getMinutes(), 
              },
            }
          )
          .catch(error => {
            console.log(error)
          })
          .then(result => {
            if(result && result.data.updateBooking != null){ 
              console.log("Reschedule successfully")
            } else {
              console.log("Reschedule failed")
            }
          });
        }
        this.dragTime = null
        this.dragEvent = null
        this.createEvent = null
        this.createStart = null
        this.extendOriginal = null
      },
      async cancelReschedule() {
        this.dragEvent.start = await this.defaultStart
        this.dragEvent.end = await this.defaultEnd
        this.dragEvent.category = await this.defaultCategory
        this.dragTime = null
        this.dragEvent = null
        this.createStart = null
        this.extendOriginal = null
        this.dialog = false
      },
      async booking(openBill) {
        var validate = this.$refs.form.validate()
        if(validate){
          // const i = this.events.indexOf(this.bookEvent)
          // this.events[i].name = this.bookingById.service_name + ' (' + this.staff_name + ')'
          // this.events[i].color = this.service_color
          this.bookingById.staff_id = this.bookingById.staff_id.id
          if(this.staff_name){
            for(let i=0; i<this.staffs.length; i++){
              if(this.staffs[i].fullname == this.staff_name){
                this.bookingById.staff_id = this.staffs[i].id
              }
            }
          }
          if(this.bookingById.service_id){
            this.price = this.bookingById.service_id.price
          }
          if(this.bookingById.outlet_id ){
            this.bookingById.outlet_name = this.outlets.filter(o => o.id == this.bookingById.outlet_id)[0].name
          }
          if(this.customprice){
            for(let i=0; i<this.customprice.length; i++){
              this.price = this.customprice[i].amount
              if(this.customprice[i].date != null){
                break
              }
            }
          }
          if(this.bookingById.staff_id){
            this.bookingById.price = this.price
            if (this.bookingById.service_id && this.bookingById.staff_id) {
              try {
                const result = await this.$apollo.query({
                  query: queries.GET_STAFF_COMMISSION_BY_BOTH_ID,
                  variables: {
                    staff_id: this.bookingById.staff_id * 1,
                    service_id: this.bookingById.service_id.id * 1
                  }
                });
                this.bookingById.staffCommission = result.data.getStaffCommissionByStaffSerice;
                var commissionAmount = this.bookingById.staffCommission.type === 'fixed' ? this.bookingById.staffCommission.commission : this.bookingById.price * this.bookingById.staffCommission.commission / 100
                this.bookingById.oriCommissionAmount = commissionAmount
                this.bookingById.commissionAmount = commissionAmount
              } catch (error) {
                console.log(JSON.stringify(error, null, 2));
              }
            }
            const startTime = this.bookingById.from_time; 
            const duration = this.bookingById.duration;

            const startTimeParts = startTime.split(":");
            const startHour = parseInt(startTimeParts[0]);
            const startMinute = parseInt(startTimeParts[1]);

            const startTimeObject = new Date(this.bookingById.booked_date);
            startTimeObject.setHours(startHour);
            startTimeObject.setMinutes(startMinute);

            const endTime = new Date(startTimeObject);
            endTime.setMinutes(startTimeObject.getMinutes() + duration);
            const time = (startHour * 60 + startMinute) * 60 * 1000;

            this.bookEvent = await{
                name: '[TEMP]'+this.bookingById.service_name + ' (' + this.staff_name + ')',
                color: this.service_color,
                start: startTimeObject,
                end:endTime,
                time: time.toString(),
                outletId: this.bookingById.outlet_id.toString(),
                category: this.staff_name,
                id:"",
                timed: true,
                temporary: true,
                temporary_id: this.selectedBookingList.length + 1,
              }
            this.bookingById.bookEvent = {...this.bookEvent}
            this.events.push(this.bookEvent)
            this.selectedBookingList.push(this.bookingById)
            if(openBill) this.openMultiBookingDialog()
          }
          
          this.bookEvent = null
          this.bookingById = {
            staff_id: '',
            outlet_id: '',
            resource_type_id: '',
            resource_type_name: '',
            service_id: '',
            service_name: '',
            booked_date: '',
            from_time: '',
            duration: '',
            number_of_people: '',
            status: '',
            price:"",
            bookEvent: "",
            suitablePackage: true,
          }
          this.time_available = true
          this.staff_name = ''
          this.service_color = null
          this.bookDialog = false
        }
      },
      cancelBooking() {
        const i = this.events.indexOf(this.bookEvent)
        if (i !== -1) {
          this.events.splice(i, 1)
        }
        this.bookEvent = null
        this.bookingById = {
          staff_id: '',
          outlet_id: '',
          resource_type_id: '',
          resource_type_name: '',
          service_id: '',
          service_name: '',
          booked_date: '',
          from_time: '',
          duration: '',
          number_of_people: '',
          status: '',
          suitablePackage: true,
        }
        if(this.type == 'category')this.bookingById.booked_date = this.value
        this.staff_name = ''
        this.service_color = null
        this.bookDialog = false
        this.time_available = true
      },
      cancelDrag () {
        if (this.createEvent || this.dragEvent && this.isMousePressed) {
          if (this.dragEvent) {
            this.dialog = true
          }
          else{
            this.bookEvent = this.createEvent
            this.bookDialog = true
          }
        }
        this.createEvent = null
        this.createStart = null
        this.dragTime = null
      },
      roundTime (time, down = true) {
        const roundTo = this.settings.slot_duration*1 ?? 15 // minutes
        const roundDownTime = roundTo * 60 * 1000

        return down
          ? time - time % roundDownTime
          : time + (roundDownTime - (time % roundDownTime))
      },
      toTime (tms) {
        return new Date(tms.year, tms.month - 1, tms.day, tms.hour, tms.minute).getTime()
      },
      setServiceName(value) {
        this.bookingById.service_name = value.name
        this.bookingById.duration = value.duration
        this.service_color = value.color
      },
      setServiceNameInBill(value) {
        this.bookingInBill.service_name = value.name
        this.bookingInBill.duration = value.duration
        this.service_color = value.color
      },
      setStaffName(value) {
                this.bookingById.staff_name = this.staff_name = value.fullname
              },
      setStaffNameInBill(value) {
        this.bookingInBill.staff_name = this.staff_name = value
      },
      setResourceTypeName(value) {
        this.bookingInBill.resource_type_name = value.resource.name
        this.bookingInBill.duration = value.duration
      },
      setOutletName(value){
        if(value){
          this.bookingById.outlet_name = this.bookingInBill.outlet_name = this.outlets.filter(o => o.id == value)[0].name
        }
      },
      addMinutes(time, minsToAdd) {
        function D(J){ return (J<10? '0':'') + J;}
        var piece = time.split(':')
        var mins = piece[0]*60 + +piece[1] + +minsToAdd

        return D(mins%(24*60)/60 | 0) + ':' + D(mins%60)
      },
      isTimeOver(time, end_time) {
        const [hours, minutes] = time.split(":").map(Number);
        const [end_hours, end_minutes] = end_time.split(":").map(Number);
        if (hours >= end_hours && minutes >= end_minutes) {
          return true;
        }
        return false;
      },
      timeSlot(){
        this.timeslots = []
        var start_time = this.from_time
        var end_time = this.to_time
        var slot_duration = this.settings.slot_duration
        while( start_time!=end_time && this.time_available && !this.isTimeOver(start_time,end_time)){
          this.timeslots.push(start_time)
          start_time = this.addMinutes(start_time, slot_duration.toString())
        }
      },
      openCheckNumberDialog(type, fromInside){
        this.purchaseType = type
        if( this.purchaseType == 'package') this.packageBillDialog = true
        else this.bookingBillDialog = true
        setTimeout(() => {
          if(!this.user.user_id || fromInside)this.checkNumberDialog = true
        }, 100);
        
      },
      checkUserPhoneNumberExists(){
        var userContact = this.parsePhoneNumber(this.user_contact)
        this.user_contact = ""
        this.invalidPhone = userContact.length < 11
        if(userContact.length < 11) return
        const CHECK_PHONE_NUMBER_EXISTS = gql`
          query CheckPhoneNumberExists($user_contact: String!) {
            checkPhoneNumberExists(user_contact: $user_contact)
          }
        `;

        this.$apollo.query(
          { 
              query: CHECK_PHONE_NUMBER_EXISTS, 
              variables:{
                user_contact: userContact
              },
              fetchPolicy: 'network-only'
          }
        ).then(result => {
          const exists = result.data.checkPhoneNumberExists;
          if(!exists){
            this.newUser = true
            this.showNameAndPkg = true
            this.user_contact = userContact
          }else{
            const GET_USER_QUERY = gql`
              query User($user_contact: String!) {
                userByContact(user_contact: $user_contact) {
                  id
                  user_id_external
                  user_fullname
                  user_contact
                }
              }
            `
            this.$apollo.query(
          { 
              query: GET_USER_QUERY, 
              variables:{
                user_contact: userContact
              },
          }).then(result => {
            this.user.user_fullname = result.data.userByContact.user_fullname
            this.user.user_contact= result.data.userByContact.user_contact
            this.user.user_id = result.data.userByContact.id
            this.checkNumberDialog = false
            if( this.purchaseType == 'package') this.packageBillDialog = true
            else this.bookingBillDialog = true
            const GET_USERBYID_QUERY = gql`
              query User($id: ID!) {
                userById(id: $id) {
                  id
                  Package_Transaction{
                    id
                    Package{
                        package_name
                    }
                    used_at
                    expiration_date
                    created_at
                    sales_id
                    resource_Type{
                        id
                        name
                    }
                    service_Type{
                        id
                        name
                    }
                    resource{
                        id
                        name
                    }
                    service{
                        id
                        name
                    }
                  }
                }
              }
            `
            this.$apollo.query(
            { 
                query: GET_USERBYID_QUERY, 
                fetchPolicy: 'network-only',
                variables:{
                  id: this.user.user_id
                },
            }).then(result => {
              if(result.data.userById.Package_Transaction.length > 0){
                const today = new Date();
                today.setHours(0, 0, 0, 0);
                const tomorrow = new Date();
                tomorrow.setDate(tomorrow.getDate() + 1);
                tomorrow.setHours(0, 0, 0, 0);
                const resultPackage = [...result.data.userById.Package_Transaction]
                this.user.package_transaction = resultPackage.filter(pt => {
                  const isUsed = pt.used_at === null;
                  const expirationDate = pt.expiration_date ? new Date(parseInt(pt.expiration_date)) : null;
                  const isExpired = expirationDate && expirationDate < tomorrow;
                  if (isUsed && (!expirationDate || !isExpired)) {
                    return pt;
                  }
                });
                this.user.all_package_transaction = [...this.user.package_transaction]
                this.filterPackage()
                this.user.package_transaction_length = this.user.package_transaction.length
              }else{
                this.user.package_transaction = []
                this.user.all_package_transaction = []
              }
            })
          })
          }
        })
      },
      openPackagePayment(){
        if(this.newUser){
          //create user
          const CREATE_USER_QUERY = gql`
            mutation createUser(
              $user_id_external: String
              $user_fullname: String
              $user_contact: String!
              $password: String
            ) {
              createUser(
                user_id_external: $user_id_external
                user_fullname: $user_fullname
                user_contact: $user_contact
                password: $password
              ) {
                id
                user_id_external
                user_fullname
                user_contact
                password
              }
            }
          `;

          var userContact = this.parsePhoneNumber(this.user_contact)
          var passwordHash = require("password-hash");
          this.$apollo.mutate({
            mutation: CREATE_USER_QUERY,
            variables: {
              user_id_external: null,
              user_fullname: this.user.user_fullname? this.user.user_fullname : userContact,
              user_contact: userContact,
              password: passwordHash.generate(this.user.password?this.user.password:userContact.slice(-6)),
            },
          }).then(result => {
            var userNew = result.data.createUser
            this.user.user_fullname = userNew.user_fullname
            this.user.user_id = userNew.id
            this.user.user_contact = userNew.user_contact
            this.user_contact = ""
            this.checkNumberDialog = false
            this.showNameAndPkg = false
            this.newUser = false
            const GET_USERBYID_QUERY = gql`
              query User($id: ID!) {
                userById(id: $id) {
                  id
                  Package_Transaction{
                    id
                    Package{
                        package_name
                    }
                    used_at
                    created_at
                    expiration_date
                    resource_Type{
                        id
                        name
                    }
                    service_Type{
                        id
                        name
                    }
                    resource{
                        id
                        name
                    }
                    service{
                        id
                        name
                    }
                  }
                }
              }
            `
            this.$apollo.query(
            { 
                query: GET_USERBYID_QUERY, 
                fetchPolicy: 'network-only',
                variables:{
                  id: this.user.user_id
                },
            }).then(result => {
              if(result.data.userById.Package_Transaction.length > 0){
                const today = new Date();
                today.setHours(0, 0, 0, 0);
                const tomorrow = new Date();
                tomorrow.setDate(tomorrow.getDate() + 1);
                tomorrow.setHours(0, 0, 0, 0);
                const resultPackage = [...result.data.userById.Package_Transaction]
                this.user.package_transaction = resultPackage.filter(pt => {
                  const isUsed = pt.used_at === null;
                  const expirationDate = pt.expiration_date ? new Date(parseInt(pt.expiration_date)) : null;
                  const isExpired = expirationDate && expirationDate < tomorrow;
                  if (isUsed && (!expirationDate || !isExpired)) {
                    return pt;
                  }
                });
                this.user.all_package_transaction = [...this.user.package_transaction]
                this.user.package_transaction_length = this.user.package_transaction.length
                this.filterPackage()
              }else{
                this.user.package_transaction = []
                this.user.all_package_transaction = []
              }
            })
            if( this.purchaseType == 'package') this.packageBillDialog = true
            else this.bookingBillDialog = true
          })
        }
        else{
          //open payment
          this.checkNumberDialog = false
          if( this.purchaseType == 'package') this.packageBillDialog = true
          else this.bookingBillDialog = true
        }
      },
      cancelPackage(){
        this.checkNumberDialog = false
        this.showNameAndPkg = false
        this.user_contact = ""
        this.newUser = false
        if(!this.user.user_id){
          this.user.user_fullname = ""
          this.user.user_contact= ""
          this.user.user_id = ""
        }
      },
      cancelPackageBill(){
        this.packageBillDialog = false
        this.user.user_fullname = ""
        this.user.user_contact= ""
        this.user.user_id = ""
        this.user.package_transaction = []
        this.user.all_package_transaction = []
        this.showNameAndPkg = false
        this.newUser = false
        this.user_contact = ""
        this.selectedPkgList = []
        this.pkgPrice.discount = 0.00
        this.splitPayment = false
        this.resetMethodPayment()
        //remove selected pkg
      },
      addPackage(){
        if(!this.selectedPkg) return
        let selectPkg = {...this.selectedPkg}
        selectPkg.expiryType='days'
        const today = new Date();
        const duration = selectPkg.duration_days * 1;
        const endDate = new Date(today.setDate(today.getDate() + duration));
        selectPkg.duration_date = endDate;
        this.selectedPkgList.push(selectPkg)
        selectPkg = null
        this.selectedPkg = null
      },
      toggleDetails(index) {
        if (this.expandedList.includes(index)) {
          this.expandedList = this.expandedList.filter((item) => item !== index);
        } else {
          this.expandedList.push(index);
        }
      },
      toggleBookingDetails(index) {
        if (this.expandedBookingList.includes(index)) {
          this.expandedBookingList = this.expandedBookingList.filter((item) => item !== index);
        } else {
          this.expandedBookingList.push(index);
        }
      },
      remove(pkg) {
        const index = this.selectedPkgList.indexOf(pkg);
        if (index !== -1) {
          this.selectedPkgList.splice(index, 1);
        }
      },
      removeBooking(item){
        const index = this.selectedBookingList.indexOf(item);
        const eventToDelete = item.bookEvent
        const indexToDelete = this.events.findIndex(event => {
            return (
                event.start === eventToDelete.start &&
                event.end === eventToDelete.end &&
                event.time === eventToDelete.time &&
                event.outletId === eventToDelete.outletId &&
                event.category === eventToDelete.category &&
                event.timed === eventToDelete.timed &&
                event.temporary === eventToDelete.temporary &&
                event.temporary_id === eventToDelete.temporary_id
            );
        });
        if (index !== -1) {
          this.selectedBookingList.splice(index, 1);
        }
        if (indexToDelete !== -1) {
          this.events.splice(indexToDelete, 1);
        }
        this.getEvents()
      },
      payPkg(){
        //this.user.user_id
        // if(this.paymentMethodsList.length > 0 && this.splitPayment){
        //   return console.log("split pay",this.selectedPkgList)
        // }
        let packageDetailsList = []
        const packageIds = []
        this.selectedPkgList.forEach(p => 
        {
          packageIds.push(p.id.toString())
          p.Package_Details.forEach( d => {
            d.packageId = p.id
            d.duration_days = p.duration_days
          })
          packageDetailsList = packageDetailsList.concat(p.Package_Details)
        })
        const outletId = this.staff_outlet.filter(o => o.name == this.$session.get("currentOutlet"))[0].id
        const CREATE_SALES = gql`
          mutation CreateSales($sales_type: salesType!, $status: payStatus!, $package_id: Json, $booking_id: Json, $amount: Float!, $user_id: Int!, $outlet_id: Int!, $staff_id: Int, $online_booking: Boolean, $split_payment: Boolean, $tax_amount: Float) {
            createSales(sales_type: $sales_type, status: $status, package_id: $package_id, booking_id: $booking_id, amount: $amount, user_id: $user_id, outlet_id: $outlet_id, staff_id: $staff_id, online_booking: $online_booking, split_payment: $split_payment, tax_amount: $tax_amount) {
              id
              sales_type
              status
              package_id
              booking_id
              amount
              user_id
              outlet_id
              staff_id
              online_booking
              invoice_no
              created_at
              updated_at
              deleted_at
              voided_at
              split_payment
            }
          }
        `;

        this.$apollo.mutate({
          mutation: CREATE_SALES,
          variables: {
            sales_type: 'package',
            status: 'approved',
            package_id: JSON.stringify(packageIds),
            booking_id:  JSON.stringify(null),
            amount: parseFloat(this.pkgPrice.total),
            user_id: parseInt(this.user.user_id),
            outlet_id: parseInt(outletId),
            staff_id: this.$session.get("user_id") * 1,
            online_booking: false,
            split_payment: this.splitPayment,
            tax_amount: this.pkgPrice.totalTax ? parseFloat(this.pkgPrice.totalTax) : null
          },
        }).then(async (response) => {
          let result = response.data
          console.log("success result: ",result)
          let createdSalesId = response.data.createSales.id
          const mutationPromises = [];

          const CREATE_PACKAGE_TRANSACTION  = gql`
            mutation CreatePackageTransaction($userId: Int!, $salesId: Int, $packageId: Int!, $serviceTypeId: Int, $serviceId: Int, $resourceTypeId: Int, $resourceId: Int, $expiration_date: String) {
              createPackageTransaction(userId: $userId, salesId: $salesId, packageId: $packageId, serviceTypeId: $serviceTypeId, serviceId: $serviceId, resourceTypeId: $resourceTypeId, resourceId: $resourceId,  expiration_date: $expiration_date) {
                id
              }
            }
          `;
          for (let i = 0; i < packageDetailsList.length; i++) {
            const item = packageDetailsList[i];
            const mutationPromise = this.$apollo.mutate({
              mutation: CREATE_PACKAGE_TRANSACTION,
              variables: {
                userId: parseInt(this.user.user_id),
                salesId: parseInt(createdSalesId),
                packageId: parseInt(item.packageId),
                serviceTypeId: parseInt(item.service_type_id),
                serviceId: parseInt(item.service_id),
                resourceTypeId: parseInt(item.resource_type_id),
                resourceId: parseInt(item.resource_id),
                expiration_date: item.duration_days * 1 ? item.duration_days.toString() : null 
              },
            });

            mutationPromises.push(mutationPromise);
          }

          const CREATE_PAYMENT_METHOD_SALES = gql`
            mutation CreatePaymentMethodSales($salesId: Int!, $paymentMethodId: Int!, $amount: Float!) {
              createPaymentMethodSales(salesId: $salesId, paymentMethodId: $paymentMethodId, amount: $amount) {
                id
                amount
              }
            }
          `;

          if(this.splitPayment){
            for (let i = 0; i < this.paymentMethodsList.length; i++) {
              const method = this.paymentMethodsList[i];
              if(method.amount > 0){
                this.$apollo.mutate({
                  mutation: CREATE_PAYMENT_METHOD_SALES,
                  variables: {
                    paymentMethodId: parseInt(method.id),
                    salesId: parseInt(createdSalesId),
                    amount: parseFloat(method.amount * 1),
                  },
                }).catch(error => {
                  console.log(JSON.stringify(error, null, 2));
                });
              }
            }
          } else if(!this.splitPayment) {
            this.$apollo.mutate({
                  mutation: CREATE_PAYMENT_METHOD_SALES,
                  variables: {
                    paymentMethodId: parseInt(this.selectedPaymentMethod.id),
                    salesId: parseInt(createdSalesId),
                    amount: parseFloat(this.pkgPrice.total),
                  },
                }).catch(error => {
                  console.log(JSON.stringify(error, null, 2));
                });
          }

          if(this.pkgPrice.taxList.length > 0){
            const CREATE_TAX_SALES = gql`
            mutation createTaxSales($tax_id: Int!, $sales_id: Int!, $amount: Float!) {
              createTaxSales(tax_id: $tax_id, sales_id: $sales_id, amount: $amount) {
                id
              }
            }
          `
          for (let i = 0; i < this.pkgPrice.taxList.length; i++) {
              const t = this.pkgPrice.taxList[i];
              if(t.amount > 0){
                this.$apollo.mutate({
                  mutation: CREATE_TAX_SALES,
                  variables: {
                    tax_id: parseInt(t.id),
                    sales_id: parseInt(createdSalesId),
                    amount: parseFloat(t.amount * 1),
                  },
                }).catch(error => {
                  console.log("tax",JSON.stringify(error, null, 2));
                });
              }
            }
          }

          pdfMake.vfs = pdfFonts.pdfMake.vfs;

          const outletDetails = await this.$apollo.query({
          query: queries.GET_OUTLET_QUERY,
          variables: { id: outletId * 1 },
          });
          const invoiceData = {
            invoice_no : response.data.createSales.invoice_no,
            date : this.$generalFunctions.formatInvoiceDateTime(response.data.createSales.created_at * 1),
            customerName: this.user.user_fullname,
            customerContact: this.user.user_contact,
            outletAddress: {...outletDetails.data.outletById},
            subtotal: this.pkgPrice.subtotal,
            tax:  Number(this.pkgPrice.totalTax).toFixed(2),
            discount:  Number(this.pkgPrice.discount).toFixed(2),
            total: Number(this.pkgPrice.total).toFixed(2),
            item: [...this.selectedPkgList]
          }
          const imageData = await this.fetchImageData();

          const addressLines = [
            invoiceData.outletAddress.name,
            invoiceData.outletAddress.address1,
            invoiceData.outletAddress.address2,
            invoiceData.outletAddress.address3,
            `${invoiceData.outletAddress.postcode} ${invoiceData.outletAddress.city}`,
            invoiceData.outletAddress.state,
            invoiceData.outletAddress.country
          ];
          this.data =  {
                type: "package",
                isPrint: false,
                date: invoiceData.date,
                invoice: invoiceData.invoice_no,
                outlet: {
                    name: invoiceData.outletAddress.name,
                    address1: invoiceData.outletAddress.address1,
                    address2: invoiceData.outletAddress.address2,
                    address3: invoiceData.outletAddress.address3,
                    passcode: invoiceData.outletAddress.postcode,
                    city: invoiceData.outletAddress.city,
                    state: invoiceData.outletAddress.state,
                    contact: invoiceData.outletAddress.contact,
                    email: invoiceData.outletAddress.email
                },
                staff:{
                    name:this.$session.get("user_fullname")
                },
                customer:{
                    name:invoiceData.customerName,
                    contact: invoiceData.customerContact,
                },
                items: invoiceData.item,
                subtotal:invoiceData.subtotal,
                total:invoiceData.total,
                tax:invoiceData.tax,
                taxList: this.pkgPrice.taxList ,
                discount:invoiceData.discount,
            }

          // Filter out empty address lines and join with commas
          const filteredAddress = addressLines
            .filter(line => line !== '')
            .join(',\n');

          const documentDefinition = {
            layout: 'fullWidth',
            content: [
            {
              columns: [
                // Left column
                {
                  width: '50%',
                  stack: [
                    imageData && { image: `data:image/jpeg;base64,${imageData}`, width: 100 },
                    { text: this.websiteName, style: 'header' },
                    { text: `Invoice#${invoiceData.invoice_no}`, style: 'subheader' },
                  ].filter(Boolean),
                  style: 'leftColumn'
                },
                // Right column
                {
                  width: '50%',
                  stack: [
                  { text: `${invoiceData.date}`, style: 'subheader',alignment: 'right' },
                  ],
                  style: 'rightColumn'
                }
              ]
            },

              {
              columns: [
                // Left column
                {
                  width: '50%',
                  stack: [

                    { text: `Customer Name: ${invoiceData.customerName}` },
                    { text: `Customer Contact: ${invoiceData.customerContact}` },
                  ],
                  style: 'leftColumn'
                },
                // Right column
                {
                  width: '50%',
                  stack: [
                    { text: `${filteredAddress}`, alignment: 'right' }
                  ],
                  style: 'rightColumn'
                }
              ]
            },
              { text: 'Items', style: 'subheader' },
              {
                table: {
                  headerRows: 1,
                  widths: ['*', 'auto', 'auto'],
                  style: 'bottomMargin',
                  body: [
                    [
                      { text: 'Item Name', style: 'tableHeader' },
                      { text: 'Quantity', style: 'tableHeader' },
                      { text: 'Price (RM)', style: 'tableHeader' },
                      
                    ],
                    ...invoiceData.item.map(item => [
                    {
                      stack: [
                        { text: item.package_name, style: 'subheader' },
                        ...item.filteredDetails.map(detail => ({
                          text: `${detail.display_name} - ${detail.amount} session`,
                          fontSize: 10,
                          margin: [0, 2, 0, 0], // Adjust margin between items in the list
                        })),
                      ],
                    },
                      item.quantity || 1,
                      { text: `${Number(item.price).toFixed(2)}`, alignment: 'right' },
                    ])
                  ]
                }
              },
              { text: `Subtotal: RM ${invoiceData.subtotal}`, alignment: 'right', margin: [0, 10, 0, 0] },
              { text: `Tax: RM ${invoiceData.tax}`, alignment: 'right' },
              { text: `Discount: -RM ${invoiceData.discount}`, alignment: 'right' },
              { text: `Total: RM ${invoiceData.total}`, style: 'total', alignment: 'right' },
            ],
            styles: {
              header: {
                fontSize: 24,
                bold: true,
                margin: [0, 0, 0, 10],
                decoration: 'underline'
              },
              subheader: {
                fontSize: 14,
                bold: true,
                margin: [0, 10, 0, 5]
              },
              tableHeader: {
                bold: true,
                fillColor: '#f2f2f2'
              },
              total: {
                fontSize: 14,
                bold: true,
                margin: [0, 10, 0, 5]
              },
              leftColumn: {
                margin: [0, 10, 0, 0]
              },
              rightColumn: {
                margin: [0, 10, 0, 0]
              },
              bottomMargin:{
                margin: [0, 0, 0, 10]
              }
            }
          };

          this.$apollo.mutate({
            mutation: queries.CREATE_RECEIPT_MUTATION,
            variables: {
              data: JSON.stringify(this.data),
              sales_id: parseInt(createdSalesId),
              type: "package",
            },
          }).catch(error => {
            console.log(JSON.stringify(error, null, 2));
          }).then(async (response) => {
            let result = response.data
            console.log("success result: ",result)
            this.data.id = result.createReceipt.id
          })
         
          await Promise.all(mutationPromises);
          this.cancelPackageBill()
          this.splitPayment = false
          this.resetMethodPayment()
          this.packageBillDialog = false
          this.overlay = true
          const fileName = response.data.createSales.invoice_no;
          const invoicpdf = pdfMake.createPdf(documentDefinition);
          await this.uploadPdf(invoicpdf, fileName, 'package', parseInt(createdSalesId));
        }).catch(error => {
            console.log(JSON.stringify(error, null, 2));
          });

      },
      payLater(){
        this.createBookings("pending")
      },
      payBooking(){
        this.createBookings("approved")
        // this.bookingBillDialog = false
        // this.cancelbookingBill()
      },
      calcPkgTotal(value){
        var subtotal = 0
        if(!value)  {
          this.pkgPrice.subtotal = subtotal
          this.pkgPrice.total =  this.pkgPrice.subtotal
          this.pkgPrice.discount = 0
          return
        }
        value.forEach( p => {
          subtotal += p.price
        })
        this.pkgPrice.discount = 0
        this.pkgPrice.subtotal = parseFloat(subtotal).toFixed(2)
        this.pkgPrice.total = parseFloat(this.pkgPrice.subtotal).toFixed(2)
        if(this.getAllTax.length > 0) {
          this.pkgPrice.taxList = []
          this.pkgPrice.totalTax = 0
          this.getAllTax.forEach(t=>{
            var taxAmount = {
              id : t.id,
              name: t.name,
              amount: (subtotal * t.amount / 100).toFixed(2),
              percentage: t.amount,
            }
            this.pkgPrice.taxList.push(taxAmount)
            this.pkgPrice.totalTax += taxAmount.amount * 1
          })
          this.pkgPrice.total = this.pkgPrice.subtotalTax =  parseFloat(this.pkgPrice.subtotal * 1  + this.pkgPrice.totalTax * 1).toFixed(2)
        }
        this.remainBalance = this.pkgPrice.total
        if(this.paymentMethodsList.length > 0){
          this.paymentMethodsList.forEach( m => {
            this.remainBalance -= m.amount * 1
          })
        }
        this.handleBlur()
      },
      calcBookingTotal(value){
        var subtotal = 0
        if(!value)  {
          this.bookingPrice.subtotal = parseFloat(subtotal)
          this.bookingPrice.total =  parseFloat(this.bookingPrice.subtotal)
          this.bookingPrice.discount = 0
          return
        }
        value.forEach( b => {
          if(b.appliedPackage){
            subtotal += 0
          }
          else{
            subtotal += b.price
          }
        })
        this.bookingPrice.discount = 0
        this.bookingPrice.subtotal = parseFloat(subtotal).toFixed(2)
        this.bookingPrice.total =  parseFloat(this.bookingPrice.subtotal).toFixed(2)
        if(this.getAllTax.length > 0) {
          this.bookingPrice.taxList = []
          this.bookingPrice.totalTax = 0
          this.getAllTax.forEach(t=>{
            var taxAmount = {
              id : t.id,
              name: t.name,
              amount: (subtotal * t.amount / 100).toFixed(2),
              percentage: t.amount,
            }
            this.bookingPrice.taxList.push(taxAmount)
            this.bookingPrice.totalTax += taxAmount.amount * 1
          })
          this.bookingPrice.total = this.bookingPrice.subtotalTax =  parseFloat(this.bookingPrice.subtotal * 1  + this.bookingPrice.totalTax * 1).toFixed(2)
        }
        this.remainBalance = this.bookingPrice.total
        if(this.paymentMethodsList.length > 0){
          this.paymentMethodsList.forEach( m => {
            this.remainBalance -= m.amount * 1
          })
        }
        this.handleBlur()
      },
      updateCurrentOutlet(value) {
        this.currentOutlet = value; // Update the currentOutlet value when selection changes
        this.$session.set("currentOutlet", value); // Update the session value
      },
      openMultiBookingDialog(){
        this.openCheckNumberDialog('booking',false)
      },
      openBookDialog(){ //for pop up from multibooking
        this.bookInBillDialog = true
      },
      cancelbookingBill(){
        // this.user.user_fullname = ""
        // this.user.user_id = ""
        // this.user.package_transaction = []
        // this.user.all_package_transaction = []
        this.bookingBillDialog = false
        this.showNameAndPkg = false
        this.newUser = false
        this.splitPayment = false
        this.resetMethodPayment()
        // this.user_contact = ""
        // this.selectedBookingList = []
      },
      cancelBookingInBillPop(){
        this.bookingInBill = {
          staff_id: '',
          outlet_id: '',
          resource_type_id: '',
          resource_type_name: '',
          service_id: '',
          service_name: '',
          booked_date: '',
          from_time: '',
          duration: '',
          number_of_people: '',
          status: '',
          suitablePackage: true
        }
        this.package_from_user={
              "id": "",
              "serviceType": null,
              "service": null,
              "resource": null,
              "resourceType": null,
              "key": null
          },
        this.time_available = true
        this.bookInBillDialog = false
        this.disableService = false
      },
      async clearAllSelectedBooking(){
        this.selectedBookingList = []
        this.events = await this.events.filter(e => !e.temporary)
        this.getEvents()
        if (this.user.all_package_transaction.length > 0)this.user.package_transaction = this.user.all_package_transaction
      },
      async addBookingInBillPop(){
        var validate = this.$refs.form2.validate()
        if(validate){
          if(this.staff_name){
            for(let i=0; i<this.staffs.length; i++){
              if(this.staffs[i].fullname == this.staff_name){
                this.bookingInBill.staff_id = this.staffs[i].id
                this.bookingInBill.staff_name = this.staff_name
              }
            }
          }

          if(this.bookingInBill.booked_date){
            this.bookingInBill.booked_date = new Date(this.bookingInBill.booked_date);
          }

          if (this.bookingInBill.service_id) {
            this.bookingInBill.price = this.bookingInBill.service_id.price
          }
          if (this.bookingInBill.resource_type_id) {
            this.bookingInBill.price = this.bookingInBill.resource_type_id.price
          }
          if (this.customprice) {
            for (let i = 0; i < this.customprice.length; i++) {
              this.bookingInBill.price = this.customprice[i].amount
              if (this.customprice[i].date != null) {
                break
              }
            }
          }
          if (this.bookingInBill.service_id && this.bookingInBill.staff_id) {
            try {
              const result = await this.$apollo.query({
                query: queries.GET_STAFF_COMMISSION_BY_BOTH_ID,
                variables: {
                  staff_id: this.bookingInBill.staff_id * 1,
                  service_id: this.bookingInBill.service_id.id * 1
                }
              });
              this.bookingInBill.staffCommission = result.data.getStaffCommissionByStaffSerice;
              var commissionAmount = this.bookingInBill.staffCommission.type === 'fixed' ? this.bookingInBill.staffCommission.commission : this.bookingInBill.price * this.bookingInBill.staffCommission.commission / 100
              this.bookingInBill.oriCommissionAmount = commissionAmount
              this.bookingInBill.commissionAmount = commissionAmount
            } catch (error) {
              console.log(JSON.stringify(error, null, 2));
            }
          }
          if(this.package_from_user.service || this.package_from_user.resource || this.package_from_user.serviceType){
            var voucher = this.user.package_transaction.find(v => v.id == this.package_from_user.id)
            const index = this.user.package_transaction.indexOf(voucher);
            if (index !== -1) {
              this.user.package_transaction.splice(index, 1);
            }
            
            this.bookingInBill.appliedPackage = voucher;
            this.bookingInBill.suitablePackage = true
          }
          const startTime = this.bookingInBill.from_time; 
          const duration = this.bookingInBill.duration;

          const startTimeParts = startTime.split(":");
          const startHour = parseInt(startTimeParts[0]);
          const startMinute = parseInt(startTimeParts[1]);

          const startTimeObject = new Date(this.bookingInBill.booked_date);
          startTimeObject.setHours(startHour);
          startTimeObject.setMinutes(startMinute);

          const endTime = new Date(startTimeObject);
          endTime.setMinutes(startTimeObject.getMinutes() + duration);
          const time = (startHour * 60 + startMinute) * 60 * 1000;
  
          this.bookEvent = await{
              name: '[TEMP]'+this.bookingInBill.service_name + ' (' + this.staff_name + ')',
              color: this.service_color,
              start: startTimeObject,
              end:endTime,
              time: time.toString(),
              outletId: this.bookingInBill.outlet_id.toString(),
              category: this.staff_name,
              id:"",
              timed: true,
              temporary: true,
              temporary_id: this.selectedBookingList.length + 1,
            }
          this.events.push(this.bookEvent)
          this.bookingInBill.bookEvent = {...this.bookEvent}
          this.selectedBookingList.push(this.bookingInBill)
          this.bookingInBill = {
            staff_id: '',
            outlet_id: '',
            resource_type_id: '',
            resource_type_name: '',
            service_id: '',
            service_name: '',
            booked_date: '',
            from_time: '',
            duration: '',
            number_of_people: '',
            status: '',
            price:'',
            suitablePackage: true,
            bookEvent:"",
          }
          this.package_from_user={
              "id": "",
              "serviceType": null,
              "service": null,
              "resource": null,
              "resourceType": null,
              "key": null
          },
          this.time_available = true
          this.staff_name = ''
          this.bookInBillDialog = false
          this.disableService = false
        }
      },
      dataReset() {
        this.bookingInBill = {
          staff_id: null,
          outlet_id: null,
          resource_type_id: null,
          resource_type_name: null,
          service_id: null,
          service_name: null,
          booked_date: null,
          from_time: null,
          duration: null,
          number_of_people:null,
          status: null,
          price:null,
          suitablePackage: true,
        }
      },
      processedResourceTypes() {
        this.processed_resource_types = []
        for (let i = 0; i < this.resourceTypes.length; i++) {
          for (let x = 0; x < this.resources.length; x++) {
            if (this.resourceTypes[i].id == this.resources[x].resource_type_id && this.resources[x].resource_schedule.length > 0) {
              var res = JSON.parse(JSON.stringify(this.resourceTypes[i]));
              res.resource = this.resources[x]
              res.display_name = this.resourceTypes[i].name +"("+ this.resources[x].name +")"
              this.processed_resource_types.push(res)
            }
          }
        }
      },
      async processedTimeSlot() {
        this.timeSlot();
        var tempBooks = await this.getTempBooking()
        var bookings =[]
        if(tempBooks && tempBooks.length>0){
          bookings = [...this.bookings, ...tempBooks]
        }else{
          bookings = [...this.bookings]
        }
        if(this.bookingInBill.outlet_id){
          for (let i = 0; i <bookings.length; i++) {
            const bookingDate = new Date(bookings[i].booked_date * 1);
            const targetDate = new Date(this.bookingInBill.booked_date);
            if (
              bookingDate.getFullYear() === targetDate.getFullYear() &&
              bookingDate.getMonth() === targetDate.getMonth() &&
              bookingDate.getDate() === targetDate.getDate()
            ) {
              if (
                this.bookingInBill.staff_id ||
                this.bookingInBill.resource_type_id
              ) {
                if(this.bookingInBill.staff_id && bookings[i].staff && this.bookingInBill.staff_id.id.toString() !== bookings[i].staff.id.toString()) continue
                if(this.bookingInBill.resource_type_id &&bookings[i].service) continue
                var bookingStartTime1 = new Date(bookings[i].booked_date * 1 +bookings[i].from_time * 1);
                var bookingEndTime1 = new Date(bookings[i].booked_date * 1 +bookings[i].from_time * 1 +bookings[i].duration * 60 * 1000);
                bookingStartTime1.setHours(bookingStartTime1.getUTCHours());
                bookingEndTime1.setHours(bookingEndTime1.getUTCHours());
                for (let x = 0; x < this.timeslots.length; x++) {
                  const [slotHours, slotMinutes] = this.timeslots[x].split(":").map(Number);
                  const timeslotStartTime = new Date(bookingDate);
                  timeslotStartTime.setHours(slotHours);
                  timeslotStartTime.setMinutes(slotMinutes);
                  if (
                    timeslotStartTime >= bookingStartTime1 &&
                    timeslotStartTime < bookingEndTime1 &&
                      (
                        this.bookingInBill.service_id ||
                        this.bookingInBill.outlet_id ||
                        this.bookingInBill.staff_id ||
                        this.bookingInBill.resource_type_id
                      )
                  ) {
                    this.timeslots.splice(x, 1);
                    break;
                  }
                }
              }
            }
          }

          if(this.bookingInBill.staff_id){
            const staff_details = this.staffs.find(s => s.id.toString() === this.bookingInBill.staff_id.id.toString())
            for (let i = 0; i < staff_details.staff_roster.length; i++) {
              const roster = staff_details.staff_roster[i];
              const breakFromTimeMs = Number(roster.break_from_time);
              const breakToTimeMs = Number(roster.break_to_time);

              // Convert break times to hours and minutes
              const staffBreakStartHour = Math.floor(breakFromTimeMs / (60 * 60 * 1000));
              const staffBreakStartMinute = Math.floor((breakFromTimeMs % (60 * 60 * 1000)) / (60 * 1000));
              const staffBreakEndHour = Math.floor(breakToTimeMs / (60 * 60 * 1000));
              const staffBreakEndMinute = Math.floor((breakToTimeMs % (60 * 60 * 1000)) / (60 * 1000));
              const staffBreakStartTime = new Date(0);
              staffBreakStartTime.setHours(staffBreakStartHour, staffBreakStartMinute, 0, 0);

              const staffBreakEndTime = new Date(0);
              staffBreakEndTime.setHours(staffBreakEndHour, staffBreakEndMinute, 0, 0);
              for (let v = 0; v < this.timeslots.length; v++) {
                const [slotHours, slotMinutes] = this.timeslots[v].split(":").map(Number);
                const timeslotStartTime = new Date(0);
                timeslotStartTime.setHours(slotHours);
                timeslotStartTime.setMinutes(slotMinutes);
                const timeslotEndTime = new Date(timeslotStartTime.getTime() + (this.slot_duration * 60 * 1000));
                // Check if the timeslot falls within the staff's break time
                if (
                  (timeslotStartTime >= staffBreakStartTime && timeslotStartTime < staffBreakEndTime) ||
                  (timeslotEndTime > staffBreakStartTime && timeslotEndTime <= staffBreakEndTime)
                ) {
                  // Remove the timeslot
                  this.timeslots.splice(v, 1);
                  v--;
                }
              }
            }
          }
          if (!this.timeslots.includes(this.bookingInBill.from_time)) {
            this.bookingInBill.from_time = "";
          }
      } else if (this.bookingById.outlet_id) {
        for (let i = 0; i <bookings.length; i++) {
          const bookingDate = new Date(bookings[i].booked_date * 1);
          const targetDate = new Date(this.bookingById.booked_date);

          if (
            bookingDate.getFullYear() === targetDate.getFullYear() &&
            bookingDate.getMonth() === targetDate.getMonth() &&
            bookingDate.getDate() === targetDate.getDate()
          ) {
            if (
              this.bookingById.staff_id ||
              this.bookingById.resource_type_id
            ) {
              if (
                this.bookingById.staff_id &&
               bookings[i].staff &&
                this.bookingById.staff_id.id.toString() !== bookings[i].staff.id.toString()
              ) {
                continue;
              }

              if (this.bookingById.resource_type_id &&bookings[i].service) {
                continue;
              }
              var bookingStartTime = new Date(bookings[i].booked_date * 1 +bookings[i].from_time * 1);
              var bookingEndTime = new Date(bookings[i].booked_date * 1 +bookings[i].from_time * 1 +bookings[i].duration * 60 * 1000);
              bookingStartTime.setHours(bookingStartTime.getUTCHours());
              bookingEndTime.setHours(bookingEndTime.getUTCHours());
              for (let x = 0; x < this.timeslots.length; x++) {
                const [slotHours, slotMinutes] = this.timeslots[x].split(":").map(Number);
                const timeslotStartTime = new Date(bookingDate);
                timeslotStartTime.setHours(slotHours);
                timeslotStartTime.setMinutes(slotMinutes);
                if (
                  timeslotStartTime >= bookingStartTime &&
                  timeslotStartTime < bookingEndTime &&
                  (
                    this.bookingById.service_id ||
                    this.bookingById.outlet_id ||
                    this.bookingById.staff_id ||
                    this.bookingById.resource_type_id
                  )
                ) {
                  this.timeslots.splice(x, 1);
                  break;
                }
              }
            }
          }
        }
        if(this.bookingById.staff_id){
            const staff_details = this.staffs.find(s => s.id.toString() ===  this.bookingById.staff_id.id.toString())
            for (let i = 0; i < staff_details.staff_roster.length; i++) {
              const roster = staff_details.staff_roster[i];
              const breakFromTimeMs = Number(roster.break_from_time);
              const breakToTimeMs = Number(roster.break_to_time);

              // Convert break times to hours and minutes
              const staffBreakStartHour = Math.floor(breakFromTimeMs / (60 * 60 * 1000));
              const staffBreakStartMinute = Math.floor((breakFromTimeMs % (60 * 60 * 1000)) / (60 * 1000));
              const staffBreakEndHour = Math.floor(breakToTimeMs / (60 * 60 * 1000));
              const staffBreakEndMinute = Math.floor((breakToTimeMs % (60 * 60 * 1000)) / (60 * 1000));
              const staffBreakStartTime = new Date(0);
              staffBreakStartTime.setHours(staffBreakStartHour, staffBreakStartMinute, 0, 0);

              const staffBreakEndTime = new Date(0);
              staffBreakEndTime.setHours(staffBreakEndHour, staffBreakEndMinute, 0, 0);
              for (let v = 0; v < this.timeslots.length; v++) {
                const [slotHours, slotMinutes] = this.timeslots[v].split(":").map(Number);
                const timeslotStartTime = new Date(0);
                timeslotStartTime.setHours(slotHours);
                timeslotStartTime.setMinutes(slotMinutes);
                const timeslotEndTime = new Date(timeslotStartTime.getTime() + (this.slot_duration * 60 * 1000));
                // Check if the timeslot falls within the staff's break time
                if (
                  (timeslotStartTime >= staffBreakStartTime && timeslotStartTime < staffBreakEndTime) ||
                  (timeslotEndTime > staffBreakStartTime && timeslotEndTime <= staffBreakEndTime)
                ) {
                  // Remove the timeslot
                  this.timeslots.splice(v, 1);
                  v--;
                }
              }
            }
          }
        if (!this.timeslots.includes(this.bookingById.from_time)) {
          this.bookingById.from_time = "";
          if(this.type == 'week' || this.type == 'category'){
            this.alertDialog = true
            this.alertDialog_text = "The staff is on break time. Please choose another timeslot."
            this.cancelBooking()
          }
        }

      }

      },
      async getTempBooking(){
        var tempBooks = []
        const result = await this.$apollo.query({query: GET_ALL_STAFF_QUERY})
        const allStaffs = result.data.staffs
        for (let index = 0; index < this.events.length; index++) {
          const element = this.events[index];
          if(element.temporary && this.bookingById.bookEvent?.temporary_id == element.temporary_id){
            continue
          }
          if(!element.temporary) continue
          if(!element.temporary && (this.bookingById.outlet_id?this.bookingById.outlet_id*1:this.bookingInBill.outlet_id*1 != element.outletId*1 )) continue
          // Parse the "start" and "end" times into Date objects
          const startTime = new Date(element.start);
          const endTime = new Date(element.end);

          // Calculate the time difference in milliseconds
          const timeDifferenceMs = endTime - startTime;

          // Convert the time difference from milliseconds to minutes
          const durationMinutes = timeDifferenceMs / (1000 * 60); // 1000 milliseconds per second, 60 seconds per minute

          // Parse the "start" time into a Date object
          const startDate = new Date(element.start);

          // Extract the date portion (year, month, and day) and set the time to midnight
          startDate.setUTCHours(0, 0, 0, 0);

          // Get the timestamp (in milliseconds) of the date without the time
          const startDateTimestamp = startDate.getTime();

          var staff = []
          staff = await allStaffs.filter( s => s.fullname == element.category)
          if(staff.length > 0){
            var bookingDetails = {
              outlet_id: element.outletId,
              booked_date: startDateTimestamp,
              from_time: element.time,
              duration: durationMinutes,
              staff:{
                  fullname: staff[0].fullname,
                  id:staff[0].id,
              },
              service:{
                color:element.color
              }
            }
            tempBooks.push(bookingDetails)
          }
        }
        return tempBooks
      },
      createBookings(method){
        if(!this.user.user_id || this.selectedBookingList.length == 0){
          return
        }
        const CREATE_BOOKING_MUTATION = gql`
            mutation createBooking($staff_id: Int,$user_id: Int , $outlet_id:Int!, $resource_type_id:Int, $resource_type_name:String, $service_id:Int, $service_name:String, $booked_date:String!, $from_time:String!, $duration:Int!, $number_of_people:Int!, $status:BookingStatus, $booking_from:String, $amount:Float!, $booking_no:String!, $user_contact:String, $user_fullname:String, $created_by_staff_id: Int, $package_transaction_id: Int, $customCommission: Float){
              createBooking(staff_id:$staff_id, user_id:$user_id, outlet_id:$outlet_id, resource_type_id:$resource_type_id, resource_type_name:$resource_type_name, service_id:$service_id, service_name:$service_name, booked_date:$booked_date, from_time:$from_time, duration:$duration, number_of_people:$number_of_people, status:$status, booking_from:$booking_from, amount:$amount, booking_no:$booking_no, user_contact:$user_contact, user_fullname:$user_fullname, created_by_staff_id: $created_by_staff_id, package_transaction_id: $package_transaction_id, customCommission: $customCommission ){
                booking_no
                booked_date
                duration
                service_name
                resource_type_name
                id
              }
            }
          `
          const promises = [];
          for (const booking of this.selectedBookingList) {
            const variables = {
              created_by_staff_id: this.$session.get("user_id") * 1,
              staff_id: booking.staff_id ? booking.staff_id * 1 : null,
              outlet_id: booking.outlet_id * 1,
              resource_type_id: booking.resource_type_id
                ? booking.resource_type_id.id * 1
                : null,
              resource_type_name: booking.resource_type_name,
              service_id: booking.service_id ? booking.service_id.id * 1 : null,
              service_name: booking.service_name,
              booked_date: booking.booked_date,
              from_time: booking.from_time,
              duration: booking.duration * 1,
              number_of_people: booking.number_of_people
                ? booking.number_of_people * 1
                : 0,
              status: "booked",
              booking_from: booking.booking_from,
              amount: booking.price,
              booking_no: "B001",
              user_id: this.user.user_id * 1,
              user_contact: this.parsePhoneNumber(this.user.user_contact),
              user_fullname:  this.user.user_fullname ,
              package_transaction_id: booking.appliedPackage ? booking.appliedPackage.id * 1 : null,
              customCommission: booking.commissionAmount && booking.oriCommissionAmount != booking.commissionAmount ? booking.commissionAmount * 1 : null 
            };

            promises.push(
              this.$apollo.mutate({
                mutation: CREATE_BOOKING_MUTATION,
                variables: variables,
              })
            );

            if(booking.appliedPackage){
              const UPDATE_PACKAGE_TRANSACTION_USED_AT = gql`
                mutation UpdatePackageTransactionUsedAt($id: ID!) {
                  updatePackageTransactionUsedAt(id: $id) {
                    id
                    used_at
                  }
                }
              `;

              this.$apollo.mutate({
                mutation: UPDATE_PACKAGE_TRANSACTION_USED_AT,
                variables: {
                  id:  booking.appliedPackage.id * 1 
                }
              }).then(result=>
              {
                console.log("update",result)
              }
              ).catch((error) => {
              // console.log(error);
              console.log(JSON.stringify(error, null, 2));
              });
            }
          }
          Promise.all(promises)
            .then((results) => {
              // Handle the results of all the promises
              const bookingIds = []
              results.forEach((result) => {
                if (result && result.data.createBooking != null) {
                  console.log("Create successfully");
                  bookingIds.push(result.data.createBooking.id)
                } else {
                  console.log("Create failed");
                }
              });
              this.events = this.events.filter(e => !e.temporary)

              // Continue with the next mutate function call or other logic here
              const outletId = this.staff_outlet.filter(o => o.name == this.$session.get("currentOutlet"))[0].id
              const CREATE_SALES = gql`
                mutation CreateSales($sales_type: salesType!, $status: payStatus!, $package_id: Json, $booking_id: Json, $amount: Float!, $user_id: Int!, $outlet_id: Int!, $staff_id: Int, $online_booking: Boolean, $split_payment: Boolean, $tax_amount: Float) {
                  createSales(sales_type: $sales_type, status: $status, package_id: $package_id, booking_id: $booking_id, amount: $amount, user_id: $user_id, outlet_id: $outlet_id, staff_id: $staff_id, online_booking: $online_booking, split_payment: $split_payment, tax_amount: $tax_amount ) {
                    id
                    sales_type
                    status
                    package_id
                    booking_id
                    amount
                    user_id
                    outlet_id
                    staff_id
                    online_booking
                    invoice_no
                    created_at
                    updated_at
                    deleted_at
                    voided_at
                    split_payment
                  }
                }
              `;

              this.$apollo.mutate({
                mutation: CREATE_SALES,
                variables: {
                  sales_type: 'booking',
                  status: method,
                  package_id: JSON.stringify(null),
                  booking_id:  JSON.stringify(bookingIds),
                  amount: this.bookingPrice.total * 1,
                  user_id: parseInt(this.user.user_id),
                  outlet_id: parseInt(outletId),
                  staff_id: this.$session.get("user_id") * 1,
                  online_booking: false,
                  split_payment: this.splitPayment,
                  tax_amount: this.bookingPrice.totalTax ? parseFloat(this.bookingPrice.totalTax) : null
                },
              }).then(async (result)=>{
                let createdSalesId = result.data.createSales.id
                if(method == "approved"){
                  const CREATE_PAYMENT_METHOD_SALES = gql`
                    mutation CreatePaymentMethodSales($salesId: Int!, $paymentMethodId: Int!, $amount: Float!) {
                      createPaymentMethodSales(salesId: $salesId, paymentMethodId: $paymentMethodId, amount: $amount) {
                        id
                        amount
                      }
                    }
                  `;

                  if(this.splitPayment){
                    for (let i = 0; i < this.paymentMethodsList.length; i++) {
                      const pMethod = this.paymentMethodsList[i];
                      if(pMethod.amount > 0){
                        this.$apollo.mutate({
                          mutation: CREATE_PAYMENT_METHOD_SALES,
                          variables: {
                            paymentMethodId: parseInt(pMethod.id),
                            salesId: parseInt(createdSalesId),
                            amount: parseFloat(pMethod.amount * 1),
                          },
                        }).catch(error => {
                          console.log(JSON.stringify(error, null, 2));
                        });
                      }
                    }
                  } else if(!this.splitPayment) {
                    this.$apollo.mutate({
                          mutation: CREATE_PAYMENT_METHOD_SALES,
                          variables: {
                            paymentMethodId: parseInt(this.selectedPaymentMethod.id),
                            salesId: parseInt(createdSalesId),
                            amount: parseFloat(this.bookingPrice.total),
                          },
                        }).catch(error => {
                          console.log(JSON.stringify(error, null, 2));
                        });
                  }
                }

                if(this.bookingPrice.taxList.length > 0){
                  const CREATE_TAX_SALES = gql`
                  mutation createTaxSales($tax_id: Int!, $sales_id: Int!, $amount: Float!) {
                    createTaxSales(tax_id: $tax_id, sales_id: $sales_id, amount: $amount) {
                      id
                    }
                  }
                `
                for (let i = 0; i < this.bookingPrice.taxList.length; i++) {
                    const t = this.bookingPrice.taxList[i];
                    if(t.amount > 0){
                      this.$apollo.mutate({
                        mutation: CREATE_TAX_SALES,
                        variables: {
                          tax_id: parseInt(t.id),
                          sales_id: parseInt(createdSalesId),
                          amount: parseFloat(t.amount * 1),
                        },
                      }).catch(error => {
                        console.log("tax",JSON.stringify(error, null, 2));
                      });
                    }
                  }
                }

                pdfMake.vfs = pdfFonts.pdfMake.vfs;

                const outletDetails = await this.$apollo.query({
                query: queries.GET_OUTLET_QUERY,
                variables: { id: outletId * 1 },
                });
                const allstaffs = await this.$apollo.query({query: GET_ALL_STAFF_QUERY})
                this.selectedBookingList.forEach(item=>{
                  if(item.staff_id){
                    item.staffname = allstaffs.data.staffs.find(s => s.id == item.staff_id)?.fullname
                  }
                })
                const invoiceData = {
                  invoice_no : result.data.createSales.invoice_no,
                  date :  this.$generalFunctions.formatInvoiceDateTime(result.data.createSales.created_at * 1),
                  customerName: this.user.user_fullname,
                  customerContact: this.user.user_contact,
                  outletAddress: {...outletDetails.data.outletById},
                  subtotal: this.bookingPrice.subtotal,
                  tax:  Number(this.bookingPrice.totalTax).toFixed(2),
                  discount:  Number(this.bookingPrice.discount).toFixed(2),
                  total: Number(this.bookingPrice.total).toFixed(2),
                  item: [...this.selectedBookingList]
                }
                const addressLines = [
                  invoiceData.outletAddress.name,
                  invoiceData.outletAddress.address1,
                  invoiceData.outletAddress.address2,
                  invoiceData.outletAddress.address3,
                  `${invoiceData.outletAddress.postcode} ${invoiceData.outletAddress.city}`,
                  invoiceData.outletAddress.state,
                  invoiceData.outletAddress.country
                ];
                const imageData = await this.fetchImageData();
                this.data =  {
                    type:"booking",
                    isPrint: false,
                    date: invoiceData.date,
                    invoice: invoiceData.invoice_no,
                    outlet: {
                        name: invoiceData.outletAddress.name,
                        address1: invoiceData.outletAddress.address1,
                        address2: invoiceData.outletAddress.address2,
                        address3: invoiceData.outletAddress.address3,
                        passcode: invoiceData.outletAddress.postcode,
                        city: invoiceData.outletAddress.city,
                        state: invoiceData.outletAddress.state,
                        contact: invoiceData.outletAddress.contact,
                        email: invoiceData.outletAddress.email
                    },
                    staff:{
                        name:this.$session.get("user_fullname")
                    },
                    customer:{
                        name:invoiceData.customerName,
                        contact: invoiceData.customerContact,
                    },
                    items: invoiceData.item,
                    subtotal:invoiceData.subtotal,
                    total:invoiceData.total,
                    tax:invoiceData.tax,
                    taxList: this.bookingPrice.taxList ,
                    discount:invoiceData.discount,
                }
                // Filter out empty address lines and join with commas
                const filteredAddress = addressLines
                  .filter(line => line !== '')
                  .join(',\n');

                const documentDefinition = {
                  layout: 'fullWidth',
                  content: [
                  {
                    columns: [
                      // Left column
                      {
                        width: '50%',
                        stack: [
                          imageData && { image: `data:image/jpeg;base64,${imageData}`, width: 100 },
                          { text: this.websiteName, style: 'header' },
                          { text: `Invoice#${invoiceData.invoice_no}`, style: 'subheader' },
                        ].filter(Boolean),
                        style: 'leftColumn'
                      },
                      // Right column
                      {
                        width: '50%',
                        stack: [
                        { text: `${invoiceData.date}`, style: 'subheader',alignment: 'right' },
                        ],
                        style: 'rightColumn'
                      }
                    ]
                  },

                    {
                    columns: [
                      // Left column
                      {
                        width: '50%',
                        stack: [

                          { text: `Customer Name: ${invoiceData.customerName}` },
                          { text: `Customer Contact: ${invoiceData.customerContact}` },
                        ],
                        style: 'leftColumn'
                      },
                      // Right column
                      {
                        width: '50%',
                        stack: [
                          { text: `${filteredAddress}`, alignment: 'right' }
                        ],
                        style: 'rightColumn'
                      }
                    ]
                  },
                    { text: 'Items', style: 'subheader' },
                    {
                      table: {
                        headerRows: 1,
                        widths: ['*', 'auto'],
                        style: 'bottomMargin',
                        body: [
                          [
                            { text: 'Item Name', style: 'tableHeader' },
                            { text: 'Price (RM)', style: 'tableHeader' },
                            
                          ],
                          ...invoiceData.item.map(item => [
                          {text: `${item.service_name || item.resource_type_name} (${item.staffname || ''}) \n ${this.formatDate(item.booked_date)} ${item.from_time} (${item.duration} mins)`},
                            { text: `${!item.appliedPackage?Number(item.price).toFixed(2):"0.00"}`, alignment: 'right' },
                          ])
                        ]
                      }
                    },
                    { text: `Subtotal: RM ${invoiceData.subtotal}`, alignment: 'right' },
                    { text: `Tax: RM ${invoiceData.tax}`, alignment: 'right' },
                    { text: `Discount: -RM ${invoiceData.discount}`, alignment: 'right' },
                    { text: `Total: RM ${invoiceData.total}`, style: 'total', alignment: 'right' },
                  ],
                  styles: {
                    header: {
                      fontSize: 24,
                      bold: true,
                      margin: [0, 0, 0, 10],
                      decoration: 'underline'
                    },
                    subheader: {
                      fontSize: 14,
                      bold: true,
                      margin: [0, 10, 0, 5]
                    },
                    tableHeader: {
                      bold: true,
                      fillColor: '#f2f2f2'
                    },
                    total: {
                      fontSize: 14,
                      bold: true,
                      margin: [0, 10, 0, 5]
                    },
                    leftColumn: {
                      margin: [0, 10, 0, 0]
                    },
                    rightColumn: {
                      margin: [0, 10, 0, 0]
                    },
                    bottomMargin:{
                      margin: [0, 0, 0, 10]
                    }
                  }
                };

                this.$apollo.mutate({
                  mutation: queries.CREATE_RECEIPT_MUTATION,
                  variables: {
                    data: JSON.stringify(this.data),
                    sales_id: parseInt(createdSalesId),
                    type: "booking",
                  },
                }).catch(error => {
                  console.log(JSON.stringify(error, null, 2));
                }).then(async (response) => {
                  let result = response.data
                  console.log("success result: ",result)
                  this.data.id = result.createReceipt.id
                })
                console.log("123",result.data)
                this.user.user_fullname = ""
                this.user.user_contact= ""
                this.user.user_id = ""
                this.user.package_transaction = []
                this.user.all_package_transaction = []
                this.showNameAndPkg = false
                this.newUser = false
                this.user_contact = ""
                this.selectedBookingList = []
                this.splitPayment = false
                this.resetMethodPayment()
                if(method == "approved" ){
                  this.overlay = true
                  const fileName = result.data.createSales.invoice_no;
                  const invoicpdf = pdfMake.createPdf(documentDefinition);
                  this.uploadPdf(invoicpdf, fileName, 'booking', parseInt(createdSalesId));
                }
                this.bookingBillDialog = false
              } 
              ).catch((error) => {
                console.log(JSON.stringify(error, null, 2));
              });
            })
            .catch((error) => {
              console.log(JSON.stringify(error, null, 2));
            });
          
      },
      usePackageDiscount(booking){
        if(!this.user.user_id) return
        this.suitablePackageList = []
        this.selectedBooking = booking
        if (booking.service_id){
          for (let i = 0; i < this.user.package_transaction.length; i++) {
            const voucher = this.user.package_transaction[i];
            if (voucher.service && voucher.service.id == booking.service_id.id) {
              this.suitablePackageList.push(voucher);
            }
          }

          for (let i = 0; i < this.user.package_transaction.length; i++) {
            const voucher = this.user.package_transaction[i];
            if (voucher.service_Type && (!voucher.service || !voucher.service.id) && voucher.service_Type.id == booking.service_id.service_type_id) {
              this.suitablePackageList.push(voucher);
            }
          }
        } else{
          for (let i = 0; i < this.user.package_transaction.length; i++) {
            const voucher = this.user.package_transaction[i];
            if (voucher.resource && voucher.resource.id == booking.resource_type_id.resource.id) {
              this.suitablePackageList.push(voucher);
            }
          }
          for (let i = 0; i < this.user.package_transaction.length; i++) {
            const voucher = this.user.package_transaction[i];
            if (voucher.resource_Type && !voucher.resource && voucher.resource_Type.id == booking.resource_type_id.id) {
              this.suitablePackageList.push(voucher);
            }
          }
        }
        if(this.suitablePackageList.length == 0)booking.suitablePackage = false
        else {
          const groups = {};
          for (const pkg of this.suitablePackageList) {
            const expiry_date =  new Date(pkg.expiration_date*1).toLocaleDateString()
            const groupName = `${pkg.Package.package_name}_${pkg.sales_id}_${expiry_date}`;
            if (!groups[groupName]) {
              groups[groupName] = { packageName: pkg.Package.package_name,packageExpiry: expiry_date, packagelist: [] };
            }
            groups[groupName].packagelist.push(pkg);
          }
          this.groupedSuitablePackages = Object.values(groups);
          this.selectPkgVoucherDialog = true
        }
      },
      useVoucher(voucher){
        const index = this.user.package_transaction.indexOf(voucher);
        if (index !== -1) {
          this.user.package_transaction.splice(index, 1);
        }
        
        this.selectedBooking.appliedPackage = voucher;
        this.selectedBooking.suitablePackage = true
        this.calcBookingTotal(this.selectedBookingList)
        this.$forceUpdate();
        this.selectedPackage = {}
        this.selectPkgVoucherDialog = false
      },
      cancelPackageDiscount(booking){
        const voucher = booking.appliedPackage;
        booking.appliedPackage = null;
        
        this.user.package_transaction.push(voucher);
        booking.suitablePackage = true
        this.calcBookingTotal(this.selectedBookingList)
        this.$forceUpdate();
      },
      openMemberPkgDialog(){
        this.filterPackage()
        this.memberPkgDialog = true
      },
      remainVoucher(){
        this.selectPkgVoucherDialog = false
        this.selectedPackage = {}
      },
      filterPackage(){
        let groupedPackages = []
        const groups = {};

        this.user.package_transaction.forEach((pkg) => {
            const packageName = pkg.Package.package_name;
            const salesId = pkg.Package.sales_id;
            const id = pkg.id;
            const createdDate = new Date(parseInt(pkg.created_at)).toLocaleDateString();
            const serviceType = pkg.service_Type ? { id: pkg.service_Type.id, name: pkg.service_Type.name } :null;
            const service = pkg.service ? { id: pkg.service.id, name: pkg.service.name } :null;
            const resource = pkg.resource ? { id: pkg.resource.id, name: pkg.resource.name } :null;
            const resourceType = pkg.resource_Type ? { id: pkg.resource_Type.id, name: pkg.resource_Type.name } :null;
            const expiration_date = pkg.expiration_date ? new Date(parseInt(pkg.expiration_date)).toLocaleDateString() : null;

            const key = `${packageName}_${createdDate}_${expiration_date}_${salesId}`;
            const item = {
              id,
              serviceType,
              service,
              resource,
              resourceType,
            };

            if (!groups[key]) {
              groups[key] = {
                packageName,
                createdDate,
                expiration_date,
                items: [item],
              };
            } else {
              groups[key].items.push(item);
            }
          });

          groupedPackages = Object.values(groups);
          groupedPackages.forEach((group) => {
            group.statistics = {}
            group.items.forEach((item) => {
              const serviceType = item.serviceType && item.serviceType.name ? item.serviceType.name : null;
              const service = item.service && item.service.name ? item.service.name : null;
              const resource = item.resource && item.resource.name ? item.resource.name : null;
              const resourceType = item.resourceType && item.resourceType.name ? item.resourceType.name : null;


              let key = '';

              if (serviceType && service) {
                key = `${serviceType}(${service})`;
              } else if (serviceType) {
                key = `${serviceType}`;
              }

              if (resource) {
                key = `${resourceType}(${resource})`;
              } else if (resourceType) {
                key = `${resourceType}`;
              }
              if (key) {
                item.key = key
                if (!group.statistics[key]) {
                  group.statistics[key] = 0;
                }
                group.statistics[key] += 1;
              }
            });
          });
        this.filteredPackages = groupedPackages.sort((a, b) => {
          const dateA = new Date(a.expiration_date);
          const dateB = new Date(b.expiration_date);
          return dateB - dateA;
        });
      },
      handleBlur() {
        if(this.bookingBillDialog) {
          const parsedValue = parseFloat(this.bookingPrice.total);
          if (!isNaN(parsedValue) && parsedValue > 0 ) {
            if( this.bookingPrice.subtotalTax * 1 > this.bookingPrice.total * 1){
              this.bookingPrice.discount = (this.bookingPrice.subtotalTax * 1 - this.bookingPrice.total * 1).toFixed(2)
            }else{
              this.bookingPrice.discount = "0.00"
            }
            this.bookingPrice.total = parsedValue.toFixed(2);
          this.bookingPrice.alertMessage = this.bookingPrice.subtotalTax ?
            this.bookingPrice.total * 1 > this.bookingPrice.subtotalTax * 1 ?
              "Your total amount is higher than subtotal + tax" :
              "" :
            this.bookingPrice.total * 1 > this.bookingPrice.subtotal * 1 ?
              "Your total amount is higher than subtotal" :
              "";
        } else {
            this.bookingPrice.total = (0.00).toFixed(2)
          }
        }else{
          const parsedValue = parseFloat(this.pkgPrice.total);
          if (!isNaN(parsedValue) && parsedValue > 0 ) {
            if( this.pkgPrice.subtotalTax * 1 > this.pkgPrice.total * 1){
              this.pkgPrice.discount = (this.pkgPrice.subtotalTax * 1 - this.pkgPrice.total * 1).toFixed(2)
            }else{
              this.pkgPrice.discount = 0.00
            }
            this.pkgPrice.total = parsedValue.toFixed(2);
            this.pkgPrice.alertMessage = this.pkgPrice.subtotalTax ?
            Number(this.pkgPrice.total) > Number(this.pkgPrice.subtotalTax) ?
              "Your total amount is higher than subtotal + tax" :
              '' :
            Number(this.pkgPrice.total) > Number(this.pkgPrice.subtotal) ?
              "Your total amount is higher than subtotal" :
              '';
          } else {
            this.pkgPrice.total = (0.00).toFixed(2)
          }
        }
        this.resetMethodPayment()
      },
      calcRemainBalance(){
        this.remainBalance = this.bookingBillDialog ? this.bookingPrice.total * 1 :  this.pkgPrice.total * 1
        this.paymentMethodsList.forEach(m => {
          if (m.amount * 1 >= 0 ){
            this.remainBalance -= m.amount * 1
          }
          else{
            m.amount = 0.00
          }
          var amount = Number(m.amount)
          m.amount = (amount).toFixed(2)
        })
        this.remainBalance = this.remainBalance.toFixed(2);
      },
      addCashAmount(value){
        this.paymentMethodsList.forEach(m => {
          if(m.name.toLowerCase() === "cash"){
            var amount = m.amount * 1
            amount += value * 1
            m.amount = amount
          }
        })
        this.calcRemainBalance()
      },
      resetMethodPayment(){
        this.paymentMethodsList.forEach(m => {
          m.amount = "0.00"
        })
        this.remainBalance = 0
        if(this.splitPayment) this.remainBalance = this.bookingBillDialog ? this.bookingPrice.total * 1 :  this.pkgPrice.total * 1
      },
      closeMemberPkgDialog(){
        this.filteredPackages = [] ;
      },
      calcDays(pkg){
        const today = new Date();
        const durationDate = new Date(pkg.duration_date);
        today.setHours(0, 0, 0, 0);
        durationDate.setHours(0, 0, 0, 0);
        const timeDiff = durationDate.getTime() - today.getTime();
        const durationDays = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
        pkg.duration_days = durationDays;
      },
      calcDate(pkg){
        const today = new Date();
        const duration = pkg.duration_days * 1;
        const endDate = new Date(today.setDate(today.getDate() + duration));
        pkg.duration_date = endDate;
      },
      resetCommission(item,orivalue){
        item.commissionAmount = orivalue
        this.$forceUpdate();
      },
      openPDFDialog(){
        this.pdfDialog = true
      },
      closePDFDialog(){
        this.pdfDialog = false
        setTimeout(() => {
           this.pdfUploaded = false
        }, 1000);
        this.pdfSalesIid = null
      },
      async downloadPdf(){
        try{
          const result = await this.$apollo.query({
                query: queries.GET_SALE,
                variables: {
                  id: this.pdfSalesIid * 1,
          }})
          window.open("https://prod-app360.sfo3.digitaloceanspaces.com/"+result.data.sales.pdfFile.filename+".pdf", '_blank');
        }catch(error){
          console.log(JSON.stringify(error, null, 2));
        }
      },
      async uploadPdf(invoicpdf,name,type,salesID){
        invoicpdf.getBlob((blob) => {
          const formData = new FormData();
          formData.append('pdf', blob, name+'.pdf');
          axios.post(process.env.VUE_APP_SERVER_DOMAIN + 'upload-pdf', formData,{
            headers: {
          'Content-Type': 'multipart/form-data',
          },
          })
            .then((result) => { 
              console.log("result",result)
              this.$apollo.mutate(
                {
                  mutation: queries.CREATE_INVOICE_PDF_MUTATION,
                  variables: {
                    type: type,
                    filename: process.env.VUE_APP_DO_SPACES_FOLDER+'/'+name,
                    salesID: salesID * 1
                  },
                }
              ).then(() => {
                this.pdfSalesIid = parseInt(salesID)
                this.pdfUploaded = true
                this.overlay = false
                this.openPDFDialog()
              }).catch((err) => {
                console.error('Error uploading PDF1:', err);
                this.pdfUploaded = false
                this.overlay = false
                this.openPDFDialog()
              });
            })
            .catch((error) => {
              this.overlay = false
              console.log(JSON.stringify(error));
            });
        });
      },
      async fetchImageData() {
        try {
          if(!this.imageURL){
            return null
          }
          const response = await axios.get(this.imageURL, {
            responseType: 'arraybuffer', // Set the response type to arraybuffer
          });

          const imageData = Buffer.from(response.data, 'binary').toString('base64'); // Convert the image data to base64

          return imageData;
        } catch (error) {
          console.error('Error fetching the image:', error);
          return null;
        }
      },
      processedServices(){
        if(this.bookingById.staff_id && this.bookingById.staff_id.id*1 != 0){
          this.$apollo.queries.staffs.refresh()
          this.booking_services = []
          for(let i=0; i<this.processed_staffs.length; i++){
            if(this.bookingById.staff_id*1 == this.processed_staffs[i].id){
              for(let x=0; x<this.services.length; x++){
                for(let y=0; y<this.processed_staffs[i].staff_service.length; y++){
                  if(this.services[x].id == this.processed_staffs[i].staff_service[y].service_id){
                    this.booking_services.push(this.services[x])
                  }
                }
              }
              break
            }
          }
        }
      },
      async openWithPackage(items,key){
        if(this.purchaseType == 'package') return

        for (let i = 0; i < items.length; i++) {
          const element = items[i];
          if(element.key == key){
            this.package_from_user = element
            if(this.package_from_user.resourceType) {
              this.package_from_user={
                  "id": "",
                  "serviceType": null,
                  "service": null,
                  "resource": null,
                  "resourceType": null,
                  "key": null
              }
              return
            }
            const services = await this.$apollo.query({ query: GET_ALL_SERVICE_QUERY }); 
            if(this.package_from_user.service && this.package_from_user.service.id){
              this.bookingInBill.service_id = services.data.services.find(s => s.id == this.package_from_user.service.id)
              this.bookingInBill.service_name = this.package_from_user.service.name
              this.bookingInBill.duration = this.bookingInBill.service_id.duration
              this.disableService = true
            }
            this.memberPkgDialog = false
            this.openBookDialog()
            break
          }
        }
      },
      changeCalenderType(){
        if (this.selectedOpen) {
            this.selectedOpen = false
          }
        if(this.type=="month"){
          this.bookingById.booked_date = null
          this.value = this.currentDate()
        }
        if(this.type == "category") this.bookingById.booked_date = this.value
      },
      clearDate(){
        this.bookingById.booked_date = null
        // this.type = "month"
        // this.value = this.currentDate()
      },
      currentDate(){
        var currentDate = new Date();
        // Extract year, month, and day components
        var year = currentDate.getFullYear();
        var month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed
        var day = String(currentDate.getDate()).padStart(2, '0');

        // Format the date as "YYYY-MM-DD"
        var formattedDate = `${year}-${month}-${day}`;

        // Assign the formatted date to this.value
        return formattedDate;
      },
      processedDate(){
        if(!this.outlet_id && !this.bookingInBill.outlet_id) return
        var off_dates = []
        var not_available_days = [1,2,3,4,5,6,0]
        for(let i=0; i<this.outletSessions.length; i++){
          if(this.outletSessions[i].date != null){
            if(this.outletSessions[i].off_day == 1){
              off_dates.push(new Date(this.outletSessions[i].date*1))
            }
          }
          else if(!this.outletSessions[i].off_day){
            var day = this.outletSessions[i].day
            if(day == 7){
              day = 0
            }
            const index = not_available_days.indexOf(day);
            if (index > -1) {
              not_available_days.splice(index, 1);
            }
          }
        }
        if(not_available_days.length == 7){
          not_available_days = []
        }
        this.disabledDates = {
          to: "",
          days: not_available_days,
          dates: off_dates
        }
      },
      isOffDay(date) {    
        const jsDate = new Date(date); // Convert the date string to a Date object
        const day = jsDate.getDay(); // 0 = Sunday, 1 = Monday, ..., 6 = Saturday
        const dateISOString = jsDate.toISOString().substring(0, 10);
        this.$forceUpdate()
        return (
          this.disabledDates.days.includes(day) ||
          this.disabledDates.dates.includes(dateISOString)
        );
      },
      async clickDay(tms){
        if(this.isOffDay(tms.date))return
        if(this.dialog || this.dragEvent) return
        if(this.isOnEvent) return this.isOnEvent = false
        const mouse = this.toTime(tms)
        const mouseRounded = await this.roundTime(mouse)
        this.staff_name = tms.category.categoryName ?? null
        if(this.staff_name){
          for(let i=0; i<this.staffs.length; i++){
            if(this.staffs[i].fullname == this.staff_name){
              this.bookingById.staff_id = this.staffs[i]
              this.bookingById.staff_name = this.staff_name
            }
          }
        }
        this.bookingById.booked_date = tms.date
        this.bookingById.from_time = ("0" + new Date(mouseRounded).getHours()).slice(-2)+':'+ ("0" + new Date(mouseRounded).getMinutes()).slice(-2)
        this.bookingById.outlet_id = this.outlet_id?this.outlet_id:this.bookingById.staff_id.staff_roster[0].outlet_id.toString()
        this.bookDialog = true
      },
      createBookingInWeek(date, time){
        if(this.isOffDay(date))return
        this.bookingById.booked_date = date
        this.bookingById.from_time = time
        this.bookingById.outlet_id = this.outlet_id?this.outlet_id:this.$session.get("currentOutlet")?this.staff_outlet.filter(o => o.name == this.$session.get("currentOutlet"))[0].id:this.staff_outlet[0].id
        this.bookingById.staff_id = this.staff_id ? this.staff_id : this.bookingById.staff_id
        this.bookDialog = true
      },
      handleMouseDown() {
        this.isMousePressed = true;
      },
      handleMouseUp() {
        this.isMousePressed = false;
      },
      async isStaffAvailability(event) {
        try {
          const start_datetime = new Date(event.start);
          const end_datetime = new Date(event.end);

          // Get the hours and minutes
          const start_time = `${start_datetime.getHours()}:${start_datetime.getMinutes()}`;
          const end_time = `${end_datetime.getHours()}:${end_datetime.getMinutes()}`;

          const result = await this.$apollo.query({
            query: queries.CHECK_STAFF_AVAILBILITY,
            variables: {
              staffId: event.staffId * 1,
              outletId: event.outletId * 1,
              date: event.date,
              start: start_time,
              end: end_time,
            },
          });

          return result.data.checkStaffAvailability.isAvailable;
        } catch (error) {
          console.error(JSON.stringify(error, null, 2));
          // Handle the error as needed
          return false; // Return a default value in case of an error
        }
      },
      async changeTemp(){
        this.events = await this.events.filter(e => e.temporary_id != this.bookingById.bookEvent.temporary_id)
        this.selectedBookingList = await this.selectedBookingList.filter(b => b.bookEvent?.temporary_id != this.bookingById.bookEvent.temporary_id)
        this.booking(false)
      },
      removeTemp(){
        this.events = this.events.filter(e => e.temporary_id != this.bookingById.bookEvent.temporary_id)
        this.selectedBookingList = this.selectedBookingList.filter(b => b.bookEvent?.temporary_id != this.bookingById.bookEvent.temporary_id)
        this.bookDialog = false
      },
      checkdetails(event){
        if(event.event.id){
          this.$router.push({ path: '/booking/' + event.event.id })
        }
      },
      openReceipt(){
        this.receiptDialog = true
      },
      closeReceiptDialog(){
        this.receiptDialog = false
      },
      setTime() { // get current time(not using now)
            const now = new Date();
            const options = {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                hour12: false,
                timeZone: 'UTC',
            };
            const formattedTime = now.toLocaleString('en-GB', options).replace(/[/]/g, '-').replace(","," ");
            this.data.date = formattedTime;
        },
        updateReceipt(){
          console.log(this.data)
          if(!this.data?.id) return
          this.$apollo.mutate({
            mutation: queries.UPDATE_RECEIPT_IS_PRINT_MUTATION,
            variables:{
              id : this.data.id * 1
            }
          }).then(()=>{
            this.data.isPrint = true;
          }).catch(error => {
            console.log("error",error)
            console.log(JSON.stringify(error, null, 2));
          })
          
        },
        openPrintSetting(){
          this.receiptWidth = this.$session.get("receiptWidth") * 1
          this.printSetting = !this.printSetting
        },
        async savePrintSetting(){
          await this.$session.set('receiptWidth', this.receiptWidth*1)
          this.receiptWidth = this.$session.get("receiptWidth") * 1
          this.printSetting = false
        },
        resetPrintSetting(open){
          this.receiptWidth = this.$session.get("receiptWidth") * 1
          this.printSetting = open
        },
        adjustPrintWidth(){
          if(isNaN(parseFloat(this.receiptWidth))){
            this.receiptWidth = 250
          }else if(this.receiptWidth * 1 < 100){
            this.receiptWidth = 100
          }else if(this.receiptWidth * 1 > 400){
            this.receiptWidth = 400
          }
        }
    },
    mounted() {
      this.currentOutlet = this.$session.get("currentOutlet"); // Set the initial value from session
      window.addEventListener('mousedown', this.handleMouseDown);
      window.addEventListener('mouseup', this.handleMouseUp);
      if(this.$session.get("receiptWidth") * 1){
        this.receiptWidth = this.$session.get("receiptWidth") * 1
      }
    },
    beforeDestroy() {
      // Clean up event listeners
      window.removeEventListener('mousedown', this.handleMouseDown);
      window.removeEventListener('mouseup', this.handleMouseUp);
    },
    computed: {
      fromDateDisp() {
        return this.dates.join(' ~ ');
        // format date, apply validations, etc. Example below.
        // return this.fromDateVal ? this.formatDate(this.fromDateVal) : "";
      },
      isExpanded() {
        return (index) => this.expandedList.includes(index);
      },
      isExpanded2() {
        return (index) => this.expandedBookingList.includes(index);
      },
      computedDateFormatted () {
        return this.formatDate(this.value)
      },
      intervalCount() {
      // Calculate the total number of intervals between fromTime and toTime
      const from = new Date(`1970-01-01 ${this.from_time}`);
      const to = new Date(`1970-01-01 ${this.to_time}`);
      const timeDiff = to - from;
      return timeDiff / (this.settings.slot_duration * 60 * 1000);
      },
      slotDurationMinutes() {
        return this.settings.slot_duration * 60 * 1000; // Convert to milliseconds
      },
    },
    filters: {
    formatPrice(value) {
      return Number(value).toFixed(2);
    },
    formatDate(value) {
      if (!(value instanceof Date)) {
        return value;
      }
      return value.toLocaleDateString('en-US');
    },
    filterdate(value){
      if (!value) return 'Permenant'; // Handle null or undefined values
  
      const expirationDate = new Date(value * 1);
      // Perform any necessary date formatting or manipulation
      // For example, you can use libraries like Moment.js or Date-fns
      
      // Return the formatted date
      return expirationDate.toDateString(); 
    },
    filterLength(value){
      if(!value) return

      return value > 99 ? "99" : value
    }
    },
    created(){
      this.$apollo.query({ query: queries.GET_ALL_PERMISSIONS_QUERY }).then(async result => {
        // Process result and set data as needed
        // For example, you can set this.getAllPermissions or perform other operations
        this.getAllPermissions = await result.data.getAllPermissions;
        // Fetch getStaffRoles query
        this.$apollo.query(
          {
            query: queries.GET_STAFF_ROLES,
            variables: {
              staffId: this.$session.get("user_id") * 1
            }
          }
        ).then(async result => {
          // Process result and set data as needed
          // For example, you can set this.getStaffRoles or perform other operations
          this.getStaffRoles = await result.data.getStaffRoles;
          // Now that you have both data, you can proceed with the rest of your logic
          this.allPermissionsName = [];
          this.getStaffRoles.forEach(role => {
            role.role.RolePermission.forEach((item) => {
              const name = item.permission.name;
              this.allPermissionsName.push(name);
            });
          })
          this.$session.set('staffPermission', this.$generalFunctions.generatePermissionArray(this.getAllPermissions, this.allPermissionsName));
          this.staffPermission = await this.$generalFunctions.generatePermissionArray(this.getAllPermissions, this.allPermissionsName)
        })
      })
    }
  }
</script>

<style>
.package_bill{
  display: flex;
  max-height: 90vh;
}
.selector_container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.pkg-card {
  margin: 0 5px 10px 5px;
}
.pkg-card > .v-card__title{
  display: flex;
  justify-content: space-between;
}
.small-text-booking{
  font-size: 75%;
  padding: 0 10px;
}
.pending_circle{
  border-radius: 50%;
  width: 20px;
  height: 20px;
  padding: 2px;
  background: #fff;
  color: #000;
  text-align: center;
  font-size: 12px;
  margin-left: 6px;
}
.vdp-datepicker__calendar {
    bottom: -450%; /* Adjust this value as needed */
    top: auto !important;
  }
</style>