<template>
    <v-app>
      <SideMenu :alert="alert" :alert_type="alert_type" :message="message"  submenuPage="Sales Listing"
            submenuGroup="Listing">
        <Form>
          <template v-slot:tabs>
            <v-tabs
            v-model="tab"
          >
            <v-tabs-slider></v-tabs-slider>
            <v-tab href="#details">Details</v-tab>
          </v-tabs>
          </template>
          <template v-slot:form>
            <v-tabs-items v-model="tab">
                <v-tab-item value="details">
                    <v-row>
                        <v-col cols="3">Sales id: </v-col>
                        <v-col cols="6">{{ saleDetails.id }}</v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="3">Sales Type: </v-col>
                        <v-col cols="6">{{ saleDetails.sales_type }}</v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="3">Status: </v-col>
                        <v-col cols="6">{{ saleDetails.status }}</v-col>
                    </v-row>
                    <v-row v-if="saleDetails.User">
                        <v-col cols="3">Customer: </v-col>
                        <v-col cols="6" @click="routeToCustomer(saleDetails.user_id)">{{ saleDetails.User.user_fullname }}({{ saleDetails.User.user_contact }})</v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="3">Booking Online: </v-col>
                        <v-col cols="6">{{ saleDetails.online_booking }}</v-col>
                    </v-row>
                    <v-row v-if="saleDetails.staff">
                        <v-col cols="3">Staff: </v-col>
                        <v-col cols="6" @click="routeToStaff(saleDetails.staff_id)" >{{ saleDetails.staff.fullname }}</v-col>
                    </v-row>
                    <v-row v-if="saleDetails.package_id">
                        <v-col cols="3">Packages: </v-col>
                        <v-col cols="6"><span @click="routeToPackage(p.id)" v-for="(p, i) of saleDetails.package_details" :key="i">{{p.name}}<span v-if="i !== saleDetails.package_details.length - 1">,</span></span></v-col>
                    </v-row>
                    <v-row v-if="saleDetails.booking_id">
                        <v-col cols="3">Booking: </v-col>
                        <v-col cols="6"><span @click="routeToBooking(p)" v-for="(p, i) of saleDetails.booking_id" :key="i">{{p}}<span v-if="i !== saleDetails.booking_id.length - 1">,</span></span></v-col>
                    </v-row>
                    <v-row v-if="saleDetails.invoice_no">
                        <v-col cols="3">Invoice No: </v-col>
                        <v-col cols="9">{{ saleDetails.invoice_no }}</v-col>
                        <v-col cols="3"></v-col>
                        <v-col cols="9">
                            <v-btn
                                small
                                color="primary"
                                class="mr-4 mt-1"
                                v-if="saleDetails.invoice_no"
                                @click="downloadInvoice(saleDetails.id)"
                            >
                            <v-icon left>
                                mdi-download
                            </v-icon>
                                Download PDF
                            </v-btn>
                            <v-btn
                                small
                                color="primary"
                                class="mr-4 mt-1"
                                v-if="saleDetails.receipts"
                                @click="receiptDialog = true"
                            >
                            <v-icon left>
                                mdi-download
                            </v-icon>
                                Print Receipt
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row v-if="saleDetails.payment_methods && saleDetails.payment_methods.length > 0">
                        <v-col cols="3">Payment Method: </v-col>
                        <v-col cols="6">
                            <template v-if="!saleDetails.split_payment"><span v-for="(p, i) of saleDetails.payment_methods" :key="i">{{ p.paymentMethod.name }}  RM {{ p.amount.toFixed(2) }}</span></template>
                            <template v-if="saleDetails.split_payment">
                                <v-simple-table class="table-border">
                                    <template v-slot:default>
                                    <thead>
                                        <tr>
                                        <th class="text-left">
                                            Name
                                        </th>
                                        <th class="text-left">
                                            Amount (RM)
                                        </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="item in saleDetails.payment_methods" :key="item.name">
                                        <td>{{ item.paymentMethod.name }}</td>
                                        <td>{{ item.amount.toFixed(2) }}</td>
                                        </tr>
                                    </tbody>
                                    </template>
                                </v-simple-table>
                                </template>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="3">Outlet: </v-col>
                        <v-col cols="6" @click="routeToOutlet(saleDetails.outlet.id)" v-if="saleDetails.Outlet">{{ saleDetails.Outlet.name }}</v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="3">Created At: </v-col>
                        <v-col cols="6">{{ saleDetails.created_at | formatDateTime }}</v-col>
                    </v-row>
                    <v-row v-if="saleDetails.voided_at">
                        <v-col cols="3">Voided At: </v-col>
                        <v-col cols="6">{{ saleDetails.voided_at | formatDateTime }}</v-col>
                    </v-row>
                    
                </v-tab-item>
            </v-tabs-items>
          </template>
          <template v-slot:buttons>
            <v-spacer>
            </v-spacer>
            <v-btn
            class="mr-4"
            text
            @click="$router.push({ path: '/listing/sales' })"
          >
            Back to list
          </v-btn>
          <v-btn
            class="mr-4"
            @click="voidSales"
            color="error"
            :disabled = "saleDetails.status === 'voided' || !staffPermission.voidSales"
          >
            Void #{{ route }}
          </v-btn>
          </template>
        </Form>
      </SideMenu>
      <template>
            <v-row justify="center">
                <v-dialog
                v-model="receiptDialog"
                max-width="450"
                @click:outside="closeReceiptDialog"
                >
                <v-card>
                <v-card-text class="d-flex flex-column justify-content-center align-items-center pt-3">
                    <div class="text-right w-100"><v-icon color="grey" @click="openPrintSetting">mdi-cog</v-icon></div>
                    <div class="print-receipt-container">
                    <div id="printMe" :style="{'width': receiptWidth+'px'}" class="d-flex flex-column mx-2">
                        <div class="d-flex flex-column align-items-center w-100 header">
                            <div class="w-25">
                                <img v-if="imageURL" class="w-100" :src="imageURL">
                            </div>
                            <p class="m-0"><b>{{ data.outlet.name }}</b></p>
                            <p class="m-0" v-if="data.outlet.address1">{{ data.outlet.address1 }}</p>
                            <p class="m-0" v-if="data.outlet.address2">{{ data.outlet.address2 }}</p>
                            <p class="m-0" v-if="data.outlet.address3">{{ data.outlet.address3 }}</p>
                            <p class="m-0" v-if="data.outlet.passcode && data.outlet.city  ">{{ data.outlet.passcode }} {{ data.outlet.city }}</p>
                            <p class="m-0" v-if="data.outlet.state">{{ data.outlet.state }}</p>
                            <p class="m-0" v-if="data.outlet.contact">Tel: {{ data.outlet.contact }}</p>
                            <p class="m-0" v-if="data.outlet.email">Email: {{ data.outlet.contact }}</p>
                            <br>
                            <p class="m-0 mb-1" v-if="data.isPrint"><b>COPY / REPRINT</b></p>
                            <p class="m-0 mb-1" v-if="data.invoice"><b>Invoice#{{ data.invoice }}</b></p>
                        </div>
                        <div class="content">
                            <p class="m-0">Date: {{ data.date }}</p>
                            <p class="m-0">Staff: {{ data.staff.name }}</p>
                            <p class="m-0 mb-2">Customer: {{ data.customer.name }}({{ data.customer.contact }})</p>
                            <p class="dashed-line m-0"></p>
                            <table class="pos-table" v-if="data.type == 'package'" >
                                <thead>
                                <tr>
                                    <th class="w-50">Item</th>
                                    <th class="w-25">QTY</th>
                                    <th class="w-25">Price(RM)</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(item, index) in data.items" :key="index" class="td-btm-spacing">
                                    <td>{{ index + 1 }}. {{ item.package_name }}</td>
                                    <td>{{ item.quantity || 1 }}</td>
                                    <td>{{ item.price | formatPrice }}</td>
                                </tr>
                                </tbody>
                            </table>
                            <table class="pos-table" v-if="data.type == 'booking'" >
                                <thead>
                                <tr>
                                    <th class="w-50">Item</th>
                                    <th class="w-25">Price(RM)</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(item, index) in data.items" :key="index" class="td-btm-spacing">
                                    <td>
                                    <span>{{ index + 1 }}. </span>
                                    <span v-if="item.service_name">{{ item.service_name }}</span>
                                    <span v-if="item.resource_type_name">{{ item.resource_type_name }}</span>
                                    <span v-if="item.staffname">({{ item.staffname }})</span>
                                    <br>{{item.booked_date | formatDate}} {{item.from_time}} ({{item.duration}} mins)
                                    </td>
                                    <td>
                                    <span v-if="item.appliedPackage">0.00</span>
                                    <span v-else>{{ item.price | formatPrice }}</span>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <p class="dashed-line m-0"></p>
                        <div class="d-flex align-items-end flex-column">
                        <p class="m-0  price">Subtotal: RM{{ data.subtotal | formatPrice }}</p>
                        <p class="m-0 price" v-for="(t,i) of data.taxList" :key="i"><small>{{ t.name }}({{ t.percentage }}%): RM{{ t.amount | formatPrice }}</small></p>
                        <p class="m-0 price" v-if="data.tax">Tax: RM{{ data.tax | formatPrice }}</p>
                        <p class="m-0 price">Discount: RM{{ data.discount | formatPrice }}</p>
                        <h4 class="m-0">Total: RM{{ data.total | formatPrice }}</h4>
                        </div>
                    </div>
                    </div>
                    <div v-if="printSetting" class="w-75">
                    <p class="m-0">Receipt Size Adjust</p>
                    <v-slider
                        v-model="receiptWidth"
                        class="align-center"
                        :max="400"
                        :min="100"
                        hide-details
                    >
                        <template v-slot:append>
                        <v-text-field
                            v-model="receiptWidth"
                            suffix="px"
                            class="mt-0 pt-0"
                            hide-details
                            single-line
                            type="number"
                            style="width: 60px"
                            min="100"
                            max="400"
                            @change="adjustPrintWidth"
                        ></v-text-field>
                        </template>
                    </v-slider>
                    <v-btn @click="savePrintSetting" color="primary"
                        class="w-100 mt-1">Save Settings</v-btn>
                    <div class="d-flex justify-content-between">
                        <v-btn @click="resetPrintSetting(true)" color="primary"
                        class="w-75 mt-1 mr-1">Reset Changes</v-btn>
                        <v-btn @click="resetPrintSetting(false)" color="error" class="w-25 mt-1 ">
                            <v-icon >mdi-close</v-icon>
                        </v-btn>
                    </div>
                        
                    <hr class="mb-0">
                    </div>
                    <div class="mt-5 w-100 text-center" v-if="!printSetting">
                    <v-btn v-print="printObj" color="primary"
                        class="w-75 mb-2">Print</v-btn>
                    <v-btn
                        color="error"
                        class="w-75"
                        @click="closeReceiptDialog"
                        >
                        Close
                    </v-btn>
                    </div>
                </v-card-text>
                </v-card>
            </v-dialog>
            </v-row>
        </template>
    </v-app>
  </template>
  
<script>
import SideMenu from '/src/components/SideMenu'
import Form from '/src/components/Form'
  import gql from 'graphql-tag'
import * as queries from '@/graphql/gqlQueries.js';
import print from 'vue-print-nb'
export default {
    name: 'CommissionDetails',

    components: {
        SideMenu,
        Form,
    },
    directives: {
        print
    },
    data() {
        return {
            receiptWidth: 250,
            printSetting: false,
            data: {
                isPrint: false,
                id:null,
                date: "",
                invoice:" ",
                outlet: {
                    name: "",
                    address1: "",
                    address2: "",
                    address3: "",
                    passcode: "",
                    city: "",
                    state: "",
                    contact: "",
                    email: ""
                },
                staff:{
                    name:""
                },
                customer:{
                    name:"",
                    contact: "",
                },
                items: [
                    { name: 'Item 1', quantity: 5, price: "0.00" },
                    { name: 'Item 2', quantity: 2, price: "0.00" },
                    { name: 'Item 3', quantity: 3, price: "0.00" },
                ],
                subtotal:"00.00",
                total:"0.00",
                tax:"0.00",
                discount:"0.00",
            },
            printLoading: true,
            printObj: {
                id: "printMe",
                preview: false,
                previewTitle: 'Print Receipt Preview', // The title of the preview window.
                popTitle: 'Receipt',
                extraCss: "https://cdn.bootcdn.net/ajax/libs/animate.css/4.1.1/animate.compat.css, https://cdn.bootcdn.net/ajax/libs/hover.css/2.3.1/css/hover-min.css",
                extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>',
                // previewBeforeOpenCallback(vue) {
                //     console.log('正在加载预览窗口', vue)
                // },
                // previewOpenCallback(vue) {
                //     console.log('已经加载完预览窗口', vue)
                // },
                // beforeOpenCallback(vue) {
                //     vue.printLoading = true
                //     console.log('打开之前')
                // },
                // openCallback: (vue) => {
                //   console.log('执行了打印',vue) 
                // },a
                closeCallback: (vue) => {
                console.log('关闭了打印工具', vue);
                this.updateReceipt(); // Use an arrow function to maintain the context
                }
            },
            receiptDialog: false,
            route: this.$route.params.id,
            alert: this.$route.params.alert,
            alert_type: this.$route.params.alert_type,
            message: this.$route.params.message,
            getAllPermissions: [],
            allPermissionsName: [],
            staffPermission: {},
            getCommissionListById: [],
            getAllSales: [],
            saleDetails: {},
            outlet_id: '',
            outlet_ids: [],
            processed_outlets: [],
            processed_staff: [],
            staff_id: "",
            tab: null,
            getAllPackages: [],
            imageURL: null,
        }
    },
    apollo: {
        getAllPackages:{
            query: queries.GET_ALL_PACKAGES_QUERY,
        },
        sales: {
            query: queries.GET_SALE,
            variables() {
                return {
                   id: this.route
                }
            },
            result() {
                const list = {...this.sales}
                this.saleDetails = list
                this.data = this.saleDetails.receipts[0].data
                this.data.isPrint = this.saleDetails.receipts[0].isPrint
                this.data.id = this.saleDetails.receipts[0].id
                console.log(this.saleDetails,this.data)
            }
        },
        settings: {
        query: queries.GET_SETTINGS_QUERY,
        result (){
            this.websiteName = this.settings.name
          if(this.settings.image_id != 0 && this.settings.image_id != null){
            const GET_IMAGE_QUERY = gql`
              query imageById($id: ID!) {
                  imageById(id:$id)  {
                    id
                    type
                    filename
                  }
              }
            `
            this.$apollo.query(
              { 
                query: GET_IMAGE_QUERY, 
                variables:{
                  id: this.settings.image_id * 1
                },
              }
            ).then(result => {
              if(result.data.imageById != null){ 
                this.imageURL = window.location.hostname === 'localhost'
  ? 'https://placekitten.com/200/200'
  : 'https://prod-app360.sfo3.digitaloceanspaces.com/' + result.data.imageById.filename;

              } else {
                console.log("Image not found")
              }
            });
          }
        }
      },
    },
    methods: {
        routeToOutlet(value){
            if(!this.staffPermission.viewOutlet) return
            this.$router.push({ path: '/outlet/' + value })
        },
        routeToBooking(value){
            if(!this.staffPermission.viewBooking) return
            this.$router.push({ path: '/booking/' + value })
        },
        routeToPackage(value){
            if(!this.staffPermission.viewPackage) return
            this.$router.push({ path: '/package/' + value })
        },
        routeToStaff(value){
            if(!this.staffPermission.viewStaff) return
            this.$router.push({ path: '/staff/' + value })
        },
        routeToCustomer(value){
            if(!this.staffPermission.viewCustomer) return
            this.$router.push({ path: '/user/' + value})
        },
        updateSalesList() {
            if (this.getAllPackages && this.getAllPackages.length > 0 && this.saleDetails ) {
                    if (this.saleDetails.package_id) {
                        this.saleDetails.package_details = [];
                        this.saleDetails.package_list = []
                        this.saleDetails.package_id.forEach((pId) => {
                            const foundPackage = this.getAllPackages.find((p) => p.id == pId);
                            if (foundPackage) {
                                this.saleDetails.package_details.push({
                                    id: foundPackage.id,
                                    name: foundPackage.package_name,
                                });
                                this.saleDetails.package_list.push(foundPackage.package_name)
                            }
                        });
                    }
            }
        },
        voidSales() {
            const VOID_SALES = gql`
            mutation voidSales($id:ID!){
                voidSales(id:$id)  {
                    id
                    voided_at
                }
            }
            `
            this.$apollo.mutate(
                {
                    mutation: VOID_SALES,
                    variables: {
                        id: this.route * 1,
                    },
                }
            ).then(result=>{
                console.log("voided",result)
                this.$router.push({name: 'Sales Listing', params:{alert:true, alert_type:'success', message:"sales #"+this.route+' voided successfully'}})
            }).catch(error=>{
                console.log(error)
            })
        },
        // deleteSales() {
        //     const DELETE_SALES = gql`
        //     mutation deleteSales($id:ID!){
        //         deleteSales(id:$id)  {
        //             id
        //             deleted_at
        //         }
        //     }
        //     `
        //     this.$apollo.mutate(
        //         {
        //             mutation: DELETE_SALES,
        //             variables: {
        //                 id: this.route * 1,
        //             },
        //         }
        //     ).then(result=>{
        //         console.log("delete",result)
        //     }).catch(error=>{
        //         console.log(error)
        //     })
        // },
        async downloadInvoice(id) {
            try {
                const result = await this.$apollo.query({
                    query: queries.GET_SALE,
                    variables: {
                        id: id * 1,
                    }
                })
                window.open("https://prod-app360.sfo3.digitaloceanspaces.com/" + result.data.sales.pdfFile.filename + ".pdf", '_blank');
            } catch (error) {
                console.log(error);
                console.log(JSON.stringify(error, null, 2));
            }
        },
        openReceipt(){
        this.receiptDialog = true
        },
        closeReceiptDialog(){
            this.receiptDialog = false
        },
        setTime() { // get current time(not using now)
            const now = new Date();
            const options = {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                hour12: false,
                timeZone: 'UTC',
            };
            const formattedTime = now.toLocaleString('en-GB', options).replace(/[/]/g, '-').replace(","," ");
            this.data.date = formattedTime;
        },
        updateReceipt(){
          console.log(this.data)
          if(!this.data?.id) return
          this.$apollo.mutate({
            mutation: queries.UPDATE_RECEIPT_IS_PRINT_MUTATION,
            variables:{
              id : this.data.id * 1
            }
          }).then((result)=>{
            console.log("result,",result)
            this.data.isPrint = true;
          }).catch(error => {
            console.log("error",error)
            console.log(JSON.stringify(error, null, 2));
          })
          
        },
        openPrintSetting(){
          this.receiptWidth = this.$session.get("receiptWidth") * 1
          this.printSetting = !this.printSetting
        },
        async savePrintSetting(){
          await this.$session.set('receiptWidth', this.receiptWidth*1)
          this.receiptWidth = this.$session.get("receiptWidth") * 1
          this.printSetting = false
        },
        resetPrintSetting(open){
          this.receiptWidth = this.$session.get("receiptWidth") * 1
          this.printSetting = open
        },
        adjustPrintWidth(){
          if(isNaN(parseFloat(this.receiptWidth))){
            this.receiptWidth = 250
          }else if(this.receiptWidth * 1 < 100){
            this.receiptWidth = 100
          }else if(this.receiptWidth * 1 > 400){
            this.receiptWidth = 400
          }
        }
    },
    filters: {
    formatDateTime(value) {
    const dateTime = new Date(value * 1);

    const year = dateTime.getFullYear();
    const month = dateTime.toLocaleString('en-US', { month: 'short' });
    const day = dateTime.getDate().toString().padStart(2, '0');

    let hours = dateTime.getHours();
    const minutes = dateTime.getMinutes().toString().padStart(2, '0');
    const period = hours >= 12 ? 'PM' : 'AM';

    // Convert hours to 12-hour format
    if (hours > 12) {
      hours -= 12;
    } else if (hours === 0) {
      hours = 12;
    }

    return `${day} ${month} ${year} ${hours}:${minutes}${period}`;
  },

        formatDate(value) {
            const date = new Date(value * 1);
            // Customize the date formatting based on your requirements
            const formattedDate = date.toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                timeZone: "Asia/Singapore",
            });
            return formattedDate;
        },
        formatTime(value) {
            const time = new Date(value * 1);
            // Customize the time formatting based on your requirements
            const formattedTime = time.toLocaleTimeString('en-US', {
                hour12: false,
                hour: 'numeric',
                minute: 'numeric',
                timeZone: "Asia/Singapore",
            });
            return formattedTime;
        },
        formatPrice(value) {
            return Number(value).toFixed(2);
        },
    },
    watch: {
        getAllPackages : {
            handler() {
                this.updateSalesList();
            },
            deep: true,
        },
        sales: {
            handler() {
                this.updateSalesList();
            },
            deep: true,
        },
    },
    created(){
    this.$apollo.query({ query: queries.GET_ALL_PERMISSIONS_QUERY }).then(async result => {
      this.getAllPermissions = await result.data.getAllPermissions;
      this.$apollo.query(
        {
          query: queries.GET_STAFF_ROLES,
          variables: {
            staffId: this.$session.get("user_id") * 1
          }
        }
      ).then(async result => {
        this.getStaffRoles = await result.data.getStaffRoles;
        this.allPermissionsName = [];
        this.getStaffRoles.forEach(role => {
          role.role.RolePermission.forEach((item) => {
            const name = item.permission.name;
            this.allPermissionsName.push(name);
          });
        })
        this.$session.set('staffPermission', this.$generalFunctions.generatePermissionArray(this.getAllPermissions, this.allPermissionsName));
        this.staffPermission = await this.$generalFunctions.generatePermissionArray(this.getAllPermissions, this.allPermissionsName)
      })
    })
  },
  mounted() {
    if(this.$session.get("receiptWidth") * 1){
        this.receiptWidth = this.$session.get("receiptWidth") * 1
    }
},
}
</script>

<style>
.table-border {
  border: 1px solid #e0e0e0;
  border-collapse: collapse;
}

</style>