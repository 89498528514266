<template>
    <v-app>
        <SideMenu submenuPage="Roles">
            <Form>
                <template v-slot:tabs>
                    <v-tabs v-model="tab">
                        <v-tabs-slider></v-tabs-slider>
                        <v-tab href="#details">Details</v-tab>
                    </v-tabs>
                </template>
                <template v-slot:form>
                    <v-tabs-items v-model="tab">
                        <v-tab-item value="details">
                            <v-form ref="form" lazy-validation>

                                <v-text-field v-model="roles.name" :counter="191" label="Name" required
                                    :rules="nameRules.concat(characterRules)"></v-text-field>

                                <p>Description</p>
                                <v-textarea rows="3" v-model="roles.description" :counter="191" outlined
                                    :rules="descRules"></v-textarea>

                                <p>Permissions</p>
                                <v-checkbox v-model="selectAll" label="Select All Permissions"
                                    @change="selectAllPermissions"></v-checkbox>
                                <v-card class="m-2" outlined v-for="(cat, index) of permissionsByCategory" :key="index">
                                    <v-list-group>
                                        <template v-slot:activator>
                                            <v-list-item-content>
                                                <v-list-item-title class="text-uppercase" style="color:black">{{
                                                    cat.category }} permissions</v-list-item-title>
                                            </v-list-item-content>
                                        </template>
                                        <div class="padding-30">
                                            <v-list-item>
                                                <v-container fluid>
                                                    <v-checkbox v-model="roles.permissions" v-for="p of cat.permissions"
                                                        :key="p.id" :label="p.description" :value="p.id"></v-checkbox>
                                                </v-container>
                                            </v-list-item>
                                        </div>
                                    </v-list-group>
                                </v-card>
                                <v-checkbox v-model="selectAll" label="Select All Permissions"
                                    @change="selectAllPermissions"></v-checkbox>
                            </v-form>
                        </v-tab-item>
                    </v-tabs-items>
                </template>
                <template v-slot:buttons>
                    <v-btn class="mr-4" text @click="$router.push({ path: '/roles' })">
                        Back
                    </v-btn>
                    <v-btn color="success" class="mr-4" @click="validate">
                        Create
                    </v-btn>
                </template>
            </Form>
        </SideMenu>
    </v-app>
</template>
<script>
import SideMenu from '/src/components/SideMenu'
import gql from 'graphql-tag'
import Form from '/src/components/Form'
const GET_ALL_PERMISSIONS_QUERY = gql`
        query getAllPermissions {
            getAllPermissions {
            id
            name
            description
            category {
                name
            }
          }
        }
    `
export default {
    name: "Role",

    components: {
        SideMenu,
        Form
    },

    data() {

        return {
            getAllPermissions: [],
            roles: {
                name: '',
                description: '',
                permissions: [],
            },
            permissionsByCategory: [],
            selectAll: false,
            tab: 'details',
            nameRules: [
                v => !!v || 'Name is required',
            ],
            characterRules: [
                v => (v.length <= 191) || 'Field must be less than or equal to 191 characters',
            ],
            descRules: [
                v => (v.length <= 191) || 'Field must be less than or equal to 500 characters',
            ],
        }
    },
    apollo: {
        getAllPermissions: {
            query: GET_ALL_PERMISSIONS_QUERY,
            pollInterval: 300,
            fetchPolicy: 'network-only',
            result() {
                console.log(this.getAllPermissions)

                this.getAllPermissions.forEach(permission => {
                    const category = permission.category.name;

                    // Find the index of the category in permissionsByCategory
                    const categoryIndex = this.permissionsByCategory.findIndex(item => item.category === category);

                    // If the category doesn't exist in permissionsByCategory, create an object for it
                    if (categoryIndex === -1) {
                        this.permissionsByCategory.push({
                            category: category,
                            permissions: [permission]
                        });
                    } else {
                        // Add the permission to the existing category object
                        this.permissionsByCategory[categoryIndex].permissions.push(permission);
                    }
                });

                console.log("this.permissionsByCategory", this.permissionsByCategory)
            }
        }
    },
    methods: {
        validate() {
            var validate = this.$refs.form.validate();
            if (!validate) {
                window.scrollTo(0, 0);
                return
            }
            if (validate) {
                const CREATE_ROLE_MUTATION = gql`
            mutation CreateRole($name: String!, $description: String!) {
                createRole(name: $name, description: $description) {
                id
                name
                description
                }
            }
            `;
                this.$apollo.mutate({
                    mutation: CREATE_ROLE_MUTATION,
                    variables: {
                        name: this.roles.name,
                        description: this.roles.description,
                    },
                }).catch(error => {
                    console.log(JSON.stringify(error, null, 2));
                }).then(async result => {
                    console.log(result.data.createRole)
                    const roleId = result.data.createRole.id;
                    const CREATE_ROLE_PERMISSION_MUTATION = gql`
                    mutation CreateRolePermission($permissionId: Int!, $roleId: Int!) {
                        createRolePermission(permissionId: $permissionId, roleId: $roleId) {
                        permissionId
                        roleId
                        }
                    }
                `;
                    const mutationPromises = [];
                    this.roles.permissions.forEach(p => {
                        const mutationPromise = this.$apollo.mutate({
                            mutation: CREATE_ROLE_PERMISSION_MUTATION,
                            variables: {
                                permissionId: parseInt(p),
                                roleId: parseInt(roleId),
                            },
                        }).catch(error => {
                            console.log(JSON.stringify(error, null, 2));
                        })
                        mutationPromises.push(mutationPromise);
                    })

                    await Promise.all(mutationPromises);
                    this.$router.push({name: 'Roles', params:{alert:true, alert_type:'success', message:this.roles.name +' created successfully'}})
                })
            }
        },
        selectAllPermissions() {
            const selectedPermissions = this.selectAll ? this.getAllPermissions.map(p => p.id) : []
            this.roles.permissions = selectedPermissions
        },
    },
    watch: {
        "roles.permissions"(value) {
            if (value.length == this.getAllPermissions.length) {
                this.selectAll = true
            } else {
                this.selectAll = false
            }
        }
    }
}
</script>