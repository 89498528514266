<template>
    <v-app>
      <SideMenu :alert="alert" :alert_type="alert_type" :message="message"  submenuPage="Commission Listing"
            submenuGroup="Listing">
        <Form>
          <template v-slot:tabs>
            <v-tabs
            v-model="tab"
          >
            <v-tabs-slider></v-tabs-slider>
            <v-tab href="#details">Details</v-tab>
          </v-tabs>
          </template>
          <template v-slot:form>
            <v-tabs-items v-model="tab">
                <v-tab-item value="details">
                    <v-row>
                        <v-col cols="3">Sales id: </v-col>
                        <v-col cols="6">{{ getCommissionListById.sales_id }}</v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="3">Sales Amount: </v-col>
                        <v-col cols="6">RM{{ getCommissionListById.amount | formatPrice }}</v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="3">Commission: </v-col>
                        <v-col cols="6">RM{{ getCommissionListById.cAmount | formatPrice }}</v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="3">Staff: </v-col>
                        <v-col cols="6" @click="routeToStaff(getCommissionListById.staff_id)" v-if="getCommissionListById.staff">{{ getCommissionListById.staff.fullname}}</v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="3">Booking id: </v-col>
                        <v-col cols="6">{{ getCommissionListById.id }}</v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="3">Service: </v-col>
                        <v-col cols="6" @click="routeToService(getCommissionListById.service_id)">{{ getCommissionListById.service_name }}</v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="3">Booked Date: </v-col>
                        <v-col cols="6">{{ getCommissionListById.booked_date | formatDate }}</v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="3">Outlet: </v-col>
                        <v-col cols="6" @click="routeToOutlet(getCommissionListById.outlet.id)" v-if="getCommissionListById.outlet">{{ getCommissionListById.outlet.name }}</v-col>
                    </v-row>
                </v-tab-item>
            </v-tabs-items>
          </template>
          <template v-slot:buttons>
            <v-spacer>
            </v-spacer>
            <v-btn
            class="mr-4"
            text
            @click="$router.push({ path: '/listing/commission' })"
          >
            Back to list
          </v-btn>
          </template>
        </Form>
      </SideMenu>
    </v-app>
  </template>
  
<script>
import SideMenu from '/src/components/SideMenu'
import Form from '/src/components/Form'
//   import gql from 'graphql-tag'
import * as queries from '@/graphql/gqlQueries.js';
export default {
    name: 'CommissionDetails',

    components: {
        SideMenu,
        Form,
    },

    data() {
        return {
            route: this.$route.params.id,
            alert: this.$route.params.alert,
            alert_type: this.$route.params.alert_type,
            message: this.$route.params.message,
            getAllPermissions: [],
            allPermissionsName: [],
            staffPermission: {},
            getCommissionListById: [],
            getAllSales: [],
            commissionList: [],
            salesList: [],
            outlet_id: '',
            outlet_ids: [],
            processed_outlets: [],
            processed_staff: [],
            staff_id: "",
            tab: null,
        }
    },
    apollo: {
        getCommissionListById: {
            query: queries.GET_COMMISSION_BY_ID,
            variables() {
                return {
                   id: this.route
                }
            },
            result() {
                this.getCommissionListById.cAmount = this.getCommissionListById.customCommission !== null ? this.getCommissionListById.customCommission : this.getCommissionListById.commission ? this.getCommissionListById.commission.type == 'fixed' ? this.getCommissionListById.commission.commission : this.getCommissionListById.amount * this.getCommissionListById.commission.commission / 100 : 0
            }
        },
        getAllSales: {
            query: queries.GET_ALL_SALES,
            result() {
                const list = [...this.getAllSales]
                this.salesList = list
                this.addSalesId()
            }
        },
    },
    methods: {
        addSalesId() {
            if (this.getCommissionListById && this.salesList.length > 0) {
                this.salesList.forEach(s => {
                    if (s.booking_id) {
                        s.booking_id.forEach(id => {
                            if (this.getCommissionListById.id == id){
                                this.getCommissionListById.sales_id = s.id.toString();
                            }
                        });
                    }
                });
            }
        },
        routeToOutlet(value){
            if(!this.staffPermission.viewOutlet) return
            this.$router.push({ path: '/outlet/' + value })
        },
        routeToService(value){
            if(!this.staffPermission.viewService) return
            this.$router.push({ path: '/service/' + value })
        },
        routeToStaff(value){
            if(!this.staffPermission.viewStaff) return
            this.$router.push({ path: '/staff/' + value })
        },
        updateCommissionList() {
        if (
            this.getCommissionListById &&
            this.getAllSales &&
            this.getAllSales.length > 0
        ) {
            const list = [...this.getAllSales];
            this.salesList = list;
            this.addSalesId();
        }
        },
    },
    filters: {formatDate(value) {
    const date = new Date(value * 1);

    const year = date.getFullYear();
    const month = date.toLocaleString("en-US", { month: "short" });
    const day = date.getDate().toString().padStart(2, '0');

    return `${day} ${month} ${year}`;
  },

  formatTime(value) {
    const time = new Date(value * 1);

    let hours = time.getHours().toString().padStart(2, '0');
    const minutes = time.getMinutes().toString().padStart(2, '0');
    const period = hours >= 12 ? 'PM' : 'AM';

    // Convert hours to 12-hour format
    if (hours > 12) {
      hours -= 12;
    } else if (hours === 0) {
      hours = 12;
    }

    return `${hours}:${minutes} ${period}`;
  },
        formatPrice(value) {
            return Number(value).toFixed(2);
        }
    },
    watch: {
        getCommissionListById: {
        handler() {
            this.updateCommissionList();
        },
        deep: true,
        },
        getAllSales: {
        handler() {
            this.updateCommissionList();
        },
        deep: true,
        },
  },
  created(){
    this.$apollo.query({ query: queries.GET_ALL_PERMISSIONS_QUERY }).then(async result => {
      this.getAllPermissions = await result.data.getAllPermissions;
      this.$apollo.query(
        {
          query: queries.GET_STAFF_ROLES,
          variables: {
            staffId: this.$session.get("user_id") * 1
          }
        }
      ).then(async result => {
        this.getStaffRoles = await result.data.getStaffRoles;
        this.allPermissionsName = [];
        this.getStaffRoles.forEach(role => {
          role.role.RolePermission.forEach((item) => {
            const name = item.permission.name;
            this.allPermissionsName.push(name);
          });
        })
        this.$session.set('staffPermission', this.$generalFunctions.generatePermissionArray(this.getAllPermissions, this.allPermissionsName));
        this.staffPermission = await this.$generalFunctions.generatePermissionArray(this.getAllPermissions, this.allPermissionsName)
      })
    })
  }
}
</script>