<template>
  <v-app>
    <SideMenu submenuPage="Resources" submenuGroup="Service Setup">
      <Form>
        <template v-slot:tabs>

        </template>
        <template v-slot:form>
          <v-form
            ref="form"
            lazy-validation
          >
            <v-text-field
              label="Resource"
              :value="resourceById.name"
              disabled
            ></v-text-field>

            <v-text-field
              label="Date"
              :value="$route.query.date"
              disabled
              v-if="$route.query.date"
            ></v-text-field>
            <v-text-field
              label="Day"
              :value="dates[$route.query.day*1]"
              disabled
              v-else
            ></v-text-field>

            <v-text-field
              label="Outlet"
              :value="resourceById.outlet.name"
              disabled
            ></v-text-field>

            <div v-if="!resourcescheduleById.off_day">
              <v-menu
                ref="from_time_menu"
                v-model="from_time_menu"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="resourcescheduleById.from_time"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="resourcescheduleById.from_time"
                    label="From Time"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-model="resourcescheduleById.from_time"
                  :allowed-minutes="allowedStep"
                  class="mt-4"
                  format="24hr"
                  @click:minute="$refs.from_time_menu.save(resourcescheduleById.from_time)"
                ></v-time-picker>
              </v-menu>

              <v-menu
                ref="to_time_menu"
                v-model="to_time_menu"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="resourcescheduleById.to_time"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="resourcescheduleById.to_time"
                    label="To Time"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-model="resourcescheduleById.to_time"
                  :allowed-minutes="allowedStep"
                  class="mt-4"
                  format="24hr"
                  @click:minute="$refs.to_time_menu.save(resourcescheduleById.to_time)"
                ></v-time-picker>
              </v-menu>

              <v-menu
                ref="break_from_time_menu"
                v-model="break_from_time_menu"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="resourcescheduleById.break_from_time"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="resourcescheduleById.break_from_time"
                    label="Break From Time"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-model="resourcescheduleById.break_from_time"
                  :allowed-minutes="allowedStep"
                  class="mt-4"
                  format="24hr"
                  @click:minute="$refs.break_from_time_menu.save(resourcescheduleById.break_from_time)"
                ></v-time-picker>
              </v-menu>

              <v-menu
                ref="break_to_time_menu"
                v-model="break_to_time_menu"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="resourcescheduleById.break_to_time"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="resourcescheduleById.break_to_time"
                    label="Break To Time"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-model="resourcescheduleById.break_to_time"
                  :allowed-minutes="allowedStep"
                  class="mt-4"
                  format="24hr"
                  @click:minute="$refs.break_to_time_menu.save(resourcescheduleById.break_to_time)"
                ></v-time-picker>
              </v-menu>
            </div>

            <v-checkbox
              v-model="resourcescheduleById.off_day"
              label="Off Day"
            ></v-checkbox>
          </v-form>
        </template>
        <template v-slot:buttons>
          <v-btn
            class="mr-4"
            text
            @click="$router.push({ path: '/resources', query:{'tab':'schedule'} })"
          >
            Back to list
          </v-btn>
          <v-btn
            color="success"
            class="mr-4"
            @click="validate"
          >
            Create
          </v-btn>
        </template>
      </Form>
      
    </SideMenu>
  </v-app>
</template>

<script>
import SideMenu from '/src/components/SideMenu'
import Form from '/src/components/Form'
import gql from 'graphql-tag'
  const GET_RESOURCES_QUERY = gql`
      query resourceById($id: ID!) {
        resourceById(id: $id) {
            name
            outlet{
              name
            }
            outlet_id
        }
      }
  `
  export default {
    name: 'Resource',

    components:{
      SideMenu,
      Form
    },

    data () {
      
      return {
        resourceById:{
          name:'',
          outlet:{
            name:''
          }
        },
        dates: {1:"Monday",2:"Tuesday",3:"Wednesday",4:"Thursday",5:"Friday",6:"Saturday",7:"Sunday"},
        resourcescheduleById:{
          off_day:false,
          from_time:'',
          to_time:'',
          break_from_time:'',
          break_to_time:'',
          outlet_id:''
        },
        from_time_menu:false,
        to_time_menu:false,
        break_from_time_menu:false,
        break_to_time_menu:false
        
      }
    },
    apollo: {
      resourceById: {
        query: GET_RESOURCES_QUERY,
        variables() {
          return{
            id: this.$route.query.resource_id*1
          }
        },
      }
    },
    methods: {
      validate () {
        var validate = this.$refs.form.validate()
        if(!validate){
          window.scrollTo(0,0);
        }
        if(validate){
          const CREATE_SCHEDULE_QUERY = gql`
            mutation createResourceSchedule($resource_id:Int!, $day:Int, $date:String, $off_day:Boolean, $from_time:String!, $to_time:String!, $break_to_time:String, $break_from_time:String, $outlet_id:Int!){
              createResourceSchedule(resource_id:$resource_id, day:$day, date:$date, off_day:$off_day, from_time:$from_time, to_time:$to_time, break_to_time:$break_to_time, break_from_time:$break_from_time, outlet_id:$outlet_id){
                date
                day
                from_time
                to_time
              }
            }
          `
          this.$apollo.mutate(
            { 
              mutation: CREATE_SCHEDULE_QUERY, 
              variables:{
                resource_id:this.$route.query.resource_id*1,
                day:this.$route.query.day*1,
                date:isNaN(new Date(this.$route.query.date)) ? null : this.$route.query.date,
                off_day:this.resourcescheduleById.off_day,
                from_time:this.resourcescheduleById.from_time,
                to_time:this.resourcescheduleById.to_time,
                break_from_time:this.resourcescheduleById.break_from_time,
                break_to_time:this.resourcescheduleById.break_to_time,
                outlet_id:this.resourceById.outlet_id*1
              },
            }
          )
          .catch(error => {
            console.log(error)
          })
          .then(result => {
            if(result && result.data.createResourceSchedule != null){ 
              console.log("Create successfully")
              this.$router.push({name: 'Resource', params:{alert:true, alert_type:'success', message:'Resource schedule created successfully'}, query:{'tab':'schedule'}})
            } else {
              console.log("Create failed")
            }
          });
        }
      },
      allowedStep: m => m % 5 === 0,
    }
  }
</script>
