<template>
  <v-app>
    <SideMenu :alert="alert" :alert_type="alert_type" :message="message" submenuPage="Customer">
      <List>
        <template v-slot:action-button>
          <v-btn color="primary" class="mr-4" @click="UserCreateForm" v-if="staffPermission.createCustomer">
            + New User
          </v-btn>
        </template>
        <template v-slot:tab-items>
          <template>
            <div style="display: flex; align-content: space-between;">
              <v-select v-model="selectedSearchType" label="Search Field" :items="searchType" variant="solo" color="primary" class="seacrhField"></v-select>
              <v-text-field v-model="searchQuery" label="Search Input" clearable variant="outlined" @input="handleSearch"></v-text-field>
            </div>
          </template>
          <v-data-table :headers="headers" :items="filteredUsers" class="elevation-1" item-key="id"  :sort-by="['id']" :sort-desc="[true]" >
            <template v-slot:item="{ item }">
              <tr @click="userUpdateForm(item.id)" style="cursor:pointer">
                <td>{{ item.user_fullname }}</td>
                <td>{{ item.user_contact }}</td>
                <td>{{ item.created_at }}</td>
                <td>{{ item.deleted_at }}</td>
              </tr>
            </template>
          </v-data-table>
        </template>
      </List>
    </SideMenu>
  </v-app>
</template>

<script>
import SideMenu from '/src/components/SideMenu'
import List from '/src/components/List'
import gql from 'graphql-tag'
import * as queries from '@/graphql/gqlQueries.js';

const GET_ALL_USER_QUERY = gql`
  query Users {
  users {
    id
    user_contact
    user_fullname
    created_at
    deleted_at
  }
}
    `
export default {
  name: 'Customer',

  components: {
    SideMenu,
    List
  },

  data() {

    return {
      searchQuery: '',
      searchType: [
        'Fullname', 'Phone Number'
      ],
      selectedSearchType:"Fullname",
      filteredUsers: [],
      alert: this.$route.params.alert,
      alert_type: this.$route.params.alert_type,
      message: this.$route.params.message,

      headers: [
        {
          text: 'Name',
          value: 'user_fullname',
          class: 'white--text'
        },
        {
          text: 'Phone number',
          value: 'user_contact',
          class: 'white--text'
        },
        {
          text: 'Created At',
          value: 'created_at',
          class: 'white--text'
        },
        {
          text: 'Deleted At',
          value: 'deleted_at',
          class: 'white--text'
        }
      ],
      getAllPermissions: [],
      allPermissionsName: [],
      staffPermission: {},
    }
  },
apollo: {
  users: {
    query: GET_ALL_USER_QUERY,
    pollInterval: 300,
    async result() {
      const sortProperties = [
                    { property: 'id', direction: 'desc' },
                    { property: 'created_at', direction: 'desc' },
                ];

      // Use the multiPropertySort function to sort the commissionList
      this.users = await this.$generalFunctions.multiPropertySort(this.users, sortProperties);
      this.users.forEach((user) => {
        const createdAt = new Date(user.created_at * 1);
        if (!isNaN(createdAt.getTime())) {
          user.created_at = this.$options.filters.formatDateTime(createdAt); // Use the filter here
        }
        // if (user.deleted_at) {
        //   const deletedAT = new Date(user.deleted_at * 1);
        //   if (!isNaN(deletedAT.getTime())) {
        //     user.deleted_at = this.$options.filters.formatDateTime(deletedAT); // Use the filter here
        //   }
        // }
      });
        this.filteredUsers = this.users.filter(user => !user.deleted_at)
      }
    },
  },
  methods: {
    UserCreateForm() {
      this.$router.push({ path: '/create/user' })
    },
    userUpdateForm(id) {
      this.$router.push({ path: '/user/' + id })
    },
    handleSearch() {
      if (this.searchQuery) {
        this.filteredUsers = this.users.filter((user) => {
          if (this.selectedSearchType === 'Fullname') {
            return user.user_fullname.toLowerCase().includes(this.searchQuery.toLowerCase());
          } else if (this.selectedSearchType === 'Phone Number') {
            return user.user_contact.toLowerCase().includes(this.searchQuery.toLowerCase());
          }
          return false;
        });
      } else {
        this.filteredUsers = this.users;
      }
    }
  },
  filters: {
  formatDateTime(value) {
    const dateTime = new Date(value * 1);

    const year = dateTime.getFullYear();
    const month = dateTime.toLocaleString('en-US', { month: 'short' });
    const day = dateTime.getDate().toString().padStart(2, '0');

    let hours = dateTime.getHours();
    const minutes = dateTime.getMinutes().toString().padStart(2, '0');
    const period = hours >= 12 ? 'PM' : 'AM';

    // Convert hours to 12-hour format
    if (hours > 12) {
      hours -= 12;
    } else if (hours === 0) {
      hours = 12;
    }

    return `${day} ${month} ${year} ${hours}:${minutes}${period}`;
  },
  },
  created(){
    this.$apollo.query({ query: queries.GET_ALL_PERMISSIONS_QUERY }).then(async result => {
      this.getAllPermissions = await result.data.getAllPermissions;
      this.$apollo.query(
        {
          query: queries.GET_STAFF_ROLES,
          variables: {
            staffId: this.$session.get("user_id") * 1
          }
        }
      ).then(async result => {
        this.getStaffRoles = await result.data.getStaffRoles;
        this.allPermissionsName = [];
        this.getStaffRoles.forEach(role => {
          role.role.RolePermission.forEach((item) => {
            const name = item.permission.name;
            this.allPermissionsName.push(name);
          });
        })
        this.$session.set('staffPermission', this.$generalFunctions.generatePermissionArray(this.getAllPermissions, this.allPermissionsName));
        this.staffPermission = await this.$generalFunctions.generatePermissionArray(this.getAllPermissions, this.allPermissionsName)
      })
    })
  }

}
</script>

<style>

.seacrhField{
  padding: 10px 10px 10px 0px;
  width: 0;
}

</style>
