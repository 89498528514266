<template>
    <v-app>
        <SideMenu :alert="alert" :alert_type="alert_type" :message="message" submenuPage="Commission Listing"
            submenuGroup="Listing">
            <List>
                <template v-slot:action-button>
                    <v-btn color="primary" class="mr-4" @click="exportCSV">
                        Export (csv)
                    </v-btn>
                </template>
                <template v-slot:tabs>
                    <v-row>
                        <v-col class="py-1" cols="6">
                            <v-select v-model="outlet_id" :items="processed_outlets" item-value="id" item-text="name" dense
                                outlined hide-details label="Outlet" class="ma-2"></v-select>
                        </v-col>
                        <v-col class="py-1" cols="6"
                            v-if="staffPermission.viewAllOutletsListing || staffPermission.viewOutletsListing">
                            <v-select v-model="staff_id" :items="processed_staff" item-value="id" item-text="fullname" dense
                                outlined hide-details label="Staff" class="ma-2"></v-select>
                        </v-col>
                        <v-col class="py-1" cols="3">
                            <v-checkbox v-model="date_range" label="Date Range" hide-details></v-checkbox>
                        </v-col>
                        <v-col class="py-1" cols="6" v-if="date_range">
                            <v-row>
                                <v-col class="py-1" cols="6">
                                    <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40"
                                        transition="scale-transition" offset-y min-width="auto">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field v-model="startDate" label="From" prepend-icon="mdi-calendar"
                                                readonly v-bind="attrs" v-on="on"></v-text-field>
                                        </template>
                                        <v-date-picker v-model="startDate" @input="menu2 = false"></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col class="py-1" cols="6">
                                    <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40"
                                        transition="scale-transition" offset-y min-width="auto">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field v-model="endDate" label="To" prepend-icon="mdi-calendar" readonly
                                                v-bind="attrs" v-on="on"></v-text-field>
                                        </template>
                                        <v-date-picker v-model="endDate" @input="menu2 = false"></v-date-picker>
                                    </v-menu>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="3">
                            <v-btn color="primary" class="mr-4 w-100" @click="search">
                                search
                            </v-btn>
                        </v-col>
                    </v-row>

                </template>
                <template v-slot:tab-items>
                    <v-data-table :headers="headers" :items="commissionList" class="elevation-1" item-key="id">
                        <template v-slot:item="{ item }">
                            <tr style="cursor:pointer" @click="routeToDetails(item.id)">
                                <td>{{ item.outlet.name }}</td>
                                <td>{{ item.staff.fullname }}</td>
                                <td>{{ item.user.user_fullname }} <br> {{ item.user.user_contact }}</td>
                                <td>{{ item.booked_date | formatDate }}</td>
                                <td>{{ item.id }}</td>
                                <td>{{ item.sale_id }}</td>
                                <td>{{ item.amount.toFixed(2) }}</td>
                                <td>{{ item.cAmount.toFixed(2) }}</td>
                            </tr>
                        </template>
                    </v-data-table>
                </template>
            </List>
        </SideMenu>
    </v-app>
</template>
  
<style>
.dot {
    height: 10px;
    width: 10px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
}
</style>
  
<script>
import SideMenu from '/src/components/SideMenu'
import List from '/src/components/List'
//   import gql from 'graphql-tag'
import * as queries from '@/graphql/gqlQueries.js';
export default {
    name: 'Listing',

    components: {
        SideMenu,
        List,
    },

    data() {
        return {
            alert: this.$route.params.alert,
            alert_type: this.$route.params.alert_type,
            message: this.$route.params.message,

            headers: [
                {
                    text: 'Outlet',
                    value: 'outlet.name',
                    class: 'white--text',
                },
                {
                    text: 'Staff Name',
                    value: 'staff.fullname',
                    class: 'white--text',
                },
                {
                    text: 'Customer (Name, Contact)',
                    value: 'user.user_fullname',
                    class: 'white--text',
                },
                {
                    text: 'Datetime',
                    value: 'booked_date',
                    class: 'white--text',
                },
                {
                    text: 'Booking ID',
                    value: 'bookingID',
                    class: 'white--text',
                },
                {
                    text: 'Sales ID',
                    value: 'sale_id',
                    class: 'white--text',
                },
                {
                    text: 'Amount (RM)',
                    value: 'amount',
                    class: 'white--text',
                },
                {
                    text: 'Commission Amount',
                    value: 'cAmount',
                    class: 'white--text',
                },
            ],

            getAllPermissions: [],
            allPermissionsName: [],
            staffPermission: {},
            getCommissionList: [],
            getAllSales: [],
            commissionList: [],
            salesList: [],
            outlet_id: '',
            outlet_ids: [],
            processed_outlets: [],
            processed_staff: [],
            staff_id: "",
            endDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            startDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            menu: false,
            menu2: false,
            date_range: false,
        }
    },
    apollo: {
        getCommissionList: {
            query: queries.GET_ALL_COMMISSION,
            variables() {
                return {
                    staff_id: this.staffPermission.viewOwnListing && !this.staffPermission.viewOutletsListing && !this.staffPermission.viewAllOutletsListing ? this.$session.get("user_id") * 1 : null,
                    outlet_id: !this.staffPermission.viewAllOutletsListing ? this.$session.get('outlets')[0] * 1 : null
                }

            },
            async result() {
                const list = [...this.getCommissionList]
                this.commissionList = list.filter(e => e.staff_id && e.user_id)
                this.commissionList.forEach(c => {
                    c.cAmount = c.customCommission !== null ? c.customCommission : c.commission ? c.commission.type == 'fixed' ? c.commission.commission : c.amount * c.commission.commission / 100 : 0
                })
                 // Define the sorting criteria
                 const sortProperties = [
                    { property: 'id', direction: 'desc' },
                    { property: 'created_at', direction: 'desc' },
                ];

                // Use the multiPropertySort function to sort the commissionList
                this.commissionList = await this.$generalFunctions.multiPropertySort(this.commissionList, sortProperties);
            }
        },
        getAllSales: {
            query: queries.GET_ALL_SALES,
            result() {
                const list = [...this.getAllSales]
                this.salesList = list
                this.addSalesId()
            }
        },

        outlets: {
            query: queries.GET_ALL_OUTLET_QUERY,
            fetchPolicy: 'network-only',
            result() {
                this.outlet_ids = this.$session.get('outlets') ?? []
                if (!this.staffPermission.viewAllOutletsListing) {
                    for (let i = 0; i < this.outlets.length; i++) {
                        for (let x = 0; x < this.outlet_ids.length; x++) {
                            if (this.outlet_ids[x] == this.outlets[i].id.toString()) {
                                this.processed_outlets.push(this.outlets[i])
                                break
                            }
                        }
                    }
                }
                else {
                    this.processed_outlets = this.outlets
                }
                if (this.staffPermission.viewAllOutletsListing && this.processed_outlets[0].name != 'All outlets') {
                    this.processed_outlets.unshift({ id: '', name: 'All outlets' })
                }
            }
        },
        staffsByOutlet: {
            query: queries.GET_STAFFS_BY_OUTLET_QUERY,
            pollInterval: 300,
            variables() {
                return {
                    outlet_id: this.outlet_id * 1,
                }
            },
            result() {
                var list = [...this.staffsByOutlet]
                this.processed_staff = []
                list.forEach(staff => {
                    this.processed_staff.push(staff.staff)
                });
                if (this.staffPermission.viewAllOutletsListing || this.staffPermission.viewOutletsListing) {
                    this.processed_staff.unshift({ id: '', fullname: 'All Staffs' })
                }
                this.processed_staff.unshift({ id: '', fullname: '--No select--' })
            }
        },
    },
    methods: {
        async addSalesId() {
            if (this.commissionList.length > 0 && this.salesList.length > 0) {
                this.salesList.forEach(s => {
                    if (s.booking_id) {
                        s.booking_id.forEach(id => {
                            const commission = this.commissionList.find(c => c.id === id);
                            if (commission) {
                                commission.sale_id = s.id.toString();
                            }
                        });
                    }
                });
                // Define the sorting criteria
                const sortProperties = [
                    { property: 'id', direction: 'desc' },
                ];

                // Use the multiPropertySort function to sort the commissionList
                this.commissionList = await this.$generalFunctions.multiPropertySort(this.commissionList, sortProperties);
            }
        },
        search() {
            this.$apollo.query(
                {
                    query: queries.GET_ALL_COMMISSION,
                    variables: {
                        staff_id: this.staff_id * 1,
                        outlet_id: this.outlet_id * 1,
                        startDate: this.date_range ? this.startDate : null,
                        endDate: this.date_range ? this.endDate : null,
                    },
                }
            ).then(async result => {
                console.log(result)
                const list = [...result.data.getCommissionList]
                this.commissionList = list.filter(e => e.staff_id && e.user_id)
                this.commissionList.forEach(c => {
                    c.cAmount = c.customCommission !== null ? c.customCommission : c.commission ? c.commission.type == 'fixed' ? c.commission.commission : c.amount * c.commission.commission / 100 : 0
                })
                this.addSalesId()
            })
        },
        routeToDetails(value){
            this.$router.push({ path: '/commission/' + value})
        },
        exportCSV() {
            var report = [];
            var totalSalesAmount = 0; // Variable to store the subtotal
            var totalCommission = 0; // Variable to store the total commission amount
            var totalSales = this.commissionList.length
            for (const c of this.commissionList) {
                const newItem = {
                    booking_id: c.id,
                    outlet: c.outlet.name,
                    staff: c.staff.fullname,
                    customer: c.user.user_fullname,
                    customer_contact: c.user.user_contact,
                    booked_date: new Date(c.booked_date * 1).toLocaleDateString(),
                    sale_id: c.sale_id,
                    amount: c.amount * 1,
                    commission_amount: c.cAmount * 1
                };
                report.push(newItem);

                totalSalesAmount += c.amount * 1; // Add the amount to the subtotal
                totalCommission += c.cAmount * 1; // Add the amount to the total commission
            }

            const keys = Object.keys(report[0]); // Get the keys from the first item in the report

            const headers = keys.join(",") + "\n";
            const rows = report
                .map(item => Object.values(item).join(","))
                .join("\n");

            const csvContent = headers + rows + "\n";
            const subtotalRow = `Total Sales:,,${totalSales}`;
            const subtotalAmountRow = `Total Sales Amount:,,${totalSalesAmount.toFixed(2)}`; // Add a subtotal row
            const totalCommissionRow = `Total Commission:,,${totalCommission.toFixed(2)}`; // Add a total commission row
            const contentWithSubtotalAndTotalCommission = csvContent + subtotalRow + "\n" + subtotalAmountRow + "\n" + totalCommissionRow;

            const blob = new Blob([contentWithSubtotalAndTotalCommission], { type: "text/csv;charset=utf-8;" });
            const link = document.createElement("a");
            const url = URL.createObjectURL(blob);

            link.setAttribute("href", url);
            link.setAttribute("download", "commission-list.csv");
            link.style.visibility = "hidden";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },
        updateCommissionList() {
        if (
            this.getCommissionList &&
            this.getCommissionList.length > 0 &&
            this.getAllSales &&
            this.getAllSales.length > 0
        ) {
            const list = [...this.getAllSales];
            this.salesList = list;
            this.addSalesId();
        }
        },

    },
    filters: {
        formatDate(value) {
    const date = new Date(value * 1);

    const year = date.getFullYear();
    const month = date.toLocaleString("en-US", { month: "short" });
    const day = date.getDate().toString().padStart(2, '0');
    

    return `${day} ${month} ${year}`;
  },

  formatTime(value) {
    const time = new Date(value * 1);

    let hours = time.getHours().toString().padStart(2, '0');
    const minutes = time.getMinutes().toString().padStart(2, '0');
    const period = hours >= 12 ? 'PM' : 'AM';

    // Convert hours to 12-hour format
    if (hours > 12) {
      hours -= 12;
    } else if (hours === 0) {
      hours = 12;
    }

    return `${hours}:${minutes} ${period}`;
  },

        formatPrice(value) {
            return Number(value).toFixed(2);
        }
    },
    watch: {
        value() {
            this.formattedValue = this.formatDate(this.value)
        },
        getCommissionList: {
            handler() {
                this.updateCommissionList();
            },
            deep: true,
        },
        getAllSales: {
            handler() {
                this.updateCommissionList();
            },
            deep: true,
        },
    },
    created(){
    this.$apollo.query({ query: queries.GET_ALL_PERMISSIONS_QUERY }).then(async result => {
      this.getAllPermissions = await result.data.getAllPermissions;
      this.$apollo.query(
        {
          query: queries.GET_STAFF_ROLES,
          variables: {
            staffId: this.$session.get("user_id") * 1
          }
        }
      ).then(async result => {
        this.getStaffRoles = await result.data.getStaffRoles;
        this.allPermissionsName = [];
        this.getStaffRoles.forEach(role => {
          role.role.RolePermission.forEach((item) => {
            const name = item.permission.name;
            this.allPermissionsName.push(name);
          });
        })
        this.$session.set('staffPermission', this.$generalFunctions.generatePermissionArray(this.getAllPermissions, this.allPermissionsName));
        this.staffPermission = await this.$generalFunctions.generatePermissionArray(this.getAllPermissions, this.allPermissionsName)
      })
    })
  }
}
</script>
  