var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('SideMenu',{attrs:{"submenuPage":"Resources","submenuGroup":"Service Setup"}},[_c('v-alert',{attrs:{"type":_vm.alert_type || 'info',"dismissible":""},model:{value:(_vm.alert),callback:function ($$v) {_vm.alert=$$v},expression:"alert"}},[_vm._v(_vm._s(_vm.message))]),_c('Form',{scopedSlots:_vm._u([{key:"tabs",fn:function(){return undefined},proxy:true},{key:"form",fn:function(){return [_c('v-form',{ref:"form",attrs:{"lazy-validation":""}},[_c('v-text-field',{attrs:{"label":"Resource","value":_vm.resourceById.name,"disabled":""}}),(_vm.resourcescheduleById.date)?_c('v-text-field',{attrs:{"label":"Date","value":_vm.resourcescheduleById.date,"disabled":""}}):_c('v-text-field',{attrs:{"label":"Day","value":_vm.dates[_vm.resourcescheduleById.day],"disabled":""}}),_c('v-text-field',{attrs:{"label":"Outlet","value":_vm.resourceById.outlet.name,"disabled":""}}),(!_vm.resourcescheduleById.off_day)?_c('div',[_c('v-menu',{ref:"from_time_menu",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.resourcescheduleById.from_time,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.resourcescheduleById, "from_time", $event)},"update:return-value":function($event){return _vm.$set(_vm.resourcescheduleById, "from_time", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"From Time","prepend-icon":"mdi-clock-time-four-outline","readonly":""},model:{value:(_vm.resourcescheduleById.from_time),callback:function ($$v) {_vm.$set(_vm.resourcescheduleById, "from_time", $$v)},expression:"resourcescheduleById.from_time"}},'v-text-field',attrs,false),on))]}}],null,false,2900110438),model:{value:(_vm.from_time_menu),callback:function ($$v) {_vm.from_time_menu=$$v},expression:"from_time_menu"}},[_c('v-time-picker',{staticClass:"mt-4",attrs:{"allowed-minutes":_vm.allowedStep,"format":"24hr"},on:{"click:minute":function($event){return _vm.$refs.from_time_menu.save(_vm.resourcescheduleById.from_time)}},model:{value:(_vm.resourcescheduleById.from_time),callback:function ($$v) {_vm.$set(_vm.resourcescheduleById, "from_time", $$v)},expression:"resourcescheduleById.from_time"}})],1),_c('v-menu',{ref:"to_time_menu",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.resourcescheduleById.to_time,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.resourcescheduleById, "to_time", $event)},"update:return-value":function($event){return _vm.$set(_vm.resourcescheduleById, "to_time", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"To Time","prepend-icon":"mdi-clock-time-four-outline","readonly":""},model:{value:(_vm.resourcescheduleById.to_time),callback:function ($$v) {_vm.$set(_vm.resourcescheduleById, "to_time", $$v)},expression:"resourcescheduleById.to_time"}},'v-text-field',attrs,false),on))]}}],null,false,451033926),model:{value:(_vm.to_time_menu),callback:function ($$v) {_vm.to_time_menu=$$v},expression:"to_time_menu"}},[_c('v-time-picker',{staticClass:"mt-4",attrs:{"allowed-minutes":_vm.allowedStep,"format":"24hr"},on:{"click:minute":function($event){return _vm.$refs.to_time_menu.save(_vm.resourcescheduleById.to_time)}},model:{value:(_vm.resourcescheduleById.to_time),callback:function ($$v) {_vm.$set(_vm.resourcescheduleById, "to_time", $$v)},expression:"resourcescheduleById.to_time"}})],1),_c('v-menu',{ref:"break_from_time_menu",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.resourcescheduleById.break_from_time,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.resourcescheduleById, "break_from_time", $event)},"update:return-value":function($event){return _vm.$set(_vm.resourcescheduleById, "break_from_time", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Break From Time","prepend-icon":"mdi-clock-time-four-outline","readonly":""},model:{value:(_vm.resourcescheduleById.break_from_time),callback:function ($$v) {_vm.$set(_vm.resourcescheduleById, "break_from_time", $$v)},expression:"resourcescheduleById.break_from_time"}},'v-text-field',attrs,false),on))]}}],null,false,1925967161),model:{value:(_vm.break_from_time_menu),callback:function ($$v) {_vm.break_from_time_menu=$$v},expression:"break_from_time_menu"}},[_c('v-time-picker',{staticClass:"mt-4",attrs:{"allowed-minutes":_vm.allowedStep,"format":"24hr"},on:{"click:minute":function($event){return _vm.$refs.break_from_time_menu.save(_vm.resourcescheduleById.break_from_time)}},model:{value:(_vm.resourcescheduleById.break_from_time),callback:function ($$v) {_vm.$set(_vm.resourcescheduleById, "break_from_time", $$v)},expression:"resourcescheduleById.break_from_time"}})],1),_c('v-menu',{ref:"break_to_time_menu",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.resourcescheduleById.break_to_time,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.resourcescheduleById, "break_to_time", $event)},"update:return-value":function($event){return _vm.$set(_vm.resourcescheduleById, "break_to_time", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Break To Time","prepend-icon":"mdi-clock-time-four-outline","readonly":""},model:{value:(_vm.resourcescheduleById.break_to_time),callback:function ($$v) {_vm.$set(_vm.resourcescheduleById, "break_to_time", $$v)},expression:"resourcescheduleById.break_to_time"}},'v-text-field',attrs,false),on))]}}],null,false,295454937),model:{value:(_vm.break_to_time_menu),callback:function ($$v) {_vm.break_to_time_menu=$$v},expression:"break_to_time_menu"}},[_c('v-time-picker',{staticClass:"mt-4",attrs:{"allowed-minutes":_vm.allowedStep,"format":"24hr"},on:{"click:minute":function($event){return _vm.$refs.break_to_time_menu.save(_vm.resourcescheduleById.break_to_time)}},model:{value:(_vm.resourcescheduleById.break_to_time),callback:function ($$v) {_vm.$set(_vm.resourcescheduleById, "break_to_time", $$v)},expression:"resourcescheduleById.break_to_time"}})],1)],1):_vm._e(),_c('v-checkbox',{attrs:{"label":"Off Day"},model:{value:(_vm.resourcescheduleById.off_day),callback:function ($$v) {_vm.$set(_vm.resourcescheduleById, "off_day", $$v)},expression:"resourcescheduleById.off_day"}})],1)]},proxy:true},{key:"buttons",fn:function(){return [_c('v-btn',{staticClass:"mr-4",attrs:{"text":""},on:{"click":function($event){return _vm.$router.push({ path: '/resources', query:{'tab':'schedule'} })}}},[_vm._v(" Back to list ")]),(_vm.staffPermission.deleteResourceSchedule)?_c('v-dialog',{attrs:{"max-width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-4",attrs:{"color":"error"}},'v-btn',attrs,false),on),[_vm._v("Delete")])]}},{key:"default",fn:function(dialog){return [_c('v-card',[_c('v-card-title',[_vm._v(" Are you sure to delete "+_vm._s(_vm.resourceById.name)+"'s schedule? ")]),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){dialog.value = false}}},[_vm._v("Close")]),_c('v-btn',{attrs:{"color":"error"},on:{"click":function($event){_vm.deleteStaffRoster();dialog.value = false}}},[_vm._v(" Delete ")])],1)],1)]}}],null,false,1791500488)}):_vm._e(),(_vm.staffPermission.updateResourceSchedule)?_c('v-btn',{staticClass:"mr-4",attrs:{"color":"success"},on:{"click":_vm.validate}},[_vm._v(" Update ")]):_vm._e()]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }