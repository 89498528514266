<template>
  <v-app>
    <SideMenu submenuPage="Staff">
      <v-alert
          :type="alert_type || 'info'"
          dismissible
          v-model="alert"
      >{{message}}</v-alert>
      <Form>
        <template v-slot:tabs>

        </template>
        <template v-slot:form>
          <v-form
            ref="form"
            lazy-validation
          >
            <v-text-field
              label="Staff"
              :value="staffById.fullname"
              readonly
            ></v-text-field>

            <v-text-field
              label="Date"
              :value="staffrosterById.date"
              disabled
              v-if="staffrosterById.date"
            ></v-text-field>
            <v-text-field
              label="Day"
              :value="dates[staffrosterById.day]"
              disabled
              v-else
            ></v-text-field>

            <v-select
              v-model="staffrosterById.outlet_id"
              :items="outlets"
              item-value="id"
              item-text="display_name"
              label="Outlet"
              :rules="[() => !!staffrosterById.outlet_id || 'Outlet is required']"
            ></v-select>

            <div v-if="!staffrosterById.off_day">
              <v-menu
                ref="from_time_menu"
                v-model="from_time_menu"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="staffrosterById.from_time"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="staffrosterById.from_time"
                    label="From Time"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    :rules="[
                      () => changeAsDate(staffrosterById.from_time) >= outlet_fromTime || 'From Time cannot be earlier than Outlet Session Start Time',
                      () => changeAsDate(staffrosterById.from_time) <= outlet_toTime || 'From Time cannot be later than Outlet Session Start Time',
                      () => staffrosterById.from_time <= staffrosterById.to_time || 'From Time has to earlier than To Time']"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-model="staffrosterById.from_time"
                  :allowed-minutes="allowedStep"
                  class="mt-4"
                  format="24hr"
                  @click:minute="$refs.from_time_menu.save(staffrosterById.from_time)"
                  :rules="[
                      () => changeAsDate(staffrosterById.from_time) >= outlet_fromTime || 'From Time cannot be earlier than Outlet Session Start Time',
                      () => changeAsDate(staffrosterById.from_time) <= outlet_toTime || 'From Time cannot be later than Outlet Session Start Time',
                      () => staffrosterById.from_time <= staffrosterById.to_time || 'From Time has to earlier than To Time']"
                ></v-time-picker>
              </v-menu>

              <v-menu
                ref="to_time_menu"
                v-model="to_time_menu"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="staffrosterById.to_time"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="staffrosterById.to_time"
                    label="To Time"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    :rules="[
                      () => changeAsDate(staffrosterById.to_time) >= outlet_fromTime || 'To Time cannot be earlier than Outlet Session Start Time',
                      () => changeAsDate(staffrosterById.to_time) <= outlet_toTime || 'To Time cannot be later than Outlet Session Start Time',
                      () => staffrosterById.to_time >= staffrosterById.from_time || 'From Time has to earlier than To Time']"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-model="staffrosterById.to_time"
                  :allowed-minutes="allowedStep"
                  class="mt-4"
                  format="24hr"
                  @click:minute="$refs.to_time_menu.save(staffrosterById.to_time)"
                ></v-time-picker>
              </v-menu>

              <v-menu
                ref="break_from_time_menu"
                v-model="break_from_time_menu"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="staffrosterById.break_from_time"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="staffrosterById.break_from_time"
                    label="Break From Time"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-model="staffrosterById.break_from_time"
                  :allowed-minutes="allowedStep"
                  class="mt-4"
                  format="24hr"
                  @click:minute="$refs.break_from_time_menu.save(staffrosterById.break_from_time)"
                ></v-time-picker>
              </v-menu>

              <v-menu
                ref="break_to_time_menu"
                v-model="break_to_time_menu"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="staffrosterById.break_to_time"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="staffrosterById.break_to_time"
                    label="Break To Time"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-model="staffrosterById.break_to_time"
                  :allowed-minutes="allowedStep"
                  class="mt-4"
                  format="24hr"
                  @click:minute="$refs.break_to_time_menu.save(staffrosterById.break_to_time)"
                ></v-time-picker>
              </v-menu>
            </div>

            <v-checkbox
              v-model="staffrosterById.off_day"
              label="Off Day"
            ></v-checkbox>
          </v-form>
        </template>
        <template v-slot:buttons>
          <v-btn
            class="mr-4"
            text
            @click="$router.push({ path: '/staffs', query:{'tab':'roster'} })"
          >
            Back to list
          </v-btn>
          <v-dialog
            max-width="500"
        >
            <template v-slot:activator="{ on, attrs }">
            <v-btn
                color="error"
                v-bind="attrs"
                v-on="on"
                class="mr-4" 
            >Delete</v-btn>
            </template>
            <template v-slot:default="dialog">
            <v-card>
                <v-card-title>
                    Are you sure to delete this {{ staffById.fullname }}'s roster?
                </v-card-title>
                <v-card-actions class="justify-end">
                <v-btn
                    text
                    @click="dialog.value = false"
                >Close</v-btn>
                <v-btn color="error" @click="deleteStaffRoster();dialog.value = false">
                    Delete
                </v-btn>
                </v-card-actions>
            </v-card>
            </template>
        </v-dialog>
          <v-btn
            color="success"
            class="mr-4"
            @click="validate"
          >
            Update
          </v-btn>
        </template>
      </Form>
    </SideMenu>
  </v-app>
</template>

<script>
import SideMenu from '/src/components/SideMenu'
import Form from '/src/components/Form'
import gql from 'graphql-tag'
import * as queries from '@/graphql/gqlQueries.js';
  const GET_ROSTER_QUERY = gql`
      query staffrosterById($id: ID!) {
        staffrosterById(id: $id) {
            id
            staff_id
            outlet_id
            day
            date
            off_day
            from_time
            to_time
            break_to_time
            break_from_time
        }
      }
  `
  const GET_USER_QUERY = gql`
      query staffById($id: ID!) {
        staffById(id: $id) {
            fullname
        }
      }
  `
  const GET_USER_OUTLET_QUERY = gql`
      query staffoutlets($staff_id:Int){
        staffoutlets(staff_id:$staff_id){
          outlet_id
          outlet{
            name
          }
        }
      }
  `
  export default {
    name: 'Staff',

    components:{
      SideMenu,
      Form
    },

    data () {
      
      return {
        outlet_toTime: "",
        outlet_fromTime: "",
        staff_id:'',
        staffById:{
          fullname:''
        },
        staffoutlets: {
          staff_id:'',
          outlet_id:'',
          staff:{
            fullname:''
          },
          outlet:{
            name:''
          }
        },
        outlets:[],
        dates: {1:"Monday",2:"Tuesday",3:"Wednesday",4:"Thursday",5:"Friday",6:"Saturday",7:"Sunday"},
        staffrosterById:{
          from_time:'',
          to_time:'',
          break_from_time:'',
          break_to_time:'',
          outlet_id:''
        },
        from_time_menu:false,
        to_time_menu:false,
        break_from_time_menu:false,
        break_to_time_menu:false,

        routeParam: this.$route.params.id,
        alert: false,
        alert_type: '',
        message: '',
      }
    },
    apollo: {
      staffrosterById: {
        query: GET_ROSTER_QUERY,
        variables() {
          return{
            id: this.routeParam
          }
        },
        result() {
          this.staff_id = this.staffrosterById.staff_id
          if(this.staffrosterById.date != null && this.staffrosterById.date>0){//if date is not null and is an integer
            this.staffrosterById.date = new Date(this.staffrosterById.date*1).toISOString().slice(0, 10)
          }
          if(this.staffrosterById.from_time && !this.staffrosterById.from_time.includes(':')){
            this.staffrosterById.from_time = ("0" + (new Date(1620691200000+this.staffrosterById.from_time*1).getUTCHours())).slice(-2) + ':' + ("0" + (new Date(1620691200000+this.staffrosterById.from_time*1).getMinutes())).slice(-2)
            this.staffrosterById.to_time = ("0" + (new Date(1620691200000+this.staffrosterById.to_time*1).getUTCHours())).slice(-2) + ':' + ("0" + (new Date(1620691200000+this.staffrosterById.to_time*1).getMinutes())).slice(-2)
            this.staffrosterById.break_from_time = ("0" + (new Date(1620691200000+this.staffrosterById.break_from_time*1).getUTCHours())).slice(-2) + ':' + ("0" + (new Date(1620691200000+this.staffrosterById.break_from_time*1).getMinutes())).slice(-2)
            this.staffrosterById.break_to_time = ("0" + (new Date(1620691200000+this.staffrosterById.break_to_time*1).getUTCHours())).slice(-2) + ':' + ("0" + (new Date(1620691200000+this.staffrosterById.break_to_time*1).getMinutes())).slice(-2)
          }
        }
      },
      staffById: {
        query: GET_USER_QUERY,
        variables() {
          return{
            id: this.staff_id*1
          }
        },
      },
      staffoutlets: {
        query: GET_USER_OUTLET_QUERY,
        variables() {
          return{
            staff_id: this.staff_id*1
          }
        },
        async result() {
          var outlets = []
          for(let i=0; i<this.staffoutlets.length; i++){
            outlets.push({'id':this.staffoutlets[i].outlet_id, 'name':this.staffoutlets[i].outlet.name})
          }
          if(outlets.length > 0){
            outlets = await this.addSessions(outlets)
            const outlet = outlets.find(o => o.id*1 === this.staffrosterById.outlet_id *1)
            const session = outlet?.session[0];
            if(session){
              this.outlet_fromTime = new Date(0, 0, 0, session.from_time.split(":")[0], session.from_time.split(":")[1]);
              this.outlet_toTime  = new Date(0, 0, 0, session.to_time.split(":")[0], session.to_time.split(":")[1]);
              this.$refs.form.validate();
            }
          }
          this.outlets = outlets
        }
      }
    },
    methods: {
      changeAsDate(time){
        return  new Date(0, 0, 0, time.split(":")[0], time.split(":")[1]);
      },
      validate () {
        var validate = this.$refs.form.validate()
        if(!validate){
          window.scrollTo(0,0);
        }
        if(validate){
          this.$apollo.query({
            query: queries.GET_VALIDATE_ROSTER_BOOKING,
            variables:{
              date: this.staffrosterById.date != null && this.staffrosterById.date>0 ? new Date(this.staffrosterById.date*1).toISOString().slice(0, 10) : null, 
              day: this.staffrosterById.day,
              staff_id:this.staff_id*1,
              from_time:this.staffrosterById.from_time,
              to_time:this.staffrosterById.to_time,
              break_from_time:this.staffrosterById.break_from_time,
              break_to_time:this.staffrosterById.break_to_time,
              outlet_id:this.staffrosterById.outlet_id*1,
              off_day:this.staffrosterById.off_day
            },
          }).then(result => {
            console.log("result",result)
            var res = result.data.validateRosterBooking
            if(!res.validate){
              this.alert = true
              this.alert_type='error'
              this.message = res.message
              window.scrollTo(0,0)
            }else{
            if(validate){
              const UPDATE_ROSTER_QUERY = gql`
                mutation updateStaffRoster($id:ID!, $staff_id:Int!, $day:Int, $date:String, $off_day:Boolean, $from_time:String!, $to_time:String!, $break_to_time:String, $break_from_time:String, $outlet_id:Int!){
                  updateStaffRoster(id:$id, staff_id:$staff_id, day:$day, date:$date, off_day:$off_day, from_time:$from_time, to_time:$to_time, break_to_time:$break_to_time, break_from_time:$break_from_time, outlet_id:$outlet_id){
                    date
                    day
                    from_time
                    to_time
                  }
                }
              `
              this.$apollo.mutate(
                { 
                  mutation: UPDATE_ROSTER_QUERY, 
                  variables:{
                    id: this.routeParam,
                    staff_id:this.staff_id,
                    from_time:this.staffrosterById.from_time,
                    to_time:this.staffrosterById.to_time,
                    break_from_time:this.staffrosterById.break_from_time,
                    break_to_time:this.staffrosterById.break_to_time,
                    outlet_id:this.staffrosterById.outlet_id,
                    off_day:this.staffrosterById.off_day
                  },
                }
              )
              .catch(error => {
                console.log(error)
              })
              .then(result => {
                if(result && result.data.updateStaffRoster != null){ 
                  console.log("Update successfully")
                  this.alert = true
                  this.alert_type='success'
                  this.message = "Staff Roster updated successfully"
                  window.scrollTo(0,0)
                } else {
                  this.alert = true
                  this.alert_type='error'
                  this.message = 'Updated fail'
                  window.scrollTo(0,0)
                }
              });
            }
            }
          }).catch(error=>{
            console.log(JSON.stringify(error, null, 2));
          })
        }
      },
      allowedStep: m => m % 5 === 0,
      deleteStaffRoster() {
        const DELETE_USER_ROSTER_QUERY = gql`
          mutation deleteStaffRoster($id:ID!) {
              deleteStaffRoster(id:$id)  {
                outlet_id
              }
          }
        `
        this.$apollo.mutate(
          { 
            mutation: DELETE_USER_ROSTER_QUERY, 
            variables:{
              id: this.routeParam,
            },
          }
        ).then(result => {
          if(result.data.deleteStaffRoster != null){ 
            console.log("Delete successfully")
            this.$router.push({name: 'Staff', params:{alert:true, alert_type:'success', message:'Staff Roster deleted successfully'}, query:{'tab':'roster'}})
          } else {
            console.log("Delete failed")
          }
        });
      },
      async addSessions(outlets) {
        try {
          const outlets_with_session = await Promise.all(
            outlets.map(async (outlet) => {
              const result = await this.$apollo.query({
                query: queries.GET_OUTLET_SESSION_QUERY,
                variables: {
                  outlet_id: outlet.id * 1,
                  date:
                    this.staffrosterById.date != null &&
                    this.staffrosterById.date > 0
                      ? new Date(this.staffrosterById.date * 1).toISOString().slice(0, 10)
                      : null,
                },
              });
              const outletSessions = result.data.outletSessions;
              var filteredSessions = await outletSessions.filter(s => s.outlet_sessions.length > 0);
              if(this.staffrosterById.date == null || this.staffrosterById.date == 0){
                filteredSessions = await outletSessions.filter(s => s.day == this.staffrosterById.day);
              }

              const formattedSessions = filteredSessions.map(s => {
                const fromTime = new Date(1620691200000 + s.from_time * 1);
                const toTime = new Date(1620691200000 + s.to_time * 1);

                return {
                  ...s,
                  from_time: `${("0" + fromTime.getUTCHours()).slice(-2)}:${("0" + fromTime.getMinutes()).slice(-2)}`,
                  to_time: `${("0" + toTime.getUTCHours()).slice(-2)}:${("0" + toTime.getMinutes()).slice(-2)}`,
                };
              });

              outlet.session = formattedSessions;
              outlet.display_name = `${outlet.name} (${formattedSessions[0].from_time}~${formattedSessions[0].to_time})`;

              return outlet;
            })
          );
          return outlets_with_session;
        } catch (error) {
          console.log(JSON.stringify(error, null, 2));
          // Handle the error here
          return outlets; // Return the original outlets if there was an error
        }
      }
    },
    watch: {
    'staffrosterById.outlet_id': function (newValue) {
      if(this.outlets.length !==  0){
        const outlet = this.outlets.find(o => o.id*1 === newValue)
        const session = outlet?.session[0];

        if (session) {
          this.outlet_fromTime = new Date(0, 0, 0, session.from_time.split(":")[0], session.from_time.split(":")[1]);
          this.outlet_toTime  = new Date(0, 0, 0, session.to_time.split(":")[0], session.to_time.split(":")[1]);
        }
      }
    },
  },
  }
</script>
