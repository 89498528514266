<template>
    <v-app>
        <SideMenu :alert="alert" :alert_type="alert_type" :message="message"  submenuPage="Roles">
            <List>
                <template v-slot:action-button>
                    <v-btn color="primary" class="mr-4" @click="RolesCreateForm" v-if="staffPermission.createRole">
                        + New Roles
                    </v-btn>
                </template>
                <template v-slot:tabs></template>
                <template v-slot:tab-items>
                    <v-data-table :headers="headers" :items="getRoles" class="elevation-1" item-key="name">
                        <template v-slot:item="{ item }">
                            <tr @click="RolesUpdateForm(item.id)" style="cursor:pointer">
                                <td>{{ item.name }}</td>
                                <td>{{ item.description }}</td>
                            </tr>
                        </template>
                    </v-data-table>
                </template>
            </List>
        </SideMenu>
    </v-app>
</template>
  
<script>
import SideMenu from '/src/components/SideMenu'
import List from '/src/components/List'
import gql from 'graphql-tag'
import * as queries from '@/graphql/gqlQueries.js';
const GET_ALL_ROLES_QUERY = gql`
        query getRoles {
            getRoles {
            id
            name
            description
          }
        }
    `
export default {
    name: 'Roles',

    components: {
        SideMenu,
        List
    },

    data() {

        return {
            getRoles: [],
            alert: this.$route.params.alert,
            alert_type: this.$route.params.alert_type,
            message: this.$route.params.message,

            headers: [
                {
                    text: 'Name',
                    value: 'name',
                    class: 'white--text'
                },
                {
                    text: 'Description',
                    value: 'description',
                    class: 'white--text'
                }
            ],
            getAllPermissions: [],
            allPermissionsName: [],
            staffPermission: {},
        }
    },
    apollo: {
        getRoles: {
            query: GET_ALL_ROLES_QUERY,
            pollInterval: 300,
            fetchPolicy: 'network-only',
            async result() {
                const sortProperties = [
                    { property: 'id', direction: 'desc' },
                ];

                // Use the multiPropertySort function to sort the commissionList
                this.getRoles = await this.$generalFunctions.multiPropertySort(this.getRoles, sortProperties);
            }
        },
    },
    methods: {
        RolesUpdateForm(id) {
            this.$router.push({ path: '/role/' + id })
        },
        RolesCreateForm() {
            this.$router.push({ path: '/create/role' })
        }
    },
    created(){
      this.$apollo.query({ query: queries.GET_ALL_PERMISSIONS_QUERY }).then(async result => {
        this.getAllPermissions = await result.data.getAllPermissions;
        this.$apollo.query(
          {
            query: queries.GET_STAFF_ROLES,
            variables: {
              staffId: this.$session.get("user_id") * 1
            }
          }
        ).then(async result => {
          this.getStaffRoles = await result.data.getStaffRoles;
          this.allPermissionsName = [];
          this.getStaffRoles.forEach(role => {
            role.role.RolePermission.forEach((item) => {
              const name = item.permission.name;
              this.allPermissionsName.push(name);
            });
          })
          this.$session.set('staffPermission', this.$generalFunctions.generatePermissionArray(this.getAllPermissions, this.allPermissionsName));
          this.staffPermission = await this.$generalFunctions.generatePermissionArray(this.getAllPermissions, this.allPermissionsName)
        })
      })
    }
}
</script>
  