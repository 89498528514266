<template>
  <v-app>
    <SideMenu submenuPage="Service Type" submenuGroup="Service Setup">
      <Form>
        <template v-slot:tabs>

        </template>
        <template v-slot:form>
          <v-form
            ref="form"
            lazy-validation
          >
            <v-text-field
              v-model="serviceTypeById.name"
              :counter="191"
              :rules="nameRules.concat(characterRules)"
              label="Name"
              required
            ></v-text-field>

            <v-text-field
              v-model="serviceTypeById.description"
              :counter="191"
              :rules="characterRules"
              label="Description"
            ></v-text-field>
          </v-form>
        </template>
        <template v-slot:buttons>
          <v-btn
            class="mr-4"
            text
            @click="$router.push({ path: '/type/services' })"
          >
            Back to list
          </v-btn>
          <v-btn
            color="success"
            class="mr-4"
            @click="validate"
          >
            Create
          </v-btn>
        </template>
      </Form>
      
    </SideMenu>
  </v-app>
</template>

<script>
import SideMenu from '/src/components/SideMenu'
import Form from '/src/components/Form'
import gql from 'graphql-tag'
  export default {
    name: 'Service',

    components:{
      SideMenu,
      Form
    },

    data () {
      
      return {
        serviceTypeById: {
          name: '',
          description: '',
          color: '',
        },
        
        nameRules: [
          v => !!v || 'Name is required',
        ],
        characterRules: [
          v => (v.length <= 191) || 'Field must be less than or equal to 191 characters',
        ],
      }
    },
    methods: {
      validate () {
        var validate = this.$refs.form.validate()
        if(!validate){
          window.scrollTo(0,0);
        }
        if(validate){
          const CREATE_SERVICE_TYPE_QUERY = gql`
            mutation createServiceType($name: String!, $description:String) {
                createServiceType(name:$name, description:$description)  {
                  name
                }
            }
          `
          this.$apollo.mutate(
            { 
              mutation: CREATE_SERVICE_TYPE_QUERY, 
              variables:{
                name: this.serviceTypeById.name,
                description: this.serviceTypeById.description
              },
            }
          )
          .catch(error => {
            console.log(error)
          })
          .then(result => {
            if(result && result.data.createServiceType != null){ 
              console.log("Create successfully")
              this.$router.push({name: 'Service Type', params:{alert:true, alert_type:'success', message:result.data.createServiceType.name+' created successfully'}})
            } else {
              console.log("Create failed")
            }
          });
        }
      },
    }
  }
</script>
