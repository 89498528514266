<template>
  <v-app>
    <SideMenu :alert="alert" :alert_type="alert_type" :message="message" submenuPage="Resources" submenuGroup="Service Setup">
      <List>
        <template v-slot:action-button>
          <v-btn
            color="primary"
            class="mr-4"
            @click="ResourceCreateForm"
            v-if="staffPermission.createResource"
          >
            + New Resource
          </v-btn>
        </template>
        <template v-slot:tabs>
          <v-tabs
            v-model="tab"
          >
            <v-tabs-slider></v-tabs-slider>
            <v-tab href="#resources">Resources</v-tab>
            <v-tab href="#schedule">Schedule</v-tab>
          </v-tabs>
        </template>
        <template v-slot:tab-items>
          <v-tabs-items v-model="tab">
            <v-tab-item value="resources">
              <v-data-table
                :headers="headers"
                :items="resources"
                class="elevation-1"
                item-key="name"
              >
                <template v-slot:item="{ item }">
                  <tr
                    @click="ResourceUpdateForm(item.id)"
                    style="cursor:pointer"
                  >
                    <td>{{ item.name }}</td>
                    <td>{{ item.resource_type.name }}</td>
                  </tr>
                </template>
              </v-data-table>
            </v-tab-item>
            <v-tab-item value="schedule">
              <v-select
              v-model="outlet_id"
                :items="outlets"
                item-value="id"
                item-text="name"
                dense
                outlined
                hide-details
                label="Outlet"
                class="ma-2"
                @change="GetSchedule()"
              ></v-select>
              <v-row no-gutters>
                <v-col cols="1">
                  <v-btn
                    icon
                    class="ma-2"
                    @click="AddDaysToDate(-7)"
                  >
                    <v-icon>mdi-chevron-left</v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="4">
                  <v-menu
                    v-model="datemenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="formattedDate"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="date"
                      @input="datemenu = false"
                      @change="AddDaysToDate(0)"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="1">
                  <v-btn
                    icon
                    class="ma-2"
                    @click="AddDaysToDate(7)"
                  >
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="4"></v-col>
                <v-col cols="2">
                  <div class="text-right">
                    <v-btn-toggle v-model="type" class="ma-1" color="blue">
                      <v-btn value="day" @change="GetWeekDays(), GetSchedule()">
                        Day
                      </v-btn>
                      <v-btn value="date" @change="GetWeekDates(), GetSchedule()">
                        Date
                      </v-btn>
                    </v-btn-toggle>
                  </div>
                </v-col>
              </v-row>
              <table class="roster">
                <thead v-if="type=='date'">
                  <td>Resources</td>
                  <td 
                    v-for="(date, index) in dates" 
                    :key="index"
                  >
                    {{date}} ({{day_strings[index]}})
                  </td>
                </thead>
                <thead v-if="type=='day'">
                  <td>Resources</td>
                  <td 
                    v-for="(date, index) in dates" 
                    :key="index"
                  >
                    {{date}}
                  </td>
                </thead>
                <tr
                  v-for="resource_schedule in resource_schedules"
                  :key="resource_schedule.id"
                >
                  <td>
                    {{resource_schedule.name}}
                  </td>
                  <td
                    v-for="schedule in resource_schedule.schedule"
                    :key="schedule.id"
                    @click="ScheduleForm(schedule.id, resource_schedule.id, schedule.day, dates[schedule.day], schedule.date)"
                  >
                    <div v-if="schedule.id">
                      <span v-if="schedule.off_day">
                        <b v-if="schedule.date">*</b>
                        Off Day
                      </span>
                      <span v-else>
                        <b v-if="schedule.date">*</b>
                        {{schedule.from_time}} ~ {{schedule.to_time}}
                      </span>
                    </div>
                    <div v-else>
                      +
                    </div>
                  </td>
                </tr>
              </table>
            </v-tab-item>
          </v-tabs-items>
        </template>
      </List>
    </SideMenu>
  </v-app>
</template>

<script>
import SideMenu from '/src/components/SideMenu'
import List from '/src/components/List'
import gql from 'graphql-tag'
import * as queries from '@/graphql/gqlQueries.js';
  const GET_ALL_RESOURCES_QUERY = gql`
      query Resources($outlet_id:Int) {
        resources(outlet_id:$outlet_id) {
          id
          name
          created_at
          resource_type{
            name
          }
          resource_schedule{
            id
            day
            date
            from_time
            to_time
            off_day
          }
          outlet_id
        }
      }
  `
  const GET_ALL_SCHEDULE_QUERY = gql`
      query resourceschedule($outlet_id:Int, $date:String) {
        resourceschedule(outlet_id:$outlet_id, date:$date) {
          id
          day
          date
          from_time
          to_time
          resource_id
          off_day
        }
      }
  `
  const GET_ALL_OUTLET_QUERY = gql`
      query Outlets {
        outlets {
          id
          name
        }
      }
  `
  export default {
    name: 'Resource',

    components:{
      SideMenu,
      List
    },

    data () {
      
      return {
        resources: [],
        resourceschedule:[],
        resource_schedules: [],
        alert: this.$route.params.alert,
        alert_type: this.$route.params.alert_type,
        message: this.$route.params.message,
        tab:this.$route.query.tab,
        outlet_id:'',
        outlets: [],
        dates: {1:"Monday",2:"Tuesday",3:"Wednesday",4:"Thursday",5:"Friday",6:"Saturday",7:"Sunday"},
        day_strings: {1:"Monday",2:"Tuesday",3:"Wednesday",4:"Thursday",5:"Friday",6:"Saturday",7:"Sunday"},
        date: new Date().toISOString().slice(0, 10),
        formattedDate: this.formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)),
        datemenu:false,

        type: 'day',

        headers:[
          {
            text: 'Name',
            value: 'name',
            class: 'white--text'
          },
          {
            text: 'Resource Type',
            value: 'resource_type_id',
            class: 'white--text'
          }
        ],
        getAllPermissions: [],
        allPermissionsName: [],
        staffPermission: {},
      }
    },
    apollo: {
      resources: {
        query: GET_ALL_RESOURCES_QUERY,
        pollInterval: 300,
        variables (){
          return {
            outlet_id: this.outlet_id*1
          }
        },
        async result() {
          const sortProperties = [
                    { property: 'id', direction: 'desc' },
                    { property: 'created_at', direction: 'desc' },
                ];

      // Use the multiPropertySort function to sort the commissionList
      this.resources = await this.$generalFunctions.multiPropertySort(this.resources, sortProperties);
          this.GetSchedule()
        }
      },
      outlets: {
        query: GET_ALL_OUTLET_QUERY,
        result() {
          this.outlets.unshift({id:'',name:'All outlets'})
        }
      },
      resourceschedule: {
        query: GET_ALL_SCHEDULE_QUERY,
        variables (){
          return {
            outlet_id: this.outlet_id*1,
            date: this.date
          }
        },
        pollInterval: 300,
        result() {
          if(this.type=='date'){
            this.GetSchedule()
          }
        }
      },
    },
    watch: {
      date () {
        this.formattedDate = this.formatDate(this.date)
      },
    },
    computed: {
      computedDateFormatted () {
        return this.formatDate(this.date)
      },
    },
    methods: {
      formatDate (date) {
        if (!date) return null
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
          "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
        ];
        const days_string = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"]
        var dateObject = new Date(date)
        return `${dateObject.getDate()} ${monthNames[dateObject.getMonth()]} ${dateObject.getFullYear()} (${days_string[dateObject.getDay()]})`
      },
      ResourceUpdateForm(id) {
        this.$router.push({ path: '/resource/'+id })
      },
      ResourceCreateForm() {
        this.$router.push({ path: '/create/resource' })
      },
      ScheduleForm(schedule_id, resource_id, day, date, schedule_date) {
        if(schedule_id){
          if(this.type == 'date' && !schedule_date){
            if(!this.staffPermission.createResourceSchedule) return
            this.$router.push({ path: '/create/schedule/resource', query:{'resource_id':resource_id, 'date':date} })
          }
          else{
            this.$router.push({ path: '/resource/schedule/'+schedule_id })
          }
        }
        else{
          var days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
          if(!days.includes(date)){
            if(!this.staffPermission.createResourceSchedule) return
            this.$router.push({ path: '/create/schedule/resource', query:{'resource_id':resource_id, 'date':date} })
          }
          else{
            if(!this.staffPermission.createResourceSchedule) return
            this.$router.push({ path: '/create/schedule/resource', query:{'resource_id':resource_id, 'day':day, 'date':date} })
          }
          
        }
      },
      GetSchedule(){
        this.resource_schedules = []

        for(let i=0; i<this.resources.length; i++){
          var schedule = []
          for(var date_index in this.dates){
            var time = {day:date_index}
            for(let y=0; y<this.resources[i].resource_schedule.length; y++){
              if(date_index == this.resources[i].resource_schedule[y].day){
                time = {
                  day:date_index,
                  id: this.resources[i].resource_schedule[y].id,
                  from_time:("0" + (new Date(1620691200000+this.resources[i].resource_schedule[y].from_time*1).getUTCHours())).slice(-2) + ':' + ("0" + (new Date(1620691200000+this.resources[i].resource_schedule[y].from_time*1).getMinutes())).slice(-2), 
                  to_time:("0" + (new Date(1620691200000+this.resources[i].resource_schedule[y].to_time*1).getUTCHours())).slice(-2) + ':' + ("0" + (new Date(1620691200000+this.resources[i].resource_schedule[y].to_time*1).getMinutes())).slice(-2),
                  off_day: this.resources[i].resource_schedule[y].off_day
                }
                break
              }
            }
            schedule.push(time)
          }
          this.resources[i].schedule = schedule
          this.resource_schedules.push(this.resources[i])
        }

        for(let i=0; i<this.resource_schedules.length; i++){
          for(date_index in this.dates){
            time = {day:date_index}
            for(let y=0; y<this.resourceschedule.length; y++){
              if(this.dates[date_index] == new Date(this.resourceschedule[y].date*1).toISOString().slice(0, 10) && this.resource_schedules[i].id == this.resourceschedule[y].resource_id){
                time = {
                  date:true,
                  day:date_index,
                  id: this.resourceschedule[y].id,
                  off_day: this.resourceschedule[y].off_day,
                  from_time:("0" + (new Date(1620691200000+this.resourceschedule[y].from_time*1).getUTCHours())).slice(-2) + ':' + ("0" + (new Date(1620691200000+this.resourceschedule[y].from_time*1).getMinutes())).slice(-2), 
                  to_time:("0" + (new Date(1620691200000+this.resourceschedule[y].to_time*1).getUTCHours())).slice(-2) + ':' + ("0" + (new Date(1620691200000+this.resourceschedule[y].to_time*1).getMinutes())).slice(-2),
                }
                this.resource_schedules[i].schedule[date_index-1] = time
                console.log(time)
              }
            }
          }
        }
      },
      GetWeekDates(){
        let current_date = new Date(this.date)
        
        let week = {}
        for (let i = 1; i <= 7; i++) {
          let first = current_date.getDate() - current_date.getDay() + i 
          if(current_date.getDay() == 0){
            first = current_date.getDate() - 7 + i 
          }
          let day = new Date(current_date.setDate(first)).toISOString().slice(0, 10)
          week[i] = day
        }
        this.dates = week
      },
      GetWeekDays(){
        this.dates = {1:"Monday",2:"Tuesday",3:"Wednesday",4:"Thursday",5:"Friday",6:"Saturday",7:"Sunday"}
      },
      AddDaysToDate(day){
        this.type = 'date'
        this.date = new Date(new Date(this.date).setDate(new Date(this.date).getDate() + day)).toISOString().slice(0, 10)
        this.GetWeekDates()
      }
    },
    created(){
      this.$apollo.query({ query: queries.GET_ALL_PERMISSIONS_QUERY }).then(async result => {
        this.getAllPermissions = await result.data.getAllPermissions;
        this.$apollo.query(
          {
            query: queries.GET_STAFF_ROLES,
            variables: {
              staffId: this.$session.get("user_id") * 1
            }
          }
        ).then(async result => {
          this.getStaffRoles = await result.data.getStaffRoles;
          this.allPermissionsName = [];
          this.getStaffRoles.forEach(role => {
            role.role.RolePermission.forEach((item) => {
              const name = item.permission.name;
              this.allPermissionsName.push(name);
            });
          })
          this.$session.set('staffPermission', this.$generalFunctions.generatePermissionArray(this.getAllPermissions, this.allPermissionsName));
          this.staffPermission = await this.$generalFunctions.generatePermissionArray(this.getAllPermissions, this.allPermissionsName)
        })
      })
    }
  }
</script>
