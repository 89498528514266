<template>
  <v-app>
    <SideMenu :alert="alert" :alert_type="alert_type" :message="message" submenuPage="Sessions" submenuGroup="Outlet Setup">
      <List>
        <template v-slot:action-button>
          <v-btn
            color="primary"
            class="mr-4"
            @click="SessionCreateForm"
            v-if="staffPermission.createOutletSession"
          >
          + New Sessions
        </v-btn>
        </template>
        <template v-slot:tab-items>
          <v-data-table
            :headers="headers"
            :items="sessions"
            class="elevation-1"
            item-key="name"
          >
          <template v-slot:item="{ item }">
            <tr
              @click="SessionUpdateForm(item.id)"
              style="cursor:pointer"
            >
              <td>{{ item.name }}</td>
              <td v-if="item.day">{{ dates[item.day] }}</td>
              <td v-else>{{ new Date(item.date*1).toISOString().slice(0, 10) }}</td>
              <td v-if="!item.off_day">{{ ("0" + (new Date(1620691200000+item.from_time*1).getUTCHours())).slice(-2) + ':' + ("0" + (new Date(1620691200000+item.from_time*1).getMinutes())).slice(-2) }} ~ {{ ("0" + (new Date(1620691200000+item.to_time*1).getUTCHours())).slice(-2) + ':' + ("0" + (new Date(1620691200000+item.to_time*1).getMinutes())).slice(-2) }}</td>
              <td v-else>Off day</td>
            </tr>
          </template>
          </v-data-table>
        </template>
      </List>
    </SideMenu>
  </v-app>
</template>

<style>
.dot {
  height: 10px;
  width: 10px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}
</style>

<script>
import SideMenu from '/src/components/SideMenu'
import List from '/src/components/List'
import gql from 'graphql-tag'
import * as queries from '@/graphql/gqlQueries.js';
  const GET_ALL_SESSIONS_QUERY = gql`
      query Sessions {
        sessions {
          id
          name
          day
          date
          off_day
          from_time
          to_time
          created_at
        }
      }
  `
  export default {
    name: 'Outlet',

    components:{
      SideMenu,
      List
    },

    data () {
      
      return {
        sessions: [],
        alert: this.$route.params.alert,
        alert_type: this.$route.params.alert_type,
        message: this.$route.params.message,
        dates: {1:"Monday",2:"Tuesday",3:"Wednesday",4:"Thursday",5:"Friday",6:"Saturday",7:"Sunday"},

        headers:[
          {
            text: 'Name',
            value: 'name',
            class: 'white--text'
          },
          {
            text: 'Day/Date',
            value: 'day',
            class: 'white--text'
          },
          {
            text: 'Time',
            value: 'time',
            class: 'white--text'
          }
        ],
        getAllPermissions: [],
        allPermissionsName: [],
        staffPermission: {},
      }
    },
    apollo: {
      sessions: {
        query: GET_ALL_SESSIONS_QUERY,
        pollInterval: 300,
        async result(){
          const sortProperties = [
                    { property: 'id', direction: 'desc' },
                    { property: 'created_at', direction: 'desc' },
                ];

      // Use the multiPropertySort function to sort the commissionList
      this.sessions = await this.$generalFunctions.multiPropertySort(this.sessions, sortProperties);
        }
      },
    },
    methods: {
      SessionUpdateForm(id) {
        if(!this.staffPermission.updateOutletSession) return
        this.$router.push({ path: '/session/'+id })
      },
      SessionCreateForm() {
        this.$router.push({ path: '/create/session' })
      }
    },
    created(){
      this.$apollo.query({ query: queries.GET_ALL_PERMISSIONS_QUERY }).then(async result => {
        this.getAllPermissions = await result.data.getAllPermissions;
        this.$apollo.query(
          {
            query: queries.GET_STAFF_ROLES,
            variables: {
              staffId: this.$session.get("user_id") * 1
            }
          }
        ).then(async result => {
          this.getStaffRoles = await result.data.getStaffRoles;
          this.allPermissionsName = [];
          this.getStaffRoles.forEach(role => {
            role.role.RolePermission.forEach((item) => {
              const name = item.permission.name;
              this.allPermissionsName.push(name);
            });
          })
          this.$session.set('staffPermission', this.$generalFunctions.generatePermissionArray(this.getAllPermissions, this.allPermissionsName));
          this.staffPermission = await this.$generalFunctions.generatePermissionArray(this.getAllPermissions, this.allPermissionsName)
        })
      })
    }
  }
</script>
