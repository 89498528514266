<template>
  <v-app>
    <SideMenu submenuPage="Resources" submenuGroup="Service Setup">
      <v-alert
        :type="alert_type || 'info'"
        dismissible
        v-model="alert"
      >{{message}}</v-alert>
      <Form>
        <template v-slot:tabs>

        </template>
        <template v-slot:form>
          <v-form
            ref="form"
            lazy-validation
          >
            <v-text-field
              label="Resource"
              :value="resourceById.name"
              disabled
            ></v-text-field>

            <v-text-field
              label="Date"
              :value="resourcescheduleById.date"
              disabled
              v-if="resourcescheduleById.date"
            ></v-text-field>
            <v-text-field
              label="Day"
              :value="dates[resourcescheduleById.day]"
              disabled
              v-else
            ></v-text-field>

            <v-text-field
              label="Outlet"
              :value="resourceById.outlet.name"
              disabled
            ></v-text-field>

            <div v-if="!resourcescheduleById.off_day">
              <v-menu
                ref="from_time_menu"
                v-model="from_time_menu"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="resourcescheduleById.from_time"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="resourcescheduleById.from_time"
                    label="From Time"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-model="resourcescheduleById.from_time"
                  :allowed-minutes="allowedStep"
                  class="mt-4"
                  format="24hr"
                  @click:minute="$refs.from_time_menu.save(resourcescheduleById.from_time)"
                ></v-time-picker>
              </v-menu>

              <v-menu
                ref="to_time_menu"
                v-model="to_time_menu"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="resourcescheduleById.to_time"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="resourcescheduleById.to_time"
                    label="To Time"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-model="resourcescheduleById.to_time"
                  :allowed-minutes="allowedStep"
                  class="mt-4"
                  format="24hr"
                  @click:minute="$refs.to_time_menu.save(resourcescheduleById.to_time)"
                ></v-time-picker>
              </v-menu>

              <v-menu
                ref="break_from_time_menu"
                v-model="break_from_time_menu"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="resourcescheduleById.break_from_time"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="resourcescheduleById.break_from_time"
                    label="Break From Time"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-model="resourcescheduleById.break_from_time"
                  :allowed-minutes="allowedStep"
                  class="mt-4"
                  format="24hr"
                  @click:minute="$refs.break_from_time_menu.save(resourcescheduleById.break_from_time)"
                ></v-time-picker>
              </v-menu>

              <v-menu
                ref="break_to_time_menu"
                v-model="break_to_time_menu"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="resourcescheduleById.break_to_time"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="resourcescheduleById.break_to_time"
                    label="Break To Time"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-model="resourcescheduleById.break_to_time"
                  :allowed-minutes="allowedStep"
                  class="mt-4"
                  format="24hr"
                  @click:minute="$refs.break_to_time_menu.save(resourcescheduleById.break_to_time)"
                ></v-time-picker>
              </v-menu>
            </div>

            <v-checkbox
              v-model="resourcescheduleById.off_day"
              label="Off Day"
            ></v-checkbox>
          </v-form>
        </template>
        <template v-slot:buttons>
          <v-btn
            class="mr-4"
            text
            @click="$router.push({ path: '/resources', query:{'tab':'schedule'} })"
          >
            Back to list
          </v-btn>
          <v-dialog
              max-width="500"
              v-if="staffPermission.deleteResourceSchedule"
          >
              <template v-slot:activator="{ on, attrs }">
              <v-btn
                  color="error"
                  v-bind="attrs"
                  v-on="on"
                  class="mr-4" 
              >Delete</v-btn>
              </template>
              <template v-slot:default="dialog">
              <v-card>
                  <v-card-title>
                    Are you sure to delete {{ resourceById.name }}'s schedule?
                  </v-card-title>
                  <v-card-actions class="justify-end">
                  <v-btn
                      text
                      @click="dialog.value = false"
                  >Close</v-btn>
                  <v-btn color="error" @click="deleteStaffRoster();dialog.value = false">
                      Delete
                  </v-btn>
                  </v-card-actions>
              </v-card>
              </template>
          </v-dialog>
          <v-btn
            color="success"
            class="mr-4"
            @click="validate"
            v-if="staffPermission.updateResourceSchedule"
          >
            Update
          </v-btn>
        </template>
      </Form>
    </SideMenu>
  </v-app>
</template>

<script>
import SideMenu from '/src/components/SideMenu'
import Form from '/src/components/Form'
import * as queries from '@/graphql/gqlQueries.js';
import gql from 'graphql-tag'
  const GET_SCHEDULE_QUERY = gql`
      query resourcescheduleById($id: ID!) {
        resourcescheduleById(id: $id) {
            id
            resource_id
            outlet_id
            day
            date
            off_day
            from_time
            to_time
            break_to_time
            break_from_time
        }
      }
  `
  const GET_RESOURCE_QUERY = gql`
      query resourceById($id: ID!) {
        resourceById(id: $id) {
            name
            outlet{
              name
            }
            outlet_id
        }
      }
  `
  export default {
    name: 'Staff',

    components:{
      SideMenu,
      Form
    },

    data () {
      
      return {
        resource_id:'',
        resourceById:{
          name:'',
          outlet:{
            name:''
          }
        },
        alert: false,
        alert_type: "info",
        message: "",
        dates: {1:"Monday",2:"Tuesday",3:"Wednesday",4:"Thursday",5:"Friday",6:"Saturday",7:"Sunday"},
        resourcescheduleById:{
          from_time:'',
          to_time:'',
          break_from_time:'',
          break_to_time:'',
          outlet_id:''
        },
        from_time_menu:false,
        to_time_menu:false,
        break_from_time_menu:false,
        break_to_time_menu:false,

        routeParam: this.$route.params.id,
        getAllPermissions: [],
        allPermissionsName: [],
        staffPermission: {},
        
      }
    },
    apollo: {
      resourcescheduleById: {
        query: GET_SCHEDULE_QUERY,
        variables() {
          return{
            id: this.routeParam
          }
        },
        result() {
          this.resource_id = this.resourcescheduleById.resource_id
          if(this.resourcescheduleById.date != null && this.resourcescheduleById.date>0){//if date is not null and is an integer
            this.resourcescheduleById.date = new Date(this.resourcescheduleById.date*1).toISOString().slice(0, 10)
          }
          if(this.resourcescheduleById.from_time && !this.resourcescheduleById.from_time.includes(':')){
            this.resourcescheduleById.from_time = ("0" + (new Date(1620691200000+this.resourcescheduleById.from_time*1).getUTCHours())).slice(-2) + ':' + ("0" + (new Date(1620691200000+this.resourcescheduleById.from_time*1).getMinutes())).slice(-2)
            this.resourcescheduleById.to_time = ("0" + (new Date(1620691200000+this.resourcescheduleById.to_time*1).getUTCHours())).slice(-2) + ':' + ("0" + (new Date(1620691200000+this.resourcescheduleById.to_time*1).getMinutes())).slice(-2)
            this.resourcescheduleById.break_from_time = ("0" + (new Date(1620691200000+this.resourcescheduleById.break_from_time*1).getUTCHours())).slice(-2) + ':' + ("0" + (new Date(1620691200000+this.resourcescheduleById.break_from_time*1).getMinutes())).slice(-2)
            this.resourcescheduleById.break_to_time = ("0" + (new Date(1620691200000+this.resourcescheduleById.break_to_time*1).getUTCHours())).slice(-2) + ':' + ("0" + (new Date(1620691200000+this.resourcescheduleById.break_to_time*1).getMinutes())).slice(-2)
          }
        }
      },
      resourceById: {
        query: GET_RESOURCE_QUERY,
        variables() {
          return{
            id: this.resource_id*1
          }
        },
      },
    },
    methods: {
      validate () {
        var validate = this.$refs.form.validate()
        if(!validate){
          window.scrollTo(0,0);
        }
        if(validate){
          const UPDATE_SCHEDULE_QUERY = gql`
            mutation updateResourceSchedule($id:ID!, $resource_id:Int!, $day:Int, $date:String, $off_day:Boolean, $from_time:String!, $to_time:String!, $break_to_time:String, $break_from_time:String, $outlet_id:Int!){
              updateResourceSchedule(id:$id, resource_id:$resource_id, day:$day, date:$date, off_day:$off_day, from_time:$from_time, to_time:$to_time, break_to_time:$break_to_time, break_from_time:$break_from_time, outlet_id:$outlet_id){
                date
                day
                from_time
                to_time
              }
            }
          `
          this.$apollo.mutate(
            { 
              mutation: UPDATE_SCHEDULE_QUERY, 
              variables:{
                id: this.routeParam,
                resource_id:this.resource_id,
                from_time:this.resourcescheduleById.from_time,
                to_time:this.resourcescheduleById.to_time,
                break_from_time:this.resourcescheduleById.break_from_time,
                break_to_time:this.resourcescheduleById.break_to_time,
                outlet_id:this.resourceById.outlet_id*1,
                off_day:this.resourcescheduleById.off_day
              },
            }
          )
          .catch(error => {
            console.log(error)
          })
          .then(result => {
            if(result && result.data.updateResourceSchedule != null){ 
              console.log("Update successfully")
              this.alert = true
              this.alert_type='success'
              this.message = 'Resource schedule updated successfully'
              window.scrollTo(0,0)
            } else {
              console.log("Update failed")
            }
          });
        }
      },
      allowedStep: m => m % 5 === 0,
      deleteStaffRoster() {
        const DELETE_SCHEDULE_QUERY = gql`
          mutation deleteResourceSchedule($id:ID!) {
              deleteResourceSchedule(id:$id)  {
                outlet_id
              }
          }
        `
        this.$apollo.mutate(
          { 
            mutation: DELETE_SCHEDULE_QUERY, 
            variables:{
              id: this.routeParam,
            },
          }
        ).then(result => {
          if(result.data.deleteResourceSchedule != null){ 
            console.log("Delete successfully")
            this.$router.push({name: 'Resource', params:{alert:true, alert_type:'success', message:'Resource schedule deleted successfully'}, query:{'tab':'schedule'}})
          } else {
            console.log("Delete failed")
          }
        });
      }
    },
    created(){
      this.$apollo.query({ query: queries.GET_ALL_PERMISSIONS_QUERY }).then(async result => {
        this.getAllPermissions = await result.data.getAllPermissions;
        this.$apollo.query(
          {
            query: queries.GET_STAFF_ROLES,
            variables: {
              staffId: this.$session.get("user_id") * 1
            }
          }
        ).then(async result => {
          this.getStaffRoles = await result.data.getStaffRoles;
          this.allPermissionsName = [];
          this.getStaffRoles.forEach(role => {
            role.role.RolePermission.forEach((item) => {
              const name = item.permission.name;
              this.allPermissionsName.push(name);
            });
          })
          this.$session.set('staffPermission', this.$generalFunctions.generatePermissionArray(this.getAllPermissions, this.allPermissionsName));
          this.staffPermission = await this.$generalFunctions.generatePermissionArray(this.getAllPermissions, this.allPermissionsName)
        })
      })
    }
  }
</script>
