<template>
  <v-app>
    <SideMenu :alert="alert" :alert_type="alert_type" :message="message" submenuPage="Staff">
      <v-alert
        :type="alert_type || 'info'"
        dismissible
        v-model="alert"
      >{{message}}</v-alert>
      <Form>
        <template v-slot:tabs>
          <v-tabs
            v-model="tab"
          >
            <v-tabs-slider></v-tabs-slider>
            <v-tab href="#details">Details</v-tab>
            <v-tab href="#outlet">Outlet</v-tab>
            <v-tab href="#service">Service</v-tab>
          </v-tabs>
        </template>
        <template v-slot:form>
          <v-tabs-items v-model="tab">
            <v-tab-item value="details">
              <v-form
                ref="form"
                lazy-validation
              >

                <v-text-field
                  v-model="staffById.fullname"
                  :counter="191"
                  :rules="nameRules.concat(characterRules)"
                  label="Full Name"
                  required
                ></v-text-field>

                <v-text-field
                  v-model="staffById.email"
                  :counter="191"
                  label="Email"
                  disabled
                ></v-text-field>

                <v-text-field
                  v-model="staffById.password"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showPassword ? 'text' : 'password'"
                  name="input-10-1"
                  hint="At least 8 characters"
                  counter
                  @click:append="showPassword = !showPassword"
                  :rules="passwordRules"
                  label="Password"
                ></v-text-field>

                <v-text-field
                  v-model="staffById.designation"
                  :counter="191"
                  :rules="characterRules"
                  label="Designation"
                ></v-text-field>

                <v-text-field
                  v-model="staffById.contact"
                  label="Phone Number"
                ></v-text-field>

                <v-select
                  v-model="staffById.status"
                  :items="status"
                  item-value="value"
                  item-text="label"
                  label="Status"
                ></v-select>

                <!-- <v-checkbox
                  v-model="staffById.admin"
                  label="Admin"
                ></v-checkbox> -->

                <p class="p-0 m-0">Roles</p>
                <v-row>
                  <v-col cols="4" class="py-1" v-for="role of getRoles" :key="role.id">
                    <v-checkbox 
                      v-model="staffRoles"
                      :label = "role.name"
                      :value = "role.id"
                      :hint = "role.description"
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-form>
            </v-tab-item>
            <v-tab-item value="outlet">
              <v-form
                ref="form2"
                lazy-validation
              >
                <v-checkbox
                  v-model="staff_outlet"
                  v-for="outlet in outlets"
                  :key="outlet.id"
                  :label="outlet.name"
                  :value="outlet.id"
                ></v-checkbox>
              </v-form>
            </v-tab-item>
            <v-tab-item value="service">
              <v-form
                ref="form3"
                lazy-validation
              >
              <v-row class="align-items-baseline">
                <v-col class="py-0" cols="4">
                  General Commission: 
                </v-col>
                <v-col cols="2" class="py-0">
                  <v-select
                    v-model="generalCommission.type"
                    :items="[{name:'fixed',label:'fixed'},{name:'percentage',label:'%'}]"
                    label="Type"
                    item-text="label"
                    item-value="name"
                    hide-details
                    height="min-content"
                  ></v-select>
                </v-col>
                <v-col cols="3" class="py-0">
                  <v-text-field
                    v-model="generalCommission.amount"
                    label="Amount"
                    :rules="amountRules"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row v-for="service in services" class="align-items-baseline"
                    :key="service.id">
                <v-col cols="3" class="py-0">
                  <v-checkbox
                    v-model="staff_service"
                    :label="service.name"
                    :value="service.id"
                  ></v-checkbox>
                </v-col>
                <v-col cols="3" class="py-0" v-if="checkServiceSelected(service.id)">
                  <v-checkbox
                    v-model="commissions"
                    :value="service.id"
                    label="Custom Commission"
                    @change="updateCustomCommission(service.id, $event)"
                  ></v-checkbox>
                </v-col>
                <v-col cols="2" class="py-0" v-if="checkCommissionSelected(service.id)">
                  <v-select
                    :items="[{name:'fixed',label:'fixed'},{name:'percentage',label:'%'}]"
                    label="Type"
                    item-text="label"
                    item-value="name"
                    value="fixed"
                    hide-details
                    height="min-content"
                    v-model="getCommission(service.id).type"
                    @change="updateCustomCommissionType(service.id, $event)"
                  ></v-select>
                </v-col>
                <v-col cols="3" class="py-0" v-if="checkCommissionSelected(service.id)">
                  <v-text-field
                    label="Amount"
                    v-model="getCommission(service.id).commission"
                    @change="updateCustomCommissionAmount(service.id, $event)"
                    :rules="amountRules"
                  ></v-text-field>
                </v-col>
              </v-row>
              </v-form>
            </v-tab-item>
          </v-tabs-items>
        </template>
        <template v-slot:buttons>
          <v-btn
            class="mr-4"
            text
            @click="$router.push({ path: '/staffs' })"
          >
            Back to list
          </v-btn>
          <v-dialog
              max-width="500"
              v-if="staffPermission.deleteStaff"
          >
              <template v-slot:activator="{ on, attrs }">
              <v-btn
                  color="error"
                  v-bind="attrs"
                  v-on="on"
                  class="mr-4" 
              >Delete</v-btn>
              </template>
              <template v-slot:default="dialog">
              <v-card>
                  <v-card-title>
                      Are you sure to delete {{ staffById.fullname }}?
                  </v-card-title>
                  <v-card-actions class="justify-end">
                  <v-btn
                      text
                      @click="dialog.value = false"
                  >Close</v-btn>
                  <v-btn color="error" @click="deleteStaff();dialog.value = false">
                      Delete
                  </v-btn>
                  </v-card-actions>
              </v-card>
              </template>
          </v-dialog>
          <v-btn
            color="success"
            class="mr-4"
            @click="validate"
            v-if="staffPermission.updateStaff"
          >
            Update
          </v-btn>
        </template>
      </Form>
    </SideMenu>
    <template>
    <v-row justify="center">
        <v-dialog
          v-model="alertDialog"
          max-width="500"
          @click:outside="alertDialog = false"
        >
          <v-card>
            <v-card-title class="text-h6">This staff has upcoming booking(s) in the outlet. Please reschedule the bookings before removing the staff from outlet.</v-card-title>
            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              @click="alertDialog = false"
            >
              Ok
            </v-btn>
          </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
    <template>
      <v-row justify="center">
          <v-dialog
            v-model="rosterAlertDialog"
            max-width="500"
            persistent
          >
            <v-card>
              <v-card-title class="text-h6">This staff has rosters on various outlet(s).<br>Please choose the outlet(s) you'd like to confirm for deletion.</v-card-title>
              <v-card-text>
                <v-container fluid>
                  <v-checkbox v-model="selectAllOutlets" :label="'All'" @change="selectOutlets"></v-checkbox>
                  <v-checkbox
                    v-for="outlet in removedOutlets"
                    v-model="confirmDeleteOutlets"
                    :value="outlet.id"
                    :key="outlet.id"
                    :label="outlet.name"
                    @change="detectAllOrNot"
                  ></v-checkbox>
                </v-container>
              </v-card-text>
              <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                @click="deleteOutlets"
              >
                Ok
              </v-btn>
            </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
    </template>
  </v-app>
</template>

<script>
import SideMenu from '/src/components/SideMenu'
import Form from '/src/components/Form'
import gql from 'graphql-tag'
import * as queries from '@/graphql/gqlQueries.js';
  const GET_USER_QUERY = gql`
      query Staff($id: ID!) {
        staffById(id: $id) {
            fullname
            designation
            contact
            email
            created_at
            status
            admin
            supervisor
            commissionAmount
            commissionType
            staff_outlet{
              outlet_id
            }
            staff_service{
              service_id
            }
        }
      }
  `
  const GET_USER_COMMISSION = gql`
      query getStaffCommissionByStaffId($staff_id: Int!) {
        getStaffCommissionByStaffId(staff_id: $staff_id) {
          id
          staff_id
          service_id
          commission
          type
        }
      }
  `
  const GET_ALL_OUTLETS_QUERY = gql`
      query Outlets {
        outlets {
          id
          name
          created_at
        }
      }
  `
  const GET_ALL_SERVICES_QUERY = gql`
      query Services {
        services {
          id
          name
          created_at
          color
          service_type{
            name
          }
        }
      }
  `
  const GET_ALL_ROLES_QUERY = gql`
        query getRoles {
            getRoles {
            id
            name
            description
          }
        }
    `
  export default {
    name: 'Staff',

    components:{
      SideMenu,
      Form
    },

    data () {
      // var staffPermission = this.$session.get("staffPermission")
      return {
        staffById: {
          fullname: '',
          designation: '',
          contact: '',
          email: '',
          password: '',
          status: 'pending',
          outlets: [],
          services: [],
          commissions:[],
          roles:[]
        },
        amountRules: [
          v => !!v || 'Amount is required',
          v => /^\d+(\.\d+)?$/.test(v) || 'Amount must be a number'
        ],
        showPassword: false,
        
        nameRules: [
          v => !!v || 'Name is required',
        ],
        characterRules: [
          v => (v.length <= 191) || 'Field must be less than or equal to 191 characters',
        ],
        passwordRules: [
          v => ((v || '12345678').length >= 8) || 'Password need at least 8 characters',
        ],
        status: [
          {value:'pending', label: 'Pending'},
          {value:'active', label: 'Active'},
          {value:'suspended', label: 'Suspended'}
        ],
        routeParam: this.$route.params.id,
        alert: false,
        alert_type: "success",
        message: "",

        staff_outlet:[],
        outlets: [],
        staff_service:[],
        services: [],
        tab: null,
        getRoles: [],
        getStaffRoles: [],
        staffRoles:[],
        getAllPermissions: [],
        allPermissionsName: [],
        staffPermission: {},
        generalCommission:{
          type: 'fixed',
          amount: 0
        },
        commissions: [],
        customCommission:[],
        alertDialog: false,
        rosterAlertDialog: false,
        removedOutlets: [],
        confirmDeleteOutlets: [],
        selectAllOutlets: true,
      }
    },
    apollo: {
      staffById: {
        query: GET_USER_QUERY,
        fetchPolicy: 'network-only',
        pollInterval: 300,
        variables() {
          return{
            id: this.routeParam
          }
        },
        result() {
          var outlets = []
          var services = []
          for(let i=0; i<this.staffById.staff_outlet.length; i++){
            outlets.push(this.staffById.staff_outlet[i].outlet_id.toString())
          }
          for(let i=0; i<this.staffById.staff_service.length; i++){
            services.push(this.staffById.staff_service[i].service_id.toString())
          }
          this.staff_outlet = outlets
          this.staff_service = services
          const type = this.staffById.commissionType
          this.generalCommission.type = type
          this.generalCommission.amount = this.staffById.commissionAmount * 1
          this.$apollo.query({
            query: queries.GET_STAFF_ROLES,
            variables: {
              staffId: this.routeParam * 1,
            },
          }).then(result=>{
            var data = result.data.getStaffRoles
            if(data.length > 0){
              data.forEach(r => {
                this.staffRoles.push(r.role.id)
              })
            }
            console.log("data",data)
          })
        }
      },
      getStaffCommissionByStaffId:{
        query: GET_USER_COMMISSION,
        fetchPolicy: 'network-only',
          variables() {
            return{
              staff_id: this.routeParam * 1
            }
          },
          result() {
            this.getStaffCommissionByStaffId.forEach(c=>{
              if(c.type != this.generalCommission.type.toLowerCase() || c.commission != this.generalCommission.amount ){
                this.commissions.push(c.service_id.toString())
              }
            })
            var customCommissionList = [...this.getStaffCommissionByStaffId ]
            this.customCommission = customCommissionList
          }
      },
      getRoles: {
            query: GET_ALL_ROLES_QUERY,
            pollInterval: 300,
            fetchPolicy: 'network-only',
      },
      outlets: {
        query: GET_ALL_OUTLETS_QUERY,
      },
      services: {
        query: GET_ALL_SERVICES_QUERY,
      },
    },
    methods: {
      async validate () {
        // Previous staff_outlet IDs
        const previousStaffOutletIds = this.staffById.staff_outlet.map(outlet => outlet.outlet_id);
        // Find the removed outlets by comparing previous and new IDs
        const removedOutletIds = previousStaffOutletIds.filter(id => !this.staff_outlet.includes(id.toString()));
        // Previous services IDs
         const previousStaffServices = this.staffById.staff_service.map(outlet => outlet.service_id);
        // Find the removed outlets by comparing previous and new IDs
        const removedServiceIds = previousStaffServices.filter(id => !this.staff_service.includes(id.toString()));

        // Declare variables to store query results
        let upComingBookingsByOutlets = [];
        let upComingBookingsByServices = [];
        let staffRosterList = [];
        if (removedOutletIds && removedOutletIds.length > 0) {
          const outletQueryResult = await this.$apollo.query({
            query: queries.GET_UPCOMING_BOOKINGS,
            variables: {
              outletIds: removedOutletIds ?? null,
              staffId: this.routeParam * 1,
            },
          });
          upComingBookingsByOutlets = outletQueryResult.data.getUpcomingBookings.filter(b => b.status == "booked");

          for (let i = 0; i < removedOutletIds.length; i++) {
            const outlet_id = removedOutletIds[i];
            const staffRosterByOutlet = await this.$apollo.query({
              query: queries.GET_STAFF_ROSTER_QUERY,
              variables: {
                outlet_id: outlet_id * 1,
                staff_id: this.routeParam * 1,
              }
            })
            if(staffRosterByOutlet.data.staffroster && staffRosterByOutlet.data.staffroster.length > 0){
              const outlet = {
                id: outlet_id * 1,
                name: this.outlets.find(o => o.id * 1 == outlet_id * 1).name
              }
              staffRosterList.push(outlet)
            }
          }
        }

        if (removedServiceIds && removedServiceIds.length > 0) {
          const serviceQueryResult = await this.$apollo.query({
            query: queries.GET_UPCOMING_BOOKINGS,
            variables: {
              serviceIds: removedServiceIds ?? null,
              staffId: this.routeParam * 1,
            },
          });
          upComingBookingsByServices = serviceQueryResult.data.getUpcomingBookings.filter(b => b.status == "booked");
        }

        const commissionList = [];

        this.staff_service.forEach(serviceId => {
          const customCommission = this.customCommission.find(item => item.service_id == serviceId);

          if (customCommission) {
            commissionList.push(customCommission);
          } else {
            const generalCommission = {
              service_id: serviceId,
              type: this.generalCommission.type,
              commission: this.generalCommission.amount
            };
            commissionList.push(generalCommission);
          }
        });
        var validate = this.$refs.form.validate()
        if(!validate){
          window.scrollTo(0,0);
        }
        var passwordHash = require('password-hash')
        if(validate){
          const UPDATE_USER_QUERY = gql`
            mutation updateStaff($id:ID!, $fullname:String, $designation: String, $contact:String, $status:String, $admin:Boolean, $supervisor:Boolean, $commissionType: CommissionType, $commissionAmount: Float) {
                updateStaff(id:$id, fullname:$fullname, designation: $designation, contact: $contact, status: $status, admin:$admin, supervisor:$supervisor,commissionType:$commissionType,commissionAmount:$commissionAmount)  {
                  id
                  fullname
                }
            }
          `

          this.$apollo.mutate(
            { 
              mutation: UPDATE_USER_QUERY, 
              variables:{
                id: this.routeParam,
                designation: this.staffById.designation,
                contact: this.staffById.contact,
                fullname: this.staffById.fullname,
                status: this.staffById.status,
                admin: this.staffById.admin,
                supervisor: this.staffById.supervisor,
                commissionAmount: this.generalCommission.amount * 1,
                commissionType: this.generalCommission.type ? this.generalCommission.type.toLowerCase() : 'fixed',
              },
            }
          ).then(result => {
            if(result.data.updateStaff != null){ 
              var staff_id = result.data.updateStaff.id*1
              console.log("Update successfully")
              const DELETE_STAFF_OUTLET_QUERY = gql`
                mutation deleteStaffOutlet($staff_id:Int!) {
                    deleteStaffOutlet(staff_id:$staff_id)  {
                      staff_id
                    }
                }
              `
              const CREATE_STAFF_OUTLET_QUERY = gql`
                mutation createStaffOutlet($staff_id:Int!, $outlet_id:Int!) {
                    createStaffOutlet(staff_id:$staff_id, outlet_id:$outlet_id)  {
                      staff_id
                    }
                }
              `
              const DELETE_STAFF_SERVICE_QUERY = gql`
                mutation deleteStaffService($staff_id:Int!) {
                    deleteStaffService(staff_id:$staff_id)  {
                      staff_id
                    }
                }
              `
              const CREATE_STAFF_SERVICE_QUERY = gql`
                mutation createStaffService($staff_id:Int!, $service_id:Int!) {
                    createStaffService(staff_id:$staff_id, service_id:$service_id)  {
                      staff_id
                    }
                }
              `
              const DELETE_STAFF_ROLES_QUERY = gql`
                mutation deleteStaffRole($staffId:Int!) {
                  deleteStaffRole(staffId:$staffId)  {
                      staffId
                    }
                }
              `
              const CREATE_STAFF_ROLES_QUERY = gql`
                mutation createStaffRole($staffId:Int!, $roleId:Int!) {
                  createStaffRole(staffId:$staffId, roleId:$roleId)  {
                      roleId
                      staffId
                    }
                }
              `
              const DELETE_STAFF_COMMISSION = gql`
                mutation deleteStaffCommission($staff_id:Int!) {
                  deleteStaffCommission(staff_id:$staff_id)  {
                      success
                    }
                }
              `
              const CREATE_STAFF_COMMISSION = gql`
                mutation CreateStaffCommission($staff_id: Int!, $service_id: Int!, $commission: Float!, $type: CommissionType!) {
                  createStaffCommission(staff_id: $staff_id, service_id: $service_id, commission: $commission, type: $type) {
                    staff_id
                    service_id
                    commission
                    type
                  }
                }
              `;
              this.$apollo.mutate(
                { 
                  mutation: DELETE_STAFF_COMMISSION, 
                  variables:{
                    staff_id: result.data.updateStaff.id*1
                  },
                }
              )
              .then(()=> {
                for(let i = 0; i < commissionList.length; i++){
                  this.$apollo.mutate(
                    { 
                      mutation: CREATE_STAFF_COMMISSION, 
                      variables:{
                        staff_id: staff_id,
                        service_id: commissionList[i].service_id*1 ,
                        commission: commissionList[i].commission * 1 ,
                        type: commissionList[i].type ? commissionList[i].type.toLowerCase() : 'fixed',
                      },
                    }
                  ).catch(error=>{
                    console.log(JSON.stringify(error, null, 2));
                  })
                }
              }).catch(error=>{
                    console.log(JSON.stringify(error, null, 2));
              })

              this.$apollo.mutate(
                { 
                  mutation: DELETE_STAFF_ROLES_QUERY, 
                  variables:{
                    staffId: result.data.updateStaff.id*1
                  },
                }
              )
              .then((result)=> {
                console.log(result)
                for(let i = 0; i < this.staffRoles.length; i++){
                  this.$apollo.mutate(
                    { 
                      mutation: CREATE_STAFF_ROLES_QUERY, 
                      variables:{
                        staffId: this.routeParam*1,
                        roleId: this.staffRoles[i]*1
                      },
                    }
                  ).catch(error => 
                {
                  console.log(JSON.stringify(error, null, 2));
                })
                }
              })
              
              if(upComingBookingsByOutlets && upComingBookingsByOutlets.length > 0){
                this.alertDialog = true
                this.$apollo.queries.staffById.refetch()
                return
              }else if(staffRosterList &&  staffRosterList.length == 0){
                this.$apollo.mutate(
                  { 
                    mutation: DELETE_STAFF_OUTLET_QUERY, 
                    variables:{
                      staff_id: result.data.updateStaff.id*1
                    },
                  }
                )
                .then(()=> {
                  for(let i = 0; i < this.staff_outlet.length; i++){
                    this.$apollo.mutate(
                      { 
                        mutation: CREATE_STAFF_OUTLET_QUERY, 
                        variables:{
                          staff_id: staff_id,
                          outlet_id: this.staff_outlet[i]*1
                        },
                      }
                    )
                  }
                })
              }

              if(upComingBookingsByServices && upComingBookingsByServices.length > 0){
                this.alertDialog = true
                this.$apollo.queries.staffById.refetch()
                return
              }else{
                this.$apollo.mutate(
                  { 
                    mutation: DELETE_STAFF_SERVICE_QUERY, 
                    variables:{
                      staff_id: result.data.updateStaff.id*1
                    },
                  }
                )
                .then(()=>{
                  for(let i = 0; i < this.staff_service.length; i++){
                    this.$apollo.mutate(
                      { 
                        mutation: CREATE_STAFF_SERVICE_QUERY, 
                        variables:{
                          staff_id: staff_id,
                          service_id: this.staff_service[i]*1
                        },
                      }
                    )
                  }
                })
              }

              if(staffRosterList && staffRosterList.length > 0){
                this.removedOutlets = staffRosterList
                this.confirmDeleteOutlets = staffRosterList.map(outlet => outlet.id);
                this.rosterAlertDialog = true
                return
              }
              if(!this.staffById.password){
                this.$apollo.queries.staffById.refetch()
                this.alert = true
                this.alert_type='success'
                this.message = result.data.updateStaff.fullname+' updated successfully'
                window.scrollTo(0,0)
              }
            } else {
              console.log("Update failed")
            }
          });

          if(this.staffById.password){
            const CHANGE_PASSWORD_QUERY = gql`
              mutation changePassword($id:ID!, $password:String!) {
                  updateStaff(id:$id, password:$password)  {
                    fullname
                  }
              }
            `

            this.$apollo.mutate(
              { 
                mutation: CHANGE_PASSWORD_QUERY, 
                variables:{
                  id: this.routeParam,
                  password: passwordHash.generate(this.staffById.password)
                },
              }
            ).then(result => {
              if(result.data.updateStaff != null){ 
                console.log("Update password successfully")
                this.alert = true
                this.alert_type='success'
                this.message = result.data.updateStaff.fullname+' updated successfully'
                window.scrollTo(0,0)
              } else {
                console.log("Update password failed")
                this.alert = true
                this.alert_type='error'
                this.message = result.data.updateStaff.fullname+' Update password failed'
                window.scrollTo(0,0)
              }
            });
          }
        }
      },
      deleteStaff() {
        const DELETE_USER_QUERY = gql`
          mutation deleteStaff($id:ID!) {
              deleteStaff(id:$id)  {
                fullname
              }
          }
        `
        this.$apollo.mutate(
          { 
            mutation: DELETE_USER_QUERY, 
            variables:{
              id: this.routeParam,
            },
          }
        ).then(result => {
          if(result.data.deleteStaff != null){ 
            console.log("Delete successfully")
            this.$router.push({name: 'Staff', params:{alert:true, alert_type:'success', message:result.data.deleteStaff.fullname+' deleted successfully'}})
          } else {
            console.log("Delete failed")
          }
        });
      },
      updateCustomCommission(serviceId, isChecked) {
        if (isChecked.includes(serviceId)) {
          this.customCommission.push({
            service_id: serviceId,
            type: null,
            commission: 0
          });
        } else {
          const index = this.customCommission.findIndex(
            item => item.service_id == serviceId
          );
          if (index !== -1) {
            this.customCommission.splice(index, 1);
          }
        }
      },
      updateCustomCommissionType(serviceId, value) {
        const customCommission = this.customCommission.find(
          item => item.service_id === serviceId
        );
        if (customCommission) {
          customCommission.type = value;
        }
      },
      updateCustomCommissionAmount(serviceId, value) {
        const customCommission = this.customCommission.find(
          item => item.service_id === serviceId
        );
        if (customCommission) {
          const parsedAmount = parseFloat(value);
          const isValidAmount = !isNaN(parsedAmount) && isFinite(parsedAmount);
          if(isValidAmount)customCommission.commission = parseFloat(value);
        }
      },
      getCommission(serviceId) {
        return this.getStaffCommissionByStaffId.find(commission => commission.service_id == serviceId) || { type: 'fixed', commission: 0 }
      },
      selectOutlets() {
      if (this.selectAllOutlets) {
        this.confirmDeleteOutlets = this.removedOutlets.map(o => o.id);
      } else {
        this.confirmDeleteOutlets = [];
      }
      },
      detectAllOrNot() {
        if (this.confirmDeleteOutlets.length === this.removedOutlets.length) {
          this.selectAllOutlets = true
        } else {
          this.selectAllOutlets = false
        }
      },
      async deleteOutlets(){
        if (this.confirmDeleteOutlets && this.confirmDeleteOutlets.length > 0) {
        this.confirmDeleteOutlets.forEach(o => {
          this.$apollo
            .mutate({
              mutation: queries.DELETE_STAFF_ROSTER_BY_OUTLET_ID,
              variables: {
                staff_id: parseInt(this.routeParam),
                outlet_id: parseInt(o * 1)
              },
            })
            .then(() => {
              this.$apollo.mutate({
                mutation: queries.DELETE_STAFF_OUTLET,
                variables: {
                  staff_id: parseInt(this.routeParam),
                  outlet_id: parseInt(o* 1)
                },
              }).then((error) => {
                this.$apollo.queries.staffById.refetch()
                console.log(JSON.stringify(error, null, 2));
                this.alert = true
                this.alert_type='success'
                this.message = this.staffById.fullname+' updated successfully'
              }).catch(error => {
                console.log(JSON.stringify(error, null, 2));
                this.alert = true
                this.alert_type='error'
                this.message = this.staffById.fullname+' updated fail'
              });
            })
            .catch(error => {
              console.log(JSON.stringify(error, null, 2));
              this.alert = true
              this.alert_type='error'
              this.message = this.staffById.fullname+' updated fail'
            });
        });

      }
        this.rosterAlertDialog = false
      }
    },
    computed:{
      checkServiceSelected() {
        return function(serviceId) {
          return this.staff_service.includes(serviceId);
        };
      },
      checkCommissionSelected() {
        return function(serviceId) {
          if(!this.commissions || this.commissions.length == 0) return false
          return this.commissions.includes(serviceId) && this.staff_service.includes(serviceId);
        };
      }
    },
    created(){
      this.$apollo.query({ query: queries.GET_ALL_PERMISSIONS_QUERY }).then(async result => {
        this.getAllPermissions = await result.data.getAllPermissions;
        this.$apollo.query(
          {
            query: queries.GET_STAFF_ROLES,
            variables: {
              staffId: this.$session.get("user_id") * 1
            }
          }
        ).then(async result => {
          this.getStaffRoles = await result.data.getStaffRoles;
          this.allPermissionsName = [];
          this.getStaffRoles.forEach(role => {
            role.role.RolePermission.forEach((item) => {
              const name = item.permission.name;
              this.allPermissionsName.push(name);
            });
          })
          this.$session.set('staffPermission', this.$generalFunctions.generatePermissionArray(this.getAllPermissions, this.allPermissionsName));
          this.staffPermission = await this.$generalFunctions.generatePermissionArray(this.getAllPermissions, this.allPermissionsName)
        })
      })
    }
  }
</script>
