<template>
  <SideMenu submenuPage="Dashboard"/>
</template>

<script>
import SideMenu from '/src/components/SideMenu';
  export default {
    name: 'Dashboard',

    components:{
      SideMenu
    },
  }
</script>
