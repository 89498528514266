<template>
  <v-app>
    <SideMenu submenuPage="Resource Type" submenuGroup="Service Setup">
      <Form>
        <template v-slot:tabs>
          <v-tabs
            v-model="tab"
          >
            <v-tabs-slider></v-tabs-slider>
            <v-tab href="#details">Details</v-tab>
            <v-tab href="#price">Price</v-tab>
          </v-tabs>
        </template>
        <template v-slot:form>
          <v-tabs-items v-model="tab">
            <v-tab-item value="details">
              <v-form
                ref="form"
                lazy-validation
              >
                <v-text-field
                  v-model="resourceTypeById.name"
                  :counter="191"
                  :rules="nameRules.concat(characterRules)"
                  label="Name"
                  required
                ></v-text-field>

                <v-text-field
                  v-model="resourceTypeById.description"
                  :counter="191"
                  :rules="characterRules"
                  label="Description"
                ></v-text-field>

                <v-text-field
                  v-model="resourceTypeById.min_pax"
                  label="Min Pax"
                  type="number"
                  class="v-text-field-number"
                  min="0"
                  step="1"
                  oninput="validity.valid||(value='');"
                  :rules="[() => !!resourceTypeById.min_pax || 'Min Pax is required']"
                ></v-text-field>

                <v-text-field
                  v-model="resourceTypeById.max_pax"
                  label="Max Pax"
                  type="number"
                  class="v-text-field-number"
                  min="0"
                  step="1"
                  oninput="validity.valid||(value='');"
                ></v-text-field>

                <v-text-field
                  v-model="resourceTypeById.duration"
                  label="Duration (minutes)"
                  type="number"
                  class="v-text-field-number"
                  min="0"
                  step="1"
                  oninput="validity.valid||(value='');"
                  :rules="[() => !!resourceTypeById.duration || 'Duration is required']"
                ></v-text-field>

                <!-- <v-text-field
                  v-model="resourceTypeById.padding_time"
                  label="Padding Time (minutes)"
                  type="number"
                  class="v-text-field-number"
                  min="0"
                  step="1"
                  oninput="validity.valid||(value='');"
                ></v-text-field> -->

                <v-color-picker
                  dot-size="25"
                  hide-mode-switch
                  mode="hexa"
                  swatches-max-height="200"
                  label="Color"
                  v-model="resourceTypeById.color"
                ></v-color-picker>
              </v-form>
            </v-tab-item>
            <v-tab-item value="price">
              <v-form
                ref="form1"
                lazy-validation
              >
                <v-text-field
                  v-model="resourceTypeById.price"
                  label="Default Price"
                  type="number"
                  class="v-text-field-number"
                  step="0.01"
                  min="0"
                  oninput="validity.valid||(value='');"
                ></v-text-field>
                <div>
                  Custom Daily Price
                </div>
                <table class="roster">
                  <tr>
                    <td>
                      Day
                    </td>
                    <td 
                      v-for="date in dates"
                      :key="date"
                    >
                      {{date}}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Price
                    </td>
                    <td
                      v-for="(price, index) in prices"
                      :key="index"
                    >
                      <v-text-field
                        v-model="price.amount"
                        label="Price"
                        type="number"
                        step="0.01"
                        min="0"
                      ></v-text-field>
                    </td>
                  </tr>
                </table>
                <br/>
                <v-btn
                  color="primary"
                  class="mr-4"
                  @click="addCustomPrice"
                >
                  + New Custom Price
                </v-btn>
                <br/>
                <table>
                  <tr
                    v-for="(custom_price, index) in custom_prices"
                    :key="index"
                  >
                    <td>
                      <v-menu
                        v-model="custom_price.datemenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="custom_price.date"
                            prepend-icon="mdi-calendar"
                            placeholder="Date"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="custom_price.date"
                          @input="custom_price.datemenu = false"
                        ></v-date-picker>
                      </v-menu>
                    </td>
                    <td>
                      <v-text-field
                        v-model="custom_price.amount"
                        label="Price"
                        type="number"
                        step="0.01"
                        min="0"
                      ></v-text-field>
                    </td>
                    <td>
                      <v-btn
                        color="error"
                        @click="removeCustomPrice(index)"
                      >
                        <v-icon dark left>mdi-minus-circle</v-icon>
                        Remove
                      </v-btn>
                    </td>
                  </tr>
                </table>
              </v-form>
            </v-tab-item>
          </v-tabs-items>
        </template>
        <template v-slot:buttons>
          <v-btn
            class="mr-4"
            text
            @click="$router.push({ path: '/type/resources' })"
          >
            Back to list
          </v-btn>
          <v-btn
            color="success"
            class="mr-4"
            @click="validate"
          >
            Create
          </v-btn>
        </template>
      </Form>
    </SideMenu>
  </v-app>
</template>

<script>
import SideMenu from '/src/components/SideMenu'
import Form from '/src/components/Form'
import gql from 'graphql-tag'
  export default {
    name: 'Resource',

    components:{
      SideMenu,
      Form
    },

    data () {
      
      return {
        resourceTypeById: {
          name: '',
          description: '',
          color: '',
          price:0
        },
        
        nameRules: [
          v => !!v || 'Name is required',
        ],
        characterRules: [
          v => (v.length <= 191) || 'Field must be less than or equal to 191 characters',
        ],
        tab: null,

        dates: {1:"Monday",2:"Tuesday",3:"Wednesday",4:"Thursday",5:"Friday",6:"Saturday",7:"Sunday"},
        prices: [{amount:null},{amount:null},{amount:null},{amount:null},{amount:null},{amount:null},{amount:null}],
        custom_prices: [{datemenu:false, date:null, amount:null}]
      }
    },
    methods: {
      validate () {
        var validate = this.$refs.form.validate()
        if(!validate){
          window.scrollTo(0,0);
        }
        if(validate){
          const CREATE_RESOURCE_TYPE_QUERY = gql`
            mutation createResourceType($name: String!, $description:String, $price_type:String!, $price:Float, $min_pax:Int!, $max_pax:Int, $duration:Int!, $color:String, $padding_time: Int) {
                createResourceType(name:$name, description:$description, price_type:$price_type, price:$price, min_pax:$min_pax, max_pax:$max_pax, duration:$duration, color:$color, padding_time:$padding_time)  {
                  id
                  name
                }
            }
          `
          this.$apollo.mutate(
            { 
              mutation: CREATE_RESOURCE_TYPE_QUERY, 
              variables:{
                name: this.resourceTypeById.name,
                description: this.resourceTypeById.description,
                min_pax: this.resourceTypeById.min_pax*1,
                max_pax: this.resourceTypeById.max_pax*1,
                duration: this.resourceTypeById.duration*1,
                color: this.resourceTypeById.color,
                padding_time: this.resourceTypeById.padding_time*1,
                price: this.resourceTypeById.price*1,
                price_type: 'single'
              },
            }
          )
          .catch(error => {
            console.log(error)
          })
          .then(result => {
            if(result && result.data.createResourceType != null){ 
              console.log("Create successfully")
              const CREATE_CUSTOM_PRICE_QUERY = gql`
                mutation createCustomPrice($resource_type_id:Int, $service_id:Int, $day:Int, $date:String, $amount:Float, $description:String){
                  createCustomPrice(resource_type_id:$resource_type_id, service_id:$service_id, day:$day, date:$date, amount:$amount, description:$description){
                    id
                  }
                }
              `
              for(let i = 0; i < this.prices.length; i++){
                if(this.prices[i].amount != null){
                  this.$apollo.mutate(
                    { 
                      mutation: CREATE_CUSTOM_PRICE_QUERY, 
                      variables:{
                        resource_type_id: result.data.createResourceType.id*1,
                        day: i+1,
                        amount: this.prices[i].amount*1
                      },
                    }
                  )
                }
              }
              for(let i = 0; i < this.custom_prices.length; i++){
                if(this.custom_prices[i].amount != null && this.custom_prices[i].date != null){
                  this.$apollo.mutate(
                    { 
                      mutation: CREATE_CUSTOM_PRICE_QUERY, 
                      variables:{
                        resource_type_id: result.data.createResourceType.id*1,
                        date: this.custom_prices[i].date,
                        amount: this.custom_prices[i].amount*1
                      },
                    }
                  )
                }
              }
              this.$router.push({name: 'Resource Type', params:{alert:true, alert_type:'success', message:result.data.createResourceType.name+' created successfully'}})
            } else {
              console.log("Create failed")
            }
          });
        }
      },
      addCustomPrice() {
        this.custom_prices.push({datemenu:false, date:null, amount:null})
      },
      removeCustomPrice(index) {
        this.custom_prices.splice(index,1)
      }
    }
  }
</script>
