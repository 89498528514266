<template>
    <v-app>
        <SideMenu submenuPage="Dashboard">
            <div>
                <h1>404 - Page Not Found</h1>
                <p>The page you are looking for does not exist.</p>
            </div>
        </SideMenu>
    </v-app>
</template>
  
<script>
import SideMenu from '/src/components/SideMenu'
export default {
    name: 'NotFound',
    components: {
        SideMenu,
    },
};

</script>
  
<style scoped>
h1 {
    font-size: 24px;
    color: red;
    margin-bottom: 10px;
}

p {
    font-size: 18px;
    color: gray;
}
</style>
  