<template>
  <v-app>
    <SideMenu :alert="alert" :alert_type="alert_type" :message="message" submenuPage="Service Type" submenuGroup="Service Setup">
      <List>
        <template v-slot:action-button>
          <v-btn
            color="primary"
            class="mr-4"
            @click="ServiceTypeCreateForm"
            v-if="staffPermission.createServiceType"
          >
            + New Service Type
          </v-btn>
        </template>
        <template v-slot:tabs></template>
        <template v-slot:tab-items>
          <v-data-table
            :headers="headers"
            :items="serviceTypes"
            class="elevation-1"
            item-key="name"
          >
          <template v-slot:item="{ item }">
            <tr
              @click="ServiceTypeUpdateForm(item.id)"
              style="cursor:pointer"
            >
              <td>{{ item.name }}</td>
            </tr>
          </template>
          </v-data-table>
        </template>
      </List>
    </SideMenu>
  </v-app>
</template>

<script>
import SideMenu from '/src/components/SideMenu'
import List from '/src/components/List'
import * as queries from '@/graphql/gqlQueries.js';
import gql from 'graphql-tag'
  const GET_ALL_SERVICES_QUERY = gql`
      query ServiceTypes {
        serviceTypes {
          id
          name
          created_at
        }
      }
  `
  export default {
    name: 'Service',

    components:{
      SideMenu,
      List
    },

    data () {
      
      return {
        serviceTypes: [],
        alert: this.$route.params.alert,
        alert_type: this.$route.params.alert_type,
        message: this.$route.params.message,

        headers:[
          {
            text: 'Name',
            value: 'name',
            class: 'white--text'
          }
        ],
        getAllPermissions: [],
        allPermissionsName: [],
        staffPermission: {},
      }
    },
    apollo: {
      serviceTypes: {
        query: GET_ALL_SERVICES_QUERY,
        pollInterval: 300,
        async result(){
          const sortProperties = [
                    { property: 'id', direction: 'desc' },
                    { property: 'created_at', direction: 'desc' },
                ];

          // Use the multiPropertySort function to sort the commissionList
          this.serviceTypes = await this.$generalFunctions.multiPropertySort(this.serviceTypes, sortProperties);
        }
      },
    },
    methods: {
      ServiceTypeUpdateForm(id) {
        this.$router.push({ path: '/type/service/'+id })
      },
      ServiceTypeCreateForm() {
        this.$router.push({ path: '/create/type/service' })
      }
    },
    created(){
      this.$apollo.query({ query: queries.GET_ALL_PERMISSIONS_QUERY }).then(async result => {
        this.getAllPermissions = await result.data.getAllPermissions;
        this.$apollo.query(
          {
            query: queries.GET_STAFF_ROLES,
            variables: {
              staffId: this.$session.get("user_id") * 1
            }
          }
        ).then(async result => {
          this.getStaffRoles = await result.data.getStaffRoles;
          this.allPermissionsName = [];
          this.getStaffRoles.forEach(role => {
            role.role.RolePermission.forEach((item) => {
              const name = item.permission.name;
              this.allPermissionsName.push(name);
            });
          })
          this.$session.set('staffPermission', this.$generalFunctions.generatePermissionArray(this.getAllPermissions, this.allPermissionsName));
          this.staffPermission = await this.$generalFunctions.generatePermissionArray(this.getAllPermissions, this.allPermissionsName)
        })
      })
    }
  }
</script>
